#startseite {

    .palette {
        max-width: 1280px;
        margin-left: 30px;
        margin-right: 30px;
        position: relative;

        .item {
            position: relative;
            @media screen and (max-width: 768px){
                width: 200px;
            }
            @media screen and (max-width: 576px){
                max-width: 130px;
            }

            img {
                mix-blend-mode: multiply;
                opacity: 0.7;
            }
            .title {
                font-family: 'Droid Serif';
                font-variant-caps: normal;
                font-weight: 800;
                font-size: 24px;
                margin-top: 0;
                margin-bottom: 5px;
            }
            a {
                font-family: 'Droid Serif';
                font-variant-caps: normal;
                font-weight: 400;
                font-size: 18px;
                margin-top: 0;
                margin-bottom: 5px;
                color: #777;
            }
        }
    }
    .awards {
        display: flex;
        justify-content: space-around;
        margin-bottom: 40px;
        @media screen and (max-width: 768px){
            flex-wrap: wrap;
        }

        img {
            height: 150px;
            @media screen and (max-width: 576px){
                margin-bottom: 10px;
            }
        }
    }
    .productslider-section {
        max-width: 1280px;
    }

}