.academy-sektion1 {
    padding-top: 20px !important;
    padding-bottom: 30px !important;

    &.even {
        padding-bottom: 200px !important;

        .left {
            flex-basis: 60% !important;
        }
        .right {
            flex-basis: 60% !important;
        }
    }
    &.odd {
        .left {
            flex-basis: 60% !important;
        }
        .right {
            flex-basis: 60% !important;
        }
    }
    .block {
        .title {
            font-family: 'Montserrat' !important;
            font-style: normal !important;
            text-transform: uppercase !important;
            font-weight: 800 !important;
            font-size: 28px !important;
            color: black !important;
            text-align: left !important;
            margin-bottom: 20px !important;
            hyphens: auto;
        }
    }
}