@charset "UTF-8";
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select {
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  margin: 0; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring, .cart.table-wrapper .actions-toolbar > .action:-moz-focusring, .action-gift:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

body {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

nav ul,
nav ol {
  list-style: none none; }

img {
  border: 0; }

img,
object,
video,
embed {
  height: auto;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

html {
  font-size: 62.5%;
  font-size-adjust: 100%; }

body {
  color: #333;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }

p {
  margin-top: 0; }

p {
  margin-bottom: 1rem; }

abbr[title] {
  border-bottom: 1px dotted #d1d1d1;
  cursor: help; }

b,
strong {
  font-weight: 700; }

em,
i {
  font-style: italic; }

mark {
  background: #f0f0f0;
  color: black; }

small,
.small {
  font-size: 12px; }

hr {
  border: 0;
  border-top: 1px solid #d1d1d1;
  margin-bottom: 20px;
  margin-top: 20px; }

sub,
sup {
  font-size: 71.42857%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dfn {
  font-style: italic; }

h1 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 0;
  margin-bottom: 2rem; }

h2 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

h3 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

h4 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h5 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h6 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  color: #333;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1; }

a,
.alink {
  color: #1979c3;
  text-decoration: none; }
  a:visited,
  .alink:visited {
    color: #1979c3;
    text-decoration: none; }
  a:hover,
  .alink:hover {
    color: #006bb4;
    text-decoration: underline; }
  a:active,
  .alink:active {
    color: #ff5501;
    text-decoration: underline; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 2.5rem; }
  ul > li,
  ol > li {
    margin-top: 0;
    margin-bottom: 1rem; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

dl {
  margin-bottom: 20px;
  margin-top: 0; }

dt {
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 0; }

dd {
  margin-bottom: 10px;
  margin-top: 0;
  margin-left: 0; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  background: #f0f0f0;
  color: #111111;
  padding: 2px 4px;
  font-size: 1.2rem;
  white-space: nowrap; }

kbd {
  background: #f0f0f0;
  color: #111111;
  padding: 2px 4px;
  font-size: 1.2rem; }

pre {
  background: #f0f0f0;
  border: 1px solid #d1d1d1;
  color: #111111;
  line-height: 1.42857;
  margin: 0 0 10px;
  padding: 10px;
  font-size: 1.2rem;
  display: block;
  word-wrap: break-word; }
  pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap; }

blockquote {
  border-left: 0 solid #d1d1d1;
  margin: 0 0 20px 40px;
  padding: 0;
  color: #333;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    color: #333;
    line-height: 1.42857;
    font-size: 1rem;
    display: block; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: "— "; }
  blockquote cite {
    font-style: normal; }

blockquote:before, blockquote:after {
  content: ''; }

q {
  quotes: none; }
  q:before, q:after {
    content: '';
    content: none; }

cite {
  font-style: normal; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%; }
  table th {
    text-align: left; }
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    vertical-align: top; }
  table > thead > tr > th,
  table > thead > tr > td {
    vertical-align: bottom; }
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    padding: 8px 10px; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  background-image: none;
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle; }
  button:focus, .cart.table-wrapper .actions-toolbar > .action:focus, .action-gift:focus, button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #333; }
  button:hover, .cart.table-wrapper .actions-toolbar > .action:hover, .action-gift:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }
  button.disabled, .cart.table-wrapper .actions-toolbar > .disabled.action, .disabled.action-gift, button[disabled], .cart.table-wrapper .actions-toolbar > .action[disabled], .action-gift[disabled],
  fieldset[disabled] button,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .action,
  fieldset[disabled] .action-gift {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner {
    border: 0;
    padding: 0; }

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  input[type="text"]:disabled,
  input[type="password"]:disabled,
  input[type="url"]:disabled,
  input[type="tel"]:disabled,
  input[type="search"]:disabled,
  input[type="number"]:disabled,
  input[type="datetime"]:disabled,
  input[type="email"]:disabled {
    opacity: 0.5; }
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="url"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="datetime"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder {
    color: #c2c2c2; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="url"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="datetime"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder {
    color: #c2c2c2; }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="url"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="datetime"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder {
    color: #c2c2c2; }

input[type="number"] {
  -moz-appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type="search"] {
  -webkit-appearance: none; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none; }

select {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 5px 10px 4px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  select:disabled {
    opacity: 0.5; }

select[multiple="multiple"] {
  height: auto;
  background-image: none; }

textarea {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: auto;
  line-height: 1.42857;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  resize: vertical; }
  textarea:disabled {
    opacity: 0.5; }
  textarea::-moz-placeholder {
    color: #c2c2c2; }
  textarea::-webkit-input-placeholder {
    color: #c2c2c2; }
  textarea:-ms-input-placeholder {
    color: #c2c2c2; }

input.mage-error,
select.mage-error,
textarea.mage-error {
  border-color: #ed8380; }

div.mage-error[generated] {
  color: #e02b27;
  font-size: 1.2rem; }

input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

form {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

address {
  font-style: normal; }

*:focus {
  box-shadow: none;
  outline: 0; }

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  box-shadow: 0 0 3px 1px #68a8e0; }

.actions-toolbar > .primary,
.actions-toolbar > .secondary {
  text-align: center;
  margin-bottom: 10px; }
  .actions-toolbar > .primary .action,
  .actions-toolbar > .secondary .action {
    margin-bottom: 10px; }
    .actions-toolbar > .primary .action:last-child,
    .actions-toolbar > .secondary .action:last-child {
      margin-bottom: 0; }
  .actions-toolbar > .primary:last-child,
  .actions-toolbar > .secondary:last-child {
    margin-bottom: 0; }

@media only screen and (min-width: 768px) {
  .actions-toolbar:before, .actions-toolbar:after {
    content: '';
    display: table; }
  .actions-toolbar:after {
    clear: both; }
  .actions-toolbar .primary {
    float: left; }
  .actions-toolbar .secondary {
    float: right; }
  .actions-toolbar .primary,
  .actions-toolbar .secondary {
    display: inline-block; }
    .actions-toolbar .primary a.action,
    .actions-toolbar .secondary a.action {
      display: inline-block; }
  .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .actions-toolbar > .secondary,
  .actions-toolbar > .primary {
    margin-bottom: 0; }
    .actions-toolbar > .secondary .action,
    .actions-toolbar > .primary .action {
      margin-bottom: 0;
      width: auto; } }

.breadcrumbs {
  margin: 0 0 20px; }
  .breadcrumbs .items {
    font-size: 1.2rem;
    color: #a3a3a3;
    margin: 0;
    padding: 0;
    list-style: none none; }
    .breadcrumbs .items > li {
      display: inline-block;
      vertical-align: top; }
  .breadcrumbs .item {
    margin: 0; }
  .breadcrumbs a {
    color: #333;
    text-decoration: none; }
    .breadcrumbs a:visited {
      color: #333;
      text-decoration: none; }
    .breadcrumbs a:hover {
      color: #333;
      text-decoration: underline; }
    .breadcrumbs a:active {
      color: #333;
      text-decoration: none; }
  .breadcrumbs strong {
    font-weight: 400; }
  .breadcrumbs .item:not(:last-child) {
    display: inline-block;
    text-decoration: none; }
    .breadcrumbs .item:not(:last-child):after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 24px;
      line-height: 18px;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }

a.action.primary, button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  border-radius: 3px; }

button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active {
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }

a.action.primary {
  display: inline-block;
  text-decoration: none; }
  a.action.primary:hover, a.action.primary:active, a.action.primary:focus {
    text-decoration: none; }

.action.primary {
  background-image: none;
  background: #1979c3;
  border: 1px solid #1979c3;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  padding: 7px 15px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle; }
  .action.primary:focus, .action.primary:active {
    background: #006bb4;
    border: 1px solid #006bb4;
    color: #fff; }
  .action.primary:hover {
    background: #006bb4;
    border: 1px solid #006bb4;
    color: #fff; }
  .action.primary.disabled, .action.primary[disabled],
  fieldset[disabled] .action.primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.abs-reset-list, .bundle-options-container .block-bundle-summary .bundle.items, .product-items, .prices-tier, .cart-container .checkout-methods-items, .opc-wrapper .opc, .opc-progress-bar, .checkout-agreements-items, .items, .block-wishlist-management .wishlist-select-items, .paypal-review .items-qty, .order-details-items .items-qty, .footer.content ul, .block-banners .banner-items,
.block-banners-inline .banner-items, .block-event .slider-panel .slider {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .abs-reset-list > li, .bundle-options-container .block-bundle-summary .bundle.items > li, .product-items > li, .prices-tier > li, .cart-container .checkout-methods-items > li, .opc-wrapper .opc > li, .opc-progress-bar > li, .checkout-agreements-items > li, .items > li, .block-wishlist-management .wishlist-select-items > li, .paypal-review .items-qty > li, .order-details-items .items-qty > li, .footer.content ul > li, .block-banners .banner-items > li, .block-banners-inline .banner-items > li, .block-event .slider-panel .slider > li {
    margin: 0; }

.abs-action-link-button, .abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist, .block-event .slider-panel .slider .item .action.event {
  background-image: none;
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px; }
  .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #333; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }
  .abs-action-link-button.disabled, .disabled.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .disabled.action, .product-info-main .disabled.action.tocompare,
  .product-options-bottom .disabled.action.tocompare, .product-info-main .disabled.action.towishlist,
  .product-options-bottom .disabled.action.towishlist,
  .block-bundle-summary .disabled.action.towishlist, .block-event .slider-panel .slider .item .disabled.action.event, .abs-action-link-button[disabled], .abs-action-addto-product[disabled], .bundle-options-container .block-bundle-summary .product-addto-links > .action[disabled], .product-info-main .action.tocompare[disabled],
  .product-options-bottom .action.tocompare[disabled], .product-info-main .action.towishlist[disabled],
  .product-options-bottom .action.towishlist[disabled],
  .block-bundle-summary .action.towishlist[disabled], .block-event .slider-panel .slider .item .action.event[disabled],
  fieldset[disabled] .abs-action-link-button,
  fieldset[disabled] .abs-action-addto-product,
  fieldset[disabled] .bundle-options-container .block-bundle-summary .product-addto-links > .action,
  .bundle-options-container .block-bundle-summary fieldset[disabled] .product-addto-links > .action,
  fieldset[disabled] .product-info-main .action.tocompare,
  .product-info-main fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-options-bottom .action.tocompare,
  .product-options-bottom fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-info-main .action.towishlist,
  .product-info-main fieldset[disabled] .action.towishlist,
  fieldset[disabled] .product-options-bottom .action.towishlist,
  .product-options-bottom fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-bundle-summary .action.towishlist,
  .block-bundle-summary fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-event .slider-panel .slider .item .action.event,
  .block-event .slider-panel .slider .item fieldset[disabled] .action.event {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active, .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus {
    text-decoration: none; }

.abs-product-options-list dt, .cart.table-wrapper .item-options dt, .block-giftregistry-shared-items .product .item-options dt, .multicheckout .item-options dt {
  clear: left;
  float: left;
  margin: 0 10px 5px 0; }
  .abs-product-options-list dt:after, .cart.table-wrapper .item-options dt:after, .block-giftregistry-shared-items .product .item-options dt:after, .multicheckout .item-options dt:after {
    content: ': '; }

.abs-product-options-list dd, .cart.table-wrapper .item-options dd, .block-giftregistry-shared-items .product .item-options dd, .multicheckout .item-options dd {
  display: inline-block;
  float: left;
  margin: 0 0 5px; }

@media only screen and (min-width: 768px) {
  .abs-product-options-list-desktop dt, .block-giftregistry-shared .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0; }
    .abs-product-options-list-desktop dt:after, .block-giftregistry-shared .item-options dt:after {
      content: ': '; }
  .abs-product-options-list-desktop dd, .block-giftregistry-shared .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px; } }

.abs-button-responsive, .actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .abs-button-desktop {
    width: auto; } }

@media only screen and (max-width: 767px) {
  .abs-button-responsive-smaller, .product-info-main .box-tocart .actions .action.tocart {
    width: 100%; } }

@media only screen and (min-width: 768px) {
  .abs-blocks-2columns, .column .block-addbysku .block-content .box, .login-container .block, .account .column.main .block:not(.widget) .block-content .box, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    width: 48.8%; }
    .abs-blocks-2columns:nth-child(odd), .column .block-addbysku .block-content .box:nth-child(odd), .login-container .block:nth-child(odd), .account .column.main .block:not(.widget) .block-content .box:nth-child(odd), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(odd), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns:nth-child(even), .column .block-addbysku .block-content .box:nth-child(even), .login-container .block:nth-child(even), .account .column.main .block:not(.widget) .block-content .box:nth-child(even), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(even), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(even),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(even) {
      float: right; } }

@media only screen and (min-width: 640px) {
  .abs-blocks-2columns-s {
    width: 48.8%; }
    .abs-blocks-2columns-s:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns-s:nth-child(even) {
      float: right; } }

.abs-reset-image-wrapper, .products-list .product-image-wrapper, .cart.table-wrapper .product-image-wrapper, .minicart-items .product-image-wrapper, .block-giftregistry-shared-items .product-image-wrapper {
  height: auto;
  padding: 0 !important; }
  .abs-reset-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo, .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo, .block-giftregistry-shared-items .product-image-wrapper .product-image-photo {
    position: static; }

.abs-adaptive-images {
  display: block;
  height: auto;
  max-width: 100%; }

.abs-adaptive-images-centered, .product.media .product.photo .photo.image {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%; }

.abs-login-block-title, .login-container .block .block-title {
  font-size: 1.8rem;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 15px;
  padding-bottom: 12px; }
  .abs-login-block-title strong, .login-container .block .block-title strong {
    font-weight: 500; }

.abs-block-title, .abs-account-blocks .block-title, .account .column.main .block:not(.widget) .block-title, .multicheckout .block-title, .paypal-review .block .block-title, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title, .sales-guest-view .column.main .block:not(.widget) .block-title, .block-compare .block-title, .block-giftregistry-results .block-title, .block-giftregistry-shared-items .block-title, .block-wishlist-search-results .block-title, .block-reorder .block-title, .widget .block-title, .block-wishlist .block-title {
  margin-bottom: 15px; }
  .abs-block-title > strong, .abs-account-blocks .block-title > strong, .account .column.main .block:not(.widget) .block-title > strong, .multicheckout .block-title > strong, .paypal-review .block .block-title > strong, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong, .sales-guest-view .column.main .block:not(.widget) .block-title > strong, .block-compare .block-title > strong, .block-giftregistry-results .block-title > strong, .block-giftregistry-shared-items .block-title > strong, .block-wishlist-search-results .block-title > strong, .block-reorder .block-title > strong, .widget .block-title > strong, .block-wishlist .block-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }

.abs-account-blocks .block-title > .action, .account .column.main .block:not(.widget) .block-title > .action, .multicheckout .block-title > .action, .paypal-review .block .block-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > .action, .sales-guest-view .column.main .block:not(.widget) .block-title > .action {
  margin-left: 15px; }

.abs-account-blocks .box-title, .account .column.main .block:not(.widget) .box-title, .multicheckout .box-title, .paypal-review .block .box-title, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title, .sales-guest-view .column.main .block:not(.widget) .box-title {
  display: inline-block;
  margin: 0 0 5px; }
  .abs-account-blocks .box-title > span, .account .column.main .block:not(.widget) .box-title > span, .multicheckout .box-title > span, .paypal-review .block .box-title > span, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > span, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > span, .sales-guest-view .column.main .block:not(.widget) .box-title > span {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .abs-account-blocks .box-title > .action, .account .column.main .block:not(.widget) .box-title > .action, .multicheckout .box-title > .action, .paypal-review .block .box-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > .action, .sales-guest-view .column.main .block:not(.widget) .box-title > .action {
    font-weight: 400;
    margin-left: 10px; }

.abs-account-blocks .block-content p:last-child, .account .column.main .block:not(.widget) .block-content p:last-child, .multicheckout .block-content p:last-child, .paypal-review .block .block-content p:last-child, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content p:last-child, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content p:last-child, .sales-guest-view .column.main .block:not(.widget) .block-content p:last-child {
  margin-bottom: 0; }

.abs-account-blocks .block-content .box, .account .column.main .block:not(.widget) .block-content .box, .multicheckout .block-content .box, .paypal-review .block .block-content .box, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box, .sales-guest-view .column.main .block:not(.widget) .block-content .box {
  margin-bottom: 20px; }

.abs-dropdown-simple, .giftregisty-dropdown, .wishlist-dropdown {
  display: inline-block;
  position: relative; }
  .abs-dropdown-simple:before, .giftregisty-dropdown:before, .wishlist-dropdown:before, .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    content: '';
    display: table; }
  .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    clear: both; }
  .abs-dropdown-simple .action.toggle, .giftregisty-dropdown .action.toggle, .wishlist-dropdown .action.toggle {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .abs-dropdown-simple .action.toggle:after, .giftregisty-dropdown .action.toggle:after, .wishlist-dropdown .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 5px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-dropdown-simple .action.toggle:hover:after, .giftregisty-dropdown .action.toggle:hover:after, .wishlist-dropdown .action.toggle:hover:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle:active:after, .giftregisty-dropdown .action.toggle:active:after, .wishlist-dropdown .action.toggle:active:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle.active, .giftregisty-dropdown .action.toggle.active, .wishlist-dropdown .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-dropdown-simple .action.toggle.active:after, .giftregisty-dropdown .action.toggle.active:after, .wishlist-dropdown .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0 0 0 5px;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .abs-dropdown-simple .action.toggle.active:hover:after, .giftregisty-dropdown .action.toggle.active:hover:after, .wishlist-dropdown .action.toggle.active:hover:after {
        color: inherit; }
      .abs-dropdown-simple .action.toggle.active:active:after, .giftregisty-dropdown .action.toggle.active:active:after, .wishlist-dropdown .action.toggle.active:active:after {
        color: inherit; }
  .abs-dropdown-simple ul.dropdown, .giftregisty-dropdown ul.dropdown, .wishlist-dropdown ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-dropdown-simple ul.dropdown li, .giftregisty-dropdown ul.dropdown li, .wishlist-dropdown ul.dropdown li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .abs-dropdown-simple ul.dropdown li:hover, .giftregisty-dropdown ul.dropdown li:hover, .wishlist-dropdown ul.dropdown li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before, .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      left: 10px;
      top: -12px; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      left: 9px;
      top: -14px; }
  .abs-dropdown-simple.active, .active.giftregisty-dropdown, .active.wishlist-dropdown {
    overflow: visible; }
    .abs-dropdown-simple.active ul.dropdown, .active.giftregisty-dropdown ul.dropdown, .active.wishlist-dropdown ul.dropdown {
      display: block; }

.abs-input-qty, .column .block-addbysku .qty .qty, .bundle-options-container .input-text.qty, .cart.table-wrapper .col.qty .input-text, .account .table-return-items .qty .input-text, .table-giftregistry-items .field.qty .qty, .block-giftregistry-shared-items .qty .qty, .table.grouped .control .qty, .block-wishlist-info-items .input-text.qty, .multicheckout .table-wrapper .col .qty .input-text, .account .table-wrapper .data.table.wishlist .box-tocart .qty, .products-grid.wishlist .product-item .box-tocart input.qty, .sidebar .block-addbysku .fieldset .fields .field.qty .qty {
  text-align: center;
  width: 47px; }

.abs-margin-for-blocks-and-widgets, .block, .sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child), .block-banners,
.block-banners-inline {
  margin-bottom: 40px; }

.abs-remove-button-for-blocks, .sidebar .action.delete, .table-comparison .cell.remove .action.delete, .block-compare .action.delete, .filtered .item .action.remove {
  display: inline-block;
  text-decoration: none; }
  .abs-remove-button-for-blocks > span, .sidebar .action.delete > span, .table-comparison .cell.remove .action.delete > span, .block-compare .action.delete > span, .filtered .item .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-remove-button-for-blocks:before, .sidebar .action.delete:before, .table-comparison .cell.remove .action.delete:before, .block-compare .action.delete:before, .filtered .item .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 15px;
    color: #303030;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-remove-button-for-blocks:hover:before, .sidebar .action.delete:hover:before, .table-comparison .cell.remove .action.delete:hover:before, .block-compare .action.delete:hover:before, .filtered .item .action.remove:hover:before {
    color: #303030; }
  .abs-remove-button-for-blocks:active:before, .sidebar .action.delete:active:before, .table-comparison .cell.remove .action.delete:active:before, .block-compare .action.delete:active:before, .filtered .item .action.remove:active:before {
    color: #303030; }

.abs-product-link, .product-item-name, .product.name a {
  font-weight: 400; }
  .abs-product-link > a, .product-item-name > a, .product.name a > a {
    color: #333;
    text-decoration: none; }
    .abs-product-link > a:visited, .product-item-name > a:visited, .product.name a > a:visited {
      color: #333;
      text-decoration: underline; }
    .abs-product-link > a:hover, .product-item-name > a:hover, .product.name a > a:hover {
      color: #333;
      text-decoration: underline; }
    .abs-product-link > a:active, .product-item-name > a:active, .product.name a > a:active {
      color: #333;
      text-decoration: underline; }

.abs-reset-left-margin, .form.giftregistry.items .actions-toolbar, .form-new-agreement .actions-toolbar, .fieldset .review-legend.legend, .form.wishlist.items .actions-toolbar {
  margin-left: 0; }

@media only screen and (min-width: 768px) {
  .abs-reset-left-margin-desktop, .bundle-options-container .legend.title, .column.main .cart-summary .actions-toolbar, .cart.table-wrapper .item-actions .actions-toolbar, .gift-summary .actions-toolbar,
  .cart.table-wrapper .gift-summary .actions-toolbar, .column.main .block-giftregistry-shared-items .actions-toolbar, .form-new-agreement .fieldset .legend, .form-new-agreement .actions-toolbar, .column.main .paypal-review .actions-toolbar, .wishlist-index-index .main .form-wishlist-items .actions-toolbar {
    margin-left: 0; } }

@media only screen and (min-width: 640px) {
  .abs-reset-left-margin-desktop-s, .column:not(.sidebar-main) .multicheckout .actions-toolbar, .multicheckout .block-shipping .box-shipping-method .fieldset .legend {
    margin-left: 0; } }

.abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove {
  left: 10px;
  margin-left: 70%;
  position: absolute;
  top: 31px;
  width: auto; }

@media only screen and (min-width: 768px) {
  .abs-action-remove-desktop, .abs-add-fields-desktop .fieldset .additional .action.remove, .form-giftregistry-share .fieldset .additional .action.remove, .form-giftregistry-edit .fieldset .additional .action.remove, .form-add-invitations .fieldset .additional .action.remove, .form-create-return .fieldset .additional .action.remove, .form.send.friend .fieldset .additional .action.remove {
    margin-left: 75.8%;
    top: 6px; } }

.abs-add-fields .fieldset, .form-giftregistry-share .fieldset, .form-giftregistry-edit .fieldset, .form-add-invitations .fieldset, .form-create-return .fieldset, .form.send.friend .fieldset {
  margin-bottom: 50px; }
  .abs-add-fields .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 70%; }
  .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary {
    float: left; }
    .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add {
      margin-top: 30px; }
  .abs-add-fields .fieldset .fields .actions-toolbar, .form-giftregistry-share .fieldset .fields .actions-toolbar, .form-giftregistry-edit .fieldset .fields .actions-toolbar, .form-add-invitations .fieldset .fields .actions-toolbar, .form-create-return .fieldset .fields .actions-toolbar, .form.send.friend .fieldset .fields .actions-toolbar {
    margin: 0; }

.abs-add-fields .message.notice, .form-giftregistry-share .message.notice, .form-giftregistry-edit .message.notice, .form-add-invitations .message.notice, .form-create-return .message.notice, .form.send.friend .message.notice {
  margin: 30px 0 0; }

.abs-add-fields .additional, .form-giftregistry-share .additional, .form-giftregistry-edit .additional, .form-add-invitations .additional, .form-create-return .additional, .form.send.friend .additional {
  margin-top: 55px;
  position: relative; }

@media only screen and (min-width: 768px) {
  .abs-add-fields-desktop .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 50%; } }

@media only screen and (min-width: 768px) {
  .abs-margin-for-forms-desktop, .column:not(.sidebar-additional) form .actions-toolbar,
  .column:not(.sidebar-main) form .actions-toolbar, .fieldset .legend, .login-container .fieldset:after {
    margin-left: 25.8%; } }

@media only screen and (min-width: 640px) {
  .abs-margin-for-forms-desktop-s {
    margin-left: 25.8%; } }

.abs-hidden, .order-details-items .order-items .order-gift-message:not(.expanded-content) {
  height: 0;
  visibility: hidden; }

.abs-visually-hidden, .fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label, .product-item-actions .actions-secondary > .action span, .table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span, .cart.table-wrapper .col.qty .label, .minicart-wrapper .action.showcart .text, .minicart-wrapper .action.showcart .counter-label, .checkout-index-index .page-title-wrapper, .checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .fieldset .fullname.field > .label, .account .column.main .limiter > .label, .field.street .field.additional .label, .account-nav .title, .page-product-downloadable .product-options-wrapper .fieldset .legend.links-title, .page-product-giftcard .fieldset.giftcard > .legend, .opc-wrapper .form-giftcard-account .field .label, .filter-options-content .filter-count-label, .block-wishlist-search-form .form-wishlist-search .fieldset > .legend, .page-multiple-wishlist .page-title-wrapper .page-title, .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .block.newsletter .label, .block-reorder .product-item .label, .opc-wrapper .form-discount .field .label, .action.skip:not(:focus), .page-header .switcher .label,
.page-footer .switcher .label, .products-grid.wishlist .product-item .comment-box .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

@media only screen and (max-width: 639px) {
  .abs-visually-hidden-mobile, .table-giftregistry-items .col.product:before {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (max-width: 767px) {
  .abs-visually-hidden-mobile-m {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 640px) {
  .abs-visually-hidden-desktop-s {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 768px) {
  .abs-visually-hidden-desktop, .modes-label, .block-search .label, .dashboard-welcome-toggler {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

.abs-visually-hidden-reset, .shipping-policy-block.field-tooltip .field-tooltip-action span {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.abs-add-clearfix:before, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):before, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):before, .form-create-return .fieldset .actions-toolbar:not(:first-child):before, .form.send.friend .fieldset .actions-toolbar:not(:first-child):before, .payment-method-braintree .hosted-date-wrap:before, .toolbar:before, .toolbar-products:before, .sidebar .product-items-images:before, .cart.table-wrapper .actions-toolbar:before, .cart.table-wrapper .item-options:before, .minicart-items .product-item > .product:before, .checkout-container:before, .checkout-onepage-success:before, .opc-estimated-wrapper:before, .opc-wrapper .field.addresses:before, .fieldset .fullname .field:before, .page-product-downloadable .product-add-form:before, .gift-options:before, .gift-item-block .content:before, .order-details-items .order-gift-message:before,
.order-details-items .block-order-details-gift-message:before, .block-giftregistry-shared-items .product .item-options:before, .gift-wrapping-list:before, .opc-wrapper .data.table .gift-wrapping:before,
.table-order-items .gift-wrapping:before,
.table-order-review .gift-wrapping:before, .order-options .product:before, .map-popup .map-info-price:before, .multicheckout .item-options:before, .multicheckout .block:before, .multicheckout .block-content:before, .customer-review .product-details:before, .header.content:before, .abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .toolbar-products:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .checkout-container:after, .checkout-onepage-success:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .fieldset .fullname .field:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after, .header.content:after {
  content: '';
  display: table; }

.abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .toolbar-products:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .checkout-container:after, .checkout-onepage-success:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .fieldset .fullname .field:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after, .header.content:after {
  clear: both; }

@media only screen and (min-width: 768px) {
  .abs-add-clearfix-desktop:before, .abs-pager-toolbar:before, .account .toolbar:before, .toolbar-giftregistry-results:before, .toolbar-wishlist-results:before, .column .block-addbysku .block-content:before, .block-cart-failed .block-content:before, .cart-container:before, .login-container:before, .account .column.main .block:not(.widget) .block-content:before, .block-addresses-list .items.addresses:before, .block-giftregistry-shared .item-options:before, .gift-wrapping .nested:before, .table .gift-wrapping .content:before, .block-wishlist-management:before, .paypal-review .block-content:before, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:before, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:before,
  .sales-guest-view .column.main .block:not(.widget) .block-content:before, .header.content:before, .page-header .header.panel:before, .footer .copyright:before, .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after, .header.content:after, .page-header .header.panel:after, .footer .copyright:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after, .header.content:after, .page-header .header.panel:after, .footer .copyright:after {
    clear: both; } }

@media only screen and (min-width: 640px) {
  .abs-add-clearfix-desktop-s:before, .multicheckout .block-billing:before, .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    clear: both; } }

@media only screen and (max-width: 639px) {
  .abs-add-clearfix-mobile:before, .abs-checkout-order-review tbody tr:before, .multicheckout.order-review .data.table tbody tr:before, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:before, .table-giftregistry-items .col.product:before, .account .toolbar:before, .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    clear: both; } }

@media only screen and (max-width: 767px) {
  .abs-add-clearfix-mobile-m:before, .form-create-return .fieldset > .actions-toolbar:before, .abs-add-clearfix-mobile-m:after, .form-create-return .fieldset > .actions-toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile-m:after, .form-create-return .fieldset > .actions-toolbar:after {
    clear: both; } }

.abs-add-box-sizing, .abs-field-date .control, .field.date .control, .abs-field-tooltip, .field .control._with-tooltip, .columns .column.main, .columns .sidebar-main, .columns .sidebar-additional, .column .block-addbysku .sku, .bundle-options-container .block-bundle-summary, .product-item, .sidebar .product-items-images .product-item, .search-autocomplete, .cart-summary, .opc-wrapper .shipping-address-item, .shipping-policy-block.field-tooltip .field-tooltip-content, .opc-block-summary, .sidebar .block-addbysku .fieldset .fields .field.sku, .block-event .slider-panel .slider .item .action.show {
  box-sizing: border-box; }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop, .column.main, .sidebar-main, .sidebar-additional {
    box-sizing: border-box; } }

@media only screen and (min-width: 640px) {
  .abs-add-box-sizing-desktop-s, .multicheckout .block-shipping .box, .multicheckout .block-billing .box-billing-address, .multicheckout .block-billing .box-billing-method {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop-m, .opc-wrapper {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-revert-field-type-desktop .fieldset > .field, .product-add-form .fieldset > .field,
  .abs-revert-field-type-desktop .fieldset .fields > .field,
  .product-add-form .fieldset .fields > .field {
    margin: 0 0 20px; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label, .product-add-form .fieldset > .field:not(.choice) > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label,
    .product-add-form .fieldset .fields > .field:not(.choice) > .label {
      box-sizing: content-box;
      float: none;
      width: auto;
      text-align: left;
      padding: 0; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control, .product-add-form .fieldset > .field:not(.choice) > .control,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control,
    .product-add-form .fieldset .fields > .field:not(.choice) > .control {
      float: none;
      width: auto; }
    .abs-revert-field-type-desktop .fieldset > .field > .label, .product-add-form .fieldset > .field > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field > .label,
    .product-add-form .fieldset .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .abs-revert-field-type-desktop .fieldset > .field.choice:before, .product-add-form .fieldset > .field.choice:before, .abs-revert-field-type-desktop .fieldset > .field.no-label:before, .product-add-form .fieldset > .field.no-label:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before,
    .product-add-form .fieldset .fields > .field.choice:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before,
    .product-add-form .fieldset .fields > .field.no-label:before {
      display: none; }
    .abs-revert-field-type-desktop .fieldset > .field:not(:first-child):last-of-type, .product-add-form .fieldset > .field:not(:first-child):last-of-type,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(:first-child):last-of-type,
    .product-add-form .fieldset .fields > .field:not(:first-child):last-of-type {
      margin-bottom: 0; } }

.abs-navigation-icon {
  display: block;
  text-decoration: none; }
  .abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    line-height: 1.2;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-navigation-icon:after {
    position: absolute;
    right: 5px;
    top: 0; }

.abs-split-button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .abs-split-button:before, .abs-split-button:after {
    content: '';
    display: table; }
  .abs-split-button:after {
    clear: both; }
  .abs-split-button .action.split {
    float: left;
    margin: 0; }
  .abs-split-button .action.toggle {
    float: right;
    margin: 0; }
  .abs-split-button button.action.split, .abs-split-button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .abs-split-button .actions-toolbar > .action.split, .abs-split-button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .abs-split-button button + .action.toggle, .abs-split-button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .abs-split-button .actions-toolbar > .action + .action.toggle, .abs-split-button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .abs-split-button .action.toggle {
    padding: 4px 5px;
    display: inline-block;
    text-decoration: none; }
    .abs-split-button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .abs-split-button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-split-button .action.toggle:hover:after {
      color: inherit; }
    .abs-split-button .action.toggle:active:after {
      color: inherit; }
    .abs-split-button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-split-button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .abs-split-button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .abs-split-button .action.toggle.active:hover:after {
        color: inherit; }
      .abs-split-button .action.toggle.active:active:after {
        color: inherit; }
  .abs-split-button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-split-button .items li {
      margin: 0;
      padding: 3px 5px; }
      .abs-split-button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-split-button .items:before, .abs-split-button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-split-button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-split-button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-split-button .items:before {
      right: 10px;
      top: -12px; }
    .abs-split-button .items:after {
      right: 9px;
      top: -14px; }
  .abs-split-button.active {
    overflow: visible; }
    .abs-split-button.active .items {
      display: block; }

.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem; }

.abs-actions-addto-gridlist, .product-item-actions .actions-secondary > .action, .table-comparison .wishlist.split.button > .action.split,
.product-items .wishlist.split.button > .action.split, .product-items .action.towishlist,
.products.list.items .action.towishlist,
.table-comparison .action.towishlist {
  display: inline-block;
  text-decoration: none; }
  .abs-actions-addto-gridlist > span, .product-item-actions .actions-secondary > .action > span, .table-comparison .wishlist.split.button > .action.split > span, .product-items .wishlist.split.button > .action.split > span, .product-items .action.towishlist > span, .products.list.items .action.towishlist > span, .table-comparison .action.towishlist > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-actions-addto-gridlist:before, .product-item-actions .actions-secondary > .action:before, .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before, .product-items .action.towishlist:before,
  .products.list.items .action.towishlist:before,
  .table-comparison .action.towishlist:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 29px;
    line-height: 24px;
    color: #333;
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-actions-addto-gridlist:hover:before, .product-item-actions .actions-secondary > .action:hover:before, .table-comparison .wishlist.split.button > .action.split:hover:before,
  .product-items .wishlist.split.button > .action.split:hover:before, .product-items .action.towishlist:hover:before,
  .products.list.items .action.towishlist:hover:before,
  .table-comparison .action.towishlist:hover:before {
    color: #006bb4; }

.abs-button-l, .bundle-actions .action.primary, .bundle-options-container .block-bundle-summary .box-tocart .action.primary, .product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart, .cart-container .checkout-methods-items .action.primary, .block-minicart .block-content > .actions > .primary .action.primary, .methods-shipping .actions-toolbar .action.primary, .block-authentication .action.action-register,
.block-authentication .action.action-login, .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary, .form-address-edit .actions-toolbar .action.primary, .multicheckout .action.primary {
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem; }

.abs-action-button-as-link, .abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove, .cart.table-wrapper .action.help.map, .opc-wrapper .edit-address-link, .opc-block-shipping-information .shipping-information-title .action-edit, .action-auth-toggle, .checkout-payment-method .checkout-billing-address .action-cancel, .checkout-agreements-block .action-show, .cart-summary .block.giftcard .action.check, .gift-options .actions-toolbar .action-cancel, .table-comparison .wishlist.split.button > .action,
.product-items .wishlist.split.button > .action, .paypal-button-widget .paypal-button {
  line-height: 1.42857;
  padding: 0;
  color: #1979c3;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  border-radius: 0;
  font-size: inherit;
  font-weight: 400; }
  .abs-action-button-as-link:visited, .abs-action-remove:visited, .abs-add-fields .action.remove:visited, .form-giftregistry-share .action.remove:visited, .form-giftregistry-edit .action.remove:visited, .form-add-invitations .action.remove:visited, .form-create-return .action.remove:visited, .form.send.friend .action.remove:visited, .cart.table-wrapper .action.help.map:visited, .opc-wrapper .edit-address-link:visited, .opc-block-shipping-information .shipping-information-title .action-edit:visited, .action-auth-toggle:visited, .checkout-payment-method .checkout-billing-address .action-cancel:visited, .checkout-agreements-block .action-show:visited, .cart-summary .block.giftcard .action.check:visited, .gift-options .actions-toolbar .action-cancel:visited, .table-comparison .wishlist.split.button > .action:visited,
  .product-items .wishlist.split.button > .action:visited, .paypal-button-widget .paypal-button:visited {
    color: #1979c3;
    text-decoration: none; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #006bb4;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active {
    color: #ff5501;
    text-decoration: underline; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #006bb4; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover, .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:focus, .abs-action-remove:focus, .abs-add-fields .action.remove:focus, .form-giftregistry-share .action.remove:focus, .form-giftregistry-edit .action.remove:focus, .form-add-invitations .action.remove:focus, .form-create-return .action.remove:focus, .form.send.friend .action.remove:focus, .cart.table-wrapper .action.help.map:focus, .opc-wrapper .edit-address-link:focus, .opc-block-shipping-information .shipping-information-title .action-edit:focus, .action-auth-toggle:focus, .checkout-payment-method .checkout-billing-address .action-cancel:focus, .checkout-agreements-block .action-show:focus, .cart-summary .block.giftcard .action.check:focus, .gift-options .actions-toolbar .action-cancel:focus, .table-comparison .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:focus, .paypal-button-widget .paypal-button:focus {
    background: none;
    border: 0; }
  .abs-action-button-as-link.disabled, .disabled.abs-action-remove, .abs-add-fields .disabled.action.remove, .form-giftregistry-share .disabled.action.remove, .form-giftregistry-edit .disabled.action.remove, .form-add-invitations .disabled.action.remove, .form-create-return .disabled.action.remove, .form.send.friend .disabled.action.remove, .cart.table-wrapper .disabled.action.help.map, .opc-wrapper .disabled.edit-address-link, .opc-block-shipping-information .shipping-information-title .disabled.action-edit, .disabled.action-auth-toggle, .checkout-payment-method .checkout-billing-address .disabled.action-cancel, .checkout-agreements-block .disabled.action-show, .cart-summary .block.giftcard .disabled.action.check, .gift-options .actions-toolbar .disabled.action-cancel, .table-comparison .wishlist.split.button > .disabled.action,
  .product-items .wishlist.split.button > .disabled.action, .paypal-button-widget .disabled.paypal-button, .abs-action-button-as-link[disabled], .abs-action-remove[disabled], .abs-add-fields .action.remove[disabled], .form-giftregistry-share .action.remove[disabled], .form-giftregistry-edit .action.remove[disabled], .form-add-invitations .action.remove[disabled], .form-create-return .action.remove[disabled], .form.send.friend .action.remove[disabled], .cart.table-wrapper .action.help.map[disabled], .opc-wrapper .edit-address-link[disabled], .opc-block-shipping-information .shipping-information-title .action-edit[disabled], .action-auth-toggle[disabled], .checkout-payment-method .checkout-billing-address .action-cancel[disabled], .checkout-agreements-block .action-show[disabled], .cart-summary .block.giftcard .action.check[disabled], .gift-options .actions-toolbar .action-cancel[disabled], .table-comparison .wishlist.split.button > .action[disabled],
  .product-items .wishlist.split.button > .action[disabled], .paypal-button-widget .paypal-button[disabled],
  fieldset[disabled] .abs-action-button-as-link,
  fieldset[disabled] .abs-action-remove,
  fieldset[disabled] .abs-add-fields .action.remove,
  .abs-add-fields fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-share .action.remove,
  .form-giftregistry-share fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-edit .action.remove,
  .form-giftregistry-edit fieldset[disabled] .action.remove,
  fieldset[disabled] .form-add-invitations .action.remove,
  .form-add-invitations fieldset[disabled] .action.remove,
  fieldset[disabled] .form-create-return .action.remove,
  .form-create-return fieldset[disabled] .action.remove,
  fieldset[disabled] .form.send.friend .action.remove,
  .form.send.friend fieldset[disabled] .action.remove,
  fieldset[disabled] .cart.table-wrapper .action.help.map,
  .cart.table-wrapper fieldset[disabled] .action.help.map,
  fieldset[disabled] .opc-wrapper .edit-address-link,
  .opc-wrapper fieldset[disabled] .edit-address-link,
  fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
  .opc-block-shipping-information .shipping-information-title fieldset[disabled] .action-edit,
  fieldset[disabled] .action-auth-toggle,
  fieldset[disabled] .checkout-payment-method .checkout-billing-address .action-cancel,
  .checkout-payment-method .checkout-billing-address fieldset[disabled] .action-cancel,
  fieldset[disabled] .checkout-agreements-block .action-show,
  .checkout-agreements-block fieldset[disabled] .action-show,
  fieldset[disabled] .cart-summary .block.giftcard .action.check,
  .cart-summary .block.giftcard fieldset[disabled] .action.check,
  fieldset[disabled] .gift-options .actions-toolbar .action-cancel,
  .gift-options .actions-toolbar fieldset[disabled] .action-cancel,
  fieldset[disabled] .table-comparison .wishlist.split.button > .action,
  .table-comparison fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .product-items .wishlist.split.button > .action,
  .product-items fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .paypal-button-widget .paypal-button,
  .paypal-button-widget fieldset[disabled] .paypal-button {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:not(:focus), .abs-action-remove:not(:focus), .abs-add-fields .action.remove:not(:focus), .form-giftregistry-share .action.remove:not(:focus), .form-giftregistry-edit .action.remove:not(:focus), .form-add-invitations .action.remove:not(:focus), .form-create-return .action.remove:not(:focus), .form.send.friend .action.remove:not(:focus), .cart.table-wrapper .action.help.map:not(:focus), .opc-wrapper .edit-address-link:not(:focus), .opc-block-shipping-information .shipping-information-title .action-edit:not(:focus), .action-auth-toggle:not(:focus), .checkout-payment-method .checkout-billing-address .action-cancel:not(:focus), .checkout-agreements-block .action-show:not(:focus), .cart-summary .block.giftcard .action.check:not(:focus), .gift-options .actions-toolbar .action-cancel:not(:focus), .table-comparison .wishlist.split.button > .action:not(:focus),
  .product-items .wishlist.split.button > .action:not(:focus), .paypal-button-widget .paypal-button:not(:focus) {
    box-shadow: none; }

.abs-revert-secondary-color, .block-compare .action.compare, .cart-summary .actions-toolbar > .primary button, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action, .cart-summary .actions-toolbar > .primary .action-gift, .cart-summary .form.giftregistry .fieldset .action.primary, .paypal-review .block .actions-toolbar .action.primary {
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #333; }
  .abs-revert-secondary-color:focus, .block-compare .action.compare:focus, .cart-summary .actions-toolbar > .primary button:focus, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action:focus, .cart-summary .actions-toolbar > .primary .action-gift:focus, .cart-summary .form.giftregistry .fieldset .action.primary:focus, .paypal-review .block .actions-toolbar .action.primary:focus, .abs-revert-secondary-color:active, .block-compare .action.compare:active, .cart-summary .actions-toolbar > .primary button:active, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action:active, .cart-summary .actions-toolbar > .primary .action-gift:active, .cart-summary .form.giftregistry .fieldset .action.primary:active, .paypal-review .block .actions-toolbar .action.primary:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #333; }
  .abs-revert-secondary-color:hover, .block-compare .action.compare:hover, .cart-summary .actions-toolbar > .primary button:hover, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action:hover, .cart-summary .actions-toolbar > .primary .action-gift:hover, .cart-summary .form.giftregistry .fieldset .action.primary:hover, .paypal-review .block .actions-toolbar .action.primary:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }

.abs-revert-secondary-size, .cart-summary .form.giftregistry .fieldset .action.primary {
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 7px 15px; }

.abs-box-tocart, .bundle-actions {
  margin: 10px 0; }

.abs-adjustment-incl-excl-tax .price-including-tax, .bundle-options-container .price-notice .price-including-tax, .product-options-wrapper .field .price-notice .price-including-tax, .cart-summary .price-including-tax, .gift-summary .price-including-tax, .gift-options .price-including-tax,
.abs-adjustment-incl-excl-tax .price-excluding-tax,
.bundle-options-container .price-notice .price-excluding-tax,
.product-options-wrapper .field .price-notice .price-excluding-tax,
.cart-summary .price-excluding-tax,
.gift-summary .price-excluding-tax,
.gift-options .price-excluding-tax,
.abs-adjustment-incl-excl-tax .weee,
.bundle-options-container .price-notice .weee,
.product-options-wrapper .field .price-notice .weee,
.cart-summary .weee,
.gift-summary .weee,
.gift-options .weee {
  font-size: 1.4rem;
  display: inline-block;
  white-space: nowrap; }

.abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax, .cart-summary .price-including-tax + .price-excluding-tax, .gift-summary .price-including-tax + .price-excluding-tax, .gift-options .price-including-tax + .price-excluding-tax {
  display: inline-block;
  font-size: 1.1rem; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:before, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:before, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:before, .cart-summary .price-including-tax + .price-excluding-tax:before, .gift-summary .price-including-tax + .price-excluding-tax:before, .gift-options .price-including-tax + .price-excluding-tax:before {
    content: "(" attr(data-label) ": "; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:after, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:after, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:after, .cart-summary .price-including-tax + .price-excluding-tax:after, .gift-summary .price-including-tax + .price-excluding-tax:after, .gift-options .price-including-tax + .price-excluding-tax:after {
    content: ')'; }

.abs-tax-total, .price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
  cursor: pointer;
  padding-right: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 10px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    position: absolute;
    right: -10px;
    top: 3px; }
  .abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
  .price-excluding-tax .cart-tax-total-expanded:after {
    content: ""; }

.abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after {
  content: ""; }

.abs-methods-shipping-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 15px; }

@media only screen and (max-width: 639px) {
  .abs-checkout-order-review tbody tr:not(:last-child), .multicheckout.order-review .data.table tbody tr:not(:last-child) {
    border-bottom: 1px solid #d1d1d1; }
  .abs-checkout-order-review tbody tr .col.item:before, .multicheckout.order-review .data.table tbody tr .col.item:before {
    display: none; }
  .abs-checkout-order-review tbody tr .col.qty, .multicheckout.order-review .data.table tbody tr .col.qty, .abs-checkout-order-review tbody tr .col.price, .multicheckout.order-review .data.table tbody tr .col.price, .abs-checkout-order-review tbody tr .col.subtotal, .multicheckout.order-review .data.table tbody tr .col.subtotal {
    box-sizing: border-box;
    float: left;
    text-align: center;
    white-space: nowrap;
    width: 33%; }
    .abs-checkout-order-review tbody tr .col.qty:before, .multicheckout.order-review .data.table tbody tr .col.qty:before, .abs-checkout-order-review tbody tr .col.price:before, .multicheckout.order-review .data.table tbody tr .col.price:before, .abs-checkout-order-review tbody tr .col.subtotal:before, .multicheckout.order-review .data.table tbody tr .col.subtotal:before {
      content: attr(data-th) ":";
      display: block;
      font-weight: 700;
      padding-bottom: 10px; }
  .abs-checkout-order-review tbody tr .product-item-name, .multicheckout.order-review .data.table tbody tr .product-item-name {
    margin: 0; } }

.abs-colon:after, .block-minicart .subtotal .label:after, .minicart-items .details-qty .label:after,
.minicart-items .price-minicart .label:after, .order-details-items .order-gift-message .label:after,
.order-details-items .block-order-details-gift-message .label:after, .block-wishlist-management .wishlist-select .wishlist-name-label:after {
  content: ': '; }

.abs-icon-add, .abs-dropdown-items-new .action.new, .wishlist.split.button .action.new, .wishlist-dropdown .action.new {
  display: inline-block;
  text-decoration: none; }
  .abs-icon-add:before, .abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }

@media only screen and (max-width: 767px) {
  .abs-icon-add-mobile, .block-wishlist-management .wishlist-add.item .add {
    display: block;
    text-decoration: none; }
    .abs-icon-add-mobile:before, .block-wishlist-management .wishlist-add.item .add:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 10px;
      line-height: 10px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 5px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; } }

.abs-dropdown-items-new .items .item:last-child:hover, .wishlist.split.button .items .item:last-child:hover, .wishlist-dropdown .items .item:last-child:hover {
  background: #e8e8e8; }

.abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
  margin-left: -17px;
  margin-right: 5px; }

@media only screen and (max-width: 767px) {
  .abs-toggling-title-mobile, .block-wishlist-management .wishlist-select .wishlist-name {
    border-bottom: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    cursor: pointer;
    margin-bottom: 0;
    padding: 10px 40px 10px 15px;
    position: relative;
    display: block;
    text-decoration: none; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: inherit;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after {
      position: absolute;
      right: 10px;
      top: 0; }
    .abs-toggling-title-mobile.active:after, .block-wishlist-management .wishlist-select .active.wishlist-name:after {
      content: ""; } }

.abs-no-display, .abs-sidebar-totals .table-caption, .cart-totals .table-caption, .opc-block-summary .table-totals .table-caption, .sidebar .subtitle, .cart-summary .block .fieldset.estimate > .legend,
.cart-summary .block .fieldset.estimate > .legend + br, .minicart-items .product-item-details .weee[data-label] .label, .page-product-giftcard .fieldset.giftcard > .legend + br, .order-details-items .order-items .order-gift-message .action.close, .multicheckout .block .methods-shipping .item-content .fieldset > .legend + br, .multicheckout .hidden, [class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle, .sidebar-main .block-reorder .subtitle,
.sidebar-additional .block-reorder .subtitle, .no-display {
  display: none; }

@media only screen and (max-width: 639px) {
  .abs-no-display-s, .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody th, .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    display: none; } }

@media only screen and (min-width: 768px) {
  .abs-no-display-desktop, .opc-estimated-wrapper, .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none; } }

.abs-status, .return-status, .order-status {
  display: inline-block;
  margin-bottom: 20px; }

@media only screen and (max-width: 767px) {
  .abs-pager-toolbar-mobile .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-wishlist-results .toolbar-amount,
  .abs-pager-toolbar-mobile .limiter,
  .account .toolbar .limiter,
  .toolbar-wishlist-results .limiter,
  .abs-pager-toolbar-mobile .pages,
  .account .toolbar .pages,
  .toolbar-wishlist-results .pages {
    float: none;
    margin-bottom: 25px; } }

@media only screen and (max-width: 639px) {
  .abs-pager-toolbar-mobile-s .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount,
  .abs-pager-toolbar-mobile-s .limiter,
  .toolbar-giftregistry-results .limiter,
  .abs-pager-toolbar-mobile-s .pages,
  .toolbar-giftregistry-results .pages {
    margin-bottom: 25px; } }

@media only screen and (min-width: 768px) {
  .abs-pager-toolbar, .account .toolbar, .toolbar-giftregistry-results, .toolbar-wishlist-results {
    margin-bottom: 20px;
    position: relative; }
    .abs-pager-toolbar .limiter, .account .toolbar .limiter, .toolbar-giftregistry-results .limiter, .toolbar-wishlist-results .limiter {
      float: right;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount, .toolbar-wishlist-results .toolbar-amount {
      float: left;
      line-height: normal;
      padding: 7px 0 0;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .pages, .account .toolbar .pages, .toolbar-giftregistry-results .pages, .toolbar-wishlist-results .pages {
      position: absolute;
      width: 100%;
      z-index: 0; } }

.abs-block-items-counter, .block-compare .counter, .block-wishlist .counter {
  color: #7d7d7d;
  font-size: 1.2rem;
  white-space: nowrap; }

.abs-shopping-cart-items .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue {
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px 7px 0;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active, .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus {
    text-decoration: none; }
  .abs-shopping-cart-items .action.continue:before, .block-cart-failed .action.continue:before, .cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.continue:hover:before, .block-cart-failed .action.continue:hover:before, .cart-container .form-cart .action.continue:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:active:before, .block-cart-failed .action.continue:active:before, .cart-container .form-cart .action.continue:active:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #333; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }
  .abs-shopping-cart-items .action.continue.disabled, .block-cart-failed .action.continue.disabled, .cart-container .form-cart .action.continue.disabled, .abs-shopping-cart-items .action.continue[disabled], .block-cart-failed .action.continue[disabled], .cart-container .form-cart .action.continue[disabled],
  fieldset[disabled] .abs-shopping-cart-items .action.continue,
  fieldset[disabled] .block-cart-failed .action.continue,
  fieldset[disabled] .cart-container .form-cart .action.continue,
  .cart-container fieldset[disabled] .form-cart .action.continue {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }

.abs-shopping-cart-items .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update {
  display: inline-block;
  text-decoration: none;
  padding-left: 5px; }
  .abs-shopping-cart-items .action.update:before, .block-cart-failed .action.update:before, .cart-container .form-cart .action.update:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.update:hover:before, .block-cart-failed .action.update:hover:before, .cart-container .form-cart .action.update:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.update:active:before, .block-cart-failed .action.update:active:before, .cart-container .form-cart .action.update:active:before {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .abs-shopping-cart-items-mobile .actions, .cart-container .form-cart .actions {
    text-align: center; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.continue, .cart-container .form-cart .action.continue, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    margin: 0 auto 10px; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    display: block; } }

@media only screen and (min-width: 768px) {
  .abs-shopping-cart-items-desktop, .block-cart-failed, .cart-container .form-cart, .cart-container .cart-gift-item {
    float: left;
    position: relative;
    width: 73%; }
    .abs-shopping-cart-items-desktop .actions, .block-cart-failed .actions, .cart-container .form-cart .actions, .cart-container .cart-gift-item .actions {
      text-align: right; }
    .abs-shopping-cart-items-desktop .action.clear, .block-cart-failed .action.clear, .cart-container .form-cart .action.clear, .cart-container .cart-gift-item .action.clear, .abs-shopping-cart-items-desktop .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update, .cart-container .cart-gift-item .action.update {
      margin-left: 10px; }
    .abs-shopping-cart-items-desktop .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue, .cart-container .cart-gift-item .action.continue {
      float: left; } }

.abs-field-date .control, .field.date .control {
  position: relative; }

.abs-field-date-input, .abs-field-date input, .field.date input, .form-giftregistry-search .fields-specific-options .datetime-picker {
  margin-right: 10px;
  width: calc(100% - 50px); }

.abs-field-tooltip, .field .control._with-tooltip {
  position: relative; }
  .abs-field-tooltip input, .field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - 36px); }

.abs-checkout-tooltip-content-position-top, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content, .shipping-policy-block.field-tooltip .field-tooltip-content {
  right: -10px;
  top: 40px;
  left: auto; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999; }
  .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px; }

@media only screen and (max-width: 767px) {
  .abs-checkout-tooltip-content-position-top-mobile, .field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      margin-top: -21px;
      right: 10px;
      left: auto;
      top: 0; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before {
      border-bottom-color: #999; }
    .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border-bottom-color: #f4f4f4;
      top: 1px; } }

.abs-checkout-title, .opc-wrapper .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  color: #333;
  font-weight: 300;
  font-size: 2.6rem; }

.abs-sidebar-totals .mark, .cart-totals .mark, .opc-block-summary .table-totals .mark {
  font-weight: 400;
  padding-left: 4px; }
  .abs-sidebar-totals .mark strong, .cart-totals .mark strong, .opc-block-summary .table-totals .mark strong {
    font-weight: 400; }

.abs-sidebar-totals .amount, .cart-totals .amount, .opc-block-summary .table-totals .amount {
  padding-right: 4px;
  text-align: right;
  white-space: nowrap; }
  .abs-sidebar-totals .amount strong, .cart-totals .amount strong, .opc-block-summary .table-totals .amount strong {
    font-weight: 400; }

.abs-sidebar-totals .grand .mark, .cart-totals .grand .mark, .opc-block-summary .table-totals .grand .mark,
.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-top: 20px; }


.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-right: 4px;
  text-align: right; }
  .abs-sidebar-totals .grand .amount strong, .cart-totals .grand .amount strong, .opc-block-summary .table-totals .grand .amount strong {
    font-weight: 700; }

.abs-sidebar-totals .msrp, .cart-totals .msrp, .opc-block-summary .table-totals .msrp {
  margin-bottom: 10px; }

.abs-sidebar-totals .totals-tax-summary .mark, .cart-totals .totals-tax-summary .mark, .opc-block-summary .table-totals .totals-tax-summary .mark,
.abs-sidebar-totals .totals-tax-summary .amount,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .amount {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer; }

.abs-sidebar-totals .totals-tax-summary .amount .price, .cart-totals .totals-tax-summary .amount .price, .opc-block-summary .table-totals .totals-tax-summary .amount .price {
  display: block;
  text-decoration: none;
  padding-right: 25px;
  position: relative; }
  .abs-sidebar-totals .totals-tax-summary .amount .price > span, .cart-totals .totals-tax-summary .amount .price > span, .opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: -5px;
    top: -12px; }

.abs-sidebar-totals .totals-tax-summary.expanded .mark, .cart-totals .totals-tax-summary.expanded .mark, .opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.abs-sidebar-totals .totals-tax-summary.expanded .amount,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
  border-bottom: 0; }

.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after, .cart-totals .totals-tax-summary.expanded .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
  content: ""; }

.abs-sidebar-totals .totals-tax-details, .cart-totals .totals-tax-details, .opc-block-summary .table-totals .totals-tax-details {
  display: none;
  border-bottom: 1px solid #d1d1d1; }
  .abs-sidebar-totals .totals-tax-details.shown, .cart-totals .totals-tax-details.shown, .opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row; }

.abs-discount-block > .title, .cart-summary .block > .title, .paypal-review-discount .block > .title {
  border-top: 1px solid #d1d1d1;
  cursor: pointer;
  font-weight: 600;
  display: block;
  text-decoration: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 7px 30px 7px 5px;
  position: relative; }
  .abs-discount-block > .title:after, .cart-summary .block > .title:after, .paypal-review-discount .block > .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-discount-block > .title:after, .cart-summary .block > .title:after, .paypal-review-discount .block > .title:after {
    position: absolute;
    right: -5px;
    top: -5px; }
  .column.main .abs-discount-block > .title strong, .column.main .cart-summary .block > .title strong, .cart-summary .column.main .block > .title strong, .column.main .paypal-review-discount .block > .title strong, .paypal-review-discount .column.main .block > .title strong {
    font-size: 1.8rem;
    font-weight: 400; }

.abs-discount-block > .content, .cart-summary .block > .content, .paypal-review-discount .block > .content {
  display: none; }

.abs-discount-block.active > .title:after, .cart-summary .active.block > .title:after, .paypal-review-discount .active.block > .title:after {
  content: ""; }

.abs-discount-block.active > .content, .cart-summary .active.block > .content, .paypal-review-discount .active.block > .content {
  display: block; }

.fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;
  letter-spacing: -0.31em; }
  .fieldset > * {
    letter-spacing: normal; }
  .fieldset > .legend {
    margin: 0 0 25px;
    padding: 0;
    box-sizing: border-box;
    float: left;
    line-height: 1.2;
    font-size: 2rem; }
    .fieldset > .legend + br {
      clear: both;
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
  .fieldset:last-child {
    margin-bottom: 0; }
  .fieldset > .field,
  .fieldset > .fields > .field {
    margin: 0 0 20px; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .fieldset > .field:last-child,
    .fieldset > .fields > .field:last-child {
      margin-bottom: 0; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      font-weight: 700; }
      .fieldset > .field > .label + br,
      .fieldset > .fields > .field > .label + br {
        display: none; }
    .fieldset > .field .choice input,
    .fieldset > .fields > .field .choice input {
      vertical-align: top; }
    .fieldset > .field .fields.group:before, .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:before,
    .fieldset > .fields > .field .fields.group:after {
      content: '';
      display: table; }
    .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:after {
      clear: both; }
    .fieldset > .field .fields.group .field,
    .fieldset > .fields > .field .fields.group .field {
      box-sizing: border-box;
      float: left; }
    .fieldset > .field .fields.group.group-2 .field,
    .fieldset > .fields > .field .fields.group.group-2 .field {
      width: 50% !important; }
    .fieldset > .field .fields.group.group-3 .field,
    .fieldset > .fields > .field .fields.group.group-3 .field {
      width: 33.3% !important; }
    .fieldset > .field .fields.group.group-4 .field,
    .fieldset > .fields > .field .fields.group.group-4 .field {
      width: 25% !important; }
    .fieldset > .field .fields.group.group-5 .field,
    .fieldset > .fields > .field .fields.group.group-5 .field {
      width: 20% !important; }
    .fieldset > .field .addon,
    .fieldset > .fields > .field .addon {
      display: inline-flex;
      flex-wrap: nowrap;
      padding: 0;
      width: 100%; }
      .fieldset > .field .addon textarea,
      .fieldset > .field .addon select,
      .fieldset > .field .addon input,
      .fieldset > .fields > .field .addon textarea,
      .fieldset > .fields > .field .addon select,
      .fieldset > .fields > .field .addon input {
        order: 2;
        flex-basis: 100%;
        display: inline-block;
        margin: 0;
        width: auto; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .field .addon .addafter,
      .fieldset > .fields > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addafter {
        background: #fff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        order: 3;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        width: auto; }
        .fieldset > .field .addon .addbefore:disabled,
        .fieldset > .field .addon .addafter:disabled,
        .fieldset > .fields > .field .addon .addbefore:disabled,
        .fieldset > .fields > .field .addon .addafter:disabled {
          opacity: 0.5; }
        .fieldset > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .field .addon .addafter::-moz-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .fields > .field .addon .addafter::-moz-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .field .addon .addafter::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .field .addon .addafter:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
          color: #c2c2c2; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addbefore {
        float: left;
        order: 1; }
    .fieldset > .field .additional,
    .fieldset > .fields > .field .additional {
      margin-top: 10px; }
    .fieldset > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
      content: '*';
      color: #e02b27;
      font-size: 1.2rem;
      margin: 0 0 0 5px; }
    .fieldset > .field .note,
    .fieldset > .fields > .field .note {
      font-size: 1.2rem;
      margin: 3px 0 0;
      padding: 0;
      display: inline-block;
      text-decoration: none; }
      .fieldset > .field .note:before,
      .fieldset > .fields > .field .note:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        line-height: 12px;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
    .fieldset > .field.choice .label,
    .fieldset > .fields > .field.choice .label {
      display: inline;
      font-weight: normal; }
    .column:not(.main) .fieldset > .field .label, .column:not(.main)
    .fieldset > .fields > .field .label {
      font-weight: normal; }
    .fieldset > .field .field.choice,
    .fieldset > .fields > .field .field.choice {
      margin-bottom: 10px; }
      .fieldset > .field .field.choice:last-child,
      .fieldset > .fields > .field .field.choice:last-child {
        margin-bottom: 0; }
    .fieldset > .field input[type=file],
    .fieldset > .fields > .field input[type=file] {
      margin: 5px 0; }

.legend + .fieldset,
.legend + div {
  clear: both; }

.legend strong {
  margin-left: 5px; }

fieldset.field {
  border: 0;
  padding: 0; }

.field.date .time-picker {
  display: inline-block;
  margin-top: 10px;
  white-space: nowrap; }

.field .message.warning {
  margin-top: 10px; }

.field-error,
div.mage-error[generated] {
  margin-top: 7px; }

.field-error {
  color: #e02b27;
  font-size: 1.2rem; }

.field .tooltip {
  position: relative; }
  .field .tooltip .tooltip-content {
    background: #fff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-left: 5px;
    left: 100%;
    top: 0; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .field .tooltip .tooltip-content:after {
      border-width: 5px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:before {
      border-width: 6px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      right: 100%; }
    .field .tooltip .tooltip-content:after {
      border-right-color: #fff;
      margin-top: -5px;
      top: 15px; }
    .field .tooltip .tooltip-content:before {
      border-right-color: #bbb;
      margin-top: -6px;
      top: 15px; }
  .field .tooltip .tooltip-toggle {
    cursor: help; }
  .field .tooltip .tooltip-toggle:hover + .tooltip-content,
  .field .tooltip .tooltip-toggle:focus + .tooltip-content,
  .field .tooltip:hover .tooltip-content {
    display: block; }
  .field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal; }

input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
  display: block; }

._has-datepicker ~ .ui-datepicker-trigger {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: block;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus, ._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger.disabled, ._has-datepicker ~ .ui-datepicker-trigger[disabled],
  fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  ._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  ._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    line-height: 40px;
    color: #7d7d7d;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0; }

.sidebar .fieldset {
  margin: 0; }
  .sidebar .fieldset > .field:not(.choice):not(:last-child),
  .sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px; }
  .sidebar .fieldset > .field:not(.choice) .label,
  .sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%; }
  .sidebar .fieldset > .field:not(.choice) .control,
  .sidebar .fieldset .fields > .field .control {
    width: 100%; }

@media only screen and (min-width: 768px) {
  .fieldset > .field {
    margin: 0 0 20px;
    box-sizing: border-box;
    margin: 0 0 29px; }
    .fieldset > .field > .label {
      margin: 0; }
    .fieldset > .field:before, .fieldset > .field:after {
      content: '';
      display: table; }
    .fieldset > .field:after {
      clear: both; }
    .fieldset > .field.choice:before, .fieldset > .field.no-label:before {
      padding: 6px 15px 0 0;
      width: 25.8%;
      box-sizing: border-box;
      content: ' ';
      float: left;
      height: 1px; }
    .fieldset > .field .description {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .label {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .control {
      width: 74.2%;
      float: left; } }

@font-face {
  font-family: "icons-blank-theme";
  src: url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot");
  src: url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot?#iefix") format("embedded-opentype"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff2") format("woff2"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff") format("woff"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.ttf") format("truetype"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.svg#icons-blank-theme") format("svg");
  font-weight: normal;
  font-style: normal; }

.columns {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }
  .columns:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden; }
  .columns .column.main {
    padding-bottom: 40px;
    flex-basis: 100%;
    flex-grow: 1;
    order: 1;
    width: 100%; }
  .columns .sidebar-main {
    flex-grow: 1;
    flex-basis: 100%;
    order: 1; }
  .columns .sidebar-additional {
    flex-grow: 1;
    flex-basis: 100%;
    order: 2; }

@media only screen and (max-width: 767px) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-main,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container {
    padding-left: 15px;
    padding-right: 15px; }
  .account .page-main,
  .cms-privacy-policy .page-main {
    padding-top: 41px;
    position: relative; } }

@media only screen and (min-width: 768px) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container,
  .page-main {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto; }
  .page-main {
    width: 100%;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */ }
    .ie9 .page-main {
      width: auto; }
  .columns {
    display: block; }
  .column.main {
    min-height: 300px; }
    .page-layout-1column .column.main {
      width: 100%;
      order: 2; }
    .page-layout-3columns .column.main {
      width: 66.66667%;
      display: inline-block;
      order: 2; }
    .page-layout-2columns-left .column.main {
      width: 83.33333%;
      float: right;
      order: 2; }
    .page-layout-2columns-right .column.main {
      width: 83.33333%;
      float: left;
      order: 1; }
  .sidebar-main {
    padding-right: 2%; }
    .page-layout-3columns .sidebar-main {
      width: 16.66667%;
      float: left;
      order: 1; }
    .page-layout-2columns-left .sidebar-main {
      width: 16.66667%;
      float: left;
      order: 1; }
    .page-layout-2columns-right .sidebar-main {
      width: 16.66667%;
      float: left;
      order: 1; }
  .page-layout-2columns-right .sidebar-main {
    padding-left: 2%;
    padding-right: 0; }
  .sidebar-additional {
    clear: right;
    padding-left: 2%; }
    .page-layout-3columns .sidebar-additional {
      width: 16.66667%;
      float: right;
      order: 3; }
    .page-layout-2columns-left .sidebar-additional {
      width: 16.66667%;
      float: right;
      order: 2; }
    .page-layout-2columns-right .sidebar-additional {
      width: 16.66667%;
      float: right;
      order: 2; }
  .page-layout-2columns-left .sidebar-additional {
    clear: left;
    float: left;
    padding-left: 0;
    padding-right: 2%; }
  .panel.header {
    padding: 10px 20px; } }

.load.indicator {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  position: absolute; }
  .load.indicator:before {
    background: transparent url("../images/loader-2.gif") no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }
  .load.indicator > span {
    display: none; }

.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5); }
  .loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; }
  .loading-mask .loader > p {
    display: none; }

body > .loading-mask {
  z-index: 9999; }

._block-content-loading {
  position: relative; }

.message.info {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.info a {
    color: #1979c3; }
    .message.info a:hover {
      color: #006bb4; }
    .message.info a:active {
      color: #006bb4; }
  .message.info > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.error {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fae5e5;
  color: #e02b27;
  padding-left: 40px;
  position: relative; }
  .message.error a {
    color: #1979c3; }
    .message.error a:hover {
      color: #006bb4; }
    .message.error a:active {
      color: #006bb4; }
  .message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #b30000;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.warning {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.warning a {
    color: #1979c3; }
    .message.warning a:hover {
      color: #006bb4; }
    .message.warning a:active {
      color: #006bb4; }
  .message.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.notice {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.notice a {
    color: #1979c3; }
    .message.notice a:hover {
      color: #006bb4; }
    .message.notice a:active {
      color: #006bb4; }
  .message.notice > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.success {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #e5efe5;
  color: #006400;
  padding-left: 40px;
  position: relative; }
  .message.success a {
    color: #1979c3; }
    .message.success a:hover {
      color: #006bb4; }
    .message.success a:active {
      color: #006bb4; }
  .message.success > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #006400;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.panel.header .links,
.panel.header .switcher {
  display: none; }

.nav-sections {
  background: #d6aa03; }

.nav-toggle {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: block;
  font-size: 0;
  position: absolute;
  left: 50px;
  top: 58px;
  z-index: 14; }
  .nav-toggle:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: inherit;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .nav-toggle:hover:before {
    color: #333; }
  .nav-toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.nav-close {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: block;
  font-size: 0;
  position: absolute;
  left: 41px;
  top: 43px;
  z-index: 14; }
  .nav-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 45px;
    line-height: inherit;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .nav-close:hover:before {
    color: #333; }
  .nav-close:before {
    color: #fff; }

.navigation {
  padding: 0;
  text-align: center; }
  .navigation .parent .level-top {
    position: relative;
    display: block;
    text-decoration: none; }
    .navigation .parent .level-top:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 42px;
      line-height: inherit;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .navigation .parent .level-top:after {
      position: absolute;
      right: 7px;
      top: -8px; }
    .navigation .parent .level-top.ui-state-active:after {
      content: ""; }
  .navigation .level0 {
    font-size: 2.6rem !important;
    border: none !important; }
  .navigation a {
    border: none !important;
    padding: 10px 0 10px 0px !important; }

.nav-sections {
  -webkit-overflow-scrolling: touch;
  transition: left 0.3s;
  height: 100%;
  left: -100%;
  left: calc(-1 * (100% - 0px));
  overflow: auto;
  position: fixed;
  top: 0;
  width: 80%;
  width: calc(100% - 0px); }
  .nav-sections .switcher {
    border-top: 1px solid #d1d1d1;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0;
    padding: 0.8rem 3.5rem 0.8rem 2rem; }
    .nav-sections .switcher .label {
      display: block;
      margin-bottom: 5px; }
    .nav-sections .switcher:last-child {
      border-bottom: 1px solid #d1d1d1; }
  .nav-sections .switcher-trigger strong {
    position: relative;
    display: block;
    text-decoration: none; }
    .nav-sections .switcher-trigger strong:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 42px;
      line-height: inherit;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .nav-sections .switcher-trigger strong:after {
      position: absolute;
      right: -40px;
      top: -16px; }
  .nav-sections .switcher-trigger.active strong:after {
    content: ""; }
  .nav-sections .switcher-dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    padding: 10px 0; }
  .nav-sections .header.links {
    margin: 0;
    padding: 0;
    list-style: none none;
    border-bottom: 1px solid #d1d1d1; }
    .nav-sections .header.links li {
      font-size: 1.6rem;
      margin: 0; }
      .nav-sections .header.links li.greet.welcome {
        border-top: 1px solid #d1d1d1;
        font-weight: 700;
        padding: 0.8rem 20px; }
      .nav-sections .header.links li > a {
        border-top: 1px solid #d1d1d1; }
    .nav-sections .header.links a,
    .nav-sections .header.links a:hover {
      color: #fff;
      text-decoration: none;
      display: block;
      font-weight: 700;
      padding: 0.8rem 20px; }
    .nav-sections .header.links .header.links {
      border: 0; }

.nav-before-open {
  height: 100%;
  overflow-x: hidden;
  width: 100%; }
  .nav-before-open .page-wrapper {
    transition: left 0.3s;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: relative; }
  .nav-before-open body {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%; }

.nav-open .page-wrapper {
  left: 80%;
  left: calc(100% - 0px); }

.nav-open .nav-sections {
  box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
  left: 0;
  z-index: 99; }

.nav-open .nav-toggle:after {
  background: rgba(0, 0, 0, 0.5);
  content: '';
  display: block;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1; }

.nav-sections-items {
  position: relative;
  z-index: 1; }
  .nav-sections-items:before, .nav-sections-items:after {
    content: '';
    display: table; }
  .nav-sections-items:after {
    clear: both; }

.nav-sections-item-title {
  display: none;
  background: #bd9603;
  border: solid #a48202;
  border-width: 0 0 1px 1px;
  box-sizing: border-box;
  float: left;
  height: 71px;
  padding-top: 24px;
  text-align: center;
  width: 33.33%; }
  .nav-sections-item-title.active {
    background: transparent;
    border-bottom: 0; }
  .nav-sections-item-title .nav-sections-item-switch:hover {
    text-decoration: none; }

.nav-sections-item-content {
  box-sizing: border-box;
  float: right;
  margin-left: -100%;
  /* margin-top: 71px; */
  width: 100%;
  padding: 25px 0; }
  .nav-sections-item-content:before, .nav-sections-item-content:after {
    content: '';
    display: table; }
  .nav-sections-item-content:after {
    clear: both; }
  .nav-sections-item-content.active {
    display: block; }

.navigation {
  background: #d6aa03;
  box-sizing: border-box; }
  .navigation ul {
    margin: 0;
    padding: 0; }
  .navigation li {
    margin: 0; }
  .navigation a {
    display: block;
    padding: 10px 0 10px 15px; }
  .navigation a,
  .navigation a:hover {
    color: #fff;
    text-decoration: none; }
  .navigation .level0 {
    border-top: 1px solid #d1d1d1;
    font-size: 1.6rem; }
    .navigation .level0 > .level-top {
      font-weight: 700;
      padding: 8px 40px 8px 15px;
      text-transform: uppercase;
      word-wrap: break-word; }
    .navigation .level0.active .all-category .ui-state-focus {
      border-color: #ff5501;
      border-style: solid;
      border-width: 0 0 0 8px;
      padding-left: 7px;
      display: inline-block; }
    .navigation .level0 > .level1 {
      font-weight: 600; }
    .navigation .level0.active > a:not(.ui-state-active), .navigation .level0.has-active > a:not(.ui-state-active) {
      border-color: #ff5501;
      border-style: solid;
      border-width: 0 0 0 8px; }
      .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
        margin-left: -8px; }
  .navigation li.level0:last-child {
    border-bottom: 1px solid #d1d1d1; }
  .navigation .submenu > li {
    word-wrap: break-word; }
  .navigation .submenu:not(:first-child) {
    font-weight: 400;
    line-height: 1.3;
    left: auto !important;
    overflow-x: hidden;
    padding: 0;
    position: relative;
    top: auto !important;
    transition: left 0.3s ease-out; }
    .navigation .submenu:not(:first-child) > li > a {
      padding-left: 15px; }
    .navigation .submenu:not(:first-child) > li:last-child {
      margin-bottom: 0; }
    .navigation .submenu:not(:first-child) ul {
      display: block;
      padding-left: 15px; }
      .navigation .submenu:not(:first-child) ul > li {
        margin: 0; }
        .navigation .submenu:not(:first-child) ul > li a {
          color: #fff;
          display: block;
          line-height: normal; }
    .navigation .submenu:not(:first-child).expanded {
      display: block !important;
      padding-right: 0;
      top: 0 !important; }
    .navigation .submenu:not(:first-child) .active > a {
      border-color: #ff5501;
      border-style: solid;
      border-width: 0 0 0 8px;
      padding-left: 7px; }
    .navigation .submenu:not(:first-child) .level1.active > a {
      padding-left: 7px; }

.pages > .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.pages .items {
  font-size: 0;
  letter-spacing: -1px;
  line-height: 0;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none none;
  display: inline-block;
  font-weight: 700; }

.pages .item {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  margin: 0 2px 0 0;
  display: inline-block; }
  .pages .item .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.pages a.page {
  color: #1979c3;
  display: inline-block;
  padding: 0 4px;
  text-decoration: none; }
  .pages a.page:visited {
    color: #1979c3; }
  .pages a.page:hover {
    color: #006bb4;
    text-decoration: none; }
  .pages a.page:active {
    color: #ff5501; }

.pages strong.page {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  color: #333;
  display: inline-block;
  font-weight: 700;
  padding: 0 4px; }

.pages .action {
  border: 1px solid #d1d1d1;
  color: #7d7d7d;
  display: inline-block;
  padding: 0;
  text-decoration: none; }
  .pages .action:visited {
    color: #7d7d7d; }
  .pages .action:hover {
    color: #7d7d7d;
    text-decoration: none; }
  .pages .action:active {
    color: #7d7d7d; }
  .pages .action.next {
    display: inline-block;
    text-decoration: none; }
    .pages .action.next:visited:before {
      color: #7d7d7d; }
    .pages .action.next:active:before {
      color: #7d7d7d; }
    .pages .action.next > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.next:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .pages .action.next:hover:before {
      color: #7d7d7d; }
    .pages .action.next:active:before {
      color: #7d7d7d; }
  .pages .action.previous {
    display: inline-block;
    text-decoration: none; }
    .pages .action.previous:visited:before {
      color: #7d7d7d; }
    .pages .action.previous:active:before {
      color: #7d7d7d; }
    .pages .action.previous > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.previous:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .pages .action.previous:hover:before {
      color: #7d7d7d; }
    .pages .action.previous:active:before {
      color: #7d7d7d; }

.pages .action {
  width: 34px; }
  .pages .action.previous {
    margin-right: 12px; }
  .pages .action.next {
    margin-left: 12px; }

.ui-dialog.popup .action.close {
  position: absolute;
  height: 40px;
  right: 0;
  top: 0;
  width: 40px;
  z-index: 1001;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .ui-dialog.popup .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .ui-dialog.popup .action.close:hover:before {
    color: inherit; }
  .ui-dialog.popup .action.close:active:before {
    color: inherit; }
  .ui-dialog.popup .action.close:focus, .ui-dialog.popup .action.close:active {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close:hover {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close.disabled, .ui-dialog.popup .action.close[disabled],
  fieldset[disabled] .ui-dialog.popup .action.close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.popup-pointer {
  margin-left: -14px;
  position: absolute;
  top: -14px; }
  .popup-pointer:before, .popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid; }
  .popup-pointer:before {
    left: 2px;
    top: 2px;
    border: solid 6px;
    border-color: transparent transparent #fff transparent;
    z-index: 99; }
  .popup-pointer:after {
    left: 1px;
    top: 0;
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98; }

@media only screen and (min-width: 640px) {
  .ui-dialog.popup {
    width: 420px; } }

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
  display: inline; }

.price-including-tax,
.price-excluding-tax {
  display: block;
  font-size: 1.8rem;
  line-height: 1; }
  .price-including-tax .price,
  .price-excluding-tax .price {
    font-weight: 700; }

.price-including-tax + .price-excluding-tax,
.weee[data-label] {
  display: block;
  font-size: 1.8rem; }
  .price-including-tax + .price-excluding-tax:before,
  .weee[data-label]:before {
    content: attr(data-label) ": ";
    font-size: 1.1rem; }
  .price-including-tax + .price-excluding-tax .price,
  .weee[data-label] .price {
    font-size: 1.1rem; }

.product.data.items {
  margin: 0;
  padding: 0;
  margin-bottom: 20px; }
  .product.data.items > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .product.data.items > .item.title > .switch {
      display: block; }
  .product.data.items > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .product.data.items > .item.content:before, .product.data.items > .item.content:after {
      content: '';
      display: table; }
    .product.data.items > .item.content:after {
      clear: both; }
    .product.data.items > .item.content.active {
      display: block; }
  .product.data.items > .item.title {
    margin: 0 0 5px; }
  .product.data.items > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #7d7d7d;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #7d7d7d;
    text-decoration: none; }
    .product.data.items > .item.title > .switch:visited {
      color: #7d7d7d;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:hover {
      color: #7d7d7d;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:active {
      color: #333;
      text-decoration: none; }
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .product.data.items > .item.title:not(.disabled) > .switch:active,
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .product.data.items > .item.content {
    background: white;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

@media only screen and (min-width: 768px) {
  .product.data.items {
    position: relative;
    z-index: 1; }
    .product.data.items:before, .product.data.items:after {
      content: '';
      display: table; }
    .product.data.items:after {
      clear: both; }
    .product.data.items > .item.title {
      float: left;
      width: auto; }
      .product.data.items > .item.title > .switch {
        height: 20px;
        display: block;
        position: relative;
        z-index: 2; }
    .product.data.items > .item.content {
      margin-top: 20px;
      box-sizing: border-box;
      float: right;
      margin-left: -100%;
      width: 100%; }
      .product.data.items > .item.content:before, .product.data.items > .item.content:after {
        content: '';
        display: table; }
      .product.data.items > .item.content:after {
        clear: both; }
      .product.data.items > .item.content.active {
        display: block; }
    .product.data.items > .item.title {
      margin: 0 5px 0 0; }
      .product.data.items > .item.title > .switch {
        color: #7d7d7d;
        font-weight: 600;
        line-height: 20px;
        font-size: 1.4rem;
        color: #7d7d7d;
        text-decoration: none;
        background: #f0f0f0;
        border: 1px solid #d1d1d1;
        border-bottom: none;
        height: 20px;
        padding: 5px 20px 5px 20px; }
        .product.data.items > .item.title > .switch:visited {
          color: #7d7d7d;
          text-decoration: none; }
        .product.data.items > .item.title > .switch:hover {
          color: #7d7d7d;
          text-decoration: none; }
        .product.data.items > .item.title > .switch:active {
          color: #333;
          text-decoration: none; }
      .product.data.items > .item.title:not(.disabled) > .switch:focus,
      .product.data.items > .item.title:not(.disabled) > .switch:hover {
        background: #fcfcfc; }
      .product.data.items > .item.title:not(.disabled) > .switch:active,
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title.active > .switch:focus,
      .product.data.items > .item.title.active > .switch:hover {
        background: white;
        color: #333; }
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title.active > .switch:focus,
      .product.data.items > .item.title.active > .switch:hover {
        padding-bottom: 6px; }
    .product.data.items > .item.content {
      background: white;
      margin-top: 31px;
      padding: 20px 20px 20px 20px;
      border: 0;
      border-top: 1px solid #d1d1d1; } }

@media only screen and (max-width: 767px) {
  .product.data.items {
    margin: 0;
    padding: 0; }
    .product.data.items > .item.title {
      box-sizing: border-box;
      float: none;
      width: 100%; }
      .product.data.items > .item.title > .switch {
        display: block; }
    .product.data.items > .item.content {
      box-sizing: border-box;
      display: block;
      float: none;
      margin: 0; }
      .product.data.items > .item.content:before, .product.data.items > .item.content:after {
        content: '';
        display: table; }
      .product.data.items > .item.content:after {
        clear: both; }
      .product.data.items > .item.content.active {
        display: block; }
    .product.data.items > .item.title {
      margin: 0 0 5px; }
    .product.data.items > .item.title > .switch {
      background: #f0f0f0;
      border-bottom: 1px solid #d1d1d1;
      border-left: 1px solid #d1d1d1;
      border-right: 1px solid #d1d1d1;
      border-top: 1px solid #d1d1d1;
      height: 40px;
      padding: 5px 20px 5px 20px;
      color: #7d7d7d;
      font-weight: 600;
      line-height: 40px;
      font-size: 1.8rem;
      color: #7d7d7d;
      text-decoration: none; }
      .product.data.items > .item.title > .switch:visited {
        color: #7d7d7d;
        text-decoration: none; }
      .product.data.items > .item.title > .switch:hover {
        color: #7d7d7d;
        text-decoration: none; }
      .product.data.items > .item.title > .switch:active {
        color: #333;
        text-decoration: none; }
    .product.data.items > .item.title:not(.disabled) > .switch:focus,
    .product.data.items > .item.title:not(.disabled) > .switch:hover {
      background: #fcfcfc; }
    .product.data.items > .item.title:not(.disabled) > .switch:active,
    .product.data.items > .item.title.active > .switch,
    .product.data.items > .item.title.active > .switch:focus,
    .product.data.items > .item.title.active > .switch:hover {
      background: white;
      padding-bottom: 5px; }
    .product.data.items > .item.content {
      background: white;
      border: 1px solid #d1d1d1;
      margin: 0 0 5px;
      padding: 20px 20px 20px 20px; }
    .product.data.items .data.item {
      display: block; } }

.table-wrapper {
  margin-bottom: 20px; }

table > caption {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.table:not(.cart):not(.totals) {
  border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td,
  .table:not(.cart):not(.totals) > tbody > tr > th,
  .table:not(.cart):not(.totals) > tbody > tr > td,
  .table:not(.cart):not(.totals) > tfoot > tr > th,
  .table:not(.cart):not(.totals) > tfoot > tr > td {
    border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: 1px solid #d1d1d1; }
  .table:not(.cart):not(.totals) tfoot > tr:first-child th,
  .table:not(.cart):not(.totals) tfoot > tr:first-child td {
    border-top: 1px solid #d1d1d1;
    padding-top: 20px; }
  .table:not(.cart):not(.totals) tfoot .mark {
    font-weight: 400;
    text-align: right; }

@media only screen and (max-width: 639px) {
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    position: relative; }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
      border: none;
      display: block; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
        display: none; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
        display: block; }
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
          display: block; }
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
            border-bottom: none;
            display: block;
            padding: 5px 0; }
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
              padding-right: 10px;
              content: attr(data-th) ": ";
              display: inline-block;
              color: #111111;
              font-weight: 700; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child {
        padding-top: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:last-child {
        padding-bottom: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 5px; }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
      display: block; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block; }
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
          padding-top: 20px; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%; }
  .data-table-definition-list thead {
    display: none; }
  .data-table-definition-list tbody th {
    padding-bottom: 0; }
  .data-table-definition-list tbody th,
  .data-table-definition-list tbody td {
    display: block;
    padding-left: 0;
    padding-right: 0; } }

.tooltip.wrapper {
  position: relative; }
  .tooltip.wrapper .tooltip.content {
    background: #fff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-top: 5px;
    left: 0;
    top: 100%; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .tooltip.wrapper .tooltip.content:after {
      border-width: 5px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:before {
      border-width: 6px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      bottom: 100%; }
    .tooltip.wrapper .tooltip.content:after {
      border-bottom-color: #fff;
      left: 15px;
      margin-left: -5px; }
    .tooltip.wrapper .tooltip.content:before {
      border-bottom-color: #bbb;
      left: 15px;
      margin-left: -6px; }
  .tooltip.wrapper .tooltip.toggle {
    cursor: help; }
  .tooltip.wrapper .tooltip.toggle:hover + .tooltip.content,
  .tooltip.wrapper .tooltip.toggle:focus + .tooltip.content,
  .tooltip.wrapper:hover .tooltip.content {
    display: block; }
  .tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0; }
  .tooltip.wrapper .tooltip.content dd {
    white-space: normal; }
  .tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px; }
  .tooltip.wrapper .tooltip.content .label {
    margin-top: 10px; }
    .tooltip.wrapper .tooltip.content .label:first-child {
      margin-top: 0; }
  .tooltip.wrapper .tooltip.content .values {
    margin: 0; }

.ui-tooltip {
  position: absolute;
  z-index: 9999; }

@media only screen and (max-width: 479px) {
  .tooltip.wrapper .tooltip.content {
    min-width: 10rem; } }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/light/opensans-300.eot");
  src: url("../fonts/opensans/light/opensans-300.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/light/opensans-300.woff2") format("woff2"), url("../fonts/opensans/light/opensans-300.woff") format("woff"), url("../fonts/opensans/light/opensans-300.ttf") format("truetype"), url("../fonts/opensans/light/opensans-300.svg#Open Sans") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/regular/opensans-400.eot");
  src: url("../fonts/opensans/regular/opensans-400.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/regular/opensans-400.woff2") format("woff2"), url("../fonts/opensans/regular/opensans-400.woff") format("woff"), url("../fonts/opensans/regular/opensans-400.ttf") format("truetype"), url("../fonts/opensans/regular/opensans-400.svg#Open Sans") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/semibold/opensans-600.eot");
  src: url("../fonts/opensans/semibold/opensans-600.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/semibold/opensans-600.woff2") format("woff2"), url("../fonts/opensans/semibold/opensans-600.woff") format("woff"), url("../fonts/opensans/semibold/opensans-600.ttf") format("truetype"), url("../fonts/opensans/semibold/opensans-600.svg#Open Sans") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/bold/opensans-700.eot");
  src: url("../fonts/opensans/bold/opensans-700.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/bold/opensans-700.woff2") format("woff2"), url("../fonts/opensans/bold/opensans-700.woff") format("woff"), url("../fonts/opensans/bold/opensans-700.ttf") format("truetype"), url("../fonts/opensans/bold/opensans-700.svg#Open Sans") format("svg");
  font-weight: 700;
  font-style: normal; }

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    margin-bottom: 40px; } }

.items {
  margin: 0;
  padding: 0;
  list-style: none none; }

body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.modals-overlay {
  z-index: 1000; }

.modal-slide,
.modal-popup {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease; }
  .modal-slide._show,
  .modal-popup._show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease; }
    .modal-slide._show .modal-inner-wrap,
    .modal-popup._show .modal-inner-wrap {
      transform: translate(0, 0); }
  .modal-slide .modal-inner-wrap,
  .modal-popup .modal-inner-wrap {
    background-color: #fff;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto; }

.modal-slide {
  left: 14.8rem;
  z-index: 900; }
  .modal-slide._show .modal-inner-wrap {
    transform: translateX(0); }
  .modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: auto; }
  .modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: flex;
    flex-direction: column; }
  .modal-slide._inner-scroll .modal-header,
  .modal-slide._inner-scroll .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-slide._inner-scroll .modal-content {
    overflow-y: auto; }
  .modal-slide._inner-scroll .modal-footer {
    margin-top: auto; }
  .modal-slide .modal-header,
  .modal-slide .modal-content,
  .modal-slide .modal-footer {
    padding: 0 2.6rem 2.6rem; }
  .modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem; }

.modal-popup {
  z-index: 900;
  left: 0;
  overflow-y: auto; }
  .modal-popup._show .modal-inner-wrap {
    transform: translateY(0); }
  .modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(-200%);
    transition: transform 0.2s ease; }
  .modal-popup._inner-scroll {
    overflow-y: visible; }
    .ie11 .modal-popup._inner-scroll,
    .ie10 .modal-popup._inner-scroll,
    .ie9 .modal-popup._inner-scroll {
      overflow-y: auto; }
    .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%; }
      .ie11 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie10 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
        max-height: none; }
    .modal-popup._inner-scroll .modal-content {
      overflow-y: auto; }
  .modal-popup .modal-header,
  .modal-popup .modal-content,
  .modal-popup .modal-footer {
    padding-left: 3rem;
    padding-right: 3rem; }
  .modal-popup .modal-header,
  .modal-popup .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-popup .modal-header {
    padding-bottom: 1.2rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 3rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer-actions {
    text-align: right; }

@media only screen and (max-width: 767px) {
  .modal-popup.modal-slide {
    left: 14.8rem;
    z-index: 900; }
    .modal-popup.modal-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .modal-popup.modal-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .modal-popup.modal-slide .modal-inner-wrap {
      margin: 0;
      max-height: none; } }

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0; }
  .modal-custom .action-close:focus, .modal-custom .action-close:active,
  .modal-popup .action-close:focus,
  .modal-popup .action-close:active,
  .modal-slide .action-close:focus,
  .modal-slide .action-close:active {
    background: none;
    border: none; }
  .modal-custom .action-close:hover,
  .modal-popup .action-close:hover,
  .modal-slide .action-close:hover {
    background: none;
    border: none; }
  .modal-custom .action-close.disabled, .modal-custom .action-close[disabled],
  fieldset[disabled] .modal-custom .action-close,
  .modal-popup .action-close.disabled,
  .modal-popup .action-close[disabled],
  fieldset[disabled]
  .modal-popup .action-close,
  .modal-slide .action-close.disabled,
  .modal-slide .action-close[disabled],
  fieldset[disabled]
  .modal-slide .action-close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .modal-custom .action-close > span,
  .modal-popup .action-close > span,
  .modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .modal-custom .action-close:before,
  .modal-popup .action-close:before,
  .modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 32px;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: inherit; }
  .modal-custom .action-close:active:before,
  .modal-popup .action-close:active:before,
  .modal-slide .action-close:active:before {
    color: inherit; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: #1a1a1a; }

.modal-custom .action-close {
  margin: 25px; }

.modal-popup {
  pointer-events: none; }
  .modal-popup .modal-title {
    border-bottom: 1px solid #c1c1c1;
    font-weight: 300;
    padding-bottom: 10px;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word; }
  .modal-popup .action-close {
    padding: 10px; }

.modal-slide .action-close {
  padding: 1.1rem 2rem; }

.modal-slide .page-main-actions {
  margin-bottom: calc(2.1rem - 15px);
  margin-top: 2.1rem; }

.modals-overlay {
  background-color: rgba(51, 51, 51, 0.55);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000; }

@media only screen and (max-width: 767px) {
  .custom-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
    left: 14.8rem;
    z-index: 900; }
    .custom-slide._show {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease; }
      .custom-slide._show .modal-inner-wrap {
        transform: translate(0, 0); }
    .custom-slide .modal-inner-wrap {
      background-color: #fff;
      box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
      opacity: 1;
      pointer-events: auto; }
    .custom-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .custom-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .custom-slide._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto; }
    .custom-slide .modal-inner-wrap {
      background-color: #f4f4f4;
      box-sizing: border-box;
      height: auto;
      min-height: 100%; }
  body._has-modal-custom {
    height: 100vh;
    overflow: hidden;
    width: 100vw; }
    body._has-modal-custom .modal-custom-overlay {
      background-color: rgba(51, 51, 51, 0.55); } }

@media only screen and (max-width: 767px) {
  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #f4f4f4; }
  .modal-popup.modal-slide._inner-scroll._show {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    height: auto;
    min-height: 100%; }
  .modal-popup .modal-title {
    font-size: 14px;
    font-weight: 700; } }

@media only screen and (min-width: 768px) {
  .modal-popup.modal-slide .modal-footer {
    border-top: 1px solid #c1c1c1;
    text-align: right; } }

.column .block-addbysku .fieldset {
  margin: 0; }
  .column .block-addbysku .fieldset .fields {
    position: relative; }
    .column .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
    .column .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 4px; }

.column .block-addbysku .sku {
  margin-right: -155px;
  padding-right: 160px;
  width: 100%; }

.column .block-addbysku .skus > .label {
  margin-top: 10px; }

.column .block-addbysku .qty .qty + .mage-error {
  width: 80px; }

.column .block-addbysku .block-content .actions-toolbar {
  clear: both;
  margin: 0 0 20px;
  text-align: left; }
  .column .block-addbysku .block-content .actions-toolbar:before, .column .block-addbysku .block-content .actions-toolbar:after {
    content: '';
    display: table; }
  .column .block-addbysku .block-content .actions-toolbar:after {
    clear: both; }
  .column .block-addbysku .block-content .actions-toolbar .primary {
    float: left; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: inline-block; }
    .column .block-addbysku .block-content .actions-toolbar .primary a.action,
    .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
      display: inline-block; }
  .column .block-addbysku .block-content .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: block;
    float: none; }
  .column .block-addbysku .block-content .actions-toolbar > .secondary {
    text-align: left; }

.column .block-addbysku .reset {
  margin: 10px 0; }

.column .block-addbysku .note {
  display: block;
  margin: 0 0 20px; }

.block-cart-failed {
  margin-top: 40px; }
  .block-cart-failed .block-title {
    font-size: 2.6rem; }
    .block-cart-failed .block-title strong {
      font-weight: 300; }
  .block-cart-failed .actions {
    text-align: center; }
    .block-cart-failed .actions .action {
      margin-bottom: 10px; }

@media only screen and (max-width: 639px) {
  .column .block-addbysku .sku {
    margin-right: -85px;
    padding-right: 90px; }
  .column .block-addbysku .action.remove {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400; }
    .column .block-addbysku .action.remove > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .column .block-addbysku .action.remove:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 1px 0 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .column .block-addbysku .action.remove:hover:before {
      color: inherit; }
    .column .block-addbysku .action.remove:active:before {
      color: inherit; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; } }

@media only screen and (min-width: 640px) {
  .column .block-addbysku .action.remove {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857;
    margin: 0;
    padding: 0;
    color: #1979c3;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    margin-top: 2px; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    .column .block-addbysku .action.remove:visited {
      color: #1979c3;
      text-decoration: none; }
    .column .block-addbysku .action.remove:hover {
      color: #006bb4;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:active {
      color: #ff5501;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:hover {
      color: #006bb4; }
    .column .block-addbysku .action.remove:hover, .column .block-addbysku .action.remove:active, .column .block-addbysku .action.remove:focus {
      background: none;
      border: 0; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      color: #1979c3;
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      text-decoration: underline; } }

@media only screen and (min-width: 768px) {
  .block-cart-failed .actions {
    text-align: left; }
  .block-cart-failed .actions.primary {
    float: right; } }

.payment-method-braintree .hosted-control {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  width: 225px; }
  .payment-method-braintree .hosted-control:disabled {
    opacity: 0.5; }
  .payment-method-braintree .hosted-control::-moz-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control::-webkit-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control:-ms-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-focused {
    border-color: #006bb4; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
    border-color: #e02b27; }
    .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
      height: auto;
      opacity: 1; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-valid {
    border-color: #006400; }
  .payment-method-braintree .hosted-control.hosted-cid {
    width: 5rem; }
  .payment-method-braintree .hosted-control.hosted-date {
    float: left;
    margin-bottom: 7px;
    width: 6rem; }
    .payment-method-braintree .hosted-control.hosted-date:first-of-type {
      margin-right: 50px; }
      .payment-method-braintree .hosted-control.hosted-date:first-of-type:after {
        content: '/';
        display: inline;
        padding-bottom: inherit;
        padding-top: inherit;
        position: absolute;
        right: -25px;
        top: 5px; }

.payment-method-braintree .field-tooltip {
  right: 0; }

.payment-method-braintree .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 1.2rem;
  height: 0;
  margin-top: 7px;
  opacity: 0; }

.braintree-paypal-logo {
  margin-top: 10px;
  text-align: center; }

.braintree-paypal-account {
  padding: 15px 0 15px 50px;
  position: relative;
  display: inline-block; }
  .braintree-paypal-account:before {
    background-image: url("../Magento_Braintree/images/paypal-small.png");
    background-position-x: 0;
    background-position-y: 0;
    line-height: 16px;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    margin: ""; }
  .braintree-paypal-account:before {
    left: 17px;
    margin-top: -8px;
    position: absolute;
    top: 50%; }
  .braintree-paypal-account .payment-method-type {
    font-weight: 700; }
  .braintree-paypal-account .payment-method-description {
    color: #575757; }

.braintree-paypal-fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.action-braintree-paypal-logo {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .action-braintree-paypal-logo:focus, .action-braintree-paypal-logo:active {
    background: none;
    border: none; }
  .action-braintree-paypal-logo:hover {
    background: none;
    border: none; }
  .action-braintree-paypal-logo.disabled, .action-braintree-paypal-logo[disabled],
  fieldset[disabled] .action-braintree-paypal-logo {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .action-braintree-paypal-logo img {
    margin: 0;
    width: 114px; }

.payment-method .payment-method-content .actions-toolbar.braintree-paypal-actions {
  margin-left: 0; }

.account .table .col.paypal-account img {
  vertical-align: middle; }

.account .table .col.paypal-account + .actions {
  vertical-align: middle; }

@media only screen and (min-width: 768px) {
  .payment-method-braintree .cvv .field-tooltip {
    left: 6rem; }
  .braintree-paypal-account {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0;
    display: inline-block;
    width: 50%; }
  .account .table-credit-cards .col.actions {
    width: 100px; } }

.bundle-options-container {
  clear: both;
  margin-bottom: 40px; }
  .bundle-options-container .legend.title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .bundle-options-container .product-add-form {
    display: none; }
  .bundle-options-container .product-options-wrapper {
    margin-bottom: 30px; }
  .bundle-options-container .action.back {
    margin-bottom: 30px; }
  .bundle-options-container .price-box .price {
    font-size: 18px;
    font-weight: 700; }
  .bundle-options-container .block-bundle-summary {
    background: #e8e8e8;
    padding: 10px 20px; }
    .bundle-options-container .block-bundle-summary > .title > strong {
      font-weight: 300;
      line-height: 1.1;
      font-size: 2.6rem;
      margin-top: 2.5rem;
      margin-bottom: 2rem; }
    .bundle-options-container .block-bundle-summary .bundle-summary {
      margin-top: 30px; }
      .bundle-options-container .block-bundle-summary .bundle-summary.empty {
        display: none; }
      .bundle-options-container .block-bundle-summary .bundle-summary > .subtitle {
        font-weight: 300;
        line-height: 1.1;
        font-size: 1.8rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        display: block; }
    .bundle-options-container .block-bundle-summary .bundle.items > li {
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .box-tocart .actions {
      display: inline-block;
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .product-addto-links > .action {
      vertical-align: top; }
  .bundle-options-container .nested .field.qty {
    margin-top: 20px; }
    .bundle-options-container .nested .field.qty .label {
      font-weight: 700;
      margin: 0 0 5px;
      display: inline-block; }
  .bundle-options-container p.required {
    color: #e02b27; }
  .bundle-options-container .product-options-bottom {
    clear: left; }

@media only screen and (min-width: 768px) {
  .bundle-options-container .bundle-options-wrapper,
  .bundle-options-container .product-options-wrapper {
    float: left;
    width: 57%; }
  .bundle-options-container .block-bundle-summary {
    float: right;
    position: relative;
    width: 40%; }
  .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
  .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
  .page-layout-3columns .bundle-options-container .bundle-options-wrapper, .page-layout-2columns-left
  .bundle-options-container .block-bundle-summary,
  .page-layout-2columns-right
  .bundle-options-container .block-bundle-summary,
  .page-layout-3columns
  .bundle-options-container .block-bundle-summary {
    width: 48%; } }

.products {
  margin: 30px 0; }

.product-item {
  vertical-align: top; }
  .products-grid .product-item {
    display: inline-block;
    width: 50%; }
  .product-item-name {
    display: block;
    margin: 5px 0;
    word-wrap: break-word;
    hyphens: auto; }
  .product-item-info {
    max-width: 100%;
    width: 152px; }
    .page-products .product-item-info {
      width: 240px; }
  .product-item-actions {
    display: none; }
    .product-item-actions .actions-secondary > .action:before {
      margin: 0; }
  .product-item-description {
    margin: 25px 0; }
  .product-item .product-reviews-summary .rating-summary {
    margin: 0 4px 0 0; }
  .product-item .product-reviews-summary .reviews-actions {
    font-size: 12px;
    margin-top: 5px;
    text-transform: lowercase; }
  .product-item .price-box {
    margin: 10px 0 25px; }
    .product-item .price-box .price {
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap; }
    .product-item .price-box .price-label {
      font-size: 12px; }
      .product-item .price-box .price-label:after {
        content: ':'; }
  .product-item .special-price .price,
  .product-item .minimal-price .price {
    font-size: 14px;
    font-weight: 700; }
  .product-item .special-price .price-wrapper,
  .product-item .minimal-price .price-wrapper {
    display: inline-block; }
  .product-item .special-price .price-including-tax + .price-excluding-tax,
  .product-item .minimal-price .price-including-tax + .price-excluding-tax {
    display: block; }
  .product-item .special-price {
    display: block; }
  .product-item .old-price .price {
    font-weight: 400; }
  .product-item .minimal-price .price-container {
    display: block; }
  .product-item .minimal-price-link {
    margin-top: 5px; }
    .product-item .minimal-price-link .price-label {
      color: #1979c3;
      font-size: 14px; }
    .product-item .minimal-price-link .price {
      font-weight: 400; }
  .product-item .minimal-price-link,
  .product-item .price-excluding-tax,
  .product-item .price-including-tax {
    display: block;
    white-space: nowrap; }
  .product-item .price-from,
  .product-item .price-to {
    margin: 0; }
  .product-item .action.tocompare:before {
    content: ""; }
  .product-item .tocart {
    white-space: nowrap; }

.column.main .product-items {
  margin-left: -20px; }

.column.main .product-item {
  padding-left: 20px; }

.price-container .price {
  font-size: 14px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
  margin-top: 5px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax:before,
.price-container .weee + .price-excluding-tax .price {
  font-size: 11px; }

.price-container .weee:before {
  content: "(" attr(data-label) ": "; }

.price-container .weee:after {
  content: ')'; }

.price-container .weee + .price-excluding-tax:before {
  content: attr(data-label) ": "; }

.products-list .product-item {
  display: table;
  width: 100%; }
  .products-list .product-item-info {
    display: table-row; }
  .products-list .product-item-photo {
    display: table-cell;
    padding: 0 30px 30px 0;
    vertical-align: top;
    width: 1%; }
  .products-list .product-item-details {
    display: table-cell;
    vertical-align: top; }

@media only screen and (max-width: 639px) {
  .products-list .product-item {
    table-layout: fixed; }
    .products-list .product-item-photo {
      padding: 0 10px 10px 0;
      width: 30%; } }

@media only screen and (min-width: 640px) {
  .products-grid .product-item {
    margin-bottom: 30px; }
  .product-item-actions {
    display: block; }
    .products-grid .product-item-actions {
      margin: 10px 0; }
    .product-item-actions .actions-primary + .actions-secondary {
      display: table-cell;
      padding-left: 5px;
      white-space: nowrap;
      width: 50%; }
      .product-item-actions .actions-primary + .actions-secondary > * {
        white-space: normal; }
    .product-item-actions .actions-primary {
      display: table-cell; }
  .products-grid .product-item {
    width: 33.3333%; }
  .page-products .products-grid .product-item,
  .page-layout-1column .products-grid .product-item,
  .page-layout-3columns .products-grid .product-item,
  .page-products.page-layout-1column .products-grid .product-item,
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 33.3333%; } }

@media only screen and (min-width: 768px) {
  .page-products .products-grid .product-item {
    width: 33.3333%; }
  .page-products.page-layout-1column .products-grid .product-item {
    width: 25%; }
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1024px) {
  .products-grid .product-item {
    width: 20%; }
  .page-layout-1column .products-grid .product-item {
    width: 16.6666%; }
  .page-layout-3columns .products-grid .product-item {
    width: 25%; }
  .page-products .products-grid .product-items {
    margin: 0; }
  .page-products .products-grid .product-item {
    margin-left: calc((100% - 4 * 23.233%) / 3);
    padding: 0;
    width: 23.233%; }
    .page-products .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; }
  .page-products.page-layout-1column .products-grid .product-item {
    margin-left: 0;
    width: 20%; }
  .page-products.page-layout-3columns .products-grid .product-item {
    margin-left: 1%;
    width: 32.667%; }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n) {
      margin-left: 1%; }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n + 1) {
      margin-left: 0; } }

.page-products .columns {
  position: relative;
  z-index: 1; }

.toolbar-amount {
  display: block;
  line-height: 28px;
  margin: 0;
  padding: 8px 0 0; }
  .products.wrapper ~ .toolbar .toolbar-amount {
    display: none; }

.toolbar-products {
  margin-bottom: 40px;
  padding: 0 10px;
  text-align: center; }
  .toolbar-products .pages {
    display: none; }
    .products.wrapper ~ .toolbar-products .pages {
      display: block; }
  .toolbar-products .limiter {
    display: none; }
    .toolbar-products .limiter .control {
      display: inline-block; }

.sorter {
  padding: 4px 0 0; }
  .products.wrapper ~ .toolbar .sorter {
    display: none; }

.sorter-options {
  margin: 0 0 0 7px;
  width: auto; }

.sorter-action {
  vertical-align: top;
  display: inline-block;
  text-decoration: none; }
  .sorter-action > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sorter-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 32px;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sorter-action:hover:before {
    color: #333; }

.sorter .sort-desc:before {
  content: ""; }

.modes {
  display: none; }

.limiter-options {
  margin: 0 5px 0 7px;
  width: auto; }

.limiter-label {
  font-weight: 400; }

.page-products .toolbar .limiter {
  display: none; }

@media only screen and (min-width: 768px) {
  .page-products .columns {
    padding-top: 0;
    position: relative;
    z-index: 1; }
  .products.wrapper ~ .toolbar .pages {
    float: left; }
  .toolbar-amount {
    float: left; }
  .sorter {
    float: right; }
  .modes {
    display: block;
    float: left;
    margin-right: 20px; }
    .products.wrapper ~ .toolbar .modes {
      display: none; }
  .modes-mode {
    color: #7d7d7d;
    border: 1px solid #d1d1d1;
    border-right: 0;
    float: left;
    font-weight: 400;
    line-height: 1;
    padding: 7px 10px;
    text-align: center;
    display: block;
    text-decoration: none; }
    .modes-mode > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .modes-mode:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 26px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .modes-mode:hover:before {
      color: #7d7d7d; }
    .modes-mode:not(.active):hover {
      color: #7d7d7d;
      background: #dedede; }
    .modes-mode:last-child {
      border-right: 1px solid #d1d1d1; }
    .modes-mode.active {
      color: #a6a6a6; }
  .mode-list:before {
    content: ""; }
  .limiter {
    float: right; }
    .products.wrapper ~ .toolbar .limiter {
      display: block; } }

.fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after, .fotorama__html, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.fotorama-grab-cursor, .fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: grab; }

.fotorama-grabbing-cursor, .fotorama__grabbing * {
  cursor: move;
  cursor: grabbing; }

.fotorama-gpu, .fotorama__caption, .fotorama--fullscreen, .fotorama__wrap--css3 .fotorama__html, .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full, .fotorama__wrap--css3 .fotorama__nav, .fotorama__nav:before, .fotorama__nav:after, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage, .fotorama__stage:before, .fotorama__stage:after, .fotorama__wrap--css3 .fotorama__stage__frame {
  transform: translateZ(0); }

.fotorama-focus, .fotorama__fullscreen-icon:focus, .fotorama__nav__frame, .fotorama__zoom-in:focus,
.fotorama__zoom-out:focus {
  outline: 0; }

.fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after {
  background-color: #006bb4;
  border-radius: inherit;
  content: ''; }

.fotorama-transform-disabled, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img, .fotorama__wrap--video .fotorama__stage, .fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__shaft {
  transform: none !important; }

.fotorama-transition-for-slide, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  transition-duration: unset;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1); }

.fotorama-no-select, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__no-select, .fotorama__video-close, .fotorama__wrap {
  user-select: none; }

.fotorama-select, .fotorama__select {
  user-select: text; }

.fotorama-empty-bg {
  background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=); }

.fotorama-auto-margin, .fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama-inline-block, .fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  display: inline;
  display: inline-block;
  vertical-align: middle;
  zoom: 1; }

.fotorama-hidden, .fotorama--hidden, .fotorama__load {
  position: absolute;
  top: -99999px;
  left: -99999px;
  z-index: -1000; }

.fotorama-visible {
  position: relative;
  left: auto;
  top: auto;
  opacity: 1;
  z-index: auto; }

.fotorama-no-tap, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.transitionDuration {
  transition-duration: 333ms; }

.transitionDurationZero {
  transition-duration: unset; }

.fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out {
  background-image: url("../images/gallery.png");
  background-repeat: no-repeat; }

@media print {
  .fotorama-print-background, .fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__thumb-border {
    background: none !important; } }

.fotorama__active {
  box-shadow: 0 0 0 transparent; }

.fotorama__stage__frame.fotorama__active {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
  z-index: 9; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__arr {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  box-sizing: border-box;
  z-index: 900;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__arr:focus {
    box-shadow: none; }
    .fotorama__arr:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__arr--prev {
    left: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
      transform: translate3d(-120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
      transform: translate3d(-120px, 0, 0); }
  .fotorama__arr--next {
    right: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
      transform: translate3d(120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
      transform: translate3d(120px, 0, 0); }
  .fotorama__arr--disabled {
    display: none;
    opacity: 0.1;
    pointer-events: none;
    cursor: default; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__arr {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr {
    display: none !important; }
  .fotorama__wrap--css3 .fotorama__arr:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__stage .fotorama__arr--shown {
    display: block !important; }
  .fotorama__stage .fotorama__arr--hidden {
    display: none !important; }

.fotorama__arr .fotorama__arr__arr {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .ie9 .fotorama__arr .fotorama__arr__arr {
    margin: -40px 0 0 -40px; }

.fotorama__arr--prev .fotorama__arr__arr {
  background-position: -80px -80px; }

.fotorama__arr--next .fotorama__arr__arr {
  background-position: -160px -80px; }

.fotorama__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  z-index: 12; }
  .fotorama__caption a {
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid;
    border-color: fade(#000, 0.5); }
    .fotorama__caption a:hover {
      color: #000;
      border-color: fade(#000, 0.5); }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption,
  .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #fff; }

.fotorama__dot {
  display: block;
  position: relative;
  top: 12px;
  left: 6px;
  width: 4px;
  height: 4px;
  border: 1px solid #858585;
  border-radius: 6px; }
  .fotorama__nav__frame--dot:focus .fotorama__dot {
    box-shadow: none; }
    .fotorama__nav__frame--dot:focus .fotorama__dot:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__dot:after,
  .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0; }
  .fotorama__active .fotorama__dot {
    background-color: #ff5501;
    border-color: #ff5501; }

.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }

.fotorama {
  min-width: 1px;
  overflow: hidden; }
  .fotorama--fullscreen {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: 0 !important;
    background: #fff;
    z-index: 1000 !important; }

.fotorama__fullscreen {
  overflow: hidden;
  position: relative; }

.fotorama__fullscreen-icon {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-position: 0 0;
  cursor: pointer;
  z-index: 1000; }
  .fotorama__fullscreen-icon:focus {
    box-shadow: none; }
    .fotorama__fullscreen-icon:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama--fullscreen-icons .fotorama__fullscreen-icon {
    display: none; }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: inline-block;
    background-position: -80px 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__fullscreen-icon {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
    display: none !important; }
  .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
    transform: translate3d(80px, -80px, 0); }
  .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
    transform: translate3d(80px, -80px, 0) !important; }
  .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:active {
      background-color: rgba(213, 213, 213, 0.5); }

.gallery-placeholder .loader img {
  position: absolute; }

.fotorama__wrap--css3 .fotorama__html {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: linear; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  opacity: 0; }

.fotorama__img {
  max-width: inherit;
  opacity: 0;
  border: none !important; }
  .fotorama__loaded .fotorama__img,
  .fotorama__error .fotorama__img {
    opacity: 1; }
  .fotorama__img--full {
    display: none; }
  .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
    opacity: 0; }
  .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
    position: absolute;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateY(-50%); }
  .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    position: absolute;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%); }
  .fotorama__stage__frame .fotorama__img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 0.3s;
    transition-property: width, height, top, left; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img,
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: inherit;
    max-height: inherit; }
  .fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
    display: none; }
    .fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
      display: block; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    display: block;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    cursor: default;
    transition: 0.3s linear; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--zoommable {
      max-width: none;
      max-height: none;
      cursor: pointer;
      transition-property: width, height, bottom, right, top, left; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--draggable {
      cursor: move;
      transition-property: none; }
  .fotorama__product-video--loaded .fotorama__img,
  .fotorama__product-video--loaded .fotorama__img--full {
    display: none !important; }

.magnifier-thumb-wrapper {
  position: relative;
  display: block;
  top: 0;
  left: 0; }

.magnifier-lens {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border: solid 1px #bbb;
  z-index: 800; }

.magnifier-loader-text {
  margin-top: 10px; }

.magnifier-large {
  position: absolute;
  width: 32%;
  z-index: 100; }

.magnifier-preview {
  position: absolute;
  top: 215px;
  bottom: 0;
  left: 58%;
  overflow: hidden;
  padding: 0; }
  .magnifier-preview:not(.hidden) {
    background-color: #fff; }
  .magnifier-preview img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: inherit; }

.magnify-lens {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #bbb;
  z-index: 1000; }

.magnify-opaque {
  opacity: 0.5; }

.magnify-hidden {
  display: none; }

.magnify-fullimage {
  display: none; }
  .fotorama__fullscreen .magnify-fullimage {
    display: inline-block; }

.fotorama__nav {
  position: relative;
  display: none;
  max-width: 100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5; }
  .fotorama__nav--dots {
    display: block;
    text-align: center; }
  .fotorama__nav--thumbs {
    display: block; }
  .fotorama__nav:before, .fotorama__nav:after {
    position: absolute;
    display: block;
    content: '';
    text-decoration: none;
    background-repeat: no-repeat;
    z-index: 10;
    pointer-events: none; }
  .fotorama__nav:before {
    left: -10px;
    top: -10px; }
  .fotorama__nav:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__nav:before, .fotorama--fullscreen .fotorama__nav:after,
  .fotorama__wrap--no-shadows .fotorama__nav:before,
  .fotorama__wrap--no-shadows .fotorama__nav:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__nav {
    max-width: 99999px !important; }

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0; }

.fotorama__nav__frame {
  position: relative;
  box-sizing: content-box; }
  .fotorama__nav--dots .fotorama__nav__frame {
    width: 18px;
    height: 30px; }
    .fotorama__nav--dots .fotorama__nav__frame--thumb {
      display: none; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame--dot {
      display: none; }
  .fotorama__nav__frame.fotorama__active .fotorama__nav__frame {
    width: 0;
    height: 0;
    border-width: 3px; }
    .fotorama__nav__frame.fotorama__active .fotorama__nav__frame:after {
      top: -3px;
      left: -3px;
      padding: 3px; }
  .fotorama__nav__frame:focus {
    box-shadow: none; }
    .fotorama__nav__frame:focus :after {
      top: -1px;
      left: -1px;
      padding: 1px; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    display: block;
    padding-bottom: inherit !important; }

.fotorama__nav__shaft {
  position: relative;
  top: 0;
  left: 0;
  text-align: left; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
    width: 100%;
    background-color: white; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__select {
  cursor: auto; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__nav.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--right:after {
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  background-size: 1px 100%, 5px 100%; }

.fotorama__nav.fotorama__shadows--top:before, .fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--bottom:after {
  left: 0;
  right: 0;
  width: auto;
  height: 10px;
  background-size: 100% 1px, 100% 5px; }

.fotorama__nav.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--left:before {
  left: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--right:after {
  right: 0;
  background-position: 100% 0, 100% 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--top:before {
  top: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--bottom:after {
  bottom: 0;
  background-position: 0 100%, 0 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__video-close.fotorama-show-control {
  opacity: 1;
  transform: translate3d(0, -10px, 0); }

.fotorama__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  display: none;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 64px;
  width: 64px;
  margin: auto;
  background-image: url("../images/loader-1.gif");
  z-index: 100; }
  .fotorama__spinner--show {
    display: block; }
  .fotorama__wrap--css3 .fotorama__spinner {
    animation: spinner 24s infinite linear; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fotorama__stage {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .fotorama__stage:before, .fotorama__stage:after {
    position: absolute;
    display: block;
    content: '';
    background-repeat: no-repeat;
    pointer-events: none;
    text-decoration: none;
    z-index: 10; }
  .fotorama__stage:before {
    left: -10px;
    top: -10px; }
  .fotorama__stage:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
  .fotorama__wrap--no-shadows .fotorama__stage:before,
  .fotorama__wrap--no-shadows .fotorama__stage:after,
  .fotorama__wrap--fade .fotorama__stage:before,
  .fotorama__wrap--fade .fotorama__stage:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__stage {
    max-width: 99999px !important; }

.fotorama__stage__frame {
  overflow: hidden;
  text-align: center; }
  .fotorama__wrap--rtl .fotorama__stage__frame {
    direction: rtl; }
  .fotorama__wrap--slide .fotorama__stage__frame {
    opacity: 1 !important; }
  .fotorama__wrap--fade .fotorama__stage__frame {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame iframe {
    left: 80px;
    width: calc(100% - 80px * 2); }
  .fotorama__wrap--only-active .fotorama__stage__frame {
    visibility: hidden; }

.fotorama__stage__shaft {
  position: relative; }
  .fotorama__stage__shaft:focus {
    box-shadow: none; }
    .fotorama__stage__shaft:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }

.fotorama__thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #ebebeb; }
  .fotorama__nav__frame:focus .fotorama__thumb {
    box-shadow: none;
    z-index: 2; }
    .fotorama__nav__frame:focus .fotorama__thumb:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 3px 1px #68a8e0;
      border-radius: inherit;
      content: '';
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__thumb,
  .fotorama__nav__frame--dot:focus .fotorama__thumb {
    overflow: inherit; }
  .fotorama__nav__frame:nth-child(2):focus .fotorama__thumb:after {
    left: 1px; }
  .fotorama__nav__frame:last-child:focus .fotorama__thumb:after {
    right: 1px; }
  .fotorama__thumb--icon {
    padding-bottom: 30px;
    font-size: 0.001px; }
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
      .ie9 .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon, .ie9
      .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
        margin: -15px 0 0 -15px; }
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
      margin: auto;
      width: 30px;
      transform: rotate(90deg); }
    .fotorama__thumb__arr--left .fotorama__thumb--icon {
      background-position: -25px -265px; }
    .fotorama__thumb__arr--right .fotorama__thumb--icon {
      background-position: -25px -350px; }

.fotorama__thumb-border {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 1px solid #ff5501;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  backface-visibility: hidden;
  z-index: 9; }
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__thumb__arr {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__thumb__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__thumb__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    z-index: 1000;
    cursor: pointer; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
    left: 0; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    right: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    cursor: pointer;
    z-index: 1000; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
    top: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    bottom: 0; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb.fotorama_vertical_ratio:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  left: 2px;
  right: 2px; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb.fotorama_vertical_ratio:after {
  top: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  bottom: 1px; }

.fotorama__video {
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; }

.fotorama__video-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  opacity: 0;
  z-index: 1000;
  cursor: pointer;
  background-position: -80px 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 0.3s ease-in-out; }
  .fotorama__fullscreen .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
    top: 97px;
    right: 93px;
    opacity: 1; }
  .fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  @media all and (max-width: 768px) {
    .fotorama__video-close {
      top: 10px;
      width: 40px;
      height: 40px;
      background-position: -100px -20px; } }

.fotorama__video-play {
  width: 160px;
  height: 160px;
  background-position: -80px -160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .fotorama__wrap--css2 .fotorama__video-play,
  .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play,
  .fotorama__error .fotorama__video-play,
  .fotorama__nav__frame .fotorama__video-play {
    display: block;
    opacity: 1; }
  .fotorama__nav__frame .fotorama__video-play {
    width: 80px;
    height: 80px;
    background-position: 0 -240px; }
  .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }

.fotorama__wrap {
  position: relative;
  direction: ltr;
  text-size-adjust: 100%;
  z-index: 0; }
  .fotorama--fullscreen .fotorama__wrap {
    max-width: 100% !important; }
  .fotorama__wrap--pan-y {
    touch-action: pan-y; }

.fotorama__zoom-in,
.fotorama__zoom-out {
  display: none;
  z-index: 1000; }
  .fotorama__zoom-in:focus,
  .fotorama__zoom-out:focus {
    box-shadow: none; }
    .fotorama__zoom-in:focus:after,
    .fotorama__zoom-out:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
  .fotorama__zoom-out {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:hover, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:active, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:active {
      background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen
  .fotorama__zoom-out {
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    cursor: pointer; }
    .fotorama__fullscreen .fotorama__zoom-in--disabled, .fotorama__fullscreen
    .fotorama__zoom-out--disabled {
      display: none; }

.fotorama__fullscreen .fotorama__zoom-out {
  top: 80px;
  background-position: 0 -80px !important; }

.fotorama__fullscreen .fotorama__zoom-in {
  background-position: 0 0 !important;
  top: 0; }

.old-price,
.old.price {
  text-decoration: line-through; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  content: "(" attr(data-label) ": "; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ')'; }

.prices-tier .price-container .weee[data-label] {
  display: inline; }
  .prices-tier .price-container .weee[data-label] .price {
    font-size: 1.1rem; }
  .prices-tier .price-container .weee[data-label]:before {
    content: " +" attr(data-label) ": "; }

.actual-price {
  font-weight: 700; }

.category-image .image {
  display: block;
  height: auto;
  max-width: 100%; }

.category-image,
.category-description {
  margin-bottom: 20px; }

.product-image-container {
  display: inline-block;
  max-width: 100%; }

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0; }

.product.media .placeholder .photo.container {
  max-width: 100%; }

.product.media .notice {
  color: #7d7d7d;
  font-size: 1.2rem;
  margin: 10px 0; }

.product.media .product.thumbs {
  margin: 20px 0 30px; }

.product.media .items.thumbs {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top; }
  .product.media .items.thumbs .active {
    display: block;
    line-height: 1; }

.product.info.detailed {
  clear: both;
  margin-bottom: 30px; }
  .product.info.detailed .additional-attributes {
    width: auto; }
    .product.info.detailed .additional-attributes > thead > tr > td,
    .product.info.detailed .additional-attributes > tbody > tr > td,
    .product.info.detailed .additional-attributes > tfoot > tr > td {
      padding: 4px 5px 10px 5px; }
    .product.info.detailed .additional-attributes > thead > tr > th,
    .product.info.detailed .additional-attributes > tbody > tr > th,
    .product.info.detailed .additional-attributes > tfoot > tr > th {
      padding: 4px 30px 10px 0; }

.product-info-main .page-title-wrapper .page-title {
  line-height: 1.42857;
  margin-bottom: 10px; }

.product-info-main .stock.available, .product-info-main .stock.unavailable {
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  vertical-align: top; }

.product-info-main .product.attribute.sku {
  display: inline-block;
  vertical-align: top;
  color: #7d7d7d; }
  .product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all; }
  .product-info-main .product.attribute.sku .type {
    margin-right: 5px; }

.product-info-main .product.attribute.overview {
  margin: 20px 0; }

.product-info-main .product.alert {
  margin: 10px 0; }

.product-info-main .price-box {
  margin-top: 10px; }

.product-info-main .product-reviews-summary .reviews-actions {
  font-size: 1.4rem; }

.product-options-wrapper .fieldset-product-options-inner .legend {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 1.4rem;
  border: none;
  display: inline-block;
  float: none;
  padding: 0; }

.product-options-wrapper .fieldset-product-options-inner input.datetime-picker ~ select.datetime-picker {
  margin-top: 10px; }

.product-options-wrapper .fieldset-product-options-inner.required .legend:after, .product-options-wrapper .fieldset-product-options-inner._required .legend:after {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px; }

.product-options-wrapper .field .note {
  display: block; }

.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee {
  font-size: 1.2rem;
  line-height: 14px;
  margin-bottom: 5px; }
  .product-info-main .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-main .price-box .weee + .price-excluding-tax .price,
  .product-info-main .price-box .weee .price,
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee .price {
    font-size: 1.2rem;
    font-weight: 700; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 1.8rem;
  font-weight: 700; }

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
  white-space: nowrap; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: block;
  margin: 10px 0; }
  .product-info-main .special-price .price-container,
  .product-options-bottom .special-price .price-container {
    font-size: 1.4rem; }
  .product-info-main .special-price .price-label + .price-wrapper,
  .product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block; }

.product-info-main .old-price .price-label:after,
.product-info-main .special-price .price-label:after,
.product-options-bottom .old-price .price-label:after,
.product-options-bottom .special-price .price-label:after {
  content: ': '; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  margin: 20px 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    padding-right: 15px; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px; }
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .actions {
    text-align: center; }

.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
  margin: 20px 0; }

.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
  vertical-align: top; }

.prices-tier {
  background: #f5f5f5;
  margin: 10px 0;
  padding: 10px 15px; }
  .prices-tier .price-container {
    display: inline-block; }
  .prices-tier .price-including-tax,
  .prices-tier .price-excluding-tax,
  .prices-tier .weee {
    display: inline-block; }
    .prices-tier .price-including-tax .price,
    .prices-tier .price-excluding-tax .price,
    .prices-tier .weee .price {
      font-size: 1.4rem;
      font-weight: 700; }

.ui-dialog-titlebar-close {
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1979c3;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .ui-dialog-titlebar-close:visited {
    color: #1979c3;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:active {
    color: #ff5501;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:active, .ui-dialog-titlebar-close:focus {
    background: none;
    border: 0; }
  .ui-dialog-titlebar-close.disabled, .ui-dialog-titlebar-close[disabled],
  fieldset[disabled] .ui-dialog-titlebar-close {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.block.related .action.select {
  margin: 0 5px; }

.sidebar .product-items .product-item {
  margin-bottom: 20px;
  position: relative; }

.sidebar .product-items .product-item-info {
  position: relative;
  width: auto; }
  .sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0; }

.sidebar .product-items .product-item-name {
  margin-top: 0; }

.sidebar .product-items .product-item-details {
  margin: 0 0 0 85px; }

.sidebar .product-items .product-item-actions {
  display: block;
  margin-top: 10px; }

.sidebar .product-items .price-box {
  display: block;
  margin: 7px 0; }

.sidebar .product-items .text {
  margin-right: 8px; }

.sidebar .product-items .counter {
  color: #7d7d7d;
  font-size: 1.2rem;
  white-space: nowrap; }

.sidebar .product-items .minilist .price {
  display: inline;
  padding: 0; }

.sidebar .product-items .minilist .weee:before {
  display: inline-block; }

.sidebar .action.delete {
  position: absolute;
  right: 0;
  top: 0; }

.sidebar .product-items-images {
  margin-left: -5px; }
  .sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px; }

.sidebar .product-items-names .product-item {
  margin-bottom: 10px; }

.sidebar .product-items-names .product-item-name {
  margin: 0; }

@media only screen and (max-width: 767px) {
  .catalog-product-view .column.main {
    display: flex;
    flex-direction: column; }
  .catalog-product-view .product.media {
    order: -1; }
  .block.related .action.select {
    display: block;
    margin: 5px 0; }
  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none; } }

@media only screen and (min-width: 768px) {
  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    display: table; }
    .product-info-main .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
      display: table-cell; }
    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
      display: table-cell;
      padding-top: 25px;
      text-align: center;
      vertical-align: bottom; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px; }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto; }
  .sidebar .product-items .product-item-details {
    margin: 0; }
  .sidebar .product-items .product-item-actions {
    clear: left; } }

@media only screen and (min-width: 1440px) {
  .sidebar .product-items .product-item-info .product-item-photo {
    float: none;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0; }
  .sidebar .product-items .product-item-details {
    margin-left: 85px; } }

@media only screen and (min-width: 768px) {
  .product-info-main {
    float: right; }
  .product.media {
    float: left;
    margin-bottom: 25px; }
  .page-layout-1column .product-info-main {
    width: 40%; }
  .page-layout-1column .product.media {
    width: 57%; }
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 48%; }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%; } }

body.catalog-product-compare-index .action.print {
  float: right;
  margin: 15px 0; }

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto; }

.table-comparison {
  table-layout: fixed; }
  .table-comparison .cell.label,
  .table-comparison td:last-child {
    border-right: 1px solid #d1d1d1; }
  .table-comparison .cell {
    padding: 15px;
    width: 140px; }
    .table-comparison .cell .attribute.value {
      overflow: hidden;
      width: 100%; }
    .table-comparison .cell.product.info, .table-comparison .cell.product.label {
      border-bottom: 1px solid #d1d1d1; }
    .table-comparison .cell.label .attribute.label {
      display: block;
      width: 100%;
      word-wrap: break-word; }
    .table-comparison .cell.attribute {
      font-size: 1.3rem; }
      .table-comparison .cell.attribute img {
        height: auto;
        max-width: 100%; }
  .table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px; }
  .table-comparison .product-image-photo {
    margin-left: 0; }
  .table-comparison .product-item-actions,
  .table-comparison .price-box,
  .table-comparison .product.rating,
  .table-comparison .product-item-name {
    display: block;
    margin: 15px 0; }
  .table-comparison .product-addto-links {
    margin-top: 15px; }
    .table-comparison .product-addto-links .action.split,
    .table-comparison .product-addto-links .action.toggle {
      line-height: 1.2rem;
      padding: 5px 8px;
      font-size: 1.1rem; }
    .table-comparison .product-addto-links .action.toggle {
      padding: 0; }
  .table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right; }
  .table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px; }
  .table-comparison .action.tocart {
    white-space: nowrap; }

.comparison.headings {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2; }

.block-compare .product-item .product-item-name {
  margin-left: 22px; }

.block-compare .action.delete {
  left: -6px;
  position: absolute;
  top: 0; }

.block-compare .actions-toolbar {
  margin: 17px 0 0; }

.block-category-event {
  background: #f0f0f0;
  color: #333;
  padding: 10px 0 15px;
  text-align: center; }
  .block-category-event.block:last-child {
    margin-bottom: 10px; }
  .block-category-event .block-title {
    margin: 0 0 10px; }
    .block-category-event .block-title strong {
      font-size: 1.5rem;
      font-weight: 300; }
  .block-category-event .ticker {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .block-category-event .ticker li {
      display: none;
      margin: 0 15px; }
    .block-category-event .ticker .value {
      font-size: 1.8rem;
      font-weight: 300; }
    .block-category-event .ticker .label {
      font-size: 1rem;
      display: block;
      text-transform: lowercase; }
  .block-category-event .dates {
    display: table;
    margin: 0 auto; }
    .block-category-event .dates .start,
    .block-category-event .dates .end {
      display: table-cell;
      vertical-align: top; }
    .block-category-event .dates .date {
      font-size: 1.8rem; }
    .block-category-event .dates .time {
      display: block; }
    .block-category-event .dates .start {
      padding-right: 40px;
      position: relative; }
      .block-category-event .dates .start:after {
        font-size: 1.8rem;
        content: '\2013';
        display: block;
        position: absolute;
        right: 15px;
        top: 0; }

@media only screen and (min-width: 768px) {
  .block-category-event.block:last-child {
    margin-bottom: 30px;
    padding: 10px 0 30px; }
  .block-category-event .block-title {
    margin: 0; }
    .block-category-event .block-title strong {
      font-size: 2.4rem; }
  .block-category-event .ticker li {
    display: none;
    margin: 0 50px; }
  .block-category-event .ticker .value {
    font-size: 6rem; }
  .block-category-event .ticker .label {
    font-size: 1.4rem;
    text-transform: none; }
  .block-category-event .dates .date {
    font-size: 5rem; }
  .block-category-event .dates .start {
    padding-right: 50px; }
    .block-category-event .dates .start:after {
      font-size: 5rem;
      right: 10px; } }

.block-search {
  margin-bottom: 0; }
  .block-search .block-title {
    display: none; }
  .block-search .block-content {
    margin-bottom: 0; }
  .block-search .label {
    display: inline-block;
    text-decoration: none;
    display: inline-block;
    float: right; }
    .block-search .label > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .block-search .label:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 35px;
      line-height: 33px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .block-search .label:hover:before {
      color: #333; }
    .block-search .label:active:before {
      color: #333; }
    .block-search .label.active + .control input {
      position: static; }
  .block-search .action.search {
    display: none; }
  .block-search .control {
    border-top: 1px solid #d1d1d1;
    clear: both;
    margin: 0 -15px -1px;
    padding: 0 15px; }
  .block-search input {
    left: -300%;
    margin: 15px 0;
    position: absolute; }
  .block-search .nested {
    display: none; }

.search-autocomplete {
  display: none;
  margin-top: -15px;
  overflow: hidden;
  position: absolute;
  z-index: 3; }
  .search-autocomplete ul {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .search-autocomplete ul li {
      border-top: 1px solid #e5e5e5;
      cursor: pointer;
      margin: 0;
      padding: 5px 40px 5px 10px;
      position: relative;
      text-align: left;
      white-space: normal; }
      .search-autocomplete ul li:not(:empty) {
        border-top: 0;
        border: 1px solid #c2c2c2;
        background: #fff; }
      .search-autocomplete ul li:first-child {
        border-top: none; }
      .search-autocomplete ul li:hover, .search-autocomplete ul li.selected {
        background: #e8e8e8; }
      .search-autocomplete ul li .amount {
        color: #999;
        position: absolute;
        right: 7px;
        top: 5px; }

.form.search.advanced .fields.range .field:first-child {
  position: relative; }
  .form.search.advanced .fields.range .field:first-child .control {
    padding-right: 25px; }
    .form.search.advanced .fields.range .field:first-child .control:after {
      content: ' \2013 ';
      display: inline-block;
      position: absolute;
      right: 0;
      text-align: center;
      top: 6px;
      width: 25px; }

.form.search.advanced .fields.range .field:last-child {
  position: relative; }
  .form.search.advanced .fields.range .field:last-child div.mage-error[generated] {
    left: 0;
    position: absolute;
    top: 32px; }

.form.search.advanced .fields.range .field.with-addon .control {
  padding-right: 45px; }

.form.search.advanced .group.price .addon .addafter {
  background: none;
  border: 0;
  padding-top: 6px;
  position: absolute;
  right: 0;
  top: 0; }

.search.summary {
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .block-search .block-content {
    margin-bottom: 0; } }

@media only screen and (max-width: 639px) {
  .block-search {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .block-search {
    float: right;
    padding-left: 15px;
    position: relative;
    width: 250px;
    z-index: 4; }
    .block-search .control {
      border-top: 0;
      margin: 0;
      padding: 0 0 25px; }
    .block-search .nested {
      display: block;
      padding-top: 5px;
      position: absolute; }
    .block-search input {
      margin: 0;
      padding-right: 35px;
      position: static; }
      .block-search input::-webkit-input-placeholder {
        color: #c2c2c2; }
      .block-search input:-moz-placeholder {
        color: #c2c2c2; }
      .block-search input::-moz-placeholder {
        color: #c2c2c2; }
      .block-search input:-ms-input-placeholder {
        color: #c2c2c2; }
    .block-search .action.search {
      display: inline-block;
      display: inline-block;
      text-decoration: none;
      background-image: none;
      background: none;
      -moz-box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-decoration: none;
      text-shadow: none;
      font-weight: 400;
      padding: 5px 0;
      position: absolute;
      right: 10px;
      top: 0;
      z-index: 1; }
      .block-search .action.search > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block-search .action.search:hover:before {
        color: inherit; }
      .block-search .action.search:active:before {
        color: inherit; }
      .block-search .action.search:focus, .block-search .action.search:active {
        background: none;
        border: none; }
      .block-search .action.search:hover {
        background: none;
        border: none; }
      .block-search .action.search.disabled, .block-search .action.search[disabled],
      fieldset[disabled] .block-search .action.search {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5; }
      .block-search .action.search:focus:before {
        color: #333; }
  .search-autocomplete {
    margin-top: 0; } }

.cart-summary {
  background: #f5f5f5;
  margin-bottom: 25px;
  padding: 1px 15px 25px; }
  .cart-summary > .title {
    display: block;
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }
  .cart-summary .block {
    margin-bottom: 0; }
    .cart-summary .block .item-options {
      margin-left: 0; }
    .cart-summary .block .fieldset {
      margin: 15px 0 25px 5px; }
      .cart-summary .block .fieldset .field {
        margin: 0 0 10px; }
        .cart-summary .block .fieldset .field.note {
          font-size: 12px; }
      .cart-summary .block .fieldset .methods .field > .label {
        display: inline; }

.cart-totals {
  border-top: 1px solid #d1d1d1;
  padding-top: 10px; }
  .cart-totals .table-wrapper {
    margin-bottom: 0;
    overflow: inherit; }

.cart.table-wrapper .items thead + .item {
  border-top: 1px solid #d1d1d1; }

.cart.table-wrapper .items > .item {
  border-bottom: 1px solid #d1d1d1;
  position: relative; }

.cart.table-wrapper .col {
  padding-top: 20px; }
  .cart.table-wrapper .col.qty .input-text {
    margin-top: -5px; }

.cart.table-wrapper .item-actions td {
  padding-bottom: 10px;
  text-align: center;
  white-space: normal; }

.cart.table-wrapper .item .col.item {
  display: block;
  min-height: 75px;
  padding: 25px 0 10px 75px;
  position: relative; }

.cart.table-wrapper .actions-toolbar > .action {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px; }
  .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .actions-toolbar > .action:focus {
    text-decoration: none; }
  .cart.table-wrapper .actions-toolbar > .action:last-child {
    margin-right: 0; }

.cart.table-wrapper .action.help.map {
  font-weight: 400; }

.cart.table-wrapper .product-item-photo {
  display: block;
  left: 0;
  max-width: 60px;
  padding: 0;
  position: absolute;
  top: 15px;
  width: 100%; }

.cart.table-wrapper .product-item-details {
  white-space: normal; }

.cart.table-wrapper .product-item-name {
  display: inline-block;
  font-weight: 400;
  margin-top: -6px; }

.cart.table-wrapper .gift-registry-name-label:after {
  content: ':'; }

.cart.table-wrapper .item-options {
  font-size: 12px;
  margin-bottom: 10px; }

.cart.table-wrapper .product-item-name + .item-options {
  margin-top: 10px; }

.cart.table-wrapper .action.configure {
  display: inline-block;
  margin: 0 0 20px; }

.cart-container .checkout-methods-items {
  margin-top: 20px;
  text-align: center; }
  .cart-container .checkout-methods-items .action.primary {
    width: 100%; }
  .cart-container .checkout-methods-items .item + .item {
    margin-top: 20px; }

.cart-products-toolbar .toolbar-amount {
  margin: 25px 0 15px;
  padding: 0;
  text-align: center; }

.cart-products-toolbar .pages {
  margin: 0 0 25px;
  text-align: center; }
  .cart-products-toolbar .pages .items > .item {
    border-bottom: 0; }

.cart-products-toolbar-top {
  border-bottom: 1px solid #d1d1d1; }

.block.crosssell {
  margin-top: 70px; }

.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
  display: block; }

.cart.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.price,
.order-items.table-wrapper .col.qty,
.order-items.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.msrp {
  text-align: right; }

@media only screen and (max-width: 767px) {
  .cart.table-wrapper {
    overflow: inherit; }
    .cart.table-wrapper thead .col:not(.item) {
      display: none; }
    .cart.table-wrapper .col.qty, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp {
      box-sizing: border-box;
      display: block;
      float: left;
      text-align: center;
      white-space: nowrap;
      width: 33%; }
      .cart.table-wrapper .col.qty:before, .cart.table-wrapper .col.price:before, .cart.table-wrapper .col.subtotal:before, .cart.table-wrapper .col.msrp:before {
        content: attr(data-th) ":";
        display: block;
        font-weight: 700;
        padding-bottom: 10px; }
    .cart.table-wrapper .col.msrp {
      white-space: normal; }
    .cart.table-wrapper .item .col.item {
      padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .cart-container .widget {
    float: left; }
  .cart-summary {
    float: right;
    position: relative;
    width: 23%; }
    .column.main .cart-summary .actions-toolbar > .secondary {
      float: none; }
    .cart-summary .block .fieldset .field {
      margin: 0 0 20px;
      margin: 0 0 10px; }
      .cart-summary .block .fieldset .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .cart-summary .block .fieldset .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .cart-summary .block .fieldset .field > .label {
        margin: 0 0 5px;
        display: inline-block; }
      .cart-summary .block .fieldset .field.choice:before, .cart-summary .block .fieldset .field.no-label:before {
        display: none; }
  .cart.table-wrapper .items {
    min-width: 100%;
    width: auto; }
  .cart.table-wrapper .item .col.item {
    padding: 27px 8px 10px; }
  .cart.table-wrapper .item-actions td {
    text-align: right; }
  .cart.table-wrapper .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding-right: 20px;
    position: static;
    vertical-align: top;
    width: 1%; }
  .cart.table-wrapper .product-item-details {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    width: 99%; }
  .cart.table-wrapper .item-actions .actions-toolbar {
    text-align: left; }
  .cart .action {
    margin-bottom: 10px; }
  .cart-products-toolbar .toolbar-amount {
    line-height: 30px;
    margin: 0; }
  .cart-products-toolbar .pages {
    float: right;
    margin: 0 0 1px; }
    .cart-products-toolbar .pages .item:last-child {
      margin-right: 0; }
  .cart.table-wrapper .cart-products-toolbar + .cart thead tr th.col {
    padding-bottom: 7px;
    padding-top: 8px; }
  .cart.table-wrapper .cart + .cart-products-toolbar {
    margin-top: 25px; } }

.block-minicart .items-total {
  float: left;
  margin: 0 10px; }
  .block-minicart .items-total .count {
    font-weight: 700; }

.block-minicart .subtotal {
  margin: 0 10px 10px;
  text-align: right; }

.block-minicart .amount .price-wrapper:first-child .price {
  font-size: 18px;
  font-weight: 700; }

.block-minicart .subtitle {
  display: none; }
  .block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center; }

.block-minicart .text.empty {
  text-align: center; }

.block-minicart .block-content > .actions {
  margin-top: 15px;
  text-align: center; }
  .block-minicart .block-content > .actions > .primary {
    margin: 0 10px 15px; }
    .block-minicart .block-content > .actions > .primary .action.primary {
      display: block;
      margin-bottom: 15px;
      width: 100%; }
      .block-minicart .block-content > .actions > .primary .action.primary:last-child {
        margin-bottom: 0; }

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
  margin: 15px 0 0;
  text-align: center; }

.minicart-wrapper {
  display: inline-block;
  position: relative;
  float: right; }
  .minicart-wrapper:before, .minicart-wrapper:after {
    content: '';
    display: table; }
  .minicart-wrapper:after {
    clear: both; }
  .minicart-wrapper .action.showcart {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .minicart-wrapper .action.showcart:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 35px;
      line-height: 33px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.showcart:hover:before {
      color: #333; }
    .minicart-wrapper .action.showcart:active:before {
      color: #8f8f8f; }
    .minicart-wrapper .action.showcart.active {
      display: inline-block;
      text-decoration: none; }
      .minicart-wrapper .action.showcart.active:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 35px;
        line-height: 33px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .minicart-wrapper .action.showcart.active:hover:before {
        color: #333; }
      .minicart-wrapper .action.showcart.active:active:before {
        color: #8f8f8f; }
  .minicart-wrapper .block-minicart {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .minicart-wrapper .block-minicart li {
      margin: 0; }
      .minicart-wrapper .block-minicart li:hover {
        cursor: pointer; }
    .minicart-wrapper .block-minicart:before, .minicart-wrapper .block-minicart:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .minicart-wrapper .block-minicart:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .minicart-wrapper .block-minicart:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .minicart-wrapper .block-minicart:before {
      left: 10px;
      top: -12px; }
    .minicart-wrapper .block-minicart:after {
      left: 9px;
      top: -14px; }
  .minicart-wrapper.active {
    overflow: visible; }
    .minicart-wrapper.active .block-minicart {
      display: block; }
  .minicart-wrapper .block-minicart {
    padding: 25px 20px;
    right: 0;
    width: 320px;
    z-index: 101; }
    .minicart-wrapper .block-minicart .block-title {
      display: none; }
    .minicart-wrapper .block-minicart:after {
      left: auto;
      right: 25px; }
    .minicart-wrapper .block-minicart:before {
      left: auto;
      right: 26px; }
  .minicart-wrapper .product .actions {
    float: right;
    margin: -24px 0 0;
    text-align: right; }
    .minicart-wrapper .product .actions > .primary,
    .minicart-wrapper .product .actions > .secondary {
      display: inline; }
  .minicart-wrapper .action.close {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px; }
    .minicart-wrapper .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-wrapper .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 32px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.close:hover:before {
      color: inherit; }
    .minicart-wrapper .action.close:active:before {
      color: inherit; }
    .minicart-wrapper .action.close:focus, .minicart-wrapper .action.close:active {
      background: none;
      border: none; }
    .minicart-wrapper .action.close:hover {
      background: none;
      border: none; }
    .minicart-wrapper .action.close.disabled, .minicart-wrapper .action.close[disabled],
    fieldset[disabled] .minicart-wrapper .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .minicart-wrapper .action.showcart {
    white-space: nowrap; }
    .minicart-wrapper .action.showcart .counter.qty {
      background: #ff5501;
      color: #fff;
      height: 24px;
      line-height: 24px;
      border-radius: 2px;
      display: inline-block;
      margin: 3px 0 0;
      min-width: 18px;
      overflow: hidden;
      padding: 0 3px;
      text-align: center;
      white-space: normal; }
      .minicart-wrapper .action.showcart .counter.qty.empty {
        display: none; }
      .minicart-wrapper .action.showcart .counter.qty .loader > img {
        max-width: 24px; }
  .minicart-wrapper .minicart-widgets {
    margin-top: 15px; }

.minicart-items-wrapper {
  border: 1px solid #ccc;
  margin: 0 -20px;
  border-left: 0;
  border-right: 0;
  overflow-x: auto;
  padding: 15px; }

.minicart-items {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .minicart-items .product-item {
    padding: 20px 0; }
    .minicart-items .product-item:not(:first-child) {
      border-top: 1px solid #ccc; }
    .minicart-items .product-item:first-child {
      padding-top: 0; }
  .minicart-items .product-item-pricing .label {
    display: inline-block;
    width: 4.5rem; }
  .minicart-items .price-minicart {
    margin-bottom: 5px; }
  .minicart-items .product > .product-item-photo,
  .minicart-items .product > .product-image-container {
    float: left; }
  .minicart-items .product .toggle {
    display: block;
    text-decoration: none;
    cursor: pointer;
    position: relative; }
    .minicart-items .product .toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 16px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .product .toggle:after {
      position: static;
      right: 20px;
      top: 0; }
  .minicart-items .product.active > .toggle:after {
    content: ""; }
  .minicart-items .product-item-name {
    font-weight: 400;
    margin: 0 0 10px; }
    .minicart-items .product-item-name a {
      color: #1979c3; }
  .minicart-items .product-item-details {
    padding-left: 88px; }
    .minicart-items .product-item-details .price {
      font-weight: 700; }
    .minicart-items .product-item-details .price-including-tax,
    .minicart-items .product-item-details .price-excluding-tax {
      margin: 5px 0 0; }
    .minicart-items .product-item-details .weee[data-label] {
      font-size: 1.1rem; }
    .minicart-items .product-item-details .details-qty {
      margin-top: 10px; }
  .minicart-items .product.options .tooltip.toggle {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .product.options .tooltip.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .product.options .tooltip.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: -3px 0 0 7px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .product.options .tooltip.toggle .details {
      display: none; }
  .minicart-items .item-qty {
    margin-right: 10px;
    text-align: center;
    width: 40px; }
  .minicart-items .update-cart-item {
    font-size: 1.1rem;
    vertical-align: top; }
  .minicart-items .subtitle {
    display: none; }
  .minicart-items .action.edit, .minicart-items .action.delete {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .action.edit > span, .minicart-items .action.delete > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .action.edit:before, .minicart-items .action.delete:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .action.edit:hover:before, .minicart-items .action.delete:hover:before {
      color: #303030; }
    .minicart-items .action.edit:active:before, .minicart-items .action.delete:active:before {
      color: #303030; }
  .minicart-items .action.delete:before {
    content: ""; }

@media only screen and (max-width: 479px) {
  .minicart-wrapper .block-minicart {
    width: 290px; } }

@media only screen and (max-width: 639px) {
  .minicart-wrapper {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .minicart-wrapper {
    margin-left: 13px; }
    .minicart-wrapper .block-minicart {
      right: -15px;
      width: 390px; } }

.checkout-container {
  margin: 0 0 20px; }

.opc-wrapper {
  margin: 0 0 20px; }
  .opc-wrapper .step-content {
    margin: 0 0 40px; }

.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
  display: none; }

.checkout-index-index .logo {
  margin-left: 0; }

.checkout-onepage-success .print {
  display: none; }

@media only screen and (max-width: 639px) {
  .opc-wrapper .step-title {
    font-size: 18px;
    border-bottom: 0;
    padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .opc-wrapper {
    width: 66.66667%;
    float: left;
    order: 1;
    padding-right: 30px; }
  .checkout-onepage-success .print {
    display: block;
    float: right;
    margin: 22px 0 0; } }

.opc-estimated-wrapper {
  border-bottom: 1px solid #ccc;
  margin: 0 0 15px;
  padding: 18px 15px; }
  .opc-estimated-wrapper .estimated-block {
    font-size: 18px;
    font-weight: 700;
    float: left; }
    .opc-estimated-wrapper .estimated-block .estimated-label {
      display: block;
      margin: 0 0 5px; }
  .opc-estimated-wrapper .minicart-wrapper .action.showcart:before {
    color: #333; }

@media only screen and (min-width: 768px) {
  .opc-estimated-wrapper {
    display: none; } }

.opc-progress-bar {
  margin: 0 0 20px;
  counter-reset: i;
  font-size: 0; }

.opc-progress-bar-item {
  margin: 0 0 10px;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 50%; }
  .opc-progress-bar-item:before {
    background: #e4e4e4;
    top: 19px;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%; }
  .opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px; }
  .opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0; }
  .opc-progress-bar-item > span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: #e4e4e4;
    font-weight: 300;
    font-size: 1.8rem; }
    .opc-progress-bar-item > span:before, .opc-progress-bar-item > span:after {
      background: #e4e4e4;
      height: 38px;
      margin-left: -19px;
      width: 38px;
      border-radius: 50%;
      content: '';
      left: 50%;
      position: absolute;
      top: 0; }
    .opc-progress-bar-item > span:after {
      background: #fff;
      height: 26px;
      margin-left: -13px;
      top: 6px;
      width: 26px;
      content: counter(i);
      counter-increment: i;
      color: #333;
      font-weight: 600;
      font-size: 1.8rem; }
  .opc-progress-bar-item._active:before {
    background: #ff5501; }
  .opc-progress-bar-item._active > span {
    color: #333; }
    .opc-progress-bar-item._active > span:before {
      background: #ff5501; }
    .opc-progress-bar-item._active > span:after {
      content: "";
      font-family: "icons-blank-theme";
      line-height: 1;
      font-size: 2.8rem; }
  .opc-progress-bar-item._complete {
    cursor: pointer; }
    .opc-progress-bar-item._complete > span {
      color: #333; }
      .opc-progress-bar-item._complete > span:after {
        content: "";
        font-family: "icons-blank-theme";
        line-height: 1;
        font-size: 2.8rem; }

@media only screen and (min-width: 768px) {
  .opc-progress-bar-item {
    width: 185px; } }

.field.choice .field-tooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -3px; }

.field.choice .field-tooltip-action {
  line-height: 21px; }

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
  border-color: #ed8380; }

.opc-wrapper .fieldset > .field > .label {
  font-weight: 400; }

@media only screen and (min-width: 768px) {
  .opc-wrapper .fieldset > .field.required, .opc-wrapper .fieldset > .field._required {
    position: relative; }
    .opc-wrapper .fieldset > .field.required > label, .opc-wrapper .fieldset > .field._required > label {
      padding-right: 25px; }
      .opc-wrapper .fieldset > .field.required > label:after, .opc-wrapper .fieldset > .field._required > label:after {
        margin-left: 10px;
        position: absolute;
        top: 9px; } }

@media only screen and (min-width: 768px) {
  .checkout-index-index .modal-popup .form-shipping-address {
    max-width: 500px; }
  .checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 10px; } }

@media only screen and (min-width: 1024px) {
  .checkout-index-index .modal-popup .modal-inner-wrap {
    margin-left: -400px;
    width: 800px;
    left: 50%; } }

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 1px; }
  .field-tooltip._active {
    z-index: 100; }
    .field-tooltip._active .field-tooltip-content {
      display: block; }
    .field-tooltip._active .field-tooltip-action:before {
      color: #333; }
  .field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none; }
    .field-tooltip .field-tooltip-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .field-tooltip .field-tooltip-action:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 21px;
      line-height: inherit;
      color: #bbb;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .field-tooltip .field-tooltip-action:hover:before {
      color: #333; }
    ._keyfocus .field-tooltip .field-tooltip-action:focus {
      z-index: 100; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
        display: block; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus:before {
        color: #333; }
  .field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2; }
    .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3; }
    .field-tooltip .field-tooltip-content:before {
      border-right-color: #666666; }
    .field-tooltip .field-tooltip-content:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4; }

@media only screen and (max-width: 479px) {
  .modal-popup .field-tooltip .field-tooltip-content {
    width: 200px; } }

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
  margin-top: 28px;
  margin-bottom: 20px; }
  .opc-wrapper .form-login .fieldset .note,
  .opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px; }

.opc-wrapper .shipping-address-items {
  font-size: 0; }

.opc-wrapper .shipping-address-item {
  border: 2px solid transparent;
  line-height: 30px;
  margin: 0 0 20px;
  padding: 20px 35px 20px 20px;
  transition: 0.3s border-color;
  width: 50%;
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: top;
  word-wrap: break-word; }
  .opc-wrapper .shipping-address-item.selected-item {
    border-color: #ff5501; }
    .opc-wrapper .shipping-address-item.selected-item:after {
      background: #ff5501;
      color: #fff;
      content: "";
      font-family: "icons-blank-theme";
      height: 27px;
      width: 29px;
      font-size: 27px;
      line-height: 21px;
      padding-top: 2px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0; }
    .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
      visibility: hidden; }

.opc-wrapper .action-show-popup {
  margin: 0 0 20px; }
  .opc-wrapper .action-show-popup > span:before {
    content: '+';
    padding-right: 5px; }

.opc-wrapper .action-select-shipping-item {
  float: right;
  margin: 20px 0 0; }

.opc-wrapper .edit-address-link {
  display: block;
  float: left;
  margin: 26px 5px 0 0; }

.checkout-shipping-method .step-title {
  margin-bottom: 0; }

.checkout-shipping-method .no-quotes-block {
  margin: 20px 0; }

.methods-shipping .actions-toolbar .action.primary {
  margin: 20px 0 0; }

.table-checkout-shipping-method thead th {
  display: none; }

.table-checkout-shipping-method tbody td {
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
  padding-top: 20px; }
  .table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px; }

.table-checkout-shipping-method tbody tr:first-child td {
  border-top: none; }

.table-checkout-shipping-method tbody .row-error td {
  border-top: none;
  padding-bottom: 10px;
  padding-top: 0; }

@media only screen and (max-width: 639px) {
  .opc-wrapper .form-login {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px; }
  .opc-wrapper .shipping-address-item {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px;
    width: 100%; }
    .opc-wrapper .shipping-address-item.selected-item {
      padding: 15px 35px 15px 18px;
      border-bottom-width: 2px; }
      .opc-wrapper .shipping-address-item.selected-item .edit-address-link {
        right: 39px; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    margin-top: 28px; }
  .opc-wrapper .action-select-shipping-item {
    float: none;
    margin-top: 10px;
    width: 100%; }
  .opc-wrapper .action-show-popup {
    width: 100%; }
  .opc-wrapper .edit-address-link {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 1px; }
    .opc-wrapper .edit-address-link > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-wrapper .edit-address-link:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .opc-wrapper .edit-address-link:hover:before {
      color: #303030; }
    .opc-wrapper .edit-address-link:active:before {
      color: #303030; } }

@media only screen and (min-width: 768px) {
  .checkout-shipping-method .actions-toolbar > .primary {
    float: right; }
  .checkout-shipping-method .actions-toolbar .action.primary {
    margin: 0; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    max-width: 500px; }
  .table-checkout-shipping-method {
    width: auto; } }

@media only screen and (min-width: 1024px) {
  .opc-wrapper .shipping-address-item {
    width: 33.33333%; }
    .opc-wrapper .shipping-address-item:before {
      background: #ccc;
      height: calc(100% - 20px);
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 1px; }
    .opc-wrapper .shipping-address-item:nth-child(3n + 1):before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item:before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item + .shipping-address-item:before {
      display: none; }
  .table-checkout-shipping-method {
    min-width: 500px; } }

.checkout-shipping-method {
  position: relative; }

.shipping-policy-block.field-tooltip {
  top: 12px; }
  .shipping-policy-block.field-tooltip .field-tooltip-action {
    color: #1979c3;
    cursor: pointer; }
    .shipping-policy-block.field-tooltip .field-tooltip-action:before {
      display: none; }
  .shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px; }

@media only screen and (max-width: 639px) {
  .shipping-policy-block.field-tooltip {
    margin-bottom: 20px;
    position: relative;
    right: auto;
    top: auto; }
    .shipping-policy-block.field-tooltip .field-tooltip-content {
      width: 300px;
      right: auto; }
      .shipping-policy-block.field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto; } }

@media only screen and (min-width: 768px) {
  .opc-sidebar {
    margin: 46px 0 20px;
    width: 33.33333%;
    float: right;
    order: 2; } }

.opc-block-shipping-information {
  padding: 0 30px; }
  .opc-block-shipping-information .shipping-information-title {
    border-bottom: 1px solid #ccc;
    margin: 0 0 20px;
    position: relative; }
    .opc-block-shipping-information .shipping-information-title .action-edit {
      top: 2px;
      display: inline-block;
      text-decoration: none;
      margin: 0;
      position: absolute;
      right: 0; }
      .opc-block-shipping-information .shipping-information-title .action-edit > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .opc-block-shipping-information .shipping-information-title .action-edit:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 28px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
        color: #333; }
      .opc-block-shipping-information .shipping-information-title .action-edit:active:before {
        color: #8f8f8f; }
  .opc-block-shipping-information .shipping-information-content {
    line-height: 27px; }
    .opc-block-shipping-information .shipping-information-content .actions-toolbar {
      margin-left: 0; }
  .opc-block-shipping-information .ship-to,
  .opc-block-shipping-information .ship-via {
    margin: 0 0 20px; }

.opc-block-summary {
  background: #f5f5f5;
  padding: 22px 30px;
  margin: 0 0 20px; }
  .opc-block-summary > .title {
    display: block; }
  .opc-block-summary .mark .value {
    color: #999;
    display: block; }
  .opc-block-summary .grand.incl + .grand.excl .mark,
  .opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0; }
    .opc-block-summary .grand.incl + .grand.excl .mark strong,
    .opc-block-summary .grand.incl + .grand.excl .amount strong {
      font-weight: 400; }
  .opc-block-summary .not-calculated {
    font-style: italic; }
  .opc-block-summary .items-in-cart > .title {
    border-bottom: 1px solid #d1d1d1;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative; }
    .opc-block-summary .items-in-cart > .title > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-block-summary .items-in-cart > .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      line-height: 12px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 3px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .opc-block-summary .items-in-cart > .title:after {
      position: absolute;
      right: 0;
      top: 10px; }
    .opc-block-summary .items-in-cart > .title strong {
      font-size: 1.8rem;
      font-weight: 300;
      margin: 0; }
  .opc-block-summary .items-in-cart.active > .title:after {
    content: ""; }
  .opc-block-summary .items-in-cart .product {
    position: relative; }
  .opc-block-summary .minicart-items-wrapper {
    margin: -15px 0 0;
    max-height: 370px;
    padding: 15px 15px 0 0;
    border: 0; }
  .column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0; }
  .opc-block-summary .product-item .product-item-inner {
    display: table;
    margin: 0 0 10px;
    width: 100%; }
  .opc-block-summary .product-item .product-item-name-block {
    display: table-cell;
    padding-right: 5px;
    text-align: left; }
  .opc-block-summary .product-item .subtotal {
    display: table-cell;
    text-align: right; }
  .opc-block-summary .product-item .price {
    font-size: 1.6rem;
    font-weight: 400; }
  .opc-block-summary .product-item .price-including-tax + .price-excluding-tax {
    margin: 0; }
    .opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
      font-size: 1rem; }
  .opc-block-summary .actions-toolbar {
    position: relative;
    z-index: 1; }
    .opc-block-summary .actions-toolbar .secondary {
      border-top: 1px solid #d1d1d1;
      display: block;
      float: none;
      margin: -1px 0 0;
      padding: 15px 0 0;
      text-align: left; }
      .opc-block-summary .actions-toolbar .secondary .action {
        margin: 0; }

@media only screen and (min-width: 768px) {
  .opc-summary-wrapper .modal-header .action-close {
    display: none; } }

.authentication-dropdown {
  box-sizing: border-box; }
  .authentication-dropdown .modal-inner-wrap {
    padding: 25px; }

.authentication-wrapper {
  float: right;
  margin-top: -60px;
  max-width: 50%;
  position: relative;
  z-index: 1; }
  ._has-auth-shown .authentication-wrapper {
    z-index: 900; }

.block-authentication .block-title {
  font-size: 1.8rem;
  border-bottom: 0;
  margin-bottom: 25px; }
  .block-authentication .block-title strong {
    font-weight: 300; }

.block-authentication .field .label {
  font-weight: 400; }

.block-authentication .actions-toolbar {
  margin-bottom: 5px; }
  .block-authentication .actions-toolbar > .secondary {
    padding-top: 25px;
    text-align: left; }

.block-authentication .block[class] {
  margin: 0; }
  .block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px; }
  .block-authentication .block[class] .field .control,
  .block-authentication .block[class] .field .label {
    float: none;
    width: auto; }
  .block-authentication .block[class] + .block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative; }
    .block-authentication .block[class] + .block::before {
      height: 36px;
      line-height: 34px;
      margin: -19px 0 -18px;
      min-width: 36px;
      background: #fff;
      border: 1px solid #c1c1c1;
      border-radius: 50%;
      box-sizing: border-box;
      color: #c1c1c1;
      content: attr(data-label);
      display: inline-block;
      left: 50%;
      letter-spacing: normal;
      padding: 0 0.2rem;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0; }

@media only screen and (min-width: 768px) {
  .authentication-dropdown {
    background-color: #fff;
    border: 1px solid #aeaeae;
    position: absolute;
    text-align: left;
    top: 100%;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear 0.1s, visibility 0s linear 0.1s;
    visibility: hidden;
    width: 100%; }
    .authentication-dropdown._show {
      z-index: 100;
      transform: scale(1, 1);
      transition: transform linear 0.1s, visibility 0s linear 0s;
      visibility: visible; }
  .authentication-wrapper {
    width: 33.33333%;
    text-align: right; }
  .block-authentication .block-title {
    font-size: 2.6rem;
    border-bottom: 0;
    margin-bottom: 25px; }
  .block-authentication .actions-toolbar > .primary {
    display: inline;
    float: right;
    margin-right: 0; }
    .block-authentication .actions-toolbar > .primary .action {
      margin-right: 0; }
  .block-authentication .actions-toolbar > .secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem; }
  .popup-authentication .modal-inner-wrap {
    min-width: 768px;
    width: 60%; }
  .popup-authentication .block-authentication {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c1c1c1; }
  .popup-authentication .block[class],
  .popup-authentication .form-login,
  .popup-authentication .fieldset,
  .popup-authentication .block-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    padding: 10px 30px 0 0;
    width: 50%; }
    .popup-authentication .block[class] + .block {
      border-left: 1px solid #c1c1c1;
      border-top: 0;
      margin: 0;
      padding: 10px 0 0 40px; }
      .popup-authentication .block[class] + .block::before {
        left: 0;
        top: 50%; }
  .popup-authentication .actions-toolbar {
    margin-bottom: 0;
    margin-top: auto; } }

.checkout-payment-method .step-title {
  border-bottom: 0;
  margin-bottom: 0; }

.checkout-payment-method .payment-method:first-child .payment-method-title {
  border-top: 0; }

.checkout-payment-method .payment-method._active .payment-method-content {
  display: block; }

.checkout-payment-method .payment-method + .payment-method .payment-method-title {
  border-top: 1px solid #ccc; }

.checkout-payment-method .payment-method-title {
  padding: 20px 0;
  margin: 0; }
  .checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle; }
  .checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px; }

.checkout-payment-method .payment-method-content {
  display: none;
  padding: 0 0 20px 22px; }
  .checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px; }

.checkout-payment-method .payment-group + .payment-group .step-title {
  margin: 20px 0 0; }

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
  max-width: 500px; }

.checkout-payment-method .billing-address-same-as-shipping-block {
  margin: 0 0 10px; }

.checkout-payment-method .checkout-billing-address {
  margin: 0 0 20px; }
  .checkout-payment-method .checkout-billing-address .primary .action-update {
    margin-right: 0; }
  .checkout-payment-method .checkout-billing-address .billing-address-details {
    line-height: 27px;
    padding: 0 0 0 23px; }

.checkout-payment-method .payment-method-note + .checkout-billing-address {
  margin-top: 20px; }

.checkout-payment-method .payment-method-iframe {
  background-color: transparent;
  display: none;
  width: 100%; }

.checkout-payment-method .no-payments-block {
  margin: 20px 0; }

@media only screen and (max-width: 767px) {
  .checkout-payment-method .payment-methods {
    margin: -15px; }
  .checkout-payment-method .payment-method-title {
    padding: 15px; }
  .checkout-payment-method .payment-method-content {
    padding: 0 15px 20px; }
  .checkout-payment-method .checkout-billing-address .action-cancel {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .actions-toolbar .primary {
    float: right;
    margin: 0; }
  .checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%; }
  .checkout-payment-method .payment-method-content .fieldset > .field {
    margin: 0 0 20px; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type .control {
      margin-left: 25.8%; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type.no-detection .control {
      margin-left: 0; }
  .checkout-billing-address .action-update {
    float: right; }
  .checkout-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0; } }

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
  content: ""; }

.checkout-payment-method .payment-option._collapsible .payment-option-title {
  cursor: pointer; }

.checkout-payment-method .payment-option._collapsible .payment-option-content {
  display: none; }

.checkout-payment-method .payment-option-title {
  border-top: 1px solid #ccc;
  padding: 20px 0; }
  .checkout-payment-method .payment-option-title .action-toggle {
    color: #1979c3;
    display: inline-block;
    text-decoration: none; }
    .checkout-payment-method .payment-option-title .action-toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .checkout-payment-method .payment-option-title .action-toggle:hover:after {
      color: #333; }
    .checkout-payment-method .payment-option-title .action-toggle:active:after {
      color: #8f8f8f; }

.checkout-payment-method .payment-option-content {
  padding: 0 0 20px 22px; }

.checkout-payment-method .payment-option-inner {
  margin: 0 0 20px; }

.checkout-payment-method .credit-card-types {
  padding: 0; }
  .checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top; }
    .checkout-payment-method .credit-card-types .item._active {
      font-weight: 700; }
      .checkout-payment-method .credit-card-types .item._active img {
        filter: grayscale(0%); }
    .checkout-payment-method .credit-card-types .item._inactive {
      opacity: 0.4; }
    .checkout-payment-method .credit-card-types .item span {
      display: inline-block;
      padding-top: 6px;
      vertical-align: top; }
  .checkout-payment-method .credit-card-types img {
    filter: grayscale(100%);
    transition: all 0.6s ease; }

.checkout-payment-method .ccard .fields > .year {
  padding-left: 5px; }

.checkout-payment-method .ccard .fields .select {
  padding-left: 5px;
  padding-right: 5px; }

.checkout-payment-method .ccard .month .select {
  width: 140px; }

.checkout-payment-method .ccard .year .select {
  width: 80px; }

.checkout-payment-method .ccard .number .input-text {
  width: 225px; }

.checkout-payment-method .ccard > .field.cvv > .control {
  padding-right: 20px;
  width: auto; }

.checkout-payment-method .ccard .cvv .input-text {
  width: 55px; }

.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
  width: auto !important; }

@media only screen and (max-width: 767px) {
  .checkout-payment-method .payment-option {
    margin: -15px; }
    .checkout-payment-method .payment-option .payment-option-title {
      padding: 15px; }
    .checkout-payment-method .payment-option .payment-option-content {
      padding: 0 15px 20px; } }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .payment-option-title {
    padding-left: 22px; }
  .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0; } }

.checkout-agreements-block .checkout-agreements {
  margin-bottom: 20px; }

.checkout-agreements-block .action-show {
  vertical-align: baseline; }

.checkout-agreements-items {
  padding-bottom: 30px; }
  .checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px; }
  .checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0; }

.checkout-agreement-item-content {
  overflow: auto; }

.login-container .block-new-customer .actions-toolbar {
  margin-top: 25px; }

.login-container .block .block-title {
  font-size: 1.8rem; }

.login-container .fieldset:after {
  margin: 10px 0 0;
  content: attr(data-hasrequired);
  display: block;
  letter-spacing: normal;
  word-spacing: normal;
  color: #e02b27;
  font-size: 1.2rem; }

.login-container .fieldset:after {
  margin-top: 35px; }

.block-addresses-list .items.addresses > .item {
  margin-bottom: 20px; }
  .block-addresses-list .items.addresses > .item:last-child {
    margin-bottom: 0; }

.form-address-edit #region_id {
  display: none; }

.form-edit-account .fieldset.password {
  display: none; }

.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
  line-height: 26px; }

.fieldset .fullname.field > .label + .control {
  width: 100%; }

.account .column.main h2 {
  margin-top: 0; }

.account .column.main .toolbar {
  text-align: center; }
  .account .column.main .toolbar .limiter-options {
    width: auto; }

.account .sidebar-additional {
  margin-top: 40px; }

.account .table-wrapper:last-child {
  margin-bottom: 0; }

.account .table-wrapper .action {
  margin-right: 15px; }
  .account .table-wrapper .action:last-child {
    margin-right: 0; }

.account-nav .content {
  background: #f5f5f5;
  padding: 15px 0; }

.account-nav .item {
  margin: 3px 0 0; }
  .account-nav .item:first-child {
    margin-top: 0; }
  .account-nav .item a,
  .account-nav .item > strong {
    border-left: 3px solid transparent;
    display: block;
    padding: 5px 18px 5px 15px; }
  .account-nav .item a {
    text-decoration: none; }
    .account-nav .item a:hover {
      background: #e8e8e8; }
  .account-nav .item.current a,
  .account-nav .item.current strong {
    border-color: #ff5501;
    font-weight: 600; }
  .account-nav .item.current a {
    border-color: #ff5501; }
  .account-nav .item .delimiter {
    border-top: 1px solid #d1d1d1;
    display: block;
    margin: 10px 1.8rem; }

.column.main .block:last-child {
  margin-bottom: 0; }

.block .title {
  margin-bottom: 10px; }
  .block .title strong {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .column.main .block .title strong {
      font-size: 18px; }

.block p:last-child {
  margin: 0; }

.block .box-actions {
  margin-top: 5px; }

.field.password .control {
  display: flex;
  flex-direction: column; }
  .field.password .control .mage-error {
    order: 2; }
  .field.password .control .input-text {
    order: 0;
    z-index: 2; }

.password-strength-meter {
  background-color: #f4f4f4;
  height: 32px;
  line-height: 32px;
  padding: 0 9px;
  position: relative;
  z-index: 1; }
  .password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .password-none .password-strength-meter:before {
    background-color: #f4f4f4;
    width: 100%; }
  .password-weak .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%; }
  .password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%; }
  .password-strong .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%; }
  .password-very-strong .password-strength-meter:before {
    background-color: #81b562;
    width: 100%; }

.control.captcha-image {
  margin-top: 10px; }
  .control.captcha-image .captcha-img {
    vertical-align: middle; }

@media only screen and (max-width: 639px) {
  .account .column.main,
  .account .sidebar-additional {
    margin: 0; } }

@media only screen and (max-width: 767px) {
  .login-container .fieldset:after {
    text-align: center; }
  .account .page.messages {
    margin-bottom: 40px; }
  .control.captcha-image .captcha-img {
    margin-bottom: 10px;
    display: block; }
  .customer-account-index .page-title-wrapper {
    position: relative; } }

@media only screen and (min-width: 768px) {
  .login-container .block.login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 30px; }
  .login-container .block.login .actions-toolbar > .secondary {
    float: left; }
  .login-container .fieldset > .field > .control {
    width: 55%; }
  .fieldset .fullname .field .label {
    padding: 6px 15px 0 0;
    text-align: right;
    width: 25.8%;
    box-sizing: border-box;
    float: left; }
  .fieldset .fullname .field .control {
    width: 74.2%;
    float: left; }
  .form.password.reset,
  .form.send.confirmation,
  .form.password.forget,
  .form.create.account {
    min-width: 600px;
    width: 50%; }
  .account.page-layout-2columns-left .sidebar-main,
  .account.page-layout-2columns-left .sidebar-additional {
    width: 22.3%; }
  .account.page-layout-2columns-left .column.main {
    width: 77.7%; }
  .block-addresses-list .items.addresses {
    font-size: 0; }
    .block-addresses-list .items.addresses > .item {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 20px;
      vertical-align: top;
      width: 48.8%; }
      .block-addresses-list .items.addresses > .item:nth-last-child(1), .block-addresses-list .items.addresses > .item:nth-last-child(2) {
        margin-bottom: 0; }
      .block-addresses-list .items.addresses > .item:nth-child(even) {
        margin-left: 2.4%; }
  .control.captcha-image .captcha-img {
    margin: 0 10px 10px 0; } }

.downloadable.samples {
  margin-bottom: 20px; }
  .downloadable.samples dd {
    margin: 0 0 10px; }

.table-downloadable-products .product-name {
  margin-right: 15px; }

.field.downloads .price-container {
  display: inline;
  white-space: nowrap; }
  .field.downloads .price-container:before {
    content: ' + ';
    display: inline; }

.field.downloads .price-excluding-tax {
  display: inline; }
  .field.downloads .price-excluding-tax:before {
    content: " (" attr(data-label) ": "; }
  .field.downloads .price-excluding-tax:after {
    content: ')'; }

.field.downloads .price-including-tax {
  font-size: 1.4rem;
  display: inline;
  font-weight: 600; }

.field.downloads .sample.link {
  float: right; }

.page-product-downloadable .product-add-form {
  clear: both;
  margin-bottom: 25px; }

.page-product-downloadable .product-options-bottom .field.qty {
  display: none !important; }

@media only screen and (min-width: 768px) {
  .page-product-downloadable .product-options-wrapper {
    float: left;
    width: 55%; }
  .page-product-downloadable .product-options-bottom {
    float: right;
    width: 40%; }
    .page-product-downloadable .product-options-bottom .field.qty + .actions {
      padding-top: 0; } }

.cart-summary .block.giftcard .action.check {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem;
  margin-top: 20px; }

.page-product-giftcard .giftcard-amount {
  margin-bottom: 0;
  text-align: left; }
  .page-product-giftcard .giftcard-amount .field:last-child {
    padding-bottom: 20px; }

.page-product-giftcard .product-options-wrapper .field:first-of-type {
  margin-top: 20px; }

.giftcard-account .please-wait {
  display: none; }

.giftcard-account .form-giftcard-redeem .giftcard.info {
  margin-bottom: 30px; }

.opc-wrapper .form-giftcard-account {
  max-width: 500px; }
  .opc-wrapper .form-giftcard-account .field {
    margin: 0 0 20px; }

.opc-wrapper .giftcard-account-info {
  margin: 0 0 20px; }
  .opc-wrapper .giftcard-account-info .giftcard-account-number {
    margin: 0 0 10px; }

.gift-message .field {
  margin-bottom: 20px; }
  .gift-message .field .label {
    color: #666;
    font-weight: 400; }

.gift-options .actions-toolbar .action-cancel {
  display: none; }

.gift-options-title {
  margin: 0 0 20px; }

.gift-options-content .fieldset {
  margin: 0 0 20px; }

.gift-summary .actions-toolbar > .secondary {
  float: none; }
  .gift-summary .actions-toolbar > .secondary .action {
    margin: 10px 20px 0 0; }

.action-gift {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  text-decoration: none; }
  .action-gift:hover, .action-gift:active, .action-gift:focus {
    text-decoration: none; }
  .action-gift:last-child {
    margin-right: 0; }
  .action-gift:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .action-gift:hover:after {
    color: inherit; }
  .action-gift:active:after {
    color: inherit; }
  .action-gift.active:after, .action-gift._active:after {
    content: ""; }

.cart.table-wrapper .gift-content {
  box-sizing: border-box;
  clear: left;
  display: none;
  float: left;
  margin: 10px 0 20px;
  text-align: left;
  width: 100%; }
  .cart.table-wrapper .gift-content._active {
    display: block; }

.gift-item-block {
  margin: 0; }
  .gift-item-block._active .title:after {
    content: ""; }
  .gift-item-block .title {
    font-weight: 700;
    border-radius: 3px;
    background-image: none;
    background: #f2f2f2;
    border: 1px solid #cdcdcd;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 20px 0 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none; }
    .gift-item-block .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .gift-item-block .title:hover:after {
      color: inherit; }
    .gift-item-block .title:active:after {
      color: inherit; }
    .gift-item-block .title:focus, .gift-item-block .title:active {
      background: #e2e2e2;
      border: 1px solid #cdcdcd;
      color: #333; }
    .gift-item-block .title:hover {
      background: #e2e2e2;
      border: 1px solid #cdcdcd;
      color: #555; }
    .gift-item-block .title.disabled, .gift-item-block .title[disabled],
    fieldset[disabled] .gift-item-block .title {
      opacity: 0.5;
      cursor: default;
      pointer-events: none; }
    .gift-item-block .title:active {
      box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }
  .gift-item-block .content {
    border-bottom: 1px solid #c1c1c1;
    padding: 10px 10px 20px;
    position: relative; }

.order-details-items .order-gift-message .item-options dt,
.order-details-items .block-order-details-gift-message .item-options dt {
  font-weight: 400;
  margin: 10px 0; }

.order-details-items .order-gift-message dt:after,
.order-details-items .block-order-details-gift-message dt:after {
  content: ''; }

.order-details-items .order-gift-message .item-message,
.order-details-items .block-order-details-gift-message .item-message {
  clear: left; }

.order-details-items .order-items .action.show {
  display: inline-block;
  text-decoration: none;
  padding-right: 20px;
  position: relative; }
  .order-details-items .order-items .action.show:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .order-details-items .order-items .action.show:after {
    position: absolute;
    right: 0;
    top: -4px; }
  .order-details-items .order-items .action.show.expanded:after {
    content: ""; }

.block-order-details-gift-message {
  border-top: 1px solid #d1d1d1;
  padding-top: 10px; }

@media only screen and (max-width: 767px) {
  .gift-options .actions-toolbar .action-update {
    font-size: 2rem;
    padding: 15px;
    width: 100%; }
  .gift-item-block {
    border-top: 1px solid #c1c1c1;
    border-bottom: 0; }
  .cart.table-wrapper .gift-content {
    margin-right: -10px; } }

@media only screen and (max-width: 639px) {
  .cart.table-wrapper .gift-content {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 20px; } }

@media only screen and (min-width: 768px) {
  .gift-message .field {
    margin-bottom: 20px; }
  .gift-options {
    position: relative;
    z-index: 1; }
    .gift-options .actions-toolbar {
      clear: both;
      float: right;
      position: static; }
      .gift-options .actions-toolbar .secondary {
        float: right; }
        .gift-options .actions-toolbar .secondary .action {
          float: right;
          margin-left: 20px; }
        .gift-options .actions-toolbar .secondary .action-cancel {
          display: block;
          float: left;
          margin-top: 6px; }
      .gift-options .actions-toolbar:nth-child(3):before {
        border-left: 1px solid #c1c1c1;
        bottom: 5rem;
        content: '';
        display: block;
        left: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 0; }
  .gift-options-title {
    font-weight: 300;
    font-size: 1.8rem; }
  .cart.table-wrapper .action-gift {
    float: left; }
  .order-options .gift-wrapping,
  .table-order-review .gift-wrapping {
    max-width: 50%; } }

.form-giftregistry-share .actions-toolbar:not(:last-child),
.form-giftregistry-edit .actions-toolbar:not(:last-child) {
  margin-bottom: 40px; }

.form-giftregistry-share .fieldset .nested .field:not(.choice) .control,
.form-giftregistry-edit .fieldset .nested .field:not(.choice) .control {
  width: 100%; }

.table-giftregistry-items .product-image {
  float: left;
  margin-right: 10px; }

.cart-summary .form.giftregistry .fieldset {
  margin: 25px 0 5px; }
  .cart-summary .form.giftregistry .fieldset .field {
    margin: 0 0 10px; }
    .cart-summary .form.giftregistry .fieldset .field .control {
      float: none;
      width: auto; }
  .cart-summary .form.giftregistry .fieldset .action.primary {
    margin-bottom: 0; }

.form-giftregistry-search {
  margin-bottom: 60px; }
  .form-giftregistry-search .fields-specific-options .field:nth-last-of-type(1) {
    margin-bottom: 0; }

.block-giftregistry-results .toolbar-giftregistry-results {
  text-align: center; }

.block-giftregistry .actions-toolbar {
  margin: 17px 0 0; }

.block-giftregistry-shared .item-options .item-value {
  margin: 0 0 25px; }

.block-giftregistry-shared-items .item {
  border-bottom: 1px solid #d1d1d1; }

.block-giftregistry-shared-items .col {
  padding: 20px 8px 10px; }
  .block-giftregistry-shared-items .col .price-box {
    display: inline-block; }
  .block-giftregistry-shared-items .col .price-wrapper {
    font-size: 1.8rem;
    display: block;
    font-weight: 700;
    white-space: nowrap; }

.block-giftregistry-shared-items .product .item-options {
  margin-bottom: 0; }

.block-giftregistry-shared-items .product-item-photo {
  display: block;
  max-width: 65px;
  padding: 10px 0; }

.block-giftregistry-shared-items .product-item-name {
  display: block;
  margin: 0 0 5px; }

@media only screen and (max-width: 639px) {
  .table-giftregistry-items .field.choice,
  .table-giftregistry-items .field.qty {
    display: inline-block; }
  .form-giftregistry-search .fieldset {
    margin-bottom: 15px; } }

@media only screen and (min-width: 768px) {
  .form-giftregistry-search .fieldset {
    margin-bottom: 29px; }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field {
      margin: 0 0 20px;
      box-sizing: border-box; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field > .label {
        margin: 0; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        content: '';
        display: table; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        clear: both; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field.choice:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field.no-label:before {
        padding: 6px 15px 0 0;
        width: 25.8%;
        box-sizing: border-box;
        content: ' ';
        float: left;
        height: 1px; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field .description {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .control {
        width: 74.2%;
        float: left; }
  .block-giftregistry-shared-items .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding: 0 20px 0 0;
    vertical-align: top;
    width: 1%; }
  .block-giftregistry-shared-items .product-item-details {
    display: table-cell;
    vertical-align: top;
    width: 99%;
    word-break: normal; }
  .block-giftregistry-shared-items .col.product {
    width: 48%; }
  .block-giftregistry-shared-items .col:not(.product) {
    text-align: center; }
  .block-giftregistry-shared-items .col.price {
    padding-top: 17px; }
  .block-giftregistry-shared-items .input-text.qty {
    margin-top: -4px; } }

.gift-wrapping .label {
  margin: 0 0 5px;
  display: inline-block; }

.gift-wrapping .field {
  margin-bottom: 10px; }

.gift-wrapping-info {
  font-weight: 300;
  font-size: 18px;
  margin: 20px 0; }

.gift-wrapping-list {
  list-style: none;
  margin: 20px 0 10px;
  padding: 0; }
  .gift-wrapping-list .no-image {
    background: #f2f2f2;
    display: inline-block;
    text-decoration: none; }
    .gift-wrapping-list .no-image > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .gift-wrapping-list .no-image:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 38px;
      line-height: 4rem;
      color: #494949;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .gift-wrapping-list .no-image:after {
      display: block; }

.gift-wrapping-preview,
.gift-wrapping-item > span {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.gift-wrapping-item {
  float: left;
  margin: 0 10px 10px 0; }
  .gift-wrapping-item:last-child {
    margin-right: 0; }
  .gift-wrapping-item._active span:before {
    border: 3px solid #333;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2; }
  .gift-wrapping-item > span {
    height: 4rem;
    width: 4rem; }

.gift-wrapping-title {
  margin: 10px 0; }
  .gift-wrapping-title .action-remove {
    margin-left: 5px; }

.gift-options-content .fieldset > .field {
  margin: 0 0 5px; }
  .gift-options-content .fieldset > .field:before {
    display: none; }

.gift-wrapping-summary .gift-wrapping-title {
  border: 0;
  display: inline-block;
  padding: 0; }

.opc-wrapper .data.table .gift-wrapping,
.table-order-items .gift-wrapping,
.table-order-review .gift-wrapping {
  margin: 20px 0; }
  .opc-wrapper .data.table .gift-wrapping .title,
  .table-order-items .gift-wrapping .title,
  .table-order-review .gift-wrapping .title {
    float: none; }
  .opc-wrapper .data.table .gift-wrapping .content,
  .table-order-items .gift-wrapping .content,
  .table-order-review .gift-wrapping .content {
    margin: 0; }
    .opc-wrapper .data.table .gift-wrapping .content > img,
    .table-order-items .gift-wrapping .content > img,
    .table-order-review .gift-wrapping .content > img {
      float: left;
      margin: 0 10px 0 0;
      max-height: 50px;
      max-width: 50px; }

.gift-summary .price-box,
.gift-options .price-box {
  margin-left: 22px; }

.gift-summary .regular-price:before,
.gift-options .regular-price:before {
  content: attr(data-label) ": "; }

.gift-summary .regular-price .price,
.gift-options .regular-price .price {
  font-weight: 700; }

.order-options .item {
  padding-bottom: 20px; }

.order-options .product {
  margin-bottom: 10px; }

.order-options .product-image-container {
  float: left;
  padding-right: 10px; }

.order-options .gift-wrapping {
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .gift-wrapping {
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 20px;
    padding-bottom: 10px; }
  .gift-wrapping-list {
    width: 100%; }
  .gift-wrapping-preview img {
    width: 100%; }
  .item-actions .actions-toolbar .gift-options .gift-wrapping,
  .item-actions .actions-toolbar .gift-options .gift-message {
    display: block; } }

@media only screen and (min-width: 768px) {
  .gift-options-cart-item .gift-wrapping,
  .cart-gift-item .gift-wrapping {
    box-sizing: border-box;
    float: left;
    padding-right: 20px;
    width: 50%; }
    .gift-options-cart-item .gift-wrapping + .gift-message,
    .cart-gift-item .gift-wrapping + .gift-message {
      border-left: 1px solid #c1c1c1;
      box-sizing: border-box;
      float: left;
      padding-left: 4.5rem;
      width: 50%; } }

.table.grouped > thead > tr > th,
.table.grouped > thead > tr > td,
.table.grouped > tbody > tr > th,
.table.grouped > tbody > tr > td,
.table.grouped > tfoot > tr > th,
.table.grouped > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.table.grouped > caption + thead > tr:first-child > th,
.table.grouped > caption + thead > tr:first-child > td,
.table.grouped > colgroup + thead > tr:first-child > th,
.table.grouped > colgroup + thead > tr:first-child > td,
.table.grouped > thead:first-child > tr:first-child > th,
.table.grouped > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table.grouped > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.table.grouped > thead > tr > th {
  border-bottom: 0; }

.table.grouped .product-item-name {
  font-weight: 400; }

.table.grouped .price-box .price {
  font-size: 14px; }

.table.grouped .stock.unavailable {
  font-weight: normal;
  margin: 0;
  text-transform: none;
  white-space: nowrap; }

.table.grouped .row-tier-price td {
  border-top: 0; }

.table.grouped .row-tier-price .prices-tier {
  margin: 0 -10px 10px; }

.form-add-invitations .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .form-add-invitations .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .form-add-invitations .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .form-add-invitations .action.add:hover:before {
    color: inherit; }
  .form-add-invitations .action.add:active:before {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 20px; } }

@media only screen and (min-width: 768px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 29px; }
  .table-invitations .col {
    width: 50%; } }

.block.filter {
  margin-bottom: 40px; }
  .block.filter .filter-title {
    margin-bottom: 20px; }
    .block.filter .filter-title strong {
      font-size: 18px; }

.block-subtitle {
  display: inline-block;
  margin-bottom: 10px; }

.filter-current .item {
  margin-bottom: 10px; }

.filter-current .filter-label {
  display: block;
  font-weight: 700; }
  .filter-current .filter-label:after {
    content: ':'; }

.filter-current + .filter-actions {
  margin-bottom: 30px; }

.filter-options {
  margin: 0; }
  .filter-options .filter-options-title {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin: 0 0 10px;
    word-break: break-all; }
  .filter-options .filter-options-content {
    margin: 0 0 25px; }
    .filter-options .filter-options-content .item {
      margin-bottom: 3px; }
  .filter-options .count {
    color: #7d7d7d; }
    .filter-options .count:before {
      content: '('; }
    .filter-options .count:after {
      content: ')'; }

.filtered .items {
  margin: 15px 0; }

.filtered .item {
  margin-bottom: 6px;
  padding-left: 22px;
  position: relative; }
  .filtered .item .label {
    font-weight: 700; }
  .filtered .item .action.remove {
    left: -6px;
    position: absolute;
    top: 0; }

.filtered + .actions {
  margin-bottom: 35px; }

@media only screen and (max-width: 767px) {
  .page-with-filter .columns .sidebar-main {
    order: 0; } }

.map-popup-wrapper.popup .action.close {
  top: 10px; }

.map-popup {
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  left: 0;
  margin-top: 10px;
  position: absolute;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0;
  box-sizing: border-box; }
  .map-popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .map-popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .map-popup .popup-content {
    margin: 10px 0 0; }
  .map-popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .map-popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .map-popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .map-popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:active:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:focus, .map-popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close.disabled, .map-popup .popup-actions .action.close[disabled],
    fieldset[disabled] .map-popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .map-popup.active {
    opacity: 1; }
  .map-popup .map-info-price {
    margin-bottom: 15px; }
    .map-popup .map-info-price .price-box {
      margin: 0 0 20px; }
      .map-popup .map-info-price .price-box .label:after {
        content: ': '; }
  .map-popup .regular-price .price-label {
    display: none; }

.map-old-price:not(:last-child),
.product-info-price .map-show-info:not(:last-child) {
  margin-right: 10px; }

.map-old-price {
  text-decoration: none; }
  .map-old-price .price-wrapper {
    text-decoration: line-through; }

.map-form-addtocart {
  display: inline-block;
  text-align: center; }
  .map-form-addtocart img {
    display: block;
    margin: 0 auto; }

button.map-show-info, .cart.table-wrapper .actions-toolbar > .map-show-info.action, .map-show-info.action-gift {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1979c3;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    background: none;
    border: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    background: none;
    border: none; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  button.map-show-info:visited, .cart.table-wrapper .actions-toolbar > .map-show-info.action:visited, .map-show-info.action-gift:visited {
    color: #1979c3;
    text-decoration: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #006bb4;
    text-decoration: underline; }
  button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    color: #ff5501;
    text-decoration: underline; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #006bb4; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active, button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus {
    background: none;
    border: 0; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .map-popup {
    max-width: 100%; }
  .map-old-price,
  .map-show-info {
    display: inline-block; }
    .map-old-price:not(:last-child),
    .map-show-info:not(:last-child) {
      margin-bottom: 10px; } }

.wishlist.split.button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .wishlist.split.button:before, .wishlist.split.button:after {
    content: '';
    display: table; }
  .wishlist.split.button:after {
    clear: both; }
  .wishlist.split.button .action.split {
    float: left;
    margin: 0; }
  .wishlist.split.button .action.toggle {
    float: right;
    margin: 0; }
  .wishlist.split.button button.action.split, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action.split, .wishlist.split.button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .wishlist.split.button button + .action.toggle, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action + .action.toggle, .wishlist.split.button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .wishlist.split.button .action.toggle {
    display: inline-block;
    text-decoration: none; }
    .wishlist.split.button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.split.button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: #333;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .wishlist.split.button .action.toggle:hover:after {
      color: #006bb4; }
    .wishlist.split.button .action.toggle:active:after {
      color: inherit; }
    .wishlist.split.button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .wishlist.split.button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .wishlist.split.button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: #333;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .wishlist.split.button .action.toggle.active:hover:after {
        color: #006bb4; }
      .wishlist.split.button .action.toggle.active:active:after {
        color: inherit; }
  .wishlist.split.button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .wishlist.split.button .items li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .wishlist.split.button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .wishlist.split.button .items:before, .wishlist.split.button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .wishlist.split.button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .wishlist.split.button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .wishlist.split.button .items:before {
      left: 10px;
      top: -12px; }
    .wishlist.split.button .items:after {
      left: 9px;
      top: -14px; }
  .wishlist.split.button.active {
    overflow: visible; }
    .wishlist.split.button.active .items {
      display: block; }
  .wishlist.split.button .items {
    text-align: left; }
    .wishlist.split.button .items .item:last-child:hover {
      background: #e8e8e8; }
  .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before {
    content: ""; }
  .table-comparison .wishlist.split.button > .action:active, .table-comparison .wishlist.split.button > .action:focus, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:hover {
    color: #006bb4; }
  .table-comparison .wishlist.split.button > .action.toggle:before,
  .product-items .wishlist.split.button > .action.toggle:before {
    border-left: 1px solid gray;
    content: '';
    float: left;
    height: 15px;
    margin-top: 3px; }
  .product-info-main .wishlist.split.button .action.split, .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.split,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.split,
  .product-add-form .wishlist.split.button .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem; }
  .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.toggle {
    padding: 0; }
  .cart.table-wrapper .wishlist.split.button {
    margin-bottom: 10px; }
    .cart.table-wrapper .wishlist.split.button > .action.toggle {
      padding: 4px 5px; }

.wishlist.window.popup {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0; }
  .wishlist.window.popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .wishlist.window.popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .wishlist.window.popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .wishlist.window.popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.window.popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .wishlist.window.popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:active:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:focus, .wishlist.window.popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close.disabled, .wishlist.window.popup .popup-actions .action.close[disabled],
    fieldset[disabled] .wishlist.window.popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .wishlist.window.popup.active {
    opacity: 1; }
  .wishlist.window.popup.active {
    display: block; }

.wishlist.overlay {
  transition: opacity 0.15s linear;
  background: #000;
  z-index: 1000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0; }
  .wishlist.overlay.active {
    opacity: 0.5;
    filter: alpha(opacity=50); }
  .wishlist.overlay.active {
    display: block; }

.block-wishlist-management {
  position: relative; }
  .block-wishlist-management .wishlist-select .wishlist-name-label {
    font-weight: 600; }
  .block-wishlist-management .wishlist-title {
    margin-bottom: 10px; }
    .block-wishlist-management .wishlist-title strong {
      font-weight: 300;
      margin-right: 10px; }
  .block-wishlist-management .wishlist-notice {
    color: #7d7d7d;
    display: inline-block;
    margin-right: 20px; }
  .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block; }
  .block-wishlist-management .wishlist-toolbar-select {
    margin-right: 20px; }
  .block-wishlist-management .wishlist-dropdown.move {
    margin-right: 20px; }

.block-wishlist-search-form .form-wishlist-search {
  margin-bottom: 60px;
  max-width: 500px; }

.block-wishlist-search-results .toolbar-wishlist-results {
  text-align: center; }

.block-wishlist-info-items .block-title {
  margin-bottom: 20px;
  font-size: 2.2rem; }
  .block-wishlist-info-items .block-title > strong {
    font-weight: 300; }

.block-wishlist-info-items .product-item {
  width: 100%; }

.block-wishlist-info-items .product-item-photo {
  margin-left: 20px; }

.block-wishlist-info-items .product-item-info {
  width: auto; }
  .block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible; }

.block-wishlist-info-items .price-wrapper {
  display: block;
  margin: 0 0 10px; }

.block-wishlist-info-items .split {
  clear: both;
  padding: 10px 0; }

.block-wishlist-info-items .action.towishlist {
  padding: 10px 0; }

.block-wishlist-info-items .input-text.qty {
  margin-right: 10px; }

@media only screen and (max-width: 767px) {
  .block-wishlist-management {
    clear: both;
    margin-top: -21px; }
    .block-wishlist-management .wishlist-select {
      margin: 0 -15px 20px; }
      .block-wishlist-management .wishlist-select-items {
        border-bottom: 1px solid #d1d1d1;
        background: #f5f5f5;
        display: none;
        padding: 15px 0; }
        .block-wishlist-management .wishlist-select-items.active {
          display: block; }
        .block-wishlist-management .wishlist-select-items .item {
          margin: 3px 0 0; }
          .block-wishlist-management .wishlist-select-items .item:first-child {
            margin-top: 0; }
          .block-wishlist-management .wishlist-select-items .item.current {
            display: none; }
          .block-wishlist-management .wishlist-select-items .item a {
            display: block;
            padding: 5px 18px;
            text-decoration: none; }
            .block-wishlist-management .wishlist-select-items .item a:hover {
              background: #e8e8e8; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 2.6rem; }
    .block-wishlist-management .wishlist-info {
      margin-bottom: 15px; }
    .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
      margin-bottom: 15px; }
  .products-grid.wishlist .product-item-checkbox {
    left: 20px;
    position: absolute;
    top: 20px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
    margin-left: 25px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-name, .page-multiple-wishlist .products-grid.wishlist .product-item-description, .page-multiple-wishlist
  .products-grid.wishlist .product-item .price-box, .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
    margin-left: 115px; }
  .products-grid.wishlist .wishlist-dropdown {
    display: none; } }

@media only screen and (min-width: 768px) {
  .wishlist.window.popup {
    bottom: auto;
    top: 20%;
    left: 50%;
    margin-left: -212px;
    width: 380px;
    right: auto; }
    .wishlist.window.popup .field {
      margin: 0 0 20px; }
      .wishlist.window.popup .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .wishlist.window.popup .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .wishlist.window.popup .field > .label {
        margin: 0 0 5px;
        display: inline-block; }
      .wishlist.window.popup .field.choice:before, .wishlist.window.popup .field.no-label:before {
        display: none; }
  .block-wishlist-management {
    margin-bottom: 20px; }
    .block-wishlist-management .wishlist-select {
      border-bottom: 1px solid #e8e8e8;
      display: table;
      margin-bottom: 15px;
      width: 100%; }
      .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        margin-right: 10px;
        padding: 5px 10px 10px;
        vertical-align: top;
        white-space: nowrap;
        width: 5%; }
      .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top; }
        .block-wishlist-management .wishlist-select-items .item {
          display: inline-block;
          margin-right: 10px;
          padding: 5px 10px 10px; }
          .block-wishlist-management .wishlist-select-items .item:last-child {
            margin-right: 0; }
        .block-wishlist-management .wishlist-select-items .current {
          border-bottom: 3px solid #ff5501;
          font-weight: 600; }
      .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none; }
    .block-wishlist-management .wishlist-add.item {
      position: absolute;
      right: 0;
      top: 0; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 4rem; }
    .block-wishlist-management .wishlist-info {
      float: left; }
    .block-wishlist-management .wishlist-toolbar {
      float: right; }
  .block-wishlist-info-items .product-item-photo {
    margin-left: 0; }
  .products-grid.wishlist .product-item-checkbox {
    float: left; }
    .products-grid.wishlist .product-item-checkbox + .product-item-name {
      margin-left: 25px; } }

.multicheckout .title {
  margin-bottom: 30px; }
  .multicheckout .title strong {
    font-weight: 400; }

.multicheckout .table-wrapper {
  margin-bottom: 0; }
  .multicheckout .table-wrapper .action.delete {
    display: inline-block; }
  .multicheckout .table-wrapper .col .qty {
    display: inline-block; }
  .multicheckout .table-wrapper .col.item .action.edit {
    font-weight: 400;
    margin-left: 10px; }

.multicheckout:not(.address) .table-wrapper .product-item-name {
  margin: 0; }

.multicheckout > .actions-toolbar {
  margin-top: 40px; }

.multicheckout .actions-toolbar > .secondary {
  display: block; }
  .multicheckout .actions-toolbar > .secondary .action {
    margin-bottom: 25px; }
    .multicheckout .actions-toolbar > .secondary .action.back {
      display: block;
      margin-left: 0; }

.multicheckout .actions-toolbar > .primary {
  margin-right: 10px; }

.multicheckout .item-options {
  margin: 10px 0 0; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field:before {
  display: none; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field .control {
  display: inline-block; }

.multicheckout .block-title,
.multicheckout .block-content .title {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px; }
  .multicheckout .block-title strong,
  .multicheckout .block-content .title strong {
    font-weight: 400; }
    .multicheckout .block-title strong span,
    .multicheckout .block-content .title strong span {
      color: #a6a6a6; }

.multicheckout .block-content .title {
  border-bottom: none;
  padding-bottom: 0; }

.multicheckout.order-review .block-title > strong {
  font-size: 2.4rem; }

.multicheckout.order-review .block-shipping .block-content:not(:last-child) {
  margin-bottom: 40px; }

.multicheckout .box-title span {
  margin-right: 10px; }

.multicheckout .box-title > .action {
  margin: 0; }

.multicheckout .box-shipping-method .price {
  font-weight: 700; }

.multicheckout .box-billing-method .fieldset {
  margin: 0; }
  .multicheckout .box-billing-method .fieldset .legend.box-title {
    margin: 0 0 5px; }

.multicheckout .checkout-review .grand.totals {
  font-size: 2.1rem;
  margin-bottom: 40px; }
  .multicheckout .checkout-review .grand.totals .mark {
    font-weight: 400; }

[class^='multishipping-'] .logo {
  margin-left: 0; }

.multishipping-checkout-success .nav-sections {
  display: block; }

@media only screen and (max-width: 639px) {
  .multicheckout .data.table .address:before {
    margin-bottom: 5px; }
  .multicheckout .product-item-name,
  .multicheckout .price-including-tax,
  .multicheckout .price-excluding-tax {
    display: inline-block; }
  .multicheckout .block-content .box:not(:last-child) {
    margin-bottom: 40px; }
  .multicheckout .block-content .box:last-child {
    margin-bottom: 0; }
  .multicheckout.order-review .box-items .data.table thead {
    display: block; }
    .multicheckout.order-review .box-items .data.table thead tr {
      display: block; }
    .multicheckout.order-review .box-items .data.table thead .col.item {
      display: block;
      padding: 0; }
  .multicheckout .actions-toolbar .action {
    margin-bottom: 25px; }
  .multicheckout .actions-toolbar > .primary {
    margin-bottom: 25px;
    margin-right: 0; } }

@media only screen and (min-width: 640px) {
  .multicheckout .actions-toolbar .secondary {
    float: none;
    margin-top: 11px;
    text-align: right; }
    .multicheckout .actions-toolbar .secondary .action {
      margin-left: 10px; }
      .multicheckout .actions-toolbar .secondary .action.back {
        display: block;
        float: left; }
  .multicheckout .item-options {
    margin: 20px 0 0; }
  .multicheckout .block-content .box {
    margin-bottom: 0; }
  .multicheckout .block-shipping .box {
    float: left;
    width: 25%; }
  .multicheckout .block-shipping .box-shipping-method {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%; }
    .multicheckout .block-shipping .box-shipping-method .fieldset .field:before {
      display: none; }
  .multicheckout .block-billing .box-billing-address {
    float: left;
    width: 25%; }
  .multicheckout .block-billing .box-billing-method {
    float: left;
    padding-left: 25px;
    width: 50%; }
  .multicheckout.form.address .table-wrapper .applicable {
    margin: 7px 0 0; }
  .multicheckout.order-review .box-items {
    clear: left;
    float: none;
    padding-top: 40px;
    width: auto; }
  .multicheckout.order-review .col.item {
    width: 75%; }
  .multicheckout .methods-payment .item-content > .fieldset {
    width: auto; }
    .multicheckout .methods-payment .item-content > .fieldset .field.cvv {
      display: inline-block;
      width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice) > .label {
    float: none;
    margin-bottom: 8px;
    text-align: left;
    width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice):not(.cvv) .control {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .multishipping-checkout-success .nav-toggle {
    display: block; }
  .multishipping-checkout-success .logo {
    margin-left: 40px; } }

.block.newsletter {
  margin-bottom: 40px; }
  .block.newsletter .form.subscribe {
    display: table;
    width: 100%; }
  .block.newsletter .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top; }
  .block.newsletter .field {
    margin: 0; }
    .block.newsletter .field .control {
      display: inline-block;
      text-decoration: none;
      display: block; }
      .block.newsletter .field .control:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 35px;
        line-height: 33px;
        color: #c2c2c2;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block.newsletter .field .control:before {
        position: absolute; }
  .block.newsletter input {
    padding-left: 35px; }
  .block.newsletter .title {
    display: none; }
  .block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%; }
  .block.newsletter .action.subscribe {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px; }

@media only screen and (min-width: 768px) {
  .block.newsletter {
    width: 32%; }
    .block.newsletter .field {
      margin-right: 5px; }
      .block.newsletter .field .control {
        width: 100%; }
    .block.newsletter .action.subscribe {
      border-radius: 3px; } }

.paypal.after:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 5px 5px 10px;
  text-transform: uppercase; }

.paypal.before:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 10px 5px;
  text-align: center;
  text-transform: uppercase; }

.paypal.acceptance {
  display: block;
  margin: 0 0 20px; }
  .paypal.acceptance img {
    max-width: 100%; }

.box-tocart .paypal img,
.block-minicart .paypal img {
  display: block;
  margin: 0 auto; }

.paypal-review .paypa-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .items-qty .item {
  white-space: nowrap; }

.paypal-review .items-qty .title:after {
  content: ': '; }

.paypal-review .paypal-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .actions-toolbar {
  margin-top: 10px; }

.paypal-review .item-options dt {
  display: inline-block; }
  .paypal-review .item-options dt:after {
    content: ': '; }

.paypal-review .item-options dd {
  margin: 0; }

.paypal-review-discount {
  border-bottom: 1px solid #d1d1d1;
  margin: 20px 0; }
  .paypal-review-discount .block {
    margin-bottom: 0; }
    .paypal-review-discount .block .fieldset {
      padding: 10px 0 20px; }

@media only screen and (min-width: 768px) {
  .paypal-review .paypal-review-title {
    border-bottom: 1px solid #d1d1d1; }
  .paypal-review .block-content .box-order-shipping-address,
  .paypal-review .block-content .box-order-shipping-method,
  .paypal-review .block-content .box-order-shipping-method + .box-order-billing-address {
    box-sizing: border-box;
    float: left;
    width: 33%; }
  .paypal-review .block-content .box-order-shipping-address {
    padding: 0 5%;
    width: 34%; }
  .paypal-review .col.subtotal,
  .paypal-review .mark,
  .paypal-review .amount {
    text-align: right; } }

.fotorama-video-container:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom right;
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama-video-container .magnify-lens {
  display: none !important; }

.fotorama-video-container.video-unplayed:hover img {
  opacity: 0.6; }

.fotorama-video-container.video-unplayed:hover:after {
  transform: scale(1.25); }

.video-thumb-icon:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom left;
  bottom: 0;
  content: '';
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 49px; }

.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }
  .product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; }

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama__product-video--loading:after {
  visibility: hidden; }

@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
  .product-video {
    height: 100%;
    width: 81%; } }

.rating-summary {
  overflow: hidden;
  white-space: nowrap; }
  .rating-summary .rating-result {
    width: 100px;
    display: inline-block;
    position: relative;
    vertical-align: middle; }
    .rating-summary .rating-result:before {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      -webkit-font-smoothing: antialiased;
      color: #c7c7c7;
      font-family: "icons-blank-theme";
      font-size: 28px;
      height: 28px;
      letter-spacing: -10px;
      line-height: 28px;
      content: "";
      display: block;
      font-style: normal;
      font-weight: normal;
      speak: none; }
    .rating-summary .rating-result > span {
      display: block;
      overflow: hidden; }
      .rating-summary .rating-result > span:before {
        position: relative;
        z-index: 2;
        -webkit-font-smoothing: antialiased;
        color: #ff5501;
        font-family: "icons-blank-theme";
        font-size: 28px;
        height: 28px;
        letter-spacing: -10px;
        line-height: 28px;
        content: "";
        display: block;
        font-style: normal;
        font-weight: normal;
        speak: none; }
      .rating-summary .rating-result > span span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
  .rating-summary .rating-result {
    margin-left: -5px; }

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.review-control-vote {
  overflow: hidden; }
  .review-control-vote:before {
    color: #c7c7c7;
    font-family: "icons-blank-theme";
    font-size: 28px;
    height: 28px;
    letter-spacing: -10px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    content: "";
    display: block;
    position: absolute;
    z-index: 1; }
  .review-control-vote input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
    .review-control-vote input[type="radio"]:focus + label:before, .review-control-vote input[type="radio"]:checked + label:before {
      opacity: 1; }
  .review-control-vote label {
    cursor: pointer;
    display: block;
    position: absolute; }
    .review-control-vote label span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .review-control-vote label:before {
      color: #ff5501;
      font-family: "icons-blank-theme";
      font-size: 28px;
      height: 28px;
      letter-spacing: -10px;
      line-height: 28px;
      font-style: normal;
      font-weight: normal;
      speak: none;
      vertical-align: top;
      -webkit-font-smoothing: antialiased;
      opacity: 0; }
    .review-control-vote label:hover:before {
      opacity: 1; }
    .review-control-vote label:hover ~ label:before {
      opacity: 0; }
  .review-control-vote .rating-5 {
    z-index: 2; }
    .review-control-vote .rating-5:before {
      content: ""; }
  .review-control-vote .rating-4 {
    z-index: 3; }
    .review-control-vote .rating-4:before {
      content: ""; }
  .review-control-vote .rating-3 {
    z-index: 4; }
    .review-control-vote .rating-3:before {
      content: ""; }
  .review-control-vote .rating-2 {
    z-index: 5; }
    .review-control-vote .rating-2:before {
      content: ""; }
  .review-control-vote .rating-1 {
    z-index: 6; }
    .review-control-vote .rating-1:before {
      content: ""; }

.block.add.review {
  margin-bottom: 40px; }
  .block.add.review > .title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .block.add.review .legend {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin: 0 0 20px; }
  .block.add.review .field.rating {
    display: table-row; }
    .block.add.review .field.rating .label {
      display: table-cell;
      padding: 5px 0;
      vertical-align: middle; }
    .block.add.review .field.rating .control {
      display: table-cell;
      vertical-align: top; }

.block.reviews.list > .title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

.block.reviews.list .review.item {
  margin-bottom: 40px; }

.block.reviews.list .review.title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

.data.table.reviews .rating-summary {
  margin-top: -4px; }

.customer-review.view {
  margin-bottom: 20px; }
  .customer-review.view .review.title,
  .customer-review.view .ratings-summary,
  .customer-review.view .product-details {
    margin-bottom: 20px; }

.product-reviews-summary {
  display: table;
  margin: 10px 0 12px; }
  .products.wrapper.list .product-reviews-summary {
    margin: 0 auto; }
  .product-reviews-summary.empty {
    margin-left: 0; }
  .product-reviews-summary .rating-summary {
    display: table-cell;
    left: -5px;
    position: relative;
    text-align: left;
    vertical-align: middle; }
  .product-reviews-summary .reviews-actions {
    display: table-cell;
    line-height: 28px;
    vertical-align: middle; }
    .product-reviews-summary .reviews-actions a {
      margin-right: 5px; }

@media only screen and (min-width: 768px) {
  .products.wrapper.list .product-reviews-summary {
    margin: 0; } }

.customer-review .product-details {
  margin-bottom: 40px; }

.customer-review .product-media {
  float: left;
  margin-right: 3%;
  max-width: 285px;
  width: 30%; }

.customer-review .review-details .customer-review-rating {
  margin-bottom: 20px; }
  .customer-review .review-details .customer-review-rating .item {
    margin-bottom: 10px; }
    .customer-review .review-details .customer-review-rating .item:last-child {
      margin-bottom: 0; }

.customer-review .review-details .review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  margin-bottom: 20px; }

.customer-review .review-details .review-content {
  margin-bottom: 20px; }

.customer-review .product-reviews-summary .rating-summary,
.customer-review .product-reviews-summary .reviews-actions {
  display: block; }

.review-field-ratings .nested {
  display: table; }

.review-field-ratings .review-field-rating {
  display: table-row; }
  .review-field-ratings .review-field-rating .label,
  .review-field-ratings .review-field-rating .control {
    display: table-cell;
    padding-bottom: 10px;
    vertical-align: top; }
  .review-field-ratings .review-field-rating .label {
    padding-right: 10px;
    padding-top: 6px; }
  .review-field-ratings .review-field-rating .control {
    padding-top: 2px; }

.review-toolbar {
  border-top: 1px solid #c9c9c9;
  margin: 0 0 20px;
  text-align: center; }
  .review-toolbar:first-child {
    display: none; }
  .review-toolbar .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center; }
  .review-toolbar .limiter {
    display: none; }

.review-add .block-title {
  display: none; }

.review-form .action.submit.primary {
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #333; }
  .review-form .action.submit.primary:focus, .review-form .action.submit.primary:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #333; }
  .review-form .action.submit.primary:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }

.fieldset .review-legend.legend {
  margin-bottom: 20px; }
  .fieldset .review-legend.legend strong {
    display: block;
    margin-left: 0; }

.review-field-rating .control {
  margin-bottom: 48px;
  margin-top: 10px; }

.review-list {
  margin-bottom: 30px; }
  .review-list .block-title strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }

.review-item {
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
  padding: 20px 0; }
  .review-item:after {
    clear: both;
    content: '';
    display: table; }
  .review-item:last-child {
    border-width: 0; }

.review-ratings {
  display: table;
  margin-bottom: 10px;
  max-width: 100%; }

.review-author {
  display: inline; }

.review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin: 0 0 20px; }

.review-content {
  margin-bottom: 20px; }

@media only screen and (min-width: 640px) {
  .review-form {
    max-width: 500px; }
  .review-ratings {
    float: left;
    margin-bottom: 0;
    max-width: 240px; }
  .review-ratings ~ .review-content,
  .review-ratings ~ .review-details {
    margin-left: 280px; }
  .review-toolbar {
    margin: 0 0 30px; }
    .review-toolbar .pages {
      padding: 30px 0; }
  .fieldset .review-legend.legend {
    margin-bottom: 30px; }
  .review-item {
    padding: 30px 0; }
  .review-title {
    margin: 0 0 30px; } }

.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
  margin-bottom: 25px; }

.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
  display: inline-block;
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .reward-settings + .actions-toolbar {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .reward-settings + .actions-toolbar {
    margin-top: -32px; } }

.form-create-return .field .control > span,
.form-create-return .field address {
  display: inline-block;
  padding-top: 6px; }

.form-create-return .additional:first-child {
  margin-top: 0; }

.form-create-return .additional .field:nth-last-child(2) {
  margin-bottom: 0; }

.form-create-return .additional .field:last-child {
  margin-top: 20px; }

.form-create-return .field.comment {
  margin-top: 55px; }

.block-returns-comments .returns-comments dt,
.block-returns-comments .returns-comments dd {
  margin: 0; }

.block-returns-comments .returns-comments dt {
  font-weight: 400; }

.block-returns-comments .returns-comments dd {
  margin-bottom: 15px; }
  .block-returns-comments .returns-comments dd:last-child {
    margin-bottom: 0; }

.block-returns-comments .fieldset {
  margin-bottom: 20px; }

.magento-rma-returns-returns .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-title-wrapper .page-title,
.magento-rma-returns-view .page-title-wrapper .page-title {
  margin-right: 25px; }

@media only screen and (max-width: 767px) {
  .account .column.main .returns-details-items .block-returns-tracking .block-title > .action.track {
    display: block;
    float: none;
    margin: 10px 0 0; } }

@media only screen and (min-width: 768px) {
  .form-create-return .additional .field:last-child {
    margin-top: 29px; }
  .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; }
  .block-returns-tracking .block-title .action {
    margin: 0 0 0 30px; }
  .block-returns-tracking .block-title .actions-track {
    float: right;
    margin-top: 12px; } }

.order-links {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 10px; }
  .order-links .item {
    display: inline-block;
    margin-right: 20px; }

.order-actions-toolbar .action {
  margin: 0 20px 0 0; }

.order-details-items {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  .order-details-items .order-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: inline-block; }
  .order-details-items .items-qty .item {
    white-space: nowrap; }
  .order-details-items .items-qty .title:after {
    content: ': '; }
  .order-details-items .table-order-items .product-item-name {
    margin-top: 0; }
  .order-details-items .table-order-items tbody + tbody {
    border-top: 1px solid #d1d1d1; }
  .order-details-items .item-options dt {
    margin: 0; }
  .order-details-items .item-options dd {
    margin: 0 0 15px; }
  .order-details-items .item-options.links dt {
    display: inline-block; }
    .order-details-items .item-options.links dt:after {
      content: ': '; }
  .order-details-items .item-options.links dd {
    margin: 0; }

.order-pager-wrapper .toolbar-amount {
  padding-top: 3px; }

.order-pager-wrapper .pages-items .item:first-child, .order-pager-wrapper .pages-items .item:last-child {
  margin-right: 0; }

.order-actions-toolbar {
  margin-bottom: 25px; }
  .order-actions-toolbar .action {
    margin-right: 30px; }
    .order-actions-toolbar .action.print {
      margin: 0; }

.page-title-wrapper .order-date {
  margin: 0 0 20px; }

.account .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-title-wrapper .page-title,
.sales-guest-view .page-title-wrapper .page-title {
  margin-right: 25px; }

.form-orders-search .field.email, .form-orders-search .field.zip {
  margin-bottom: 0; }

.block-reorder .product-item .checkbox {
  position: relative;
  top: 2px;
  vertical-align: baseline; }

.block-reorder .product-item .field.item {
  display: inline-block; }

.block-reorder .product-item-name {
  display: inline-block; }

.block-reorder .actions-toolbar {
  margin: 17px 0;
  max-width: 178px; }

.block-order-details-comments {
  margin: 0 0 40px; }
  .block-order-details-comments .comment-date {
    font-weight: 600; }
  .block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px; }

.block-order-details-view .box-content .payment-method .title {
  font-weight: 400; }

.block-order-details-view .box-content .payment-method .content {
  margin: 0; }
  .block-order-details-view .box-content .payment-method .content > strong {
    font-weight: 400; }
    .block-order-details-view .box-content .payment-method .content > strong:after {
      content: ': '; }

.order-tracking {
  border-bottom: 1px solid #d1d1d1;
  margin: 0;
  padding: 20px 0; }
  .order-tracking .tracking-title {
    display: inline-block; }
  .order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px; }

@media only screen and (max-width: 639px) {
  .account .order-details-items .table-order-items .product-item-name,
  .account .order-details-items .table-order-items .price-including-tax,
  .account .order-details-items .table-order-items .price-excluding-tax,
  .account .order-details-items .table-order-items .items-qty {
    display: inline-block;
    vertical-align: top; }
  .account .toolbar .pages {
    float: right; }
  .account .toolbar .limiter {
    clear: both; }
  .order-details-items thead {
    display: block; }
    .order-details-items thead tr,
    .order-details-items thead td {
      display: block; }
  .table-wrapper .table.table-order-items tfoot td.order-pager-wrapper {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .order-pager-wrapper .toolbar-amount {
    left: inherit;
    position: relative;
    text-align: center;
    top: inherit; }
  .order-pager-wrapper .pages {
    text-align: center; }
  .order-pager-wrapper .action.previous,
  .order-pager-wrapper .action.next {
    margin: 0; } }

@media only screen and (min-width: 768px) {
  .table-order-items .subtotal,
  .table-order-items .amount {
    text-align: right; }
  .table-order-items.creditmemo .col.qty, .table-order-items.creditmemo .col.discount, .table-order-items.creditmemo .col.subtotal {
    text-align: center; }
  .table-order-items.creditmemo .col.total {
    text-align: right; }
  .order-pager-wrapper .order-pager-wrapper-top {
    padding-left: 0;
    padding-right: 0; }
  .order-pager-wrapper .pages {
    float: right; }
  .order-actions-toolbar .action.print {
    display: block;
    float: right; }
  .page-title-wrapper .order-date {
    margin-top: -20px; }
  [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; } }

.opc-wrapper .form-discount {
  max-width: 500px; }

.action.mailto.friend {
  margin-left: -7px;
  display: inline-block;
  text-decoration: none; }
  .action.mailto.friend:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .action.mailto.friend span {
    display: inline-block;
    line-height: 28px;
    vertical-align: top; }

.swatch-attribute-label {
  font-weight: bold;
  position: relative; }

.swatch-attribute-label.required {
  padding-right: 10px; }

.swatch-attribute-label[data-required="1"]:after {
  content: '*';
  color: red;
  position: absolute;
  right: -11px;
  top: -2px;
  font-weight: bold;
  font-size: 1em; }

.swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px; }

.swatch-attribute-options {
  margin-top: 10px; }

.swatch-option {
  padding: 1px 2px;
  min-width: 30px;
  max-width: 90px;
  height: 20px;
  float: left;
  margin: 0 10px 5px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 1px solid #dadada;
  overflow: hidden;
  text-overflow: ellipsis; }

.swatch-option.text {
  background: #f0f0f0;
  color: #686868;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 4px 8px;
  min-width: 22px;
  margin-right: 7px; }

.swatch-option.selected {
  outline: 2px solid #ff5501;
  border: 1px solid #fff;
  color: #333; }

.swatch-option.text.selected {
  background-color: #fff !important; }

.clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
  background: #f0f0f0;
  color: #949494; }

.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
  color: black;
  background: #fff;
  border: 1px solid #fff; }

.swatch-option:not(.disabled):hover {
  outline: 1px solid #999;
  border: 1px solid #fff;
  color: #333; }

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
  outline: 2px solid #ee0000;
  border: 1px solid #fff; }

.swatch-option.disabled {
  cursor: default; }

.swatch-option.disabled:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, white 43%, white 46%, #ff5216 47%, #ff5216 53%, white 54%, white 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%); }

.swatch-option-tooltip {
  max-width: 140px;
  max-height: 100%;
  min-height: 20px;
  min-width: 20px;
  position: absolute;
  padding: 5px;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  text-align: center; }

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
  left: 40%;
  position: absolute;
  bottom: 0;
  height: 8px; }

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
  content: '';
  position: relative;
  top: 1px;
  left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: #fff transparent transparent transparent;
  font-size: 1px; }

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
  content: '';
  position: relative;
  top: 2px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8.5px 0 8.5px;
  border-color: #adadad transparent transparent transparent;
  font-size: 1px; }

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
  display: block;
  height: 130px;
  width: 130px;
  margin: 0 auto; }

.swatch-option-tooltip .title {
  max-width: 140px;
  min-height: 20px;
  max-height: 200px;
  color: #282828;
  text-align: center;
  display: block;
  overflow: hidden; }

.swatch-opt {
  margin: 20px 0; }

.swatch-more {
  display: inline-block;
  margin: 2px 0;
  text-decoration: none !important;
  position: static;
  z-index: 1; }

.swatch-option-link-layered {
  position: relative;
  margin: 0 !important;
  padding: 0 !important; }

.swatch-option-link-layered:focus > div {
  box-shadow: 0 0 3px 1px #68a8e0; }

.swatch-option-tooltip-layered {
  width: 140px;
  position: absolute;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  left: -47px; }

.swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px; }

.swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px; }

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block; }

.swatch-attribute-options .swatch-option-tooltip-layered .title {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #282828;
  text-align: center;
  margin-bottom: 10px; }

.swatch-option-disabled:after {
  content: '';
  position: absolute;
  width: 42px;
  height: 2px;
  background: red;
  transform: rotate(-30deg);
  z-index: 995;
  left: -4px;
  top: 10px; }

.swatch-option-disabled {
  outline: none !important;
  cursor: default;
  border: 0; }

.swatch-opt-listing {
  margin-bottom: 10px; }

.swatch-option-loading {
  content: url(../Magento_Swatches/images/loader-2.gif); }

.swatch-input {
  left: -1000px;
  position: absolute;
  visibility: hidden; }

body {
  background-color: #fff; }

.page-header {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px; }

.header.content {
  padding-top: 10px;
  position: relative; }

.logo {
  float: left;
  margin: 0 0 10px 40px;
  max-width: 50%;
  position: relative;
  z-index: 5; }
  .logo img {
    display: block; }
  .page-print .logo {
    float: none; }

.page-main > .page-title-wrapper .page-title + .action {
  margin-top: 30px; }

.action.skip:focus {
  background: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 15; }

.action-skip-wrapper {
  height: 0;
  position: relative; }

.message.global p {
  margin: 0; }

.message.global.noscript, .message.global.cookie {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  margin: 0; }
  .message.global.noscript a, .message.global.cookie a {
    color: #1979c3; }
    .message.global.noscript a:hover, .message.global.cookie a:hover {
      color: #006bb4; }
    .message.global.noscript a:active, .message.global.cookie a:active {
      color: #006bb4; }

.message.global.cookie {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 3; }
  .message.global.cookie .actions {
    margin-top: 10px; }

.message.global.demo {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  margin-bottom: 0;
  text-align: center; }
  .message.global.demo a {
    color: #1979c3; }
    .message.global.demo a:hover {
      color: #006bb4; }
    .message.global.demo a:active {
      color: #006bb4; }

.page-footer {
  margin-top: auto; }

.footer.content {
  border-top: 1px solid #d1d1d1;
  margin-top: 25px;
  padding-bottom: 25px;
  padding-top: 25px; }
  .footer.content .links > li {
    margin: 0 0 8px; }
  .footer.content .switcher-store {
    margin: 0 0 30px; }

.footer .copyright,
.footer .bugs {
  display: block;
  margin: 20px 0 0; }

.page-header .switcher,
.page-footer .switcher {
  margin-right: 10px; }
  .page-header .switcher .options,
  .page-footer .switcher .options {
    display: inline-block;
    position: relative; }
    .page-header .switcher .options:before, .page-header .switcher .options:after,
    .page-footer .switcher .options:before,
    .page-footer .switcher .options:after {
      content: '';
      display: table; }
    .page-header .switcher .options:after,
    .page-footer .switcher .options:after {
      clear: both; }
    .page-header .switcher .options .action.toggle,
    .page-footer .switcher .options .action.toggle {
      padding: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none; }
      .page-header .switcher .options .action.toggle > span,
      .page-footer .switcher .options .action.toggle > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .page-header .switcher .options .action.toggle:after,
      .page-footer .switcher .options .action.toggle:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .page-header .switcher .options .action.toggle:hover:after,
      .page-footer .switcher .options .action.toggle:hover:after {
        color: inherit; }
      .page-header .switcher .options .action.toggle:active:after,
      .page-footer .switcher .options .action.toggle:active:after {
        color: inherit; }
      .page-header .switcher .options .action.toggle.active,
      .page-footer .switcher .options .action.toggle.active {
        display: inline-block;
        text-decoration: none; }
        .page-header .switcher .options .action.toggle.active > span,
        .page-footer .switcher .options .action.toggle.active > span {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px; }
        .page-header .switcher .options .action.toggle.active:after,
        .page-footer .switcher .options .action.toggle.active:after {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 22px;
          line-height: 22px;
          color: inherit;
          content: "";
          font-family: "icons-blank-theme";
          margin: 0;
          vertical-align: top;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center; }
        .page-header .switcher .options .action.toggle.active:hover:after,
        .page-footer .switcher .options .action.toggle.active:hover:after {
          color: inherit; }
        .page-header .switcher .options .action.toggle.active:active:after,
        .page-footer .switcher .options .action.toggle.active:active:after {
          color: inherit; }
    .page-header .switcher .options ul.dropdown,
    .page-footer .switcher .options ul.dropdown {
      margin: 0;
      padding: 0;
      list-style: none none;
      background: #fff;
      border: 1px solid #bbb;
      margin-top: 4px;
      min-width: 160px;
      z-index: 100;
      box-sizing: border-box;
      display: none;
      position: absolute;
      top: 100%;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
      .page-header .switcher .options ul.dropdown li,
      .page-footer .switcher .options ul.dropdown li {
        margin: 0;
        padding: 0; }
        .page-header .switcher .options ul.dropdown li:hover,
        .page-footer .switcher .options ul.dropdown li:hover {
          background: #e8e8e8;
          cursor: pointer; }
      .page-header .switcher .options ul.dropdown:before, .page-header .switcher .options ul.dropdown:after,
      .page-footer .switcher .options ul.dropdown:before,
      .page-footer .switcher .options ul.dropdown:after {
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0; }
      .page-header .switcher .options ul.dropdown:before,
      .page-footer .switcher .options ul.dropdown:before {
        border: 6px solid;
        border-color: transparent transparent #fff transparent;
        z-index: 99; }
      .page-header .switcher .options ul.dropdown:after,
      .page-footer .switcher .options ul.dropdown:after {
        border: 7px solid;
        border-color: transparent transparent #bbb transparent;
        z-index: 98; }
      .page-header .switcher .options ul.dropdown:before,
      .page-footer .switcher .options ul.dropdown:before {
        left: 10px;
        top: -12px; }
      .page-header .switcher .options ul.dropdown:after,
      .page-footer .switcher .options ul.dropdown:after {
        left: 9px;
        top: -14px; }
    .page-header .switcher .options.active,
    .page-footer .switcher .options.active {
      overflow: visible; }
      .page-header .switcher .options.active ul.dropdown,
      .page-footer .switcher .options.active ul.dropdown {
        display: block; }
    .page-header .switcher .options ul.dropdown a,
    .page-footer .switcher .options ul.dropdown a {
      display: block;
      padding: 8px; }
      .page-header .switcher .options ul.dropdown a:hover,
      .page-footer .switcher .options ul.dropdown a:hover {
        text-decoration: none; }
  .page-header .switcher li,
  .page-footer .switcher li {
    font-size: 12px;
    margin: 0; }
  .page-header .switcher strong,
  .page-footer .switcher strong {
    font-weight: 400; }

.widget {
  clear: both; }

.page-header .widget.block,
.page-footer .widget.block {
  margin: 20px 0; }

.ui-datepicker td {
  padding: 0; }

@media only screen and (min-width: 768px) {
  html,
  body {
    height: 100%; }
  .page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
    .page-wrapper > .breadcrumbs,
    .page-wrapper > .top-container,
    .page-wrapper > .widget {
      box-sizing: border-box;
      width: 100%; }
    .ie10 .page-wrapper,
    .ie11 .page-wrapper {
      height: 100%; }
  .navigation ul {
    padding: 0 8px; }
  .header.panel > .header.links {
    float: right;
    font-size: 0;
    margin: 0;
    padding: 0;
    list-style: none none;
    margin-left: auto; }
    .header.panel > .header.links > li {
      display: inline-block;
      vertical-align: top; }
    .header.panel > .header.links > li {
      font-size: 14px;
      margin: 0 0 0 15px; }
      .header.panel > .header.links > li.welcome,
      .header.panel > .header.links > li a {
        line-height: 1.4; }
      .header.panel > .header.links > li.welcome a {
        padding-left: 5px; }
  .header.content {
    padding: 30px 20px 0; }
  .page-header {
    border: 0;
    margin-bottom: 0; }
    .page-header .panel.wrapper {
      border-bottom: 1px solid #e8e8e8; }
    .page-header .header.panel {
      padding-bottom: 10px;
      padding-top: 10px; }
    .page-header .switcher {
      display: inline-block; }
  .page-main > .page-title-wrapper .page-title {
    display: inline-block; }
  .page-main > .page-title-wrapper .page-title + .action {
    float: right;
    margin-top: 20px; }
  .logo {
    margin: -8px auto 25px 0; }
    .logo img {
      max-height: inherit; }
  .footer.content .block {
    float: right; }
  .footer.content .links {
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 50px 0 0;
    vertical-align: top; }
  .footer.content .switcher.store {
    display: inline-block;
    padding-right: 50px;
    vertical-align: top; } }

.my-credit-cards .status {
  font-style: italic; }

.my-credit-cards .action.delete {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1979c3;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .my-credit-cards .action.delete:focus, .my-credit-cards .action.delete:active {
    background: none;
    border: none; }
  .my-credit-cards .action.delete:hover {
    background: none;
    border: none; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards .action.delete:visited {
    color: #1979c3;
    text-decoration: none; }
  .my-credit-cards .action.delete:hover {
    color: #006bb4;
    text-decoration: underline; }
  .my-credit-cards .action.delete:active {
    color: #ff5501;
    text-decoration: underline; }
  .my-credit-cards .action.delete:hover {
    color: #006bb4; }
  .my-credit-cards .action.delete:hover, .my-credit-cards .action.delete:active, .my-credit-cards .action.delete:focus {
    background: none;
    border: 0; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.my-credit-cards-popup .modal-footer {
  text-align: right; }

.my-credit-cards-popup .action.secondary {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1979c3;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  margin-right: 20px; }
  .my-credit-cards-popup .action.secondary:focus, .my-credit-cards-popup .action.secondary:active {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary:hover {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards-popup .action.secondary:visited {
    color: #1979c3;
    text-decoration: none; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #006bb4;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:active {
    color: #ff5501;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #006bb4; }
  .my-credit-cards-popup .action.secondary:hover, .my-credit-cards-popup .action.secondary:active, .my-credit-cards-popup .action.secondary:focus {
    background: none;
    border: 0; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
    display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
      display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
      display: block; }
  .my-credit-cards .card-type img {
    vertical-align: middle; } }

@media only screen and (min-width: 768px) {
  .my-credit-cards .card-type img {
    display: block; } }

.minilist .weee {
  display: table-row;
  font-size: 1.1rem; }
  .minilist .weee:before {
    display: none; }

.price-container.weee:before {
  display: none; }

.product-items .action.towishlist:before,
.products.list.items .action.towishlist:before,
.table-comparison .action.towishlist:before {
  content: ""; }

.account .table-wrapper .data.table.wishlist > thead > tr > th,
.account .table-wrapper .data.table.wishlist > thead > tr > td,
.account .table-wrapper .data.table.wishlist > tbody > tr > th,
.account .table-wrapper .data.table.wishlist > tbody > tr > td,
.account .table-wrapper .data.table.wishlist > tfoot > tr > th,
.account .table-wrapper .data.table.wishlist > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > td {
  border-top: 0; }

.account .table-wrapper .data.table.wishlist > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist thead > tr > th {
  border-bottom: 0; }

.account .table-wrapper .data.table.wishlist tbody > tr:last-child > td {
  border-bottom: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist .product.name {
  display: inline-block;
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .box-tocart {
  margin: 10px 0; }
  .account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle; }

.account .table-wrapper .data.table.wishlist .col.item {
  width: 50%; }

.account .table-wrapper .data.table.wishlist .col.photo {
  max-width: 150px; }

.account .table-wrapper .data.table.wishlist .col.selector {
  max-width: 15px; }

.account .table-wrapper .data.table.wishlist textarea {
  margin: 10px 0; }

.account .table-wrapper .data.table.wishlist .input-text.qty {
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .action.primary {
  vertical-align: top; }

.account .table-wrapper .data.table.wishlist .price {
  font-weight: 700; }

.block-wishlist .product-item-name {
  margin-right: 25px; }

.products-grid.wishlist .product-item-photo {
  display: block;
  margin-bottom: 10px; }

.products-grid.wishlist .product-item-name {
  margin-top: 0; }

.products-grid.wishlist .product-item .price-box {
  margin: 0; }

.products-grid.wishlist .product-item-comment {
  display: block;
  height: 42px;
  margin: 10px 0; }

.products-grid.wishlist .product-item-actions > * {
  margin-right: 15px; }
  .products-grid.wishlist .product-item-actions > *:last-child {
    margin-right: 0; }

.products-grid.wishlist .product-item .box-tocart input.qty {
  height: 32px; }

@media only screen and (max-width: 639px) {
  .products-grid.wishlist .product-item {
    border-bottom: 1px solid #e8e8e8; }
    .products-grid.wishlist .product-item:first-child {
      border-top: 1px solid #e8e8e8; } }

@media only screen and (max-width: 767px) {
  .products-grid.wishlist {
    margin-bottom: 30px;
    margin-right: -10px; }
    .products-grid.wishlist .product-item {
      padding: 20px 10px 20px 20px;
      position: relative; }
      .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: 20px; }
      .products-grid.wishlist .product-item-name {
        font-size: 1.6rem; }
      .products-grid.wishlist .product-item-actions {
        display: block; }
        .products-grid.wishlist .product-item-actions .action {
          margin-right: 15px; }
          .products-grid.wishlist .product-item-actions .action:last-child {
            margin-right: 0; }
          .products-grid.wishlist .product-item-actions .action.edit {
            float: left; }
          .products-grid.wishlist .product-item-actions .action.delete {
            float: right; }
          .products-grid.wishlist .product-item-actions .action.edit, .products-grid.wishlist .product-item-actions .action.delete {
            margin-top: 7px; }
      .products-grid.wishlist .product-item-name, .products-grid.wishlist .product-item-description,
      .products-grid.wishlist .product-item .price-box, .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px; }
      .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px; }
        .products-grid.wishlist .product-item .box-tocart .stock {
          margin-top: 7px; }
      .products-grid.wishlist .product-item .giftregisty-dropdown,
      .products-grid.wishlist .product-item .field.qty {
        display: none; }
    .products-grid.wishlist .product-image-container {
      max-width: 80px; }
  .wishlist-index-index .product-item {
    width: 100%; }
    .wishlist-index-index .product-item-info {
      width: auto; } }

@media only screen and (min-width: 640px) {
  .wishlist-index-index .products-grid .product-item {
    margin-bottom: 20px; }
  .wishlist-index-index .products-grid .product-item-actions {
    margin: 0; } }

@media only screen and (min-width: 768px) {
  .products-grid.wishlist .product-item-tooltip {
    display: inline-block; }
  .products-grid.wishlist .product-item-actions {
    margin: 10px 0 0; }
  .products-grid.wishlist .product-item .fieldset .field.qty {
    margin-bottom: 10px;
    padding-right: 10px; }
    .products-grid.wishlist .product-item .fieldset .field.qty .label {
      width: auto; }
  .products-grid.wishlist .product-item .box-tocart .actions-primary {
    margin: 0; }
  .products-grid.wishlist .product-item .box-tocart .stock {
    margin: 20px 0 0; }
  .wishlist-index-index .product-item-info {
    width: 240px; } }

@media only screen and (min-width: 1024px) {
  .wishlist-index-index .products-grid .product-items {
    margin: 0; }
  .wishlist-index-index .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%; }
    .wishlist-index-index .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; } }

.sidebar .block-addbysku .fieldset {
  margin: 0; }
  .sidebar .block-addbysku .fieldset .fields {
    position: relative; }
    .sidebar .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
      .sidebar .block-addbysku .fieldset .fields .field.sku {
        margin-right: -85px;
        padding-right: 90px;
        width: 100%; }
      .sidebar .block-addbysku .fieldset .fields .field.qty .qty + .mage-error {
        width: 80px; }
    .sidebar .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 6px; }

.sidebar .block-addbysku .form-addbysku .actions-toolbar {
  margin-bottom: 20px; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left; }
    .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
      width: auto; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left; }

.sidebar .block-addbysku .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .sidebar .block-addbysku .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.add:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.add:active:before {
    color: inherit; }

.sidebar .block-addbysku .action.links {
  display: inline-block;
  margin: 20px 0 0; }

.sidebar .block-addbysku .action.reset {
  display: block;
  margin: 10px 0; }

.sidebar .block-addbysku .action.remove {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .sidebar .block-addbysku .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.remove:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:active:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:focus, .sidebar .block-addbysku .action.remove:active {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove:hover {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove.disabled, .sidebar .block-addbysku .action.remove[disabled],
  fieldset[disabled] .sidebar .block-addbysku .action.remove {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.block-banners .banner-item,
.block-banners-inline .banner-item {
  display: block; }

.block-banners .banner-item-content,
.block-banners-inline .banner-item-content {
  margin-bottom: 20px; }
  .block-banners .banner-item-content img,
  .block-banners-inline .banner-item-content img {
    display: block;
    margin: 0 auto; }

.block-product-link.widget,
.block-category-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-product-link-inline.widget {
  margin: 0; }

.block.widget .product-item-info {
  width: auto; }

.block.widget .pager {
  padding: 0; }
  .block.widget .pager .toolbar-amount {
    float: none;
    font-size: 1.2rem; }
  .block.widget .pager .pages-item-previous {
    padding-left: 0; }
  .block.widget .pager .pages-item-next {
    position: relative; }
  .block.widget .pager .items {
    white-space: nowrap; }

@media only screen and (min-width: 640px) {
  .block.widget .products-grid .product-item,
  .page-layout-1column .block.widget .products-grid .product-item,
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 33.33333%; } }

@media only screen and (min-width: 768px) {
  .block.widget .products-grid .product-item {
    width: 33.33333%; }
    .sidebar .block.widget .products-grid .product-item {
      margin-left: 0;
      width: 100%; }
      .sidebar .block.widget .products-grid .product-item .actions-secondary {
        display: block;
        padding: 10px 0; }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 25%; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1024px) {
  .block.widget .products-grid .product-item {
    width: 20%; }
  .page-layout-1column .block.widget .products-grid .product-item {
    margin-left: calc((100% - 5 * (100% / 6)) / 4);
    width: 16.66667%; }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n + 1) {
      margin-left: calc((100% - 5 * (100% / 6)) / 4); }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(5n + 1) {
      margin-left: 0; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 25%; }
  .block.widget .products-grid .product-items {
    margin: 0; }
  .block.widget .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%; }
    .block.widget .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; } }

@media only screen and (min-width: 768px) {
  .sidebar .block.widget .pager .pages-item-next {
    padding: 0; }
    .sidebar .block.widget .pager .pages-item-next .action {
      margin: 0; } }

.columns .block-event {
  position: relative;
  z-index: 2; }

.block-event .block-content {
  position: relative; }
  .block-event .block-content > .action {
    cursor: pointer;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    z-index: 3; }
    .block-event .block-content > .action.backward, .block-event .block-content > .action.forward {
      opacity: 0.5;
      overflow: hidden;
      width: 20px;
      display: inline-block;
      text-decoration: none; }
      .block-event .block-content > .action.backward > span, .block-event .block-content > .action.forward > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 55px;
        line-height: 30px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        margin-left: -15px; }
    .block-event .block-content > .action.backward {
      left: 0; }
    .block-event .block-content > .action.forward {
      right: 0; }
      .block-event .block-content > .action.forward:before {
        content: ""; }
      .block-event .block-content > .action.forward:before {
        margin-left: -20px; }
    .block-event .block-content > .action.inactive {
      cursor: default;
      display: none; }
    .block-event .block-content > .action:hover {
      opacity: 1; }

.block-event .slider-panel {
  overflow: hidden;
  position: relative;
  z-index: 2; }
  .block-event .slider-panel .slider {
    white-space: nowrap; }
    .block-event .slider-panel .slider .item {
      background: #f0f0f0;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      white-space: normal;
      width: 16.35%; }
      .sidebar-main .block-event .slider-panel .slider .item,
      .sidebar-additional .block-event .slider-panel .slider .item {
        width: 100%; }
      .block-event .slider-panel .slider .item img {
        max-width: 100%; }
      .block-event .slider-panel .slider .item .category-name {
        display: inline-block;
        font-size: 1.8rem;
        padding: 15px 5px 0;
        word-break: break-all; }
      .block-event .slider-panel .slider .item .ticker {
        margin: 0;
        padding: 0;
        list-style: none none; }
        .block-event .slider-panel .slider .item .ticker li {
          display: none;
          margin: 0 5px; }
        .block-event .slider-panel .slider .item .ticker .value {
          font-size: 2.6rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .ticker .label {
          display: block;
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .dates {
        font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .start,
        .block-event .slider-panel .slider .item .dates .end,
        .block-event .slider-panel .slider .item .dates .date {
          display: block; }
        .block-event .slider-panel .slider .item .dates .start:after {
          font-size: 1.8rem;
          content: '\2013';
          display: block; }
        .block-event .slider-panel .slider .item .dates .date {
          font-size: 1.8rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .time {
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .box-event .box-content {
        padding: 5px 0 10px; }
      .block-event .slider-panel .slider .item .box-event .box-title {
        display: block;
        font-weight: 300;
        margin: 10px 0 0; }
      .block-event .slider-panel .slider .item .action.show {
        display: block;
        padding: 0; }
        .block-event .slider-panel .slider .item .action.show .category.name {
          display: inline-block;
          padding: 30px 10px 0; }
        .block-event .slider-panel .slider .item .action.show img {
          display: block; }
      .block-event .slider-panel .slider .item .action.event {
        background-image: none;
        background: #1979c3;
        border: 1px solid #1979c3;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 700;
        padding: 7px 15px;
        font-size: 1.4rem;
        box-sizing: border-box;
        vertical-align: middle;
        margin-top: 15px; }
        .block-event .slider-panel .slider .item .action.event:focus, .block-event .slider-panel .slider .item .action.event:active {
          background: #006bb4;
          border: 1px solid #006bb4;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event:hover {
          background: #006bb4;
          border: 1px solid #006bb4;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event.disabled, .block-event .slider-panel .slider .item .action.event[disabled],
        fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
          opacity: 0.5;
          cursor: default;
          pointer-events: none; }
        .block-event .slider-panel .slider .item .action.event:hover {
          text-decoration: none; }

.block-static-block.widget,
.block-cms-link.widget {
  margin-bottom: 20px; }
  .links .block-static-block.widget, .links
  .block-cms-link.widget {
    margin-bottom: 0; }

.block-cms-link-inline.widget {
  margin: 0; }

.block-wishlist-search .form-wishlist-search {
  margin: 20px 0 0; }

.block-cms-hierarchy-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-cms-hierarchy-link-inline.widget {
  margin: 0; }

@font-face {
  font-family: 'Droid Serif';
  src: url(../fonts/DroidSerif-Regular.ttf);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Droid Serif';
  src: url(../fonts/DroidSerif-Bold.ttf);
  font-weight: 800; }

@font-face {
  font-family: 'Droid Serif';
  src: url(../fonts/DroidSerif-Italic.ttf);
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Droid Serif';
  src: url(../fonts/DroidSerif-BoldItalic.ttf);
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Regular.ttf);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Medium.ttf);
  font-weight: 500; }

@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Bold.ttf);
  font-weight: 700; }

@media screen and (min-width: 1024px) {
  .page-products .column.main {
    width: 74.2% !important; } }

@media screen and (max-width: 1023px) {
  .page-products .column.main {
    width: 70% !important; } }

.page-products .page-title-wrapper {
  display: none !important; }

.page-products .page-wrapper {
  background-color: white; }

.page-products .columns {
  margin-top: 50px; }
  @media screen and (max-width: 768px) {
    .page-products .columns {
      display: flex; } }

.abs-actions-addto-gridlist:hover:before, .product-item-actions .actions-secondary > .action:hover:before, .table-comparison .wishlist.split.button > .action.split:hover:before,
.product-items .wishlist.split.button > .action.split:hover:before, .product-items .action.towishlist:hover:before,
.products.list.items .action.towishlist:hover:before,
.table-comparison .action.towishlist:hover:before {
  color: #d7aa02 !important; }

.category-image {
  margin-bottom: 50px;
  height: 350px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (max-width: 768px) {
    .category-image {
      height: 260px; } }

.product.attribute.delivery_time .description-toggle {
  display: none !important; }

.image-text-container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  color: #fff;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }
  .image-text-container .page-title-wrapper {
    text-align: center;
    width: 70%;
    margin: 0 auto; }
    .image-text-container .page-title-wrapper h1 {
      font-size: 86px;
      font-family: 'Droid Serif';
      font-style: italic;
      margin-bottom: 0; }
  .image-text-container .category-description {
    text-align: center;
    max-width: 1240px;
    margin: 0 auto; }
    .image-text-container .category-description.product-line-selected h1 {
      margin-top: 60px !important;
      margin-bottom: 60px !important; }
    @media screen and (max-width: 1440px) {
      .image-text-container .category-description {
        margin-left: 100px;
        margin-right: 100px; } }
    @media screen and (max-width: 1280px) {
      .image-text-container .category-description {
        margin-left: 85px;
        margin-right: 85px; } }
    @media screen and (max-width: 576px) {
      .image-text-container .category-description {
        margin-left: 20px;
        margin-right: 20px; } }
    .image-text-container .category-description h1 {
      font-size: 86px;
      font-family: 'Droid Serif';
      font-style: italic;
      margin-bottom: 20px; }
      @media screen and (max-width: 932px) {
        .image-text-container .category-description h1 {
          font-size: 48px;
          line-height: 64px;
          text-align: left; } }
      @media screen and (max-width: 768px) {
        .image-text-container .category-description h1 {
          font-size: 36px;
          line-height: 48px; } }
    .image-text-container .category-description p {
      font-size: 16px; }
      @media screen and (max-width: 932px) {
        .image-text-container .category-description p {
          text-align: left;
          font-weight: 100; } }

@media screen and (min-width: 1024px) {
  .sidebar {
    width: 25% !important;
    padding-right: 10px !important; } }

@media screen and (max-width: 1023px) {
  .sidebar {
    width: 26% !important;
    padding-right: 10px !important; } }

@media screen and (max-width: 768px) {
  .sidebar {
    padding-right: 0px !important; } }

.columns > .sidebar {
  margin-top: 75px !important; }
  @media screen and (max-width: 768px) {
    .columns > .sidebar {
      margin-top: 0 !important; } }

.columns > .sidebar ~ .sidebar {
  margin-top: 0 !important; }

.products.list.items.product-items {
  margin-left: 0 !important; }

.page-products .products-grid .product-item {
  margin-right: 2%;
  margin-left: 0;
  padding: 0;
  border: 2px solid #f2f2f2;
  margin-bottom: 30px !important; }
  @media screen and (min-width: 1367px) {
    .page-products .products-grid .product-item {
      width: 31.33%; }
      .page-products .products-grid .product-item:nth-child(3n) {
        margin-right: 0; } }
  @media screen and (max-width: 1366px) {
    .page-products .products-grid .product-item {
      width: 49%; }
      .page-products .products-grid .product-item:nth-child(2n) {
        margin-right: 0; } }
  @media screen and (max-width: 932px) {
    .page-products .products-grid .product-item {
      width: 100%;
      margin-right: 0; } }
  @media screen and (max-width: 768px) {
    .page-products .products-grid .product-item {
      width: 49%;
      margin-right: 2%; }
      .page-products .products-grid .product-item:nth-child(2n) {
        margin-right: 0; } }
  @media screen and (max-width: 650px) {
    .page-products .products-grid .product-item {
      width: 100%;
      margin-right: 0; } }
  @media screen and (max-width: 576px) {
    .page-products .products-grid .product-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px; } }
  .page-products .products-grid .product-item .swatch-attribute.amount .swatch-attribute-options {
    margin-top: 0; }
    .page-products .products-grid .product-item .swatch-attribute.amount .swatch-attribute-options .swatch-option {
      width: 45px;
      padding-left: 0;
      padding-right: 0;
      height: 10px;
      font-size: 10px;
      line-height: 10px;
      font-weight: 400;
      margin-bottom: 0px;
      margin-right: 0;
      background-color: white;
      border-radius: 2px;
      border-color: #777; }
      .page-products .products-grid .product-item .swatch-attribute.amount .swatch-attribute-options .swatch-option:hover {
        outline: none;
        background-color: #f2f2f2; }
      .page-products .products-grid .product-item .swatch-attribute.amount .swatch-attribute-options .swatch-option.selected {
        background-color: #d7aa02 !important;
        color: white;
        border-color: #d7aa02;
        outline: none; }
    .page-products .products-grid .product-item .swatch-attribute.amount .swatch-attribute-options .swatch-option + .swatch-option {
      margin-left: 4px; }

.swatch-attribute-label {
  z-index: 99999; }

.swatch-option-tooltip {
  max-width: 0px !important;
  max-height: 0px !important;
  overflow: hidden;
  min-height: 0px !important;
  min-width: 0px !important;
  border: none !important;
  background: transparent !important; }

.product-image-wrapper {
  background-color: #f2f2f2; }

.product-item-info {
  width: 100% !important; }
  .product-item-info .product-image-container {
    width: 100% !important; }
    .product-item-info .product-image-container .product-image-photo {
      max-height: 100%;
      width: auto; }

@media screen and (max-width: 768px) {
  .toolbar-sorter {
    text-align: left;
    padding-bottom: 15px;
    border-bottom: 1px solid #aaa;
    float: none; } }

.toolbar-sorter .sorter-label {
  font-family: 'Droid Serif';
  font-size: 16px;
  font-style: italic;
  color: #777; }
  @media screen and (max-width: 950px) {
    .toolbar-sorter .sorter-label {
      display: none; } }
  @media screen and (max-width: 768px) {
    .toolbar-sorter .sorter-label {
      display: block;
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      padding-bottom: 10px;
      color: black;
      margin-bottom: 0; } }

.toolbar-sorter .sorter-options {
  width: 170px;
  height: 35px;
  text-align-last: center;
  padding-bottom: 7px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Montserrat';
  line-height: 16px;
  appearance: none;
  border-radius: 0;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .toolbar-sorter .sorter-options {
      border: none;
      font-family: 'Droid Serif';
      text-transform: none;
      font-size: 16px;
      height: 32px;
      text-align: left;
      text-align-last: initial;
      padding-left: 0;
      margin-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      line-height: normal;
      color: #777;
      display: inline-block;
      width: calc(100% - 32px); } }
  .toolbar-sorter .sorter-options:focus {
    box-shadow: none; }

@media screen and (max-width: 768px) {
  .toolbar-sorter .sorter-action:before {
    transform: translateX(8px); } }

.product-item-actions {
  position: absolute;
  bottom: 15px;
  width: 100%;
  margin: 0 !important; }
  @media screen and (max-width: 639px) {
    .product-item-actions {
      display: block !important; } }
  .product-item-actions .actions-primary, .product-item-actions .actions-secondary {
    display: inline-block !important; }
  .product-item-actions .actions-secondary {
    width: 62px !important; }
    .product-item-actions .actions-secondary .action.tocompare {
      display: none !important; }

.product-item-details {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 17px;
  font-family: 'Montserrat';
  height: 185px; }

.tax-span {
  margin-bottom: 15px;
  color: #777; }

#top-toolbar {
  padding: 0; }
  @media screen and (max-width: 768px) {
    #top-toolbar {
      margin-bottom: 80px; } }

.filter-wrapper {
  display: flex;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 40px;
  max-height: 5000px;
  overflow: hidden;
  transition: 0.1s; }
  .filter-wrapper .filter-wrapper-title {
    margin-top: -50px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: -85px;
    margin-right: -85px;
    top: 0;
    border-bottom: 2px solid #ddd;
    padding-left: 85px;
    padding-right: 85px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px;
    cursor: pointer; }
    .filter-wrapper .filter-wrapper-title:after {
      content: '-';
      position: absolute;
      right: 85px; }
    @media screen and (max-width: 576px) {
      .filter-wrapper .filter-wrapper-title {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px; }
        .filter-wrapper .filter-wrapper-title:after {
          right: 20px; } }
  .filter-wrapper.hidden {
    max-height: 0px;
    margin-bottom: 0;
    margin-top: 30px;
    transition: 0.1s; }
    .filter-wrapper.hidden .filter-wrapper-title:after {
      content: '+' !important; }

.filter-options .filter-options-title {
  word-break: break-all;
  font-family: 'Droid Serif';
  font-style: italic;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 22px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .filter-options .filter-options-title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      padding-bottom: 20px;
      margin-bottom: 0;
      cursor: pointer; }
      .filter-options .filter-options-title:after {
        content: "▶︎";
        position: absolute;
        right: 0;
        transform: rotate(0deg);
        transition: 0.2s;
        font-size: 20px;
        line-height: 22px; } }

@media screen and (max-width: 768px) {
  .filter-options .filter-options-title-open:after {
    transform: rotate(90deg);
    transition: 0.2s; } }

.filter-options .filter-options-content {
  margin-bottom: 50px;
  transition: max-height 0.2s; }
  @media screen and (max-width: 768px) {
    .filter-options .filter-options-content {
      max-height: 0;
      overflow: hidden;
      margin-bottom: 0;
      margin-bottom: 22px;
      border-bottom: 1px solid #aaa;
      transition: max-height 0.2s; } }
  .filter-options .filter-options-content .items .item {
    margin-bottom: 22px;
    font-family: 'Droid Serif';
    color: #bbb;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    line-height: 22px;
    margin-left: 8px; }
    @media screen and (max-width: 768px) {
      .filter-options .filter-options-content .items .item {
        font-style: normal; } }
    .filter-options .filter-options-content .items .item:before {
      content: '→ ';
      display: inline-block;
      margin-right: -8px;
      color: #aaa;
      transform: translateX(-10px); }
    .filter-options .filter-options-content .items .item a {
      color: #777; }
      .filter-options .filter-options-content .items .item a .count {
        color: #777; }
    .filter-options .filter-options-content .items .item .count {
      color: #bbb; }

.filter-options .mobile-open {
  max-height: 5000px;
  transition: max-height 0.2s; }

.filter-current .item {
  margin-bottom: 50px; }
  @media screen and (max-width: 768px) {
    .filter-current .item {
      border-bottom: 1px solid #aaa;
      margin-bottom: 20px;
      padding-bottom: 10px; } }
  .filter-current .item .filter-label {
    display: block;
    word-break: break-all;
    font-family: 'Droid Serif';
    font-style: italic;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 16px; }
    @media screen and (max-width: 768px) {
      .filter-current .item .filter-label {
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 10px; } }
    .filter-current .item .filter-label:after {
      content: none; }
  .filter-current .item .filter-value {
    margin-bottom: 10px;
    font-family: 'Droid Serif';
    color: #d7aa02;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    line-height: 32px; }
    @media screen and (max-width: 768px) {
      .filter-current .item .filter-value {
        color: #777;
        font-style: normal; } }
  .filter-current .item .action.remove:hover {
    text-decoration: none; }
    .filter-current .item .action.remove:hover + .filter-value {
      text-decoration: line-through; }
  .filter-current .item .action.remove .remove-filter {
    background-image: url(../images/delete_icon.svg);
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    transform: translateY(3px); }
    @media screen and (max-width: 768px) {
      .filter-current .item .action.remove .remove-filter {
        filter: saturate(0);
        margin-left: -3px; } }

.block-actions.filter-actions {
  float: left;
  line-height: 43px;
  margin-left: 10px;
  display: none; }
  @media screen and (max-width: 768px) {
    .block-actions.filter-actions {
      margin-left: 0;
      width: 100%; } }
  .block-actions.filter-actions.visible {
    display: block; }
  .block-actions.filter-actions .action.clear.filter-clear {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: black;
    border: 1px solid #c2c2c2; }
    @media screen and (max-width: 768px) {
      .block-actions.filter-actions .action.clear.filter-clear {
        text-align: center;
        display: block;
        background-color: #d7aa02;
        color: white;
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none; } }
    .block-actions.filter-actions .action.clear.filter-clear:hover {
      text-decoration: none; }

#toolbar-amount {
  font-family: 'Droid Serif';
  font-style: italic;
  font-size: 16px;
  color: #777; }

#limiter.limiter-options {
  width: 70px;
  height: 35px;
  text-align-last: center;
  padding-bottom: 7px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Montserrat';
  line-height: 16px;
  font-style: normal;
  appearance: none;
  border-radius: 0;
  cursor: pointer; }
  #limiter.limiter-options:focus {
    box-shadow: none; }

.field.limiter {
  font-family: 'Droid Serif';
  color: #777;
  font-size: 16px;
  font-style: italic; }

.pages .items .item strong span {
  font-family: 'Droid Serif';
  font-size: 16px;
  font-weight: 800;
  color: #d7aa02; }

.pages .items .item a span {
  color: #777;
  font-family: 'Droid Serif';
  font-size: 16px;
  font-weight: 400; }
  .pages .items .item a span:hover {
    text-decoration: underline; }

@media screen and (max-width: 768px) {
  .sidebar-additional .block-wishlist {
    display: none !important; } }

.sidebar-additional .block .block-title {
  font-family: 'Droid Serif';
  font-style: italic;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 16px; }
  .sidebar-additional .block .block-title .counter.qty {
    display: none !important; }

.sidebar-additional .block .empty {
  margin-bottom: 10px;
  font-family: 'Droid Serif';
  color: #bbb;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 32px; }

.sidebar-additional .block .product-item-name {
  font-family: 'Droid Serif';
  color: #d7aa02;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 24px;
  font-variant-caps: normal;
  text-transform: none;
  height: auto !important;
  margin-left: 20px !important;
  hyphens: none !important; }
  .sidebar-additional .block .product-item-name a {
    color: inherit !important;
    hyphens: none !important; }

.sidebar-additional .block .product-item-photo {
  display: none !important; }
  .sidebar-additional .block .product-item-photo .product-image-container {
    display: none !important; }

.sidebar-additional .block .action.delete {
  width: 30px;
  top: -3px; }
  .sidebar-additional .block .action.delete:before {
    content: "";
    background-image: url(../images/delete_icon.svg);
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-transform: translateY(3px);
    transform: translateY(3px); }

.sidebar-additional .block .actions-toolbar .primary {
  float: none; }
  .sidebar-additional .block .actions-toolbar .primary a {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: black;
    border: 1px solid #c2c2c2;
    border-radius: 0px;
    background-color: transparent; }

.sidebar-additional .block .actions-toolbar .secondary {
  display: block;
  float: none;
  text-align: left;
  font-family: 'Droid Serif';
  font-style: italic;
  font-size: 16px;
  margin-top: 10px; }
  .sidebar-additional .block .actions-toolbar .secondary a {
    color: #777; }
    .sidebar-additional .block .actions-toolbar .secondary a:before {
      content: '→ ';
      display: inline-block;
      margin-right: 4px;
      color: #aaa; }

.category-view {
  background-image: url(../images/standard_category_hero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px; }

.category-termine .category-view {
  display: none; }

.fotorama--fullscreen .fotorama__wrap .fotorama__stage {
  height: 100vh !important; }

@media screen and (max-width: 768px) {
  .catalog-product-view .columns {
    margin-left: 45px;
    margin-right: 45px; } }

@media screen and (max-width: 576px) {
  .catalog-product-view .columns {
    margin-left: 20px;
    margin-right: 20px; } }

.catalog-product-view .page-main {
  padding-top: 40px; }
  @media screen and (max-width: 768px) {
    .catalog-product-view .page-main {
      padding-top: 0; } }

.catalog-product-view .product.media {
  margin-left: -100px; }
  @media screen and (max-width: 768px) {
    .catalog-product-view .product.media {
      width: 50%;
      margin-left: -45px; } }
  @media screen and (max-width: 767px) {
    .catalog-product-view .product.media {
      width: 100vw; } }
  @media screen and (max-width: 576px) {
    .catalog-product-view .product.media {
      margin-left: -20px; } }
  .catalog-product-view .product.media .fotorama__stage {
    height: 500px !important;
    background-color: #f2f2f2; }
    @media screen and (max-width: 576px) {
      .catalog-product-view .product.media .fotorama__stage {
        height: 350px !important; } }
    .catalog-product-view .product.media .fotorama__stage .fotorama__caption {
      display: none; }
  .catalog-product-view .product.media .fotorama__nav.fotorama__nav--thumbs {
    display: flex !important;
    justify-content: space-evenly !important; }
  .catalog-product-view .product.media .fotorama__arr {
    display: none !important; }
  .catalog-product-view .product.media .fotorama__wrap {
    position: relative !important; }
  .catalog-product-view .product.media .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
    position: absolute !important;
    bottom: 10px !important; }
  .catalog-product-view .product.media .fotorama__thumb__arr {
    display: none !important; }
  .catalog-product-view .product.media .fotorama__nav__frame.fotorama__nav__frame--thumb {
    border-radius: 50%;
    overflow: hidden;
    padding: 0 !important;
    margin-right: 2px; }
    .catalog-product-view .product.media .fotorama__nav__frame.fotorama__nav__frame--thumb .fotorama__thumb {
      background-color: #999; }
      .catalog-product-view .product.media .fotorama__nav__frame.fotorama__nav__frame--thumb .fotorama__thumb img {
        display: none; }
  .catalog-product-view .product.media .fotorama__thumb-border {
    width: 10px;
    height: 10px;
    background-color: #d7aa02;
    border: none;
    border-radius: 50%;
    margin-top: 0 !important; }
  .catalog-product-view .product.media .fotorama__nav__frame--dot.fotorama__active .fotorama__dot {
    background-color: #d7aa02;
    border-color: #d7aa02;
    height: 10px;
    width: 10px; }
  .catalog-product-view .product.media .fotorama__nav__frame--dot .fotorama__dot {
    background-color: #999;
    border-color: #999;
    height: 10px;
    width: 10px; }

@media screen and (max-width: 767px) {
  .catalog-product-view .product.info.detailed {
    margin-bottom: 60px !important; } }

.catalog-product-view .product-info-main {
  width: 47%;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .catalog-product-view .product-info-main {
      float: right !important; } }
  @media screen and (max-width: 768px) {
    .catalog-product-view .product-info-main {
      width: 50%;
      margin-top: 40px; } }
  @media screen and (max-width: 767px) {
    .catalog-product-view .product-info-main {
      width: 100%; } }
  .catalog-product-view .product-info-main .page-title-wrapper {
    margin-top: 7px; }
  .catalog-product-view .product-info-main .price-box {
    display: none; }
  .catalog-product-view .product-info-main .price-details {
    display: none; }
  .catalog-product-view .product-info-main .product-info-price {
    display: inline-block;
    margin-right: 20px; }
    .catalog-product-view .product-info-main .product-info-price .price-box {
      display: block;
      margin-top: 0 !important;
      margin-bottom: 6px !important; }
      .catalog-product-view .product-info-main .product-info-price .price-box .special-price {
        display: inline; }
      .catalog-product-view .product-info-main .product-info-price .price-box .price-label {
        display: none; }
      .catalog-product-view .product-info-main .product-info-price .price-box .price {
        font-family: 'Droid Serif';
        font-size: 18px !important;
        font-weight: 700 !important;
        margin-right: 20px !important; }
    .catalog-product-view .product-info-main .product-info-price .price-details {
      display: block;
      margin-bottom: 20px; }
  .catalog-product-view .product-info-main .product.attribute.delivery_time {
    display: inline-block;
    font-family: 'Droid Serif';
    color: #777; }
    @media screen and (max-width: 768px) {
      .catalog-product-view .product-info-main .product.attribute.delivery_time {
        margin-bottom: 20px; } }
    .catalog-product-view .product-info-main .product.attribute.delivery_time .type {
      color: black;
      line-height: 29px; }
  .catalog-product-view .product-info-main .product.attribute.description {
    position: relative; }
    .catalog-product-view .product-info-main .product.attribute.description .description-toggle {
      display: block;
      cursor: pointer;
      padding-top: 20px;
      width: 160px;
      transition: 0.2s;
      font-family: 'Droid Serif';
      font-size: 16px;
      color: #777; }
    .catalog-product-view .product-info-main .product.attribute.description .description {
      max-height: 158px;
      overflow: hidden;
      transition: 0.2s;
      font-family: 'Droid Serif' !important;
      font-size: 16px;
      color: #777;
      line-height: 28px;
      hyphens: auto; }
      .catalog-product-view .product-info-main .product.attribute.description .description:after {
        content: "";
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        height: 140px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
        transition: 0.2s; }
      .catalog-product-view .product-info-main .product.attribute.description .description:hover:after {
        height: 50px;
        transition: 0.2s; }
      .catalog-product-view .product-info-main .product.attribute.description .description p {
        font-family: 'Droid Serif' !important;
        font-size: 16px;
        color: #777 !important;
        line-height: 28px;
        hyphens: auto; }
        .catalog-product-view .product-info-main .product.attribute.description .description p span {
          font-family: 'Droid Serif' !important;
          font-size: 16px;
          color: #777 !important;
          line-height: 28px;
          hyphens: auto; }
    .catalog-product-view .product-info-main .product.attribute.description .description-hover:after {
      height: 20px;
      transition: 0.2s; }
    .catalog-product-view .product-info-main .product.attribute.description .opened {
      max-height: 100000px;
      transition: 0.2s; }
      .catalog-product-view .product-info-main .product.attribute.description .opened:after {
        content: "";
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        height: 0px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
        transition: 0.2s; }
      .catalog-product-view .product-info-main .product.attribute.description .opened:hover:after {
        height: 0 !important; }
  .catalog-product-view .product-info-main #product-addtocart-button {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 20px; }
    @media screen and (max-width: 940px) {
      .catalog-product-view .product-info-main #product-addtocart-button {
        margin-left: 0;
        display: block;
        width: 100%; } }
  .catalog-product-view .product-info-main .action.tocompare,
  .catalog-product-view .product-info-main .action.towishlist {
    background-color: white;
    border-radius: 0;
    height: 32px;
    width: 200px;
    text-align: center;
    line-height: 21px;
    font-family: 'Montserrat';
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12px;
    overflow: hidden;
    margin-right: 4px; }
    @media screen and (max-width: 767px) {
      .catalog-product-view .product-info-main .action.tocompare,
      .catalog-product-view .product-info-main .action.towishlist {
        width: 100%;
        line-height: 40px;
        height: 50px;
        font-size: 16px; } }
  .catalog-product-view .product-info-main .product-add-form {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .catalog-product-view .product-info-main .product-add-form {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0; } }
  .catalog-product-view .product-info-main .quantity-selector {
    display: inline-block;
    margin-top: 24px; }
    .catalog-product-view .product-info-main .quantity-selector .qty-minus {
      display: inline-block;
      border: 2px solid #777;
      color: #777;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 26px;
      font-size: 28px;
      cursor: pointer;
      user-select: none; }
    .catalog-product-view .product-info-main .quantity-selector .qty-plus {
      display: inline-block;
      border: 2px solid #777;
      color: #777;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 28px;
      transform: translateY(2px);
      cursor: pointer;
      user-select: none; }
  @media screen and (max-width: 768px) {
    .catalog-product-view .product-info-main .box-tocart {
      width: 100%; } }
  @media screen and (max-width: 940px) {
    .catalog-product-view .product-info-main .box-tocart .field.qty {
      display: block; } }
  .catalog-product-view .product-info-main .box-tocart .field.qty label {
    display: none; }
  @media screen and (max-width: 940px) {
    .catalog-product-view .product-info-main .box-tocart .actions {
      padding-top: 0;
      display: block;
      width: 100%; } }
  .catalog-product-view .product-info-main .box-tocart input.input-text.qty {
    border: none;
    font-size: 24px;
    font-family: 'Montserrat';
    user-select: none; }
    .catalog-product-view .product-info-main .box-tocart input.input-text.qty:focus {
      box-shadow: none; }

.catalog-product-view .page-title {
  font-family: 'Droid Serif';
  font-size: 28px;
  margin-bottom: 18px !important; }

.catalog-product-view .data.item.title.active {
  color: #fff !important; }
  @media screen and (max-width: 576px) {
    .catalog-product-view .data.item.title.active {
      width: 100vw;
      margin-left: -20px; } }
  .catalog-product-view .data.item.title.active a {
    background-color: #d7aa02 !important;
    font-family: 'Montserrat' !important;
    border: none !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    color: inherit !important; }
    @media screen and (max-width: 767px) {
      .catalog-product-view .data.item.title.active a {
        background-color: transparent !important;
        color: black !important;
        font-weight: 800 !important;
        border-top: 1px solid #ddd !important; }
        .catalog-product-view .data.item.title.active a:after {
          content: '-';
          float: right; } }

.catalog-product-view .data.item.title {
  margin-right: 0 !important;
  margin-bottom: 0 !important; }
  @media screen and (max-width: 576px) {
    .catalog-product-view .data.item.title {
      width: 100vw !important;
      margin-left: -20px !important; } }
  .catalog-product-view .data.item.title a {
    background-color: transparent !important;
    font-family: 'Montserrat' !important;
    border: 1px solid #aaa !important;
    border-bottom: none !important;
    font-weight: 500 !important;
    text-transform: uppercase !important; }
    @media screen and (max-width: 767px) {
      .catalog-product-view .data.item.title a {
        background-color: transparent !important;
        color: black !important;
        font-weight: 800 !important;
        border-left: none !important;
        border-right: none !important;
        border-top: 1px solid #ddd !important;
        border-bottom: 1px solid #ddd !important; }
        .catalog-product-view .data.item.title a:after {
          content: '+';
          float: right; } }

.catalog-product-view .data.item.content {
  border: 1px solid #aaa !important;
  border-top-color: #d7aa02 !important; }
  @media screen and (max-width: 767px) {
    .catalog-product-view .data.item.content {
      border: none !important;
      padding: 0 !important;
      padding-top: 10px !important; } }
  .catalog-product-view .data.item.content#additional {
    padding-top: 35px; }
  .catalog-product-view .data.item.content#awards {
    padding-top: 35px; }
    .catalog-product-view .data.item.content#awards .award-details {
      font-family: "Droid Serif";
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: #333;
      font-style: italic; }
      .catalog-product-view .data.item.content#awards .award-details .single-award {
        padding-bottom: 10px; }

.catalog-product-view .additional-attributes-wrapper {
  font-family: 'Droid Serif';
  font-size: 16px;
  font-style: italic; }
  .catalog-product-view .additional-attributes-wrapper th {
    font-weight: 800; }
  .catalog-product-view .additional-attributes-wrapper td {
    font-weight: 400;
    white-space: pre-line; }

.catalog-product-view .more-from-category-header {
  font-size: 22px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 40px; }

.catalog-product-view .more-products-slider-wrapper {
  display: flex; }
  .catalog-product-view .more-products-slider-wrapper .product-item {
    padding-left: 0;
    border: 2px solid #f2f2f2;
    margin-right: 20px;
    width: 300px; }
    .catalog-product-view .more-products-slider-wrapper .product-item:last-child {
      margin-right: 0; }
    .catalog-product-view .more-products-slider-wrapper .product-item .product-item-details {
      height: auto; }

.catalog-product-view .review-form {
  max-width: 600px; }
  .catalog-product-view .review-form .fieldset.review-fieldset .legend.review-legend span {
    display: inline-block;
    font-family: 'Droid Serif';
    font-style: italic;
    font-size: 18px;
    margin-right: 10px; }
  .catalog-product-view .review-form .fieldset.review-fieldset .legend.review-legend strong {
    display: inline-block;
    font-family: 'Droid Serif';
    font-style: italic;
    font-size: 18px; }
  .catalog-product-view .review-form .fieldset.review-fieldset .field label {
    font-family: 'Droid Serif';
    font-size: 16px;
    font-style: italic;
    text-align: left;
    width: 100%; }
  .catalog-product-view .review-form .fieldset.review-fieldset .field .control {
    width: 100%; }
    .catalog-product-view .review-form .fieldset.review-fieldset .field .control input {
      font-family: 'Droid Serif'; }
  .catalog-product-view .review-form .actions-toolbar.review-form-actions {
    margin-left: 0; }
    .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary button, .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .actions-toolbar > .action, .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .action-gift {
      background-color: #d7aa02;
      border: none;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 30px;
      padding-right: 30px;
      color: white;
      font-size: 18px; }
      .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary button:hover, .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .actions-toolbar > .action:hover, .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .action-gift:hover {
        border: none;
        background-color: #e1b202; }
      .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary button:focus, .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .actions-toolbar > .action:focus, .catalog-product-view .review-form .actions-toolbar.review-form-actions .primary.actions-primary .action-gift:focus {
        border: none;
        background-color: #e6b602; }

.catalog-product-view .review {
  font-family: 'Droid Serif';
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row; }
  @media screen and (max-width: 576px) {
    .catalog-product-view .review {
      flex-direction: column; } }
  .catalog-product-view .review:last-child {
    border-bottom: none; }
  .catalog-product-view .review .rating {
    display: inline-block;
    width: 100px;
    margin-right: 20px;
    flex-shrink: 0; }
  .catalog-product-view .review .text {
    display: inline-block;
    font-size: 16px;
    font-style: italic; }

.catalog-product-view .hidden-reviews {
  display: none; }

.catalog-product-view .show-reviews {
  display: block; }

.catalog-product-view .block.related .block-content {
  position: relative; }

.catalog-product-view .block.related .arrow-left {
  position: absolute;
  top: 35%;
  left: -40px;
  background-image: url(../images/weiter-zurueck.svg);
  width: 24px;
  height: 24px;
  background-size: cover;
  transform: rotate(180deg);
  cursor: pointer;
  user-select: none; }
  @media screen and (max-width: 576px) {
    .catalog-product-view .block.related .arrow-left {
      top: 103%;
      left: 33%; } }
  .catalog-product-view .block.related .arrow-left.slick-disabled {
    cursor: default;
    filter: saturate(0%);
    opacity: 0.2; }

.catalog-product-view .block.related .arrow-right {
  position: absolute;
  top: 35%;
  right: -40px;
  background-image: url(../images/weiter-zurueck.svg);
  width: 24px;
  height: 24px;
  background-size: cover;
  cursor: pointer;
  user-select: none; }
  @media screen and (max-width: 576px) {
    .catalog-product-view .block.related .arrow-right {
      top: 103%;
      right: 33%; } }
  .catalog-product-view .block.related .arrow-right.slick-disabled {
    cursor: default;
    filter: saturate(0%);
    opacity: 0.2; }

.catalog-product-view .block.related .products.list {
  margin-left: 0; }
  .catalog-product-view .block.related .products.list .item.product {
    padding-left: 0;
    border: 2px solid #f2f2f2; }
    .catalog-product-view .block.related .products.list .item.product .field.choice.related {
      display: none; }

.catalog-product-view .slick-slide {
  margin: 0 10px; }

.catalog-product-view .slick-list {
  margin: 0 -10px; }

.catalog-product-view .show-all-reviews {
  margin-top: 20px; }

.catalog-product-view .tax-details {
  font-family: 'Droid Serif';
  color: #777;
  font-size: 14px; }

.catalog-product-view .product-info-stock-sku {
  margin-bottom: 8px; }

.catalog-product-view .field.configurable {
  margin-top: 0px;
  margin-bottom: 20px; }
  .catalog-product-view .field.configurable .label {
    display: none; }
    .catalog-product-view .field.configurable .label.mage-error {
      border-width: 2px; }
  .catalog-product-view .field.configurable .control {
    display: block; }
    .catalog-product-view .field.configurable .control select {
      width: 100%;
      height: 32px;
      text-align-last: center;
      padding-bottom: 7px;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 13px;
      font-family: 'Montserrat';
      line-height: 16px;
      appearance: none;
      border-radius: 0;
      cursor: pointer; }

.catalog-product-view .swatch-attribute-label {
  z-index: 99999; }

.catalog-product-view .swatch-option-tooltip {
  max-width: 0px !important;
  max-height: 0px !important;
  overflow: hidden;
  min-height: 0px !important;
  min-width: 0px !important;
  border: none !important;
  background: transparent !important; }

.catalog-product-view .swatch-attribute.amount .swatch-option-tooltip {
  display: none !important; }

.catalog-product-view .swatch-attribute.amount .swatch-attribute-label {
  display: none; }

.catalog-product-view .swatch-attribute.amount .swatch-attribute-selected-option {
  display: none; }

.catalog-product-view .swatch-attribute.amount .swatch-attribute-options {
  margin-top: 0;
  display: inline-block; }
  .catalog-product-view .swatch-attribute.amount .swatch-attribute-options:focus {
    box-shadow: none; }
    .catalog-product-view .swatch-attribute.amount .swatch-attribute-options:focus .swatch-option {
      border-color: red; }
  .catalog-product-view .swatch-attribute.amount .swatch-attribute-options .swatch-option + .swatch-option {
    margin-left: 10px; }
  .catalog-product-view .swatch-attribute.amount .swatch-attribute-options .swatch-option {
    height: 35px;
    width: 64px;
    line-height: 35px;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Montserrat';
    border: 1px solid #aaa;
    border-radius: 2px;
    background-color: white;
    outline: none !important;
    padding: 0;
    margin-bottom: 0;
    margin-right: 0; }
    .catalog-product-view .swatch-attribute.amount .swatch-attribute-options .swatch-option:hover {
      outline: none;
      background-color: #f2f2f2; }
    .catalog-product-view .swatch-attribute.amount .swatch-attribute-options .swatch-option.selected {
      background-color: #d7aa02 !important;
      color: white !important;
      border: 1px solid #d7aa02;
      outline: none !important; }

.catalog-product-view .stock.available {
  display: none; }

.grey-login-wrapper-outer {
  background-color: #f4f4f4;
  padding-top: 50px;
  padding-bottom: 100px; }
  @media screen and (max-width: 540px) {
    .grey-login-wrapper-outer {
      padding-top: 1px;
      padding-bottom: 1px; } }
  .grey-login-wrapper-outer .grey-login-wrapper-inner {
    max-width: 1200px;
    margin: 0 auto; }
    @media screen and (max-width: 1250px) {
      .grey-login-wrapper-outer .grey-login-wrapper-inner {
        margin-left: 30px;
        margin-right: 30px; } }
    @media screen and (max-width: 540px) {
      .grey-login-wrapper-outer .grey-login-wrapper-inner {
        margin-left: 10px;
        margin-right: 10px; } }
    .grey-login-wrapper-outer .grey-login-wrapper-inner h2 {
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 60px;
      margin-top: 50px;
      padding-left: 20px; }
    .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block {
      padding: 20px;
      width: 45%;
      padding-top: 40px;
      padding-bottom: 40px; }
      @media screen and (max-width: 900px) {
        .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block {
          width: calc(100% - 40px);
          margin-right: 0;
          float: none; } }
      .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-title {
        font-size: 18px;
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-weight: 800;
        border-bottom: none;
        padding-bottom: 0; }
        .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-title strong {
          font-weight: inherit; }
      .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content {
        font-family: 'Droid Serif';
        color: #777;
        font-size: 16px;
        line-height: 28px; }
        .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset {
          box-shadow: none !important;
          padding: 0 !important;
          background-color: unset !important; }
          .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field.email,
          .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field.password {
            width: 50%;
            display: inline-block;
            position: relative; }
            @media screen and (max-width: 540px) {
              .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field.email,
              .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field.password {
                width: 100%; } }
          .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field {
            position: relative; }
            .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field label {
              position: absolute !important;
              bottom: 0px;
              display: block;
              float: none;
              width: 90%;
              text-align: left;
              font-size: 16px;
              font-weight: 400;
              position: relative;
              z-index: 1;
              transition: 0.2s; }
              @media screen and (max-width: 540px) {
                .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field label {
                  width: 100%; } }
              .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field label:after {
                color: #d7aa02; }
            .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field .control {
              width: 90%;
              display: block;
              position: relative;
              z-index: 10; }
              @media screen and (max-width: 540px) {
                .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field .control {
                  width: 100%; } }
              .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field .control input {
                border: none;
                border-bottom: 2px solid #aaa;
                width: 100%;
                display: block;
                background: transparent;
                padding: 0;
                font-family: 'Droid Serif';
                font-size: 16px;
                color: #777;
                transition: 0.2s; }
                .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field .control input:focus {
                  box-shadow: none; }
            .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field.focused label {
              font-size: 14px;
              transition: 0.2s;
              bottom: 30px; }
            .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .field.focused .control input {
              border-color: #d7aa02;
              color: #d7aa02;
              transition: 0.2s; }
          @media screen and (max-width: 540px) {
            .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar {
              margin-top: 40px; } }
          .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar .primary button, .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar .primary .actions-toolbar > .action, .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar .primary .action-gift {
            height: 40px; }
          .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar .secondary {
            float: none;
            margin-left: 20px; }
            @media screen and (max-width: 540px) {
              .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar .secondary {
                margin-left: 0; } }
            .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset .actions-toolbar .secondary a {
              color: #d7aa02;
              font-size: 14px; }
        .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content .fieldset.login:after {
          display: none; }
        .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block .block-content #email-error {
          position: absolute;
          width: 200%; }
    .grey-login-wrapper-outer .grey-login-wrapper-inner .login-container .block-customer-login {
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }
  .grey-login-wrapper-outer #remember-me-box {
    display: flex;
    align-items: center; }
    .grey-login-wrapper-outer #remember-me-box:before {
      display: none !important; }
    .grey-login-wrapper-outer #remember-me-box:after {
      display: none !important; }
    .grey-login-wrapper-outer #remember-me-box .tooltip.wrapper {
      display: none !important; }
    .grey-login-wrapper-outer #remember-me-box .label {
      position: relative !important;
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      bottom: -1px;
      width: auto; }
    .grey-login-wrapper-outer #remember-me-box .checkbox {
      filter: sepia(1) brightness(1.1) saturate(1.6);
      position: relative !important;
      display: inline-block;
      margin-right: 10px; }
      .grey-login-wrapper-outer #remember-me-box .checkbox:focus {
        box-shadow: none !important; }

.customer-account-create .page-wrapper .page-main {
  background-color: #f4f4f4; }
  .customer-account-create .page-wrapper .page-main .page-title-wrapper {
    margin-left: 100px;
    padding-left: 20px;
    padding-top: 100px;
    padding-bottom: 15px; }
    @media screen and (max-width: 1280px) {
      .customer-account-create .page-wrapper .page-main .page-title-wrapper {
        margin-left: 70px; } }
    @media screen and (max-width: 768px) {
      .customer-account-create .page-wrapper .page-main .page-title-wrapper {
        margin-left: 85px; } }
    @media screen and (max-width: 576px) {
      .customer-account-create .page-wrapper .page-main .page-title-wrapper {
        margin-left: 20px; } }
    @media screen and (max-width: 540px) {
      .customer-account-create .page-wrapper .page-main .page-title-wrapper {
        padding-top: 40px; } }
    .customer-account-create .page-wrapper .page-main .page-title-wrapper .page-title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-weight: 800;
      font-size: 28px; }
  .customer-account-create .page-wrapper .page-main .columns .column.main {
    display: flex;
    justify-content: space-around;
    padding-bottom: 140px; }
    @media screen and (max-width: 1040px) {
      .customer-account-create .page-wrapper .page-main .columns .column.main {
        display: block; } }
    @media screen and (max-width: 540px) {
      .customer-account-create .page-wrapper .page-main .columns .column.main {
        padding-bottom: 40px; } }
    .customer-account-create .page-wrapper .page-main .columns .column.main .block-new-customer {
      padding: 40px;
      width: 45%;
      padding-top: 40px;
      padding-bottom: 40px; }
      @media screen and (max-width: 1040px) {
        .customer-account-create .page-wrapper .page-main .columns .column.main .block-new-customer {
          display: none; } }
      .customer-account-create .page-wrapper .page-main .columns .column.main .block-new-customer .block-title {
        font-size: 18px;
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-weight: 800;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 20px; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .block-new-customer .block-title strong {
          font-weight: inherit; }
      .customer-account-create .page-wrapper .page-main .columns .column.main .block-new-customer .block-content {
        font-family: 'Droid Serif';
        color: #777;
        font-size: 16px;
        line-height: 28px; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .block-new-customer .block-content .actions-toolbar {
          display: none; }
    .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account {
      background-color: white;
      padding: 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }
      @media screen and (max-width: 768px) {
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account {
          min-width: unset !important;
          width: unset !important; } }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset.create.account {
        margin-top: 20px;
        margin-bottom: 60px; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset.create.account .field .control {
          width: 97.5%; }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .legend {
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 50px; }
        @media screen and (max-width: 700px) {
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .legend {
            margin-bottom: 10px; } }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.email,
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password {
        width: 50%;
        display: inline-block;
        position: relative; }
        @media screen and (max-width: 700px) {
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.email,
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password {
            width: 100%; } }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field {
        position: relative;
        color: #777; }
        @media screen and (max-width: 700px) {
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field {
            margin-top: 20px; } }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-name-firstname {
          width: 50%;
          display: inline-block; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-name-firstname {
              width: 100%; } }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-name-lastname {
          width: 50%;
          display: inline-block; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-name-lastname {
              width: 100%; } }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.newsletter {
          display: flex; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.newsletter label {
            display: inline-block;
            bottom: 0; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.newsletter input {
            display: inline-block;
            height: 20px;
            margin-right: 20px; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.newsletter.focused label {
            position: relative !important;
            bottom: 0;
            font-size: 16px; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.newsletter:before {
            display: none; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob label {
          bottom: 8px; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob label {
              bottom: 0; } }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob.focused label {
          bottom: 38px; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob.focused label {
              bottom: 30px; } }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob .control {
          width: 97.5%; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob .control input {
            width: calc(100% - 50px);
            display: inline-block; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob .control button, .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob .control .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob .control .actions-toolbar > .action, .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.field-dob .control .action-gift {
            display: inline-block; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password {
          margin-top: 10px;
          display: inline-block;
          width: 50%; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password {
              width: 100%;
              margin-top: 30px; } }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password label {
            top: 0; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password.focused label {
            top: -30px; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password .control {
            width: 95% !important; }
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password .control .password-strength-meter {
              font-family: 'Droid Serif';
              position: absolute;
              left: 0;
              right: 0;
              cursor: pointer; }
              .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.password .control .password-strength-meter:after {
                content: "?";
                color: white;
                position: absolute;
                right: 10px;
                z-index: 10;
                cursor: pointer;
                background-color: #d7aa02;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                top: 5px; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.confirmation {
          display: inline-block;
          width: 50%;
          margin-top: 10px;
          margin-bottom: 30px; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.confirmation {
              width: 100%;
              margin-top: 60px; } }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.confirmation .control {
            width: 95% !important; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field label {
          position: absolute !important;
          bottom: 4px;
          font-family: 'Droid Serif';
          display: block;
          float: none;
          width: 95%;
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          position: relative;
          z-index: 1;
          transition: 0.2s; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field label {
              bottom: 0; } }
          @media screen and (max-width: 540px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field label {
              width: 100%; } }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field label:after {
            color: #d7aa02; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field .control {
          width: 95%;
          display: block;
          position: relative;
          z-index: 10; }
          @media screen and (max-width: 540px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field .control {
              width: 100%; } }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field .control input {
            border: none;
            border-bottom: 2px solid #aaa;
            width: 100%;
            display: block;
            background: transparent;
            padding: 0;
            font-family: 'Droid Serif';
            font-size: 16px;
            color: #777;
            transition: 0.2s; }
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field .control input:focus {
              box-shadow: none; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.focused label {
          bottom: 34px;
          font-size: 14px;
          transition: 0.2s; }
          @media screen and (max-width: 700px) {
            .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.focused label {
              bottom: 30px; } }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .field.focused .control input {
          border-color: #d7aa02;
          color: #d7aa02;
          transition: 0.2s; }
      @media screen and (max-width: 540px) {
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar {
          margin-top: 40px; } }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar .primary button, .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar .primary .actions-toolbar > .action, .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar .primary .action-gift {
        height: 40px; }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar .secondary {
        float: none;
        margin-left: 20px; }
        @media screen and (max-width: 540px) {
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar .secondary {
            margin-left: 0; } }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset .actions-toolbar .secondary a {
          color: #d7aa02;
          font-size: 14px; }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .fieldset.login:after {
        display: none; }
      .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .actions-toolbar {
        margin: 0; }
        .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .actions-toolbar .primary {
          width: 100%; }
          .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .actions-toolbar .primary button, .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .actions-toolbar .primary .actions-toolbar > .action, .customer-account-create .page-wrapper .page-main .columns .column.main .form-create-account .actions-toolbar .primary .action-gift {
            padding-top: 15px;
            padding-bottom: 15px; }

.account:not(.page-print):not(.form-create-account) #maincontent {
  background-color: #f4f4f4; }
  @media screen and (max-width: 768px) {
    .account:not(.page-print):not(.form-create-account) #maincontent {
      padding-top: 41px; } }
  .account:not(.page-print):not(.form-create-account) #maincontent .page-title-wrapper {
    max-width: 1240px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 10px; }
    @media screen and (max-width: 1440px) {
      .account:not(.page-print):not(.form-create-account) #maincontent .page-title-wrapper {
        margin-left: 100px;
        margin-right: 100px; } }
    @media screen and (max-width: 1280px) {
      .account:not(.page-print):not(.form-create-account) #maincontent .page-title-wrapper {
        margin-left: 70px;
        margin-right: 85px; } }
    @media screen and (max-width: 768px) {
      .account:not(.page-print):not(.form-create-account) #maincontent .page-title-wrapper {
        margin-left: 85px;
        margin-right: 85px;
        padding-top: 0;
        padding-bottom: 0; } }
    @media screen and (max-width: 576px) {
      .account:not(.page-print):not(.form-create-account) #maincontent .page-title-wrapper {
        margin-left: 20px;
        margin-right: 20px; } }
    .account:not(.page-print):not(.form-create-account) #maincontent .page-title-wrapper .page-title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 800; }
  .account:not(.page-print):not(.form-create-account) #maincontent .columns .main {
    width: 70%; }
    @media screen and (max-width: 1090px) {
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main {
        width: 60%; } }
    @media screen and (max-width: 768px) {
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main {
        width: 100%; } }
    .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      margin-left: 30px; }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-title strong {
        font-weight: 800; }
    .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-content .box {
      background-color: white;
      padding: 30px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      width: 40%;
      min-height: 100px;
      font-family: 'Droid Serif';
      font-size: 16px;
      line-height: 32px;
      color: #777; }
      @media screen and (max-width: 1090px) {
        .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-content .box {
          width: calc(100% - 60px);
          float: none; } }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-content .box .box-title {
        margin-bottom: 0; }
        .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-content .box .box-title span {
          font-weight: 800;
          font-size: 16px;
          line-height: 32px; }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-content .box .box-content {
        line-height: 32px; }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-content .box .box-actions {
        margin-top: 20px; }
        .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .block .block-content .box .box-actions a {
          color: #d7aa02;
          font-size: 14px;
          margin-right: 20px; }
    .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .table-wrapper table {
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      font-family: 'Montserrat'; }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .table-wrapper table th {
        padding: 20px;
        text-transform: uppercase; }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .table-wrapper table tbody tr:nth-child(even) {
        background-color: #f4f4f4; }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .table-wrapper table td {
        padding: 20px; }
        .account:not(.page-print):not(.form-create-account) #maincontent .columns .main .table-wrapper table td.col.actions a.action.view {
          background-image: url(../images/open.svg);
          width: 18px;
          height: 18px;
          display: block;
          background-repeat: no-repeat; }
  @media screen and (max-width: 1090px) {
    .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar {
      width: 36% !important; } }
  @media screen and (max-width: 768px) {
    .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar {
      width: 100% !important; } }
  .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main {
    margin-top: 0 !important; }
    .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content {
      background-color: #e9e9e9; }
      .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content .nav.items {
        font-family: 'Droid Serif';
        font-size: 16px;
        color: #777; }
        .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content .nav.items .nav.item a {
          color: #777; }
        .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content .nav.items .current {
          border: none; }
          .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content .nav.items .current a {
            border: none; }
            .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content .nav.items .current a strong {
              padding-left: 0; }
          .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content .nav.items .current strong {
            border: none;
            color: #d7aa02;
            padding-left: 15px; }
            .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-main .block .content .nav.items .current strong:before {
              content: '→ ';
              display: inline-block;
              margin-right: 8px; }
  .account:not(.page-print):not(.form-create-account) #maincontent .columns .sidebar.sidebar-additional {
    display: none !important; }

.account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item {
  background-color: white;
  width: calc(33% - 20px);
  margin-right: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 0 !important;
  margin-left: 0 !important; }
  @media screen and (max-width: 1380px) {
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item {
      width: calc(50% - 20px); } }
  @media screen and (max-width: 960px) {
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item {
      width: 100%;
      margin-right: 0; } }
  .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info {
    font-family: 'Montserrat'; }
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .product-item-name {
      padding: 0 20px;
      font-family: 'Montserrat';
      text-transform: uppercase; }
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .price-box {
      padding: 0 20px; }
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .price-details {
      padding: 0 20px; }
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .product-item-inner {
      padding: 0 20px; }
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .box-tocart .fieldset {
      padding: 0;
      box-shadow: none;
      background: none; }
      .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .box-tocart .fieldset .field.qty {
        display: inline-block; }
      .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .box-tocart .fieldset .product-item-actions {
        position: relative;
        width: auto;
        transform: translateY(-31px);
        float: right; }
    .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .product-item-actions {
      position: relative; }
      .account:not(.page-print):not(.form-create-account) .products-grid.wishlist .product-items .product-item .product-item-info .product-item-actions .action.delete {
        float: right; }

.account:not(.page-print):not(.form-create-account) .fieldset {
  background-color: white;
  padding: 20px;
  padding-bottom: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }
  .account:not(.page-print):not(.form-create-account) .fieldset.password {
    padding-bottom: 40px; }
    @media screen and (max-width: 540px) {
      .account:not(.page-print):not(.form-create-account) .fieldset.password .new.password {
        margin-bottom: 60px !important; } }
    .account:not(.page-print):not(.form-create-account) .fieldset.password .new.password #password-strength-meter-container {
      position: absolute;
      width: 100%; }
      .account:not(.page-print):not(.form-create-account) .fieldset.password .new.password #password-strength-meter-container .password-strength-meter {
        height: auto; }
  .account:not(.page-print):not(.form-create-account) .fieldset > .legend {
    margin-bottom: 60px; }
    .account:not(.page-print):not(.form-create-account) .fieldset > .legend span {
      font-family: 'Droid Serif';
      font-size: 18px;
      line-height: 32px; }
  .account:not(.page-print):not(.form-create-account) .fieldset .field.email,
  .account:not(.page-print):not(.form-create-account) .fieldset .field.password {
    width: 50%;
    display: inline-block;
    position: relative; }
    @media screen and (max-width: 540px) {
      .account:not(.page-print):not(.form-create-account) .fieldset .field.email,
      .account:not(.page-print):not(.form-create-account) .fieldset .field.password {
        width: 100%; } }
  .account:not(.page-print):not(.form-create-account) .fieldset .choice {
    margin-bottom: 20px; }
    .account:not(.page-print):not(.form-create-account) .fieldset .choice:before {
      display: none; }
  .account:not(.page-print):not(.form-create-account) .fieldset .field.choice {
    font-family: 'Droid Serif';
    display: inline-block;
    margin-right: 20px; }
  .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) {
    position: relative;
    width: 50%;
    display: inline-block;
    margin-bottom: 30px !important; }
    @media screen and (max-width: 540px) {
      .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) {
        width: 100%; } }
    .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice).street .nested {
      display: none; }
    .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice).region {
      display: none; }
    .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) label {
      font-family: 'Droid Serif';
      position: absolute !important;
      bottom: 0px;
      display: block;
      float: none !important;
      text-align: left !important;
      width: 90% !important;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      position: relative;
      margin-bottom: 4px;
      z-index: 1;
      transition: 0.2s; }
      @media screen and (max-width: 540px) {
        .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) label {
          width: 100%; } }
      .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) label:after {
        color: #d7aa02 !important; }
    .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) .control {
      width: 90%;
      display: block;
      position: relative;
      z-index: 10; }
      @media screen and (max-width: 540px) {
        .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) .control {
          width: 100%; } }
      .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) .control > .field {
        display: none; }
      .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) .control input {
        border: none;
        border-bottom: 2px solid #aaa;
        width: 100%;
        display: block;
        background: transparent;
        padding: 0;
        font-family: 'Droid Serif';
        font-size: 16px;
        color: #777;
        transition: 0.2s; }
        .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) .control input:focus {
          box-shadow: none; }
      .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice) .control select {
        appearance: none;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid #aaa;
        width: 100%;
        display: block;
        background: transparent;
        padding: 0;
        font-family: 'Droid Serif';
        font-size: 16px;
        color: #777;
        transition: 0.2s; }
    .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice).focused label {
      font-size: 14px;
      transition: 0.2s;
      bottom: 30px; }
    .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice).focused .control input {
      border-color: #d7aa02;
      color: #d7aa02;
      transition: 0.2s; }
    .account:not(.page-print):not(.form-create-account) .fieldset .field:not(.choice).focused .control select {
      border-color: #d7aa02;
      color: #d7aa02;
      transition: 0.2s; }

.account:not(.page-print):not(.form-create-account) .actions-toolbar {
  margin-left: 0 !important; }
  @media screen and (max-width: 768px) {
    .account:not(.page-print):not(.form-create-account) .actions-toolbar {
      width: 100% !important; } }
  @media screen and (max-width: 540px) {
    .account:not(.page-print):not(.form-create-account) .actions-toolbar {
      margin-top: 40px; } }
  @media screen and (max-width: 768px) {
    .account:not(.page-print):not(.form-create-account) .actions-toolbar .primary {
      width: 100% !important; } }
  @media screen and (max-width: 768px) {
    .account:not(.page-print):not(.form-create-account) .actions-toolbar .primary button, .account:not(.page-print):not(.form-create-account) .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .account:not(.page-print):not(.form-create-account) .actions-toolbar .primary .actions-toolbar > .action, .account:not(.page-print):not(.form-create-account) .actions-toolbar .primary .action-gift {
      width: 100% !important; } }
  .account:not(.page-print):not(.form-create-account) .actions-toolbar .secondary {
    float: none;
    margin-left: 20px; }
    @media screen and (max-width: 540px) {
      .account:not(.page-print):not(.form-create-account) .actions-toolbar .secondary {
        margin-left: 0; } }
    .account:not(.page-print):not(.form-create-account) .actions-toolbar .secondary a {
      color: #d7aa02;
      font-size: 14px; }

.account:not(.page-print):not(.form-create-account) #swissup-address-fields-container {
  display: none; }

.account:not(.page-print):not(.form-create-account) .ui-datepicker-trigger {
  position: absolute;
  bottom: 0;
  right: 0px; }

.account:not(.page-print):not(.form-create-account) .swissup-customer-fields-container {
  display: none !important; }

.account:not(.page-print):not(.form-create-account) .items.addresses .item:not(.actions) {
  background-color: white;
  padding: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 200px;
  min-height: 100px;
  font-family: 'Droid Serif';
  font-size: 16px;
  line-height: 32px;
  color: #777;
  margin: 0 !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important; }
  @media screen and (max-width: 1055px) {
    .account:not(.page-print):not(.form-create-account) .items.addresses .item:not(.actions) {
      width: calc(100% - 60px); } }
  .account:not(.page-print):not(.form-create-account) .items.addresses .item:not(.actions) .item.actions a {
    color: #d7aa02; }
    .account:not(.page-print):not(.form-create-account) .items.addresses .item:not(.actions) .item.actions a span {
      color: #d7aa02;
      font-size: 14px;
      margin-right: 20px; }
    .account:not(.page-print):not(.form-create-account) .items.addresses .item:not(.actions) .item.actions a:hover {
      color: #d7aa02; }
      .account:not(.page-print):not(.form-create-account) .items.addresses .item:not(.actions) .item.actions a:hover span {
        color: #d7aa02;
        text-decoration: none; }

@media screen and (max-width: 1090px) {
  .sales-order-history .columns .main {
    width: 100% !important; } }

@media screen and (max-width: 1090px) {
  .sales-order-history .columns .sidebar {
    width: 100% !important; } }

.wishlist-index-index .block.block-wishlist {
  display: none !important; }

.wishlist-index-index .products-grid.wishlist {
  margin-right: 0 !important; }

.wishlist-index-index .actions-toolbar .primary button, .wishlist-index-index .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .wishlist-index-index .actions-toolbar .primary .actions-toolbar > .action, .wishlist-index-index .actions-toolbar .primary .action-gift {
  background-color: #d7aa02;
  border-radius: 0;
  border: none;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: white; }

@media screen and (max-width: 768px) {
  .wishlist-index-index .product-items {
    margin-left: 0 !important; } }

@media screen and (max-width: 768px) {
  .wishlist-index-index .product-item {
    width: calc(50% - 10px) !important;
    margin-right: 20px !important; }
    .wishlist-index-index .product-item:nth-child(2) {
      margin-right: 0 !important; }
    .wishlist-index-index .product-item .product-item-info .product-item-photo {
      float: none !important;
      margin-right: 0 !important;
      margin-bottom: 10px !important; }
      .wishlist-index-index .product-item .product-item-info .product-item-photo .product-image-container {
        max-width: 100% !important; }
    .wishlist-index-index .product-item .product-item-info .price-box.price-configured_price {
      margin-left: 0 !important; }
    .wishlist-index-index .product-item .product-item-info .product-item-name {
      font-size: 14px !important; } }

@media screen and (max-width: 639px) {
  .wishlist-index-index .product-item {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important; } }

.wishlist-index-index .product-item .price-details {
  margin-bottom: 20px; }

.wishlist-index-index .product-item .product-item-tooltip {
  display: none; }

.wishlist-index-index .product-item .field.qty {
  display: none !important; }

.wishlist-index-index .product-item .remove-item-from-wishlist {
  background-image: url(../images/delete_icon.svg);
  height: 24px;
  width: 24px;
  background-size: cover;
  background-position: center; }

.wishlist-index-index .product-item .product-item-inner .box-tocart {
  width: auto;
  display: inline-block; }

.wishlist-index-index .product-item .product-item-inner .product-item-actions {
  width: auto;
  display: inline-block;
  transform: unset !important;
  float: right;
  transform: translateY(4px) !important; }
  @media screen and (max-width: 767px) {
    .wishlist-index-index .product-item .product-item-inner .product-item-actions {
      transform: none !important; } }

.wishlist-index-index .fieldset {
  box-shadow: none;
  padding: 0; }

.sales-order-view .payment-method.purchase.order .content,
.sales-order-shipment .payment-method.purchase.order .content,
.sales-order-invoice .payment-method.purchase.order .content {
  display: none; }

.sales-order-shipment a.action.print {
  float: right; }

.sales-order-shipment .order-details-items.shipments .actions-toolbar {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px; }
  .sales-order-shipment .order-details-items.shipments .actions-toolbar .action.track {
    line-height: 40px; }

.sales-order-shipment .order-details-items.shipments .order-tracking {
  display: none !important; }

.sales-order-shipment .order-details-items.shipments .table-wrapper.order-items-shipment {
  margin-top: 10px; }

.sales-order-invoice a.action.print {
  float: right; }

.sales-order-invoice .order-details-items.invoice .actions-toolbar {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px; }

.checkout-index-index {
  font-family: 'Montserrat'; }
  .checkout-index-index form {
    width: 100% !important;
    max-width: unset !important; }
    .checkout-index-index form.form-login {
      border: none !important; }
    .checkout-index-index form .fieldset {
      width: 100%;
      max-width: unset;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 13px;
      margin: 0; }
      @media screen and (max-width: 768px) {
        .checkout-index-index form .fieldset {
          display: block; } }
      .checkout-index-index form .fieldset#customer-email-fieldset {
        grid-template-columns: 1fr; }
        .checkout-index-index form .fieldset#customer-email-fieldset .actions-toolbar {
          margin: 0; }
          .checkout-index-index form .fieldset#customer-email-fieldset .actions-toolbar .primary {
            width: 100%; }
            .checkout-index-index form .fieldset#customer-email-fieldset .actions-toolbar .primary button, .checkout-index-index form .fieldset#customer-email-fieldset .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .checkout-index-index form .fieldset#customer-email-fieldset .actions-toolbar .primary .actions-toolbar > .action, .checkout-index-index form .fieldset#customer-email-fieldset .actions-toolbar .primary .action-gift {
              width: 100%; }
          .checkout-index-index form .fieldset#customer-email-fieldset .actions-toolbar .secondary a {
            color: #d7aa02; }
      .checkout-index-index form .fieldset legend {
        display: none !important; }
    .checkout-index-index form .field-tooltip {
      display: none; }
    .checkout-index-index form .field {
      width: 100%;
      max-width: unset;
      position: relative;
      color: #777;
      margin: 0 0 30px;
      height: 32px; }
      @media screen and (max-width: 768px) {
        .checkout-index-index form .field {
          margin: 0 0 30px; } }
      .checkout-index-index form .field[name="shippingAddress.street.0"] label {
        padding: 6px 15px 0 0; }
        .checkout-index-index form .field[name="shippingAddress.street.0"] label:after {
          content: '*';
          color: #d7aa02;
          margin-left: 2px;
          position: absolute;
          font-size: 1.2rem; }
      .checkout-index-index form .field label {
        position: absolute !important;
        top: 0px;
        padding: 0 !important;
        font-family: 'Droid Serif';
        display: block !important;
        float: none;
        width: 100% !important;
        float: none !important;
        text-align: left !important;
        font-size: 16px;
        font-weight: 400 !important;
        z-index: 1;
        transition: 0.2s; }
        @media screen and (max-width: 700px) {
          .checkout-index-index form .field label {
            bottom: 0; } }
        .checkout-index-index form .field label:after {
          color: #d7aa02 !important;
          margin-left: 2px !important;
          top: unset !important; }
      .checkout-index-index form .field .control {
        width: 100% !important;
        display: block;
        position: relative;
        z-index: 10; }
        @media screen and (max-width: 540px) {
          .checkout-index-index form .field .control {
            width: 100%; } }
        .checkout-index-index form .field .control input,
        .checkout-index-index form .field .control select {
          appearance: none;
          border: none;
          border-bottom: 2px solid #aaa;
          width: 100%;
          display: block;
          background: transparent;
          padding: 0;
          font-family: 'Droid Serif';
          font-size: 16px;
          color: #777;
          transition: 0.2s; }
          .checkout-index-index form .field .control input:focus,
          .checkout-index-index form .field .control select:focus {
            box-shadow: none; }
          .checkout-index-index form .field .control input:placeholder-shown,
          .checkout-index-index form .field .control select:placeholder-shown {
            text-align: end; }
        .checkout-index-index form .field .control .field-error {
          display: none; }
      .checkout-index-index form .field.focused label {
        top: -24px;
        font-size: 14px;
        font-weight: 400 !important;
        transition: 0.2s; }
        @media screen and (max-width: 700px) {
          .checkout-index-index form .field.focused label {
            bottom: 30px; } }
      .checkout-index-index form .field.focused .control input,
      .checkout-index-index form .field.focused .control select {
        border-color: #d7aa02;
        color: #d7aa02;
        transition: 0.2s; }
        .checkout-index-index form .field.focused .control input:placeholder-shown,
        .checkout-index-index form .field.focused .control select:placeholder-shown {
          text-align: start; }
      .checkout-index-index form .field._error label {
        color: #e02b27; }
      .checkout-index-index form .field._error .control input,
      .checkout-index-index form .field._error .control select {
        border-color: #e02b27; }
  .checkout-index-index aside .modal-inner-wrap .modal-content {
    word-break: break-word;
    hyphens: auto; }
  @media screen and (max-width: 768px) {
    .checkout-index-index aside {
      left: 20px !important; }
      .checkout-index-index aside .modal-inner-wrap {
        height: 100vh !important; } }
  .checkout-index-index aside #opc-new-shipping-address {
    padding-top: 50px; }
    .checkout-index-index aside #opc-new-shipping-address .field.choice {
      grid-column-start: 1;
      grid-column-end: span 2;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      height: unset; }
      .checkout-index-index aside #opc-new-shipping-address .field.choice:before, .checkout-index-index aside #opc-new-shipping-address .field.choice:after {
        display: none !important; }
      .checkout-index-index aside #opc-new-shipping-address .field.choice input[type="checkbox"] {
        appearance: none;
        width: 25px;
        height: 25px;
        border: 1px solid #d7aa02;
        box-sizing: border-box;
        border-radius: 50%;
        position: relative;
        margin-right: 25px;
        flex-shrink: 0; }
        .checkout-index-index aside #opc-new-shipping-address .field.choice input[type="checkbox"]:checked:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: #d7aa02; }
      .checkout-index-index aside #opc-new-shipping-address .field.choice label {
        position: relative !important;
        top: unset;
        bottom: unset;
        font-size: 16px;
        font-weight: 700 !important;
        font-family: 'Montserrat'; }
  .checkout-index-index .page-main {
    background-color: #f4f4f4;
    padding-top: 25px; }
    @media screen and (max-width: 768px) {
      .checkout-index-index .page-main {
        padding-top: 15px; } }
    .checkout-index-index .page-main .page-title-wrapper {
      display: none; }
    .checkout-index-index .page-main .columns {
      max-width: 1240px;
      margin: 0 auto; }
      @media screen and (max-width: 1280px) {
        .checkout-index-index .page-main .columns {
          margin-left: 20px;
          margin-right: 20px; } }
      .checkout-index-index .page-main .columns .column.main .checkout-container {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-column-gap: 20px; }
        .checkout-index-index .page-main .columns .column.main .checkout-container .authentication-wrapper {
          display: none !important; }
        @media screen and (max-width: 768px) {
          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-estimated-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            margin: 0;
            grid-column-start: 1;
            grid-column-end: span 12;
            border: none;
            margin-bottom: 20px;
            background: white; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-estimated-wrapper:before, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-estimated-wrapper:after {
              display: none; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-estimated-wrapper .estimated-block {
              font-size: 16px;
              line-height: 120%;
              font-family: 'Droid Serif';
              font-weight: 400; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-estimated-wrapper .minicart-wrapper .showcart {
              background: #d7aa02;
              border: none;
              border-radius: 0; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-estimated-wrapper .minicart-wrapper .showcart:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-image: url(../images/Vallendar_Cart_m.png);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                filter: invert(1); }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-estimated-wrapper .minicart-wrapper .showcart .counter.qty {
                display: none; } }
        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar {
          display: contents;
          margin-bottom: 60px; }
          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item {
            width: 100%;
            margin-bottom: 60px; }
            @media screen and (max-width: 768px) {
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item {
                margin-bottom: 20px; } }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item:first-child {
              grid-column-start: 1;
              grid-column-end: span 6; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item:first-child span:after {
                content: '1'; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item:last-child {
              grid-column-start: 7;
              grid-column-end: span 6; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item:last-child span:after {
                content: '2'; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item:before {
              height: 1px;
              background: #777; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item span {
              color: #777;
              font-size: 18px;
              line-height: 150%;
              font-weight: 400;
              padding-top: 40px; }
              @media screen and (max-width: 768px) {
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item span {
                  font-size: 12px; } }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item span:before {
                width: 24px;
                height: 24px;
                margin-left: -12px;
                top: 8px;
                background: #777; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item span:after {
                color: #777;
                font-family: 'Montserrat';
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                width: 20px;
                height: 20px;
                margin-left: -10px;
                top: 10px; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._active:before, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._complete:before {
              background: #d7aa02; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._active span, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._complete span {
              color: #d7aa02;
              font-weight: 700; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._active span:before, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._complete span:before {
                background: #d7aa02; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._active span:after, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-progress-bar .opc-progress-bar-item._complete span:after {
                color: #d7aa02;
                font-family: 'Montserrat'; }
        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper {
          width: unset;
          max-width: unset;
          margin: 0;
          padding: 0;
          grid-column-start: 1;
          grid-column-end: span 7; }
          @media screen and (max-width: 1024px) {
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper {
              grid-column-start: 1;
              grid-column-end: span 12; } }
          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li {
            background: white;
            padding: 35px;
            margin-bottom: 35px;
            position: relative; }
            @media screen and (max-width: 768px) {
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li {
                padding: 20px;
                margin-bottom: 20px; } }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li .step-title {
              margin-left: 70px;
              border: none;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 24px;
              margin-bottom: 40px; }
              @media screen and (max-width: 768px) {
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li .step-title {
                  margin-bottom: 50px; } }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li .step-title:before {
                position: absolute;
                left: 35px;
                top: 0;
                background: #d7aa02;
                color: white;
                width: 53px;
                height: 62px;
                display: flex;
                justify-content: center;
                align-items: center; }
                @media screen and (max-width: 768px) {
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li .step-title:before {
                    left: 20px; } }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li .step-content {
              margin: 0; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .step-title:before {
              content: '1'; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .shipping-address-items .shipping-address-item {
              padding: 15px 35px 15px 18px; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .shipping-address-items .shipping-address-item.selected-item {
                border-color: #d7aa02; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .shipping-address-items .shipping-address-item.selected-item:after {
                  background: #d7aa02; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .shipping-address-items .action-select-shipping-item {
              float: right;
              margin: 0;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              color: transparent;
              background: transparent;
              border-color: transparent; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .shipping-address-items .action-select-shipping-item:hover {
                border-color: #d7aa02; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .new-address-popup button, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .new-address-popup .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .new-address-popup .actions-toolbar > .action, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .new-address-popup .action-gift {
              border: none;
              border-radius: 0;
              background: #d7aa02;
              color: white;
              font-weight: 500;
              text-transform: uppercase; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#shipping .edit-address-link span {
              color: #d7aa02; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .step-title {
              margin-bottom: 10px !important;
              padding-bottom: 10px !important; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .step-title:before {
                content: '2'; }
              @media screen and (max-width: 768px) {
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .step-title {
                  margin-bottom: 20px !important; } }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .checkout-shipping-method {
              position: unset; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .checkout-shipping-method .table-checkout-shipping-method {
                font-size: 16px;
                width: 100%;
                display: block; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .checkout-shipping-method .table-checkout-shipping-method tbody tr td {
                  padding-top: 10px;
                  padding-bottom: 10px; }
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .checkout-shipping-method .table-checkout-shipping-method tbody tr td.col-price {
                    width: 50px; }
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .checkout-shipping-method .table-checkout-shipping-method tbody tr td.col-method:last-of-type {
                    display: none; }
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .checkout-shipping-method .table-checkout-shipping-method tbody tr td.col-method input[type="radio"] {
                    appearance: none;
                    width: 25px;
                    height: 25px;
                    border: 1px solid #d7aa02;
                    box-sizing: border-box;
                    border-radius: 50%;
                    position: relative; }
                    .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#opc-shipping_method .checkout-shipping-method .table-checkout-shipping-method tbody tr td.col-method input[type="radio"]:checked:after {
                      content: '';
                      position: absolute;
                      top: 5px;
                      left: 5px;
                      width: 13px;
                      height: 13px;
                      border-radius: 50%;
                      background: #d7aa02; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-title {
              margin-bottom: 30px; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-title:before {
                content: '3'; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content {
              display: contents; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form {
                display: contents; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset {
                  display: contents; }
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset > br {
                    display: none; }
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment {
                    display: contents; }
                    .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods {
                      display: contents; }
                      .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method {
                        padding: 25px 0;
                        border-bottom: 1px solid #9BA2A7; }
                        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method:last-child {
                          border-bottom: none; }
                        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-title {
                          padding: 0;
                          margin: 0;
                          display: flex;
                          align-items: center;
                          border: none;
                          height: unset; }
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-title input[type="radio"] {
                            appearance: none;
                            width: 25px;
                            height: 25px;
                            border: 1px solid #d7aa02;
                            box-sizing: border-box;
                            border-radius: 50%;
                            position: relative;
                            margin-right: 25px;
                            flex-shrink: 0; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-title input[type="radio"]:checked:after {
                              content: '';
                              position: absolute;
                              top: 5px;
                              left: 5px;
                              width: 13px;
                              height: 13px;
                              border-radius: 50%;
                              background: #d7aa02; }
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-title label {
                            position: relative !important;
                            top: unset;
                            bottom: unset;
                            font-size: 16px;
                            font-weight: 700 !important;
                            font-family: 'Montserrat'; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-title label img {
                              display: none !important; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-title label .action-help {
                              display: none !important; }
                        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content {
                          padding: 0;
                          padding-top: 20px;
                          font-size: 16px;
                          color: #777; }
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-image,
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-description,
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-note {
                            display: none; }
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address {
                            margin: 0; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > .field {
                              display: flex;
                              align-items: center;
                              margin-bottom: 10px;
                              height: unset; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > .field input[type="checkbox"] {
                                appearance: none;
                                width: 25px;
                                height: 25px;
                                border: 1px solid #d7aa02;
                                box-sizing: border-box;
                                border-radius: 50%;
                                position: relative;
                                margin-right: 25px;
                                flex-shrink: 0; }
                                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > .field input[type="checkbox"]:checked:after {
                                  content: '';
                                  position: absolute;
                                  top: 5px;
                                  left: 5px;
                                  width: 13px;
                                  height: 13px;
                                  border-radius: 50%;
                                  background: #d7aa02; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > .field label {
                                position: relative !important;
                                top: unset;
                                bottom: unset;
                                font-size: 16px;
                                font-weight: 700 !important;
                                font-family: 'Montserrat'; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address .billing-address-details {
                              padding-left: 50px; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset {
                              display: block;
                              padding-left: 50px;
                              margin-top: 40px;
                              box-sizing: border-box;
                              margin-bottom: 50px; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .field-select-billing {
                                margin: unset;
                                height: unset; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .billing-address-form {
                                max-width: unset; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .actions-toolbar {
                                margin: 0; }
                                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .actions-toolbar .primary {
                                  float: unset;
                                  display: grid;
                                  grid-template-columns: repeat(2, minmax(0, 1fr));
                                  grid-column-gap: 30px; }
                                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .actions-toolbar .primary .action-update,
                                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .actions-toolbar .primary .action-cancel {
                                    border-radius: 0;
                                    border: none;
                                    font-family: 'Montserrat';
                                    text-transform: uppercase;
                                    color: white;
                                    margin: 0; }
                                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .actions-toolbar .primary .action-update {
                                    background-color: #d7aa02; }
                                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .payment-method-billing-address .checkout-billing-address > fieldset .actions-toolbar .primary .action-cancel {
                                    background-color: #777; }
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content p[data-bind="html: getInstructions()"] {
                            padding-left: 50px;
                            margin-bottom: 50px; }
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements {
                            display: block; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field {
                              display: flex;
                              align-items: center;
                              margin-bottom: 20px;
                              height: unset; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field:before {
                                display: none; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field input[type="checkbox"] {
                                appearance: none;
                                width: 25px;
                                height: 25px;
                                border: 1px solid #d7aa02;
                                box-sizing: border-box;
                                border-radius: 50%;
                                position: relative;
                                margin-right: 25px;
                                flex-shrink: 0; }
                                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field input[type="checkbox"]:checked:after {
                                  content: '';
                                  position: absolute;
                                  top: 5px;
                                  left: 5px;
                                  width: 13px;
                                  height: 13px;
                                  border-radius: 50%;
                                  background: #d7aa02; }
                              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label {
                                position: relative !important;
                                top: unset;
                                bottom: unset;
                                font-size: 16px;
                                font-weight: 700 !important;
                                font-family: 'Montserrat'; }
                                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label:after {
                                  display: none; }
                                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label button, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label .actions-toolbar > .action, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label .action-gift {
                                  text-align: start; }
                                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label button span, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label .cart.table-wrapper .actions-toolbar > .action span, .cart.table-wrapper .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label .actions-toolbar > .action span, .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .opc-payment .payment-methods .payment-group .payment-method .payment-method-content .checkout-agreements-block .checkout-agreements .field label .action-gift span {
                                    color: #d7aa02;
                                    text-decoration: underline;
                                    font-size: 14px; }
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code {
                    margin: 0; }
                    .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-title {
                      display: none; }
                    .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content {
                      display: block !important;
                      padding: 0; }
                      .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content .form-discount {
                        display: grid;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        grid-column-gap: 30px;
                        margin-top: 40px;
                        max-width: unset; }
                        @media screen and (max-width: 768px) {
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content .form-discount {
                            grid-template-columns: 1fr;
                            grid-row-gap: 20px; } }
                        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content .form-discount .payment-option-inner {
                          margin: 0;
                          margin-top: 8px; }
                          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content .form-discount .payment-option-inner .field {
                            margin: 0; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content .form-discount .payment-option-inner .field label {
                              height: unset;
                              clip: unset;
                              overflow: unset; }
                            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content .form-discount .payment-option-inner .field input::placeholder {
                              color: transparent; }
                        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-wrapper .opc li#payment .step-content #co-payment-form > fieldset .discount-code .payment-option-content .form-discount .actions-toolbar .primary {
                          width: 100%;
                          height: 100%;
                          font-size: 18px;
                          font-weight: 500; }
        .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar {
          width: unset;
          max-width: unset;
          margin-top: 0;
          grid-column-start: 9;
          grid-column-end: span 4; }
          @media screen and (max-width: 1200px) {
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar {
              grid-column-start: 8;
              grid-column-end: span 5; } }
          @media screen and (max-width: 1024px) {
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar {
              grid-column-start: 1;
              grid-column-end: span 12; } }
          @media screen and (max-width: 767px) {
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar {
              left: 20px; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .modal-inner-wrap {
                background: white; } }
          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary {
            background: white;
            padding: 35px;
            margin-bottom: 35px; }
            @media screen and (max-width: 768px) {
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary {
                padding: 20px; } }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary > .title {
              border: none;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 24px;
              margin-bottom: 40px;
              margin-bottom: 25px; }
              @media screen and (max-width: 768px) {
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary > .title {
                  margin-bottom: 50px; } }
              @media screen and (max-width: 768px) {
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary > .title {
                  margin-bottom: 25px; } }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals {
              font-size: 16px; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr th {
                padding-left: 0; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr td {
                padding-right: 0; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.grand.totals.incl th,
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.grand.totals.incl td {
                font-weight: 700;
                border-top: 1px solid #777;
                padding-bottom: 20px;
                padding-top: 20px; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.grand.totals.incl th strong,
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.grand.totals.incl td strong {
                  font-weight: 700; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.grand.totals.excl th,
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.grand.totals.excl td {
                font-size: 16px;
                border-top: 1px solid #777;
                padding-top: 20px;
                padding-bottom: 0; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.grand.totals.excl th {
                color: #777; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals.shipping.incl th {
                color: #777; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals.shipping.incl th .value {
                  display: none; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals.shipping.incl th,
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals.shipping.incl td {
                padding-bottom: 20px; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals.sub th,
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals.sub td {
                padding-top: 0;
                padding-bottom: 0; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals.sub th {
                color: #777; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals-tax th {
                color: #777; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals-tax th,
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .table-totals tr.totals-tax td {
                padding-bottom: 20px; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .block.items-in-cart {
              border-top: 1px solid #777;
              border-bottom: 1px solid #777; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .block.items-in-cart .title {
                border: none;
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 700;
                padding: 20px 40px 20px 0; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .block.items-in-cart .title:after {
                  top: 20px; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .block.items-in-cart .title strong {
                  font-size: inherit;
                  font-weight: inherit; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .block.items-in-cart .content.minicart-items {
                padding-bottom: 20px; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .minicart-items-wrapper {
              margin: 0;
              padding: 0; }
              @media screen and (max-width: 768px) {
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-summary .minicart-items-wrapper {
                  max-height: unset; } }
          .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-shipping-information {
            padding: 0; }
            .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-shipping-information .shipping-information {
              padding: 35px;
              background: white; }
              @media screen and (max-width: 768px) {
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-shipping-information .shipping-information {
                  padding: 20px; } }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-shipping-information .shipping-information .shipping-information-title {
                font-family: 'Montserrat';
                font-weight: 700;
                text-transform: uppercase;
                font-size: 24px;
                border: none;
                padding: 0; }
                .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-shipping-information .shipping-information .shipping-information-title .action-edit {
                  top: 0; }
                  .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-shipping-information .shipping-information .shipping-information-title .action-edit:before {
                    content: '';
                    background-image: url(../images/pencil.svg);
                    background-repeat: no-repeat;
                    width: 27px;
                    height: 27px;
                    background-size: 27px 27px; }
              .checkout-index-index .page-main .columns .column.main .checkout-container .opc-sidebar .opc-block-shipping-information .shipping-information .shipping-information-content {
                font-size: 16px;
                color: #777; }

.checkout-cart-index .paypal.checkout {
  display: none; }

.checkout-cart-index .page-main {
  background-color: #f4f4f4; }
  .checkout-cart-index .page-main .page-title-wrapper {
    margin-left: 100px;
    padding-left: 20px;
    padding-top: 100px;
    padding-bottom: 15px; }
    @media screen and (max-width: 1280px) {
      .checkout-cart-index .page-main .page-title-wrapper {
        margin-left: 70px; } }
    @media screen and (max-width: 768px) {
      .checkout-cart-index .page-main .page-title-wrapper {
        margin-left: 85px; } }
    @media screen and (max-width: 576px) {
      .checkout-cart-index .page-main .page-title-wrapper {
        margin-left: 20px; } }
    @media screen and (max-width: 540px) {
      .checkout-cart-index .page-main .page-title-wrapper {
        padding-top: 40px; } }
    .checkout-cart-index .page-main .page-title-wrapper .page-title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-weight: 800;
      font-size: 28px; }
  .checkout-cart-index .page-main .columns .column.main .cart-container .cart-summary {
    background-color: white;
    padding: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family: 'Droid Serif';
    width: 27% !important;
    top: 0 !important; }
    @media screen and (max-width: 1090px) {
      .checkout-cart-index .page-main .columns .column.main .cart-container .cart-summary {
        float: unset !important;
        width: 100% !important; } }
    .checkout-cart-index .page-main .columns .column.main .cart-container .cart-summary .summary.title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-weight: 800; }
    .checkout-cart-index .page-main .columns .column.main .cart-container .cart-summary .block .title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      padding-left: 0; }
      .checkout-cart-index .page-main .columns .column.main .cart-container .cart-summary .block .title strong {
        font-size: 14px;
        font-weight: 500; }
    .checkout-cart-index .page-main .columns .column.main .cart-container .cart-summary .block .estimate .field.note {
      display: none !important; }
  .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart {
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
    width: 70% !important; }
    @media screen and (max-width: 1090px) {
      .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart {
        float: unset !important;
        width: 100% !important; } }
    .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper {
      font-family: 'Droid Serif'; }
      .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart {
        border-bottom: 1px solid #ddd; }
      .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper thead {
        font-family: 'Montserrat';
        text-transform: uppercase;
        border-bottom: 1px solid #ddd; }
        .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper thead tr .col.item {
          padding-left: 20px !important; }
        .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper thead tr .col.subtotal {
          padding-right: 20px !important; }
      .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart.item {
        border-bottom: 1px solid #ddd;
        border-top: none !important; }
        .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart.item .item-info .col.item {
          padding-top: 20px !important;
          padding-left: 20px !important; }
          .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart.item .item-info .col.item .product-item-details {
            padding-top: 0 !important; }
            .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart.item .item-info .col.item .product-item-details .product-item-name {
              font-weight: 800; }
        .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart.item .item-info .col.subtotal {
          padding-right: 20px !important; }
        .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart.item .item-actions td {
          padding-left: 20px !important;
          padding-right: 20px !important; }
    .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .main.actions {
      padding: 20px; }
      .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .main.actions .action {
        background-color: #d7aa02;
        border: none;
        -webkit-appearance: unset;
        color: white;
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-weight: 500;
        border-radius: 0px;
        padding: 10px;
        padding-left: 15px;
        padding-right: 15px; }
    .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .action {
      background-color: transparent;
      border: 1px solid #bbb;
      -webkit-appearance: unset;
      color: black;
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-weight: 400;
      border-radius: 0px;
      padding: 10px;
      padding-left: 15px;
      padding-right: 15px; }

.page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) .page-wrapper .page-main {
  background-color: #f4f4f4;
  padding-bottom: 1px; }
  .page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) .page-wrapper .page-main .page-title-wrapper {
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 20px;
    padding-top: 100px;
    padding-bottom: 15px; }
    @media screen and (max-width: 1280px) {
      .page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) .page-wrapper .page-main .page-title-wrapper {
        margin-left: 70px;
        margin-right: 85px; } }
    @media screen and (max-width: 768px) {
      .page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) .page-wrapper .page-main .page-title-wrapper {
        margin-left: 85px;
        margin-right: 85px; } }
    @media screen and (max-width: 576px) {
      .page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) .page-wrapper .page-main .page-title-wrapper {
        margin-left: 20px;
        margin-right: 20px; } }
    .page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) .page-wrapper .page-main .page-title-wrapper .page-title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 28px; }
  .page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) .page-wrapper .page-main .columns .column.main {
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 100px;
    font-family: 'Droid Serif'; }

.age-verification-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  max-width: unset;
  background-color: white;
  z-index: 999;
  display: none;
  background-color: rgba(0, 0, 0, 0.5); }
  .age-verification-wrapper .a, .age-verification-wrapper a {
    display: inline-block; }
  .age-verification-wrapper.show {
    display: block; }
  .age-verification-wrapper .logo {
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 140px; }
  .age-verification-wrapper .verification {
    width: 50%;
    height: 0px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    text-align: center; }
    @media screen and (max-width: 680px) {
      .age-verification-wrapper .verification {
        width: 80%; } }
    @media screen and (max-width: 576px) {
      .age-verification-wrapper .verification {
        width: 90%;
        height: 300px; } }
    .age-verification-wrapper .verification .bg-image {
      position: absolute;
      top: -280px;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1; }
      @media screen and (max-width: 576px) {
        .age-verification-wrapper .verification .bg-image {
          width: 200px;
          margin: 0 auto;
          top: 180px !important; } }
      @media screen and (max-height: 740px) {
        .age-verification-wrapper .verification .bg-image {
          width: 200px;
          margin: 0 auto;
          top: -220px; } }
    .age-verification-wrapper .verification .wrap {
      z-index: 2;
      position: relative;
      background: white;
      padding: 35px 0;
      margin: -30% 0; }
      .age-verification-wrapper .verification .wrap:before {
        content: "";
        background-image: url(../images/age_paper_left.png);
        left: 0;
        bottom: 0;
        height: 100%;
        width: 70px;
        position: absolute;
        background-size: contain;
        background-position-y: bottom;
        background-position-x: left;
        background-repeat: no-repeat; }
        @media screen and (max-width: 1200px) {
          .age-verification-wrapper .verification .wrap:before {
            width: 35px; } }
      .age-verification-wrapper .verification .wrap:after {
        content: "";
        background-image: url(../images/age_paper_right.png);
        right: 0;
        top: 0;
        height: 100%;
        width: 70px;
        position: absolute;
        background-size: contain;
        background-position-y: top;
        background-position-x: right;
        background-repeat: no-repeat; }
        @media screen and (max-width: 1200px) {
          .age-verification-wrapper .verification .wrap:after {
            width: 35px; } }
      @media screen and (max-width: 768px) {
        .age-verification-wrapper .verification .wrap {
          margin: 0; } }
      .age-verification-wrapper .verification .wrap .title {
        font-family: 'Droid Serif';
        font-size: 20px;
        color: #555;
        line-height: 32px;
        padding-bottom: 30px;
        margin: 0 70px; }
        @media screen and (max-width: 1200px) {
          .age-verification-wrapper .verification .wrap .title {
            margin: 0 35px; } }
        @media screen and (max-width: 640px) {
          .age-verification-wrapper .verification .wrap .title {
            font-size: 16px;
            line-height: 28px; } }
      .age-verification-wrapper .verification .wrap .buttons {
        text-align: center; }
        .age-verification-wrapper .verification .wrap .buttons .a:hover, .age-verification-wrapper .verification .wrap .buttons a:hover {
          text-decoration: none; }
        .age-verification-wrapper .verification .wrap .buttons .a.age-invalid, .age-verification-wrapper .verification .wrap .buttons a.age-invalid {
          margin-left: 18px; }
          @media screen and (max-width: 640px) {
            .age-verification-wrapper .verification .wrap .buttons .a.age-invalid, .age-verification-wrapper .verification .wrap .buttons a.age-invalid {
              margin-left: 8px; } }
        .age-verification-wrapper .verification .wrap .buttons .a.age-valid, .age-verification-wrapper .verification .wrap .buttons a.age-valid {
          margin-right: 18px; }
          @media screen and (max-width: 640px) {
            .age-verification-wrapper .verification .wrap .buttons .a.age-valid, .age-verification-wrapper .verification .wrap .buttons a.age-valid {
              margin-right: 8px; } }
        .age-verification-wrapper .verification .wrap .buttons .verification-button {
          width: 240px;
          height: 40px;
          font-size: 22px;
          font-weight: 500; }
          @media screen and (max-width: 1200px) {
            .age-verification-wrapper .verification .wrap .buttons .verification-button {
              width: 140px; } }
          @media screen and (max-width: 640px) {
            .age-verification-wrapper .verification .wrap .buttons .verification-button {
              width: 100px;
              height: 30px;
              font-size: 16px; } }
  .age-verification-wrapper #navbar-search {
    display: none !important; }
  .age-verification-wrapper .action.nav-toggle {
    display: none !important; }
  .age-verification-wrapper .page-main {
    background-color: #eee; }
  .age-verification-wrapper .verification-wrapper {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px; }
    @media screen and (max-width: 576px) {
      .age-verification-wrapper .verification-wrapper {
        padding-top: 10px;
        padding-bottom: 10px; } }
    .age-verification-wrapper .verification-wrapper .inner-wrapper {
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      padding: 60px 20px;
      margin: 10px; }
      .age-verification-wrapper .verification-wrapper .inner-wrapper .title {
        font-family: 'Droid Serif';
        color: #777;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 30px; }
      .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons {
        margin-bottom: 40px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap; }
        .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons .a, .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons a {
          padding: 20px;
          color: white;
          font-family: 'Montserrat';
          border-radius: 2px;
          width: 220px;
          margin: 10px; }
          .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons .a.age-invalid, .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons a.age-invalid {
            background-color: #ee5a5a; }
            .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons .a.age-invalid:hover, .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons a.age-invalid:hover {
              background-color: #f16868; }
          .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons .a.age-valid, .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons a.age-valid {
            background-color: #d7aa02; }
            .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons .a.age-valid:hover, .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons a.age-valid:hover {
              background-color: #e1b202; }
          .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons .a:hover, .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons a:hover {
            text-decoration: none; }
          .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons .a strong, .age-verification-wrapper .verification-wrapper .inner-wrapper .buttons a strong {
            display: block;
            color: white;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 10px; }
      .age-verification-wrapper .verification-wrapper .inner-wrapper .info {
        font-family: 'Droid Serif';
        color: #777;
        font-size: 14px;
        line-height: 24px; }
        .age-verification-wrapper .verification-wrapper .inner-wrapper .info .a, .age-verification-wrapper .verification-wrapper .inner-wrapper .info a {
          color: #d7aa02; }

.amgdprcookie-modal-container {
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: Montserrat;
  color: #333333;
  line-height: 150%;
  /* toggle switch */ }
  .amgdprcookie-modal-container .modal-inner-wrap {
    max-width: 900px; }
    @media screen and (min-width: 1440px) {
      .amgdprcookie-modal-container .modal-inner-wrap {
        width: 50%; } }
  .amgdprcookie-modal-container h1 {
    font-family: Noto Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: #333333; }
    @media screen and (max-width: 767px) {
      .amgdprcookie-modal-container h1 {
        font-size: 30px; } }
  .amgdprcookie-modal-container .amgdprcookie-policy-container {
    margin-bottom: 30px; }
  .amgdprcookie-modal-container .amgdprcookie-groups-container {
    height: auto !important; }
    .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal {
      margin-bottom: 30px; }
      .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal .amgdprcookie-header {
        text-transform: uppercase;
        display: table;
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #333333; }
        .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal .amgdprcookie-header .amgdprcookie-caption {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          margin: 0;
          color: #333333;
          display: table-cell;
          vertical-align: bottom; }
        .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal .amgdprcookie-header .amgdprcookie-link {
          cursor: pointer;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          display: table-cell;
          vertical-align: bottom;
          text-align: right; }
      .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal .amgdprcookie-content {
        clear: both;
        display: table;
        width: 100%;
        padding: 10px 0; }
        .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal .amgdprcookie-content .amgdprcookie-text {
          display: table-cell;
          width: 85%; }
          @media screen and (max-width: 767px) {
            .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal .amgdprcookie-content .amgdprcookie-text {
              width: 65%; } }
        .amgdprcookie-modal-container .amgdprcookie-groups-container .amgdprcookie-cookie-container.-modal .amgdprcookie-content .amgdprcookie-toggle-cookie {
          display: table-cell; }
  .amgdprcookie-modal-container .amgdprcookie-buttons-block {
    margin-top: 50px; }
    .amgdprcookie-modal-container .amgdprcookie-buttons-block .amgdprcookie-button {
      background: #E1B203;
      border: none;
      border-radius: 0;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      width: 49%;
      min-height: 35px; }
      .amgdprcookie-modal-container .amgdprcookie-buttons-block .amgdprcookie-button.-save {
        float: left; }
      .amgdprcookie-modal-container .amgdprcookie-buttons-block .amgdprcookie-button.-allow {
        float: right; }
  .amgdprcookie-modal-container .modal-content {
    padding: 0 2.6rem 5.6rem; }
  .amgdprcookie-modal-container .amgdprcookie-toggle-cookie {
    position: relative;
    padding-left: 50px; }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input {
      visibility: hidden;
      width: 0;
      height: 0; }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-label {
      position: absolute;
      top: 0;
      right: 0;
      width: 85px;
      height: 40px;
      border-radius: 40px;
      background: #a5a5a5;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
      cursor: pointer; }
      @media screen and (max-width: 767px) {
        .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-label {
          width: 50px;
          height: 29px; } }
      .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-label:after {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #fff;
        content: '';
        transition: all .4s ease; }
        @media screen and (max-width: 767px) {
          .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-label:after {
            width: 25px;
            height: 25px; } }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:disabled + .amgdprcookie-label {
      background: #e8e8e8; }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:disabled + .amgdprcookie-label:after {
      left: 95%;
      transform: translateX(-100%); }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
      background: #E1B203; }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label:after {
      left: 95%;
      transform: translateX(-100%); }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input:not([checked]) + .amgdprcookie-label {
      background: #a5a5a5; }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input:disabled + .amgdprcookie-label {
      background: #e8e8e8; }
    .amgdprcookie-modal-container .amgdprcookie-toggle-cookie .amgdprcookie-input:disabled + .amgdprcookie-label:after {
      left: 2px; }

.page-layout-blog_page_layout.blog-index-index .blog-post-list-item .blog-post-item-content img {
  display: none; }

.page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper {
  max-width: 1240px; }
  @media screen and (max-width: 1440px) {
    .page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper {
      margin-left: 100px;
      margin-right: 100px; } }
  @media screen and (max-width: 1280px) {
    .page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper {
      margin-left: 85px;
      margin-right: 85px; } }
  @media screen and (max-width: 576px) {
    .page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper {
      margin-left: 20px;
      margin-right: 20px; } }
  .page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper .blog-post-list-item {
    padding-bottom: 20px;
    border-bottom: 2px solid #ccc; }
    .page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper .blog-post-list-item:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    .page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper .blog-post-list-item .content {
      width: 100%; }
      .page-layout-blog_page_layout.blog-index-index #maincontent .blog-wrapper .blog-post-list-item .content .blog-post-item-date {
        text-align: left !important;
        margin-bottom: 10px !important; }

.page-layout-blog_page_layout #maincontent {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative; }
  .page-layout-blog_page_layout #maincontent .blog-wrapper {
    padding-bottom: 60px;
    max-width: 600px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .page-layout-blog_page_layout #maincontent .blog-wrapper {
        margin-left: 85px;
        margin-right: 85px; } }
    @media screen and (max-width: 576px) {
      .page-layout-blog_page_layout #maincontent .blog-wrapper {
        margin-left: 20px;
        margin-right: 20px; } }
    .page-layout-blog_page_layout #maincontent .blog-wrapper.blog-wrapper-wide {
      max-width: 1000px !important; }
      @media screen and (max-width: 1200px) {
        .page-layout-blog_page_layout #maincontent .blog-wrapper.blog-wrapper-wide {
          max-width: 100% !important;
          margin-left: 85px !important;
          margin-right: 85px !important; } }
      @media screen and (max-width: 576px) {
        .page-layout-blog_page_layout #maincontent .blog-wrapper.blog-wrapper-wide {
          margin-left: 20px !important;
          margin-right: 20px !important; } }
    .page-layout-blog_page_layout #maincontent .blog-wrapper .page-title-wrapper {
      display: block !important;
      max-width: 1240px;
      margin: 0 auto;
      padding-top: 40px;
      padding-bottom: 10px;
      font-family: 'Montserrat';
      font-weight: 800;
      text-transform: uppercase; }
      @media screen and (max-width: 576px) {
        .page-layout-blog_page_layout #maincontent .blog-wrapper .page-title-wrapper {
          padding-top: 20px; } }
      .page-layout-blog_page_layout #maincontent .blog-wrapper .page-title-wrapper h1 {
        font-size: 28px;
        font-weight: 800;
        margin-bottom: 20px;
        text-align: center; }
      .page-layout-blog_page_layout #maincontent .blog-wrapper .page-title-wrapper .caption {
        font-family: 'Droid Serif';
        text-align: center;
        font-style: italic;
        color: #777;
        font-size: 14px;
        font-weight: 400;
        width: 70%;
        margin: 0 auto; }
    .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item {
      margin-bottom: 40px;
      display: flex;
      flex-direction: row; }
      @media screen and (max-width: 900px) {
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item {
          flex-direction: column; } }
      .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .image-link {
        flex: 0 0 300px;
        height: auto;
        background-size: cover;
        background-position: center;
        background-color: #f2f2f2; }
        @media screen and (max-width: 900px) {
          .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .image-link {
            width: 100%;
            flex: 0 0 300px;
            margin-bottom: 20px; } }
        @media screen and (max-width: 576px) {
          .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .image-link {
            flex: 0 0 200px; } }
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .image-link .image {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center; }
      .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content {
        padding-left: 20px; }
        @media screen and (max-width: 768px) {
          .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content {
            padding-left: 0; } }
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content p, .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content span {
          font-family: 'Droid Serif' !important;
          font-size: 16px !important;
          color: #777 !important;
          line-height: 28px !important;
          font-weight: 400 !important;
          text-align: left !important; }
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content strong {
          font-family: 'Droid Serif' !important;
          font-size: 16px !important;
          color: #777 !important;
          line-height: 28px !important;
          font-weight: 800 !important;
          text-align: left !important; }
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content.no-image {
          padding-left: 0; }
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content .blog-post-item-date {
          font-family: 'Droid Serif';
          font-size: 14px;
          color: #777;
          margin-bottom: 40px;
          text-align: center; }
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content .blog-post-item-title {
          font-family: 'Montserrat';
          font-weight: 800;
          text-transform: uppercase;
          font-size: 18px;
          margin-bottom: 15px;
          margin-top: 0; }
          .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content .blog-post-item-title a {
            color: #333; }
        .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content .blog-post-item-content {
          font-family: 'Droid Serif';
          line-height: 28px;
          font-size: 16px;
          color: #777; }
          .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content .blog-post-item-content a {
            color: #777; }
            .page-layout-blog_page_layout #maincontent .blog-wrapper .blog-post-list .blog-post-list-item .content .blog-post-item-content a:hover {
              text-decoration: none; }

.photo-album {
  margin: 0 auto; }
  .photo-album .album-item {
    width: calc(33.33% - 6px);
    margin-bottom: 7px;
    cursor: pointer; }
    @media screen and (max-width: 1198px) {
      .photo-album .album-item {
        width: calc(50% - 5px); } }
    @media screen and (max-width: 795px) {
      .photo-album .album-item {
        width: 100%; } }
    .photo-album .album-item .album-image {
      width: 100%; }
    .photo-album .album-item .all-products-block {
      height: 200px;
      background-color: #eee;
      padding-top: 50px;
      margin-bottom: 3px; }
      .photo-album .album-item .all-products-block a:hover {
        text-decoration: none; }
      .photo-album .album-item .all-products-block img {
        mix-blend-mode: multiply; }
      .photo-album .album-item .all-products-block .link {
        font-family: 'Montserrat';
        text-transform: uppercase;
        color: black;
        font-size: 16px;
        font-weight: 800;
        margin-top: 8px; }

.other-albums {
  display: flex;
  justify-content: space-between;
  margin-top: 60px; }
  .other-albums a.other-album {
    display: inline-block; }
    .other-albums a.other-album .image-bg {
      position: relative;
      background-size: cover;
      height: 260px;
      width: auto;
      margin: 10px;
      background-position: center;
      background-repeat: no-repeat; }
      .other-albums a.other-album .image-bg:hover {
        box-shadow: inset 0px 0px 0px 5px #d7aa02; }
        .other-albums a.other-album .image-bg:hover .title {
          padding-left: 25px;
          padding-right: 25px;
          width: calc(100% - 60px);
          padding-bottom: 15px;
          margin-left: 5px;
          margin-bottom: 5px;
          margin-right: 5px; }
      .other-albums a.other-album .image-bg .title {
        color: white;
        position: absolute;
        margin-bottom: 0;
        hyphens: auto;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 24px;
        font-family: 'Droid Serif';
        text-transform: initial;
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        width: calc(100% - 60px);
        margin-top: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); }

.lightbox {
  margin-top: 1000%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 99999;
  transition: margin-top 1.5s; }
  .lightbox .full-image-slider {
    width: 1200px;
    height: auto;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%); }
    @media screen and (max-width: 1280px) {
      .lightbox .full-image-slider {
        width: 900px; } }
    @media screen and (max-width: 991px) {
      .lightbox .full-image-slider {
        width: 700px; } }
    @media screen and (max-width: 767px) {
      .lightbox .full-image-slider {
        width: 500px; } }
    @media screen and (max-width: 575px) {
      .lightbox .full-image-slider {
        width: 320px; } }
    .lightbox .full-image-slider .image-strip {
      width: calc(100% * 2); }
      .lightbox .full-image-slider .image-strip .img-wrapper {
        height: 50vh;
        width: 1200px;
        float: left;
        position: relative; }
        @media screen and (max-width: 1280px) {
          .lightbox .full-image-slider .image-strip .img-wrapper {
            width: 900px; } }
        @media screen and (max-width: 991px) {
          .lightbox .full-image-slider .image-strip .img-wrapper {
            width: 700px; } }
        @media screen and (max-width: 767px) {
          .lightbox .full-image-slider .image-strip .img-wrapper {
            width: 500px; } }
        @media screen and (max-width: 575px) {
          .lightbox .full-image-slider .image-strip .img-wrapper {
            width: 320px; } }
        .lightbox .full-image-slider .image-strip .img-wrapper img {
          display: block;
          height: 100%;
          max-height: 50vh;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto; }
  .lightbox .thumbnails-wrapper {
    width: 100%;
    position: absolute;
    bottom: 4vh; }
    .lightbox .thumbnails-wrapper .thumbnails {
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .lightbox .thumbnails-wrapper .thumbnails .thumbnail {
        margin: 10px;
        width: 100px;
        height: 100px;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }
        @media screen and (max-width: 1280px) {
          .lightbox .thumbnails-wrapper .thumbnails .thumbnail {
            width: 80px;
            height: 80px; } }
        @media screen and (max-width: 991px) {
          .lightbox .thumbnails-wrapper .thumbnails .thumbnail {
            width: 60px;
            height: 60px; } }
        @media screen and (max-width: 767px) {
          .lightbox .thumbnails-wrapper .thumbnails .thumbnail {
            width: 50px;
            height: 50px; } }
        @media screen and (max-width: 575px) {
          .lightbox .thumbnails-wrapper .thumbnails .thumbnail {
            width: 30px;
            height: 30px; } }
        .lightbox .thumbnails-wrapper .thumbnails .thumbnail:hover {
          box-shadow: inset 0px 0px 0px 2px white; }
      .lightbox .thumbnails-wrapper .thumbnails .item-selected {
        box-shadow: inset 0px 0px 0px 2px white; }
  .lightbox .lightbox-close {
    float: right;
    margin-right: 40px;
    margin-top: 40px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer; }
    .lightbox .lightbox-close:focus {
      outline: none; }

.lightbox-visible {
  margin-top: 0;
  transition: margin-top 0.7s; }

#fotoalben .albums {
  display: block;
  margin-top: 60px;
  margin: 0px auto; }
  #fotoalben .albums .album {
    display: block;
    margin-bottom: 10px;
    overflow: hidden; }
    #fotoalben .albums .album .image-bg {
      position: relative;
      background-size: cover;
      height: 260px;
      width: 390px;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (max-width: 390px) {
        #fotoalben .albums .album .image-bg {
          width: 320px;
          height: 230px; } }
      #fotoalben .albums .album .image-bg:hover {
        box-shadow: inset 0px 0px 0px 5px #d7aa02; }
        #fotoalben .albums .album .image-bg:hover .title {
          padding-left: 25px;
          padding-right: 25px;
          width: calc(100% - 60px);
          padding-bottom: 15px;
          margin-left: 5px;
          margin-bottom: 5px;
          margin-right: 5px; }
      #fotoalben .albums .album .image-bg .title {
        color: white;
        position: absolute;
        margin-bottom: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 24px;
        font-family: 'Droid Serif';
        text-transform: initial;
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        width: calc(100% - 60px);
        margin-top: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); }

.album-switcher {
  border-top: 2px solid #aaa;
  display: flex;
  justify-content: space-evenly;
  padding-top: 20px;
  align-items: center; }
  .album-switcher .album-title {
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500; }
  .album-switcher .previous {
    background-image: url(../images/arrow.svg);
    transform: rotate(180deg);
    height: 18px;
    width: 35px;
    background-repeat: no-repeat;
    background-position: center; }
  .album-switcher .next {
    background-image: url(../images/arrow.svg);
    height: 18px;
    width: 35px;
    background-repeat: no-repeat;
    background-position: center; }

.cms-page-wrapper .slider, .cms-page-main .slider {
  position: relative;
  width: 100%;
  height: 680px; }
  @media screen and (max-width: 768px) {
    .cms-page-wrapper .slider, .cms-page-main .slider {
      height: 500px; } }
  @media screen and (max-width: 576px) {
    .cms-page-wrapper .slider, .cms-page-main .slider {
      height: 260px; } }
  .cms-page-wrapper .slider .title, .cms-page-main .slider .title {
    position: absolute;
    bottom: 40%;
    left: 10%;
    color: white;
    font-family: 'Droid Serif';
    font-style: italic;
    font-size: 86px;
    line-height: 100px;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .cms-page-wrapper .slider .title, .cms-page-main .slider .title {
        font-size: 66px;
        line-height: 80px; } }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .slider .title, .cms-page-main .slider .title {
        font-size: 34px;
        line-height: 46px; } }
  .cms-page-wrapper .slider .title.right-align, .cms-page-main .slider .title.right-align {
    left: 45%; }
    @media screen and (max-width: 1040px) {
      .cms-page-wrapper .slider .title.right-align, .cms-page-main .slider .title.right-align {
        left: 10%; } }
  .cms-page-wrapper .slider .title.center-align, .cms-page-main .slider .title.center-align {
    left: 0;
    right: 0;
    bottom: 45%;
    text-align: center; }
    .cms-page-wrapper .slider .title.center-align.bottom-align, .cms-page-main .slider .title.center-align.bottom-align {
      bottom: 25%; }
  .cms-page-wrapper .slider .subtitle, .cms-page-main .slider .subtitle {
    position: absolute;
    bottom: 35%;
    left: 10%;
    color: white;
    font-family: 'Montserrat';
    font-size: 20px;
    line-height: 30px;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .cms-page-wrapper .slider .subtitle, .cms-page-main .slider .subtitle {
        bottom: 30%; } }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .slider .subtitle, .cms-page-main .slider .subtitle {
        bottom: 15%;
        font-size: 16px;
        line-height: 26px; } }
  .cms-page-wrapper .slider .subtitle.right-align, .cms-page-main .slider .subtitle.right-align {
    left: 45%; }
    @media screen and (max-width: 1040px) {
      .cms-page-wrapper .slider .subtitle.right-align, .cms-page-main .slider .subtitle.right-align {
        left: 10%; } }
  .cms-page-wrapper .slider .subtitle.center-align, .cms-page-main .slider .subtitle.center-align {
    left: 0;
    right: 0;
    bottom: unset;
    top: 55%;
    text-align: center; }
    .cms-page-wrapper .slider .subtitle.center-align.bottom-align, .cms-page-main .slider .subtitle.center-align.bottom-align {
      top: 75%; }
  .cms-page-wrapper .slider .slides, .cms-page-main .slider .slides {
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .cms-page-wrapper .slider .slides .slick-list, .cms-page-main .slider .slides .slick-list {
      height: 100%; }
      .cms-page-wrapper .slider .slides .slick-list .slick-track, .cms-page-main .slider .slides .slick-list .slick-track {
        height: 100%; }
    .cms-page-wrapper .slider .slides .slide, .cms-page-main .slider .slides .slide {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100%; }
    .cms-page-wrapper .slider .slides .slick-dots, .cms-page-main .slider .slides .slick-dots {
      bottom: 40px; }
      @media screen and (max-width: 576px) {
        .cms-page-wrapper .slider .slides .slick-dots, .cms-page-main .slider .slides .slick-dots {
          bottom: unset;
          top: 20px;
          left: 20px;
          text-align: left; } }
      .cms-page-wrapper .slider .slides .slick-dots li.slick-active button:before, .cms-page-wrapper .slider .slides .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .cms-page-wrapper .slider .slides .slick-dots li.slick-active .actions-toolbar > .action:before, .cms-page-wrapper .slider .slides .slick-dots li.slick-active .action-gift:before, .cms-page-main .slider .slides .slick-dots li.slick-active button:before, .cms-page-main .slider .slides .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .cms-page-main .slider .slides .slick-dots li.slick-active .actions-toolbar > .action:before, .cms-page-main .slider .slides .slick-dots li.slick-active .action-gift:before {
        color: white !important;
        font-size: 46px !important; }
      .cms-page-wrapper .slider .slides .slick-dots li button:before, .cms-page-wrapper .slider .slides .slick-dots li .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .cms-page-wrapper .slider .slides .slick-dots li .actions-toolbar > .action:before, .cms-page-wrapper .slider .slides .slick-dots li .action-gift:before, .cms-page-main .slider .slides .slick-dots li button:before, .cms-page-main .slider .slides .slick-dots li .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .cms-page-main .slider .slides .slick-dots li .actions-toolbar > .action:before, .cms-page-main .slider .slides .slick-dots li .action-gift:before {
        color: white !important;
        font-size: 46px !important; }

.cms-page-wrapper .cms-section-wrapper.top .cms-section,
.cms-page-wrapper .cms-section-wrapper.bottom .cms-section, .cms-page-main .cms-section-wrapper.top .cms-section,
.cms-page-main .cms-section-wrapper.bottom .cms-section {
  position: relative;
  z-index: 10; }

.cms-page-wrapper .cms-section-wrapper.grey, .cms-page-main .cms-section-wrapper.grey {
  background-color: #efefef; }

.cms-page-wrapper .cms-section-wrapper.light-grey, .cms-page-main .cms-section-wrapper.light-grey {
  background-color: #f2f2f2; }

.cms-page-wrapper .cms-section-wrapper.bordered, .cms-page-main .cms-section-wrapper.bordered {
  border-top: 2px solid #efefef;
  border-bottom: 2px solid #efefef; }

.cms-page-wrapper .border, .cms-page-main .border {
  height: 2px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #efefef; }
  @media screen and (max-width: 1240px) {
    .cms-page-wrapper .border, .cms-page-main .border {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px; } }

.cms-page-wrapper .no-padding-top, .cms-page-main .no-padding-top {
  padding-top: 1px !important; }

.cms-page-wrapper .no-padding-bottom, .cms-page-main .no-padding-bottom {
  padding-top: 0px !important; }

.cms-page-wrapper .cms-section, .cms-page-main .cms-section {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px; }
  .cms-page-wrapper .cms-section .title, .cms-page-main .cms-section .title {
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 800;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 20px; }
    .cms-page-wrapper .cms-section .title.italic, .cms-page-main .cms-section .title.italic {
      font-family: 'Droid Serif';
      font-style: italic;
      color: #666;
      font-weight: 500;
      margin-top: 80px;
      text-transform: unset;
      font-size: 32px; }
  .cms-page-wrapper .cms-section .subtitle, .cms-page-main .cms-section .subtitle {
    font-family: 'Droid Serif';
    font-style: italic;
    max-width: 70%;
    margin: 0 auto;
    font-size: 16px;
    color: #777;
    line-height: 28px;
    margin-bottom: 60px; }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .cms-section .subtitle, .cms-page-main .cms-section .subtitle {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px; } }
    .cms-page-wrapper .cms-section .subtitle.wider, .cms-page-main .cms-section .subtitle.wider {
      max-width: 70%; }
      @media screen and (max-width: 576px) {
        .cms-page-wrapper .cms-section .subtitle.wider, .cms-page-main .cms-section .subtitle.wider {
          max-width: 100%; } }
    .cms-page-wrapper .cms-section .subtitle a, .cms-page-main .cms-section .subtitle a {
      color: #d7aa02; }
  .cms-page-wrapper .cms-section .text, .cms-page-main .cms-section .text {
    column-count: 2;
    text-align: left;
    column-gap: 50px;
    font-size: 18px;
    font-family: 'Droid Serif';
    color: #777;
    line-height: 32px; }
    @media screen and (max-width: 1280px) {
      .cms-page-wrapper .cms-section .text, .cms-page-main .cms-section .text {
        padding-left: 20px;
        padding-right: 20px; } }
    @media screen and (max-width: 768px) {
      .cms-page-wrapper .cms-section .text, .cms-page-main .cms-section .text {
        column-count: 1;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        line-height: 28px; } }
  .cms-page-wrapper .cms-section .cto img, .cms-page-main .cms-section .cto img {
    margin-top: 70px;
    margin-bottom: 10px;
    mix-blend-mode: multiply; }
  .cms-page-wrapper .cms-section .cto span, .cms-page-main .cms-section .cto span {
    font-family: 'Montserrat';
    text-transform: uppercase;
    color: black;
    font-weight: 800;
    font-size: 16px; }
  .cms-page-wrapper .cms-section .cto:hover, .cms-page-main .cms-section .cto:hover {
    text-decoration: none; }

.cms-page-wrapper .quote, .cms-page-main .quote {
  font-family: 'Droid Serif';
  font-style: italic;
  font-size: 36px;
  color: #777;
  margin-left: 20%;
  text-indent: -40%; }
  @media screen and (max-width: 768px) {
    .cms-page-wrapper .quote, .cms-page-main .quote {
      text-indent: unset;
      margin-left: 20px;
      margin-right: 20px; } }
  @media screen and (max-width: 576px) {
    .cms-page-wrapper .quote, .cms-page-main .quote {
      text-indent: unset;
      margin-left: 20px;
      margin-right: 20px;
      font-size: 30px;
      text-align: left; } }

.cms-page-wrapper .cms-section.full-width, .cms-page-main .cms-section.full-width {
  max-width: 1440px; }

.cms-page-wrapper .cms-section-wrapper.top, .cms-page-main .cms-section-wrapper.top {
  position: relative; }
  .cms-page-wrapper .cms-section-wrapper.top:before, .cms-page-main .cms-section-wrapper.top:before {
    content: "";
    background-image: url(../images/cms_pages/paper_top_left.png);
    background-position-y: top;
    background-position-x: left;
    height: 100%;
    width: 215px;
    position: absolute;
    background-repeat: no-repeat;
    left: 0;
    top: 0; }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .cms-section-wrapper.top:before, .cms-page-main .cms-section-wrapper.top:before {
        background-size: 30%; } }
  .cms-page-wrapper .cms-section-wrapper.top:after, .cms-page-main .cms-section-wrapper.top:after {
    content: "";
    background-image: url(../images/cms_pages/paper_top_right.png);
    background-position-y: top;
    background-position-x: right;
    height: 100%;
    width: 215px;
    position: absolute;
    background-repeat: no-repeat;
    right: 0;
    top: 0; }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .cms-section-wrapper.top:after, .cms-page-main .cms-section-wrapper.top:after {
        background-size: 50%; } }

.cms-page-wrapper .cms-section-wrapper.bottom, .cms-page-main .cms-section-wrapper.bottom {
  position: relative; }
  .cms-page-wrapper .cms-section-wrapper.bottom:before, .cms-page-main .cms-section-wrapper.bottom:before {
    content: "";
    background-image: url(../images/cms_pages/paper_top_right.png);
    background-position-y: top;
    background-position-x: right;
    transform: rotate(180deg);
    height: 100%;
    width: 215px;
    position: absolute;
    background-repeat: no-repeat;
    left: 0;
    bottom: 0; }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .cms-section-wrapper.bottom:before, .cms-page-main .cms-section-wrapper.bottom:before {
        background-size: 50%; } }
  .cms-page-wrapper .cms-section-wrapper.bottom:after, .cms-page-main .cms-section-wrapper.bottom:after {
    content: "";
    background-image: url(../images/cms_pages/paper_top_left.png);
    background-position-y: top;
    background-position-x: left;
    transform: rotate(180deg);
    height: 100%;
    width: 215px;
    position: absolute;
    background-repeat: no-repeat;
    right: 0;
    bottom: 0; }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .cms-section-wrapper.bottom:after, .cms-page-main .cms-section-wrapper.bottom:after {
        background-size: 30%; } }

.cms-page-wrapper .cms-section-wrapper.top-hor, .cms-page-main .cms-section-wrapper.top-hor {
  position: relative; }
  .cms-page-wrapper .cms-section-wrapper.top-hor .cms-section, .cms-page-wrapper .cms-section-wrapper.top-hor .cms-split-section, .cms-page-main .cms-section-wrapper.top-hor .cms-section, .cms-page-main .cms-section-wrapper.top-hor .cms-split-section {
    position: relative;
    z-index: 10; }

.cms-page-wrapper .cms-section-wrapper.bottom-hor, .cms-page-main .cms-section-wrapper.bottom-hor {
  position: relative; }
  .cms-page-wrapper .cms-section-wrapper.bottom-hor .cms-section, .cms-page-wrapper .cms-section-wrapper.bottom-hor .cms-split-section, .cms-page-main .cms-section-wrapper.bottom-hor .cms-section, .cms-page-main .cms-section-wrapper.bottom-hor .cms-split-section {
    position: relative;
    z-index: 10; }
  .cms-page-wrapper .cms-section-wrapper.bottom-hor:after, .cms-page-main .cms-section-wrapper.bottom-hor:after {
    content: '';
    z-index: 1;
    background-image: url(../images/cms_pages/content-vintage-oben.jpeg);
    background-size: contain;
    background-position-y: bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    background-repeat: no-repeat;
    right: 0;
    left: 0;
    bottom: 0; }

.cms-page-wrapper .cms-section-wrapper.top-hor, .cms-page-main .cms-section-wrapper.top-hor {
  position: relative; }
  .cms-page-wrapper .cms-section-wrapper.top-hor .cms-section, .cms-page-wrapper .cms-section-wrapper.top-hor .cms-split-section, .cms-page-main .cms-section-wrapper.top-hor .cms-section, .cms-page-main .cms-section-wrapper.top-hor .cms-split-section {
    position: relative;
    z-index: 10; }
  .cms-page-wrapper .cms-section-wrapper.top-hor:after, .cms-page-main .cms-section-wrapper.top-hor:after {
    content: '';
    z-index: 1;
    background-image: url(../images/cms_pages/content-vintage-unten.jpeg);
    background-size: contain;
    background-position-y: top;
    height: 100%;
    width: 100%;
    position: absolute;
    background-repeat: no-repeat;
    right: 0;
    left: 0;
    top: 0; }

.cms-page-wrapper .cms-split-section, .cms-page-main .cms-split-section {
  display: flex;
  flex-direction: row;
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: flex-start;
  align-items: center;
  padding-right: 100px;
  padding-left: 100px; }
  @media screen and (max-width: 992px) {
    .cms-page-wrapper .cms-split-section, .cms-page-main .cms-split-section {
      padding-right: 85px;
      padding-left: 85px; } }
  @media screen and (max-width: 768px) {
    .cms-page-wrapper .cms-split-section, .cms-page-main .cms-split-section {
      padding-left: 85px;
      padding-right: 85px; } }
  @media screen and (max-width: 576px) {
    .cms-page-wrapper .cms-split-section, .cms-page-main .cms-split-section {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (max-width: 768px) {
    .cms-page-wrapper .cms-split-section.odd, .cms-page-main .cms-split-section.odd {
      padding-top: 60px;
      flex-direction: column; } }
  .cms-page-wrapper .cms-split-section.odd .left, .cms-page-main .cms-split-section.odd .left {
    margin-right: 120px;
    flex-basis: 30%;
    flex-grow: 2; }
    @media screen and (max-width: 992px) {
      .cms-page-wrapper .cms-split-section.odd .left, .cms-page-main .cms-split-section.odd .left {
        margin-right: 40px; } }
    @media screen and (max-width: 768px) {
      .cms-page-wrapper .cms-split-section.odd .left, .cms-page-main .cms-split-section.odd .left {
        order: 1;
        margin: 0 auto; }
        .cms-page-wrapper .cms-split-section.odd .left .image, .cms-page-main .cms-split-section.odd .left .image {
          text-align: center; } }
  .cms-page-wrapper .cms-split-section.odd .right, .cms-page-main .cms-split-section.odd .right {
    flex-basis: 60%;
    flex-grow: 1; }
    @media screen and (max-width: 768px) {
      .cms-page-wrapper .cms-split-section.odd .right, .cms-page-main .cms-split-section.odd .right {
        order: 0; } }
  @media screen and (max-width: 768px) {
    .cms-page-wrapper .cms-split-section.even, .cms-page-main .cms-split-section.even {
      padding-top: 60px;
      flex-direction: column; } }
  .cms-page-wrapper .cms-split-section.even .left, .cms-page-main .cms-split-section.even .left {
    flex-basis: 60%;
    flex-grow: 1; }
    @media screen and (max-width: 768px) {
      .cms-page-wrapper .cms-split-section.even .left, .cms-page-main .cms-split-section.even .left {
        order: 0; } }
  .cms-page-wrapper .cms-split-section.even .right, .cms-page-main .cms-split-section.even .right {
    margin-left: 120px;
    flex-basis: 30%;
    flex-grow: 2; }
    @media screen and (max-width: 992px) {
      .cms-page-wrapper .cms-split-section.even .right, .cms-page-main .cms-split-section.even .right {
        margin-left: 40px; } }
    @media screen and (max-width: 768px) {
      .cms-page-wrapper .cms-split-section.even .right, .cms-page-main .cms-split-section.even .right {
        order: 1;
        margin: 0 auto; }
        .cms-page-wrapper .cms-split-section.even .right .image, .cms-page-main .cms-split-section.even .right .image {
          text-align: center; } }
  .cms-page-wrapper .cms-split-section.brennerei-section, .cms-page-main .cms-split-section.brennerei-section {
    padding-top: 0;
    padding-bottom: 60px; }
    .cms-page-wrapper .cms-split-section.brennerei-section .left, .cms-page-main .cms-split-section.brennerei-section .left {
      flex-basis: 50% !important; }
  .cms-page-wrapper .cms-split-section .left, .cms-page-main .cms-split-section .left {
    width: 100%; }
  .cms-page-wrapper .cms-split-section .block, .cms-page-main .cms-split-section .block {
    font-size: 18px;
    font-family: 'Droid Serif';
    color: #777;
    line-height: 32px;
    margin-bottom: 40px; }
    .cms-page-wrapper .cms-split-section .block .title, .cms-page-main .cms-split-section .block .title {
      text-align: center;
      font-size: 32px;
      font-style: italic;
      margin-bottom: 30px;
      color: #666; }
    .cms-page-wrapper .cms-split-section .block .content, .cms-page-main .cms-split-section .block .content {
      hyphens: auto; }

.cms-page-wrapper .brennerei-title, .cms-page-main .brennerei-title {
  text-align: left !important;
  color: #333 !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: 800 !important;
  font-size: 28px !important; }
  .cms-page-wrapper .brennerei-title.center-align, .cms-page-main .brennerei-title.center-align {
    text-align: center !important; }

.cms-page-wrapper .light-grey .cms-split-section, .cms-page-main .light-grey .cms-split-section {
  padding-top: 0;
  padding-bottom: 100px; }
  .cms-page-wrapper .light-grey .cms-split-section.odd .left, .cms-page-main .light-grey .cms-split-section.odd .left {
    flex-basis: 50%; }
  .cms-page-wrapper .light-grey .cms-split-section.even .right, .cms-page-main .light-grey .cms-split-section.even .right {
    flex-basis: 50%; }
  .cms-page-wrapper .light-grey .cms-split-section.no-padding-bottom, .cms-page-main .light-grey .cms-split-section.no-padding-bottom {
    padding-bottom: 0; }
  .cms-page-wrapper .light-grey .cms-split-section .block .title, .cms-page-main .light-grey .cms-split-section .block .title {
    color: #333;
    font-style: normal;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 800; }

.cms-page-wrapper .grey-box-outline, .cms-page-main .grey-box-outline {
  border: 1px solid #eee;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: -20px;
  max-width: 1050px;
  margin: 0 auto;
  margin-top: 60px; }
  @media screen and (max-width: 576px) {
    .cms-page-wrapper .grey-box-outline, .cms-page-main .grey-box-outline {
      margin-left: 40px;
      margin-right: -20px;
      padding-bottom: 50px;
      z-index: 10;
      position: relative; } }
  .cms-page-wrapper .grey-box-outline .grey-box, .cms-page-main .grey-box-outline .grey-box {
    background-color: #f2f2f2;
    padding: 100px;
    padding-top: 80px;
    padding-bottom: 80px;
    column-count: 1;
    column-gap: 50px;
    font-size: 18px;
    font-family: 'Droid Serif';
    color: #777;
    line-height: 32px;
    text-align: center;
    transform: translateY(25px); }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .grey-box-outline .grey-box, .cms-page-main .grey-box-outline .grey-box {
        margin-left: -47px;
        margin-right: 13px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: left;
        font-size: 16px;
        line-height: 28px; } }
    .cms-page-wrapper .grey-box-outline .grey-box p, .cms-page-main .grey-box-outline .grey-box p {
      margin-bottom: 30px; }
    .cms-page-wrapper .grey-box-outline .grey-box a, .cms-page-main .grey-box-outline .grey-box a {
      color: #d7aa02; }

.cms-page-wrapper .grey-box-block, .cms-page-main .grey-box-block {
  display: flex;
  justify-content: space-between;
  max-height: 630px;
  height: 47vw;
  margin-top: -100px; }
  @media screen and (max-width: 576px) {
    .cms-page-wrapper .grey-box-block, .cms-page-main .grey-box-block {
      flex-direction: column;
      max-height: unset;
      height: unset; } }
  .cms-page-wrapper .grey-box-block .image:first-of-type, .cms-page-main .grey-box-block .image:first-of-type {
    align-self: flex-start;
    margin-right: 20px; }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .grey-box-block .image:first-of-type, .cms-page-main .grey-box-block .image:first-of-type {
        margin-right: 0;
        margin-left: 20px;
        transform: translate(50px, -40px);
        z-index: 1;
        position: relative; } }
  .cms-page-wrapper .grey-box-block .image:nth-of-type(2), .cms-page-main .grey-box-block .image:nth-of-type(2) {
    align-self: flex-end; }
    @media screen and (max-width: 576px) {
      .cms-page-wrapper .grey-box-block .image:nth-of-type(2), .cms-page-main .grey-box-block .image:nth-of-type(2) {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -10px; } }

.page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section {
  text-align: center;
  font-family: 'Droid Serif' !important;
  font-size: 16px !important;
  color: #777 !important;
  line-height: 28px !important;
  max-width: 1000px; }
  @media screen and (max-width: 1040px) {
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section {
      padding-left: 20px;
      padding-right: 20px; } }
  .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section .title {
    color: black !important; }
  .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h1 {
    font-family: 'Montserrat' !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    font-size: 28px !important;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center !important;
    color: black !important; }
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h1 a {
      color: #d7aa02 !important; }
      .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h1 a:hover {
        color: #d7aa02 !important; }
  .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 {
    text-align: left !important;
    font-family: 'Montserrat' !important;
    text-transform: uppercase !important;
    color: black !important;
    font-weight: 800 !important; }
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 a {
      color: #d7aa02 !important; }
      .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 a:hover {
        color: #d7aa02 !important; }
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 p, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 span, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 p, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 span, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 p, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 span, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 p, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 span {
      color: black !important;
      font-weight: 800 !important; }
      .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 p a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 span a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 p a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 span a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 p a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 span a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 p a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 span a {
        color: #d7aa02 !important; }
        .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 p a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h2 span a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 p a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h3 span a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 p a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h4 span a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 p a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section h5 span a:hover {
          color: #d7aa02 !important; }
  .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section p, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section span {
    text-align: left !important;
    font-family: 'Droid Serif' !important;
    font-size: 16px !important;
    color: #777 !important;
    line-height: 28px !important; }
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section p a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section span a {
      color: #d7aa02 !important; }
      .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section p a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section span a:hover {
        color: #d7aa02 !important; }
  .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section table, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul {
    text-align: left !important; }
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section table a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul a {
      color: #d7aa02 !important; }
      .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section table a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul a:hover {
        color: #d7aa02 !important; }
  .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul li a {
    color: #d7aa02 !important; }
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul li a:hover {
      color: #d7aa02 !important; }
  .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul li span a, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul li p a {
    color: #d7aa02 !important; }
    .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul li span a:hover, .page-layout-legal_pages_page_layout .cms-page-main .cms-section-wrapper .cms-section ul li p a:hover {
      color: #d7aa02 !important; }

#startseite .palette {
  max-width: 1280px;
  margin-left: 30px;
  margin-right: 30px;
  position: relative; }
  #startseite .palette .item {
    position: relative; }
    @media screen and (max-width: 768px) {
      #startseite .palette .item {
        width: 200px; } }
    @media screen and (max-width: 576px) {
      #startseite .palette .item {
        max-width: 130px; } }
    #startseite .palette .item img {
      mix-blend-mode: multiply;
      opacity: 0.7; }
    #startseite .palette .item .title {
      font-family: 'Droid Serif';
      font-variant-caps: normal;
      font-weight: 800;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 5px; }
    #startseite .palette .item a {
      font-family: 'Droid Serif';
      font-variant-caps: normal;
      font-weight: 400;
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 5px;
      color: #777; }

#startseite .awards {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px; }
  @media screen and (max-width: 768px) {
    #startseite .awards {
      flex-wrap: wrap; } }
  #startseite .awards img {
    height: 150px; }
    @media screen and (max-width: 576px) {
      #startseite .awards img {
        margin-bottom: 10px; } }

#startseite .productslider-section {
  max-width: 1280px; }

#hubertus-vallendar .block-1 {
  display: flex;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px; }
  @media screen and (max-width: 768px) {
    #hubertus-vallendar .block-1 {
      margin-right: 20px; } }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .block-1 {
      flex-direction: column;
      margin-top: 40px; } }
  #hubertus-vallendar .block-1 .image {
    margin-right: 50px;
    flex-basis: 50%; }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-1 .image {
        margin-right: 20px;
        margin-bottom: 40px; } }
  #hubertus-vallendar .block-1 .text {
    column-count: 1;
    flex-basis: 50%; }

#hubertus-vallendar .block-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 300px;
  margin-top: -80px; }
  @media screen and (max-width: 850px) {
    #hubertus-vallendar .block-2 {
      margin-bottom: 120px; } }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .block-2 {
      margin-top: -140px;
      margin-bottom: 40px; } }
  #hubertus-vallendar .block-2 .image {
    margin-right: 150px;
    flex: 0 0 auto; }
    @media screen and (max-width: 768px) {
      #hubertus-vallendar .block-2 .image {
        transform: translateX(50%); } }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-2 .image {
        display: none; } }
  #hubertus-vallendar .block-2 .image-stack img:first-child {
    position: relative;
    z-index: 1; }
  #hubertus-vallendar .block-2 .image-stack img:nth-child(2) {
    position: absolute;
    top: 230px;
    z-index: 0; }
    @media screen and (max-width: 1300px) {
      #hubertus-vallendar .block-2 .image-stack img:nth-child(2) {
        right: 0; } }
    @media screen and (max-width: 850px) {
      #hubertus-vallendar .block-2 .image-stack img:nth-child(2) {
        float: right;
        top: 0;
        position: relative; } }

#hubertus-vallendar .block-3 {
  margin-top: 60px;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .block-3 {
      flex-direction: column; } }
  #hubertus-vallendar .block-3 .text {
    column-count: 1;
    margin-right: 100px; }
    @media screen and (max-width: 768px) {
      #hubertus-vallendar .block-3 .text {
        margin-right: 20px; } }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-3 .text {
        order: 1;
        margin-right: 0; } }
    #hubertus-vallendar .block-3 .text p {
      margin-bottom: 30px; }
  #hubertus-vallendar .block-3 .image {
    margin-right: 100px;
    flex: 0 0 auto;
    flex-basis: 32.6%; }
    @media screen and (max-width: 768px) {
      #hubertus-vallendar .block-3 .image {
        margin-right: 50px; } }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-3 .image {
        order: 2;
        margin-right: 0; } }

#hubertus-vallendar .quote-2 {
  margin-left: 30%; }
  @media screen and (max-width: 768px) {
    #hubertus-vallendar .quote-2 {
      margin-left: 20px;
      margin-right: 20px; } }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .quote-2 {
      margin-left: 20px;
      margin-right: 20px; } }

#hubertus-vallendar .quote-3 {
  margin-left: 0%;
  text-indent: 0%; }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .quote-3 {
      margin-right: 20px;
      margin-left: 20px; } }

#hubertus-vallendar .block-4 {
  display: flex;
  justify-content: space-between;
  max-height: 630px;
  height: 47vw;
  margin-top: -100px; }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .block-4 {
      flex-direction: column;
      max-height: unset;
      height: unset; } }
  #hubertus-vallendar .block-4 .image:first-of-type {
    align-self: flex-start;
    margin-right: 20px; }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-4 .image:first-of-type {
        margin-right: 0;
        margin-left: 20px;
        transform: translate(50px, -40px);
        z-index: 1;
        position: relative; } }
  #hubertus-vallendar .block-4 .image:nth-of-type(2) {
    align-self: flex-end; }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-4 .image:nth-of-type(2) {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -10px; } }

#hubertus-vallendar .block-5 {
  margin-top: 100px;
  display: flex;
  padding-left: 12%; }
  @media screen and (max-width: 768px) {
    #hubertus-vallendar .block-5 {
      padding-left: 0; } }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .block-5 {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0; } }
  #hubertus-vallendar .block-5 .image {
    margin-right: 50px;
    flex: 0 0 auto;
    flex-basis: 50%; }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-5 .image {
        margin-left: 20px;
        margin-right: 0; } }
  #hubertus-vallendar .block-5 .text {
    column-count: 1;
    margin-right: 20px; }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-5 .text {
        margin-right: 0;
        padding-top: 40px; } }

#hubertus-vallendar .block-6 .block-images {
  display: flex;
  max-height: 580px;
  height: 48vw;
  justify-content: space-between; }
  @media screen and (max-width: 576px) {
    #hubertus-vallendar .block-6 .block-images {
      flex-direction: column;
      height: auto;
      margin-top: -60px;
      margin-bottom: -40px; } }
  #hubertus-vallendar .block-6 .block-images .image:first-child {
    align-self: flex-start;
    margin-right: 20px; }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-6 .block-images .image:first-child {
        transform: translateX(-60px); } }
  #hubertus-vallendar .block-6 .block-images .image:nth-child(2) {
    align-self: flex-end; }
    @media screen and (max-width: 576px) {
      #hubertus-vallendar .block-6 .block-images .image:nth-child(2) {
        margin-left: 20px;
        margin-top: 30px; } }

#hubertus-vallendar .block-6 .text {
  column-count: 1;
  max-width: 890px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 60px; }
  @media screen and (max-width: 768px) {
    #hubertus-vallendar .block-6 .text {
      margin-left: 20px;
      margin-right: 20px; } }

#unsere-destillate .title {
  margin-bottom: 110px; }

#unsere-destillate .destillat {
  display: flex;
  flex-direction: row;
  padding: 60px;
  position: relative;
  margin-bottom: 60px;
  height: 310px; }
  @media screen and (max-width: 768px) {
    #unsere-destillate .destillat {
      height: unset; } }
  @media screen and (max-width: 576px) {
    #unsere-destillate .destillat {
      padding: 20px;
      flex-direction: column; } }
  #unsere-destillate .destillat .col1 {
    flex: 1;
    z-index: 1; }
    #unsere-destillate .destillat .col1 .title {
      font-family: 'Droid Serif';
      font-size: 32px;
      color: #777;
      font-weight: 400;
      text-align: left;
      text-transform: unset;
      margin-bottom: 20px;
      margin-top: 0; }
      @media screen and (max-width: 1100px) {
        #unsere-destillate .destillat .col1 .title {
          font-size: 22px;
          margin-bottom: 10px; } }
    #unsere-destillate .destillat .col1 .text {
      column-count: 1;
      font-size: 16px;
      line-height: 24px;
      padding: 0 !important; }
      @media screen and (max-width: 1100px) {
        #unsere-destillate .destillat .col1 .text {
          font-size: 14px;
          line-height: 22px; } }
    #unsere-destillate .destillat .col1 .link {
      text-align: left;
      margin-top: 20px;
      font-family: 'Droid Serif';
      font-size: 16px; }
      @media screen and (max-width: 1100px) {
        #unsere-destillate .destillat .col1 .link {
          font-size: 14px; } }
      #unsere-destillate .destillat .col1 .link a {
        color: #d7aa02; }
  #unsere-destillate .destillat .col2 {
    flex: 1.1; }
    @media screen and (max-width: 576px) {
      #unsere-destillate .destillat .col2 {
        position: relative;
        height: 220px;
        flex: unset; } }
    #unsere-destillate .destillat .col2 .bg {
      position: absolute;
      top: 0;
      bottom: 0; }
      @media screen and (max-width: 1100px) {
        #unsere-destillate .destillat .col2 .bg:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0; } }
      @media screen and (max-width: 576px) {
        #unsere-destillate .destillat .col2 .bg {
          overflow: hidden;
          height: 160px;
          width: 100%; } }
      #unsere-destillate .destillat .col2 .bg img {
        height: 100%; }
        @media screen and (max-width: 576px) {
          #unsere-destillate .destillat .col2 .bg img {
            width: 100%;
            height: auto;
            transform: translateY(-25%); } }
    #unsere-destillate .destillat .col2 .image {
      position: absolute; }
      @media screen and (max-width: 1100px) {
        #unsere-destillate .destillat .col2 .image {
          transform: scale(0.8);
          bottom: 0; } }
      @media screen and (max-width: 720px) {
        #unsere-destillate .destillat .col2 .image {
          bottom: 15%; } }
      @media screen and (max-width: 576px) {
        #unsere-destillate .destillat .col2 .image {
          right: 0 !important;
          left: 0 !important;
          transform: scale(0.7);
          bottom: -20px !important; }
          #unsere-destillate .destillat .col2 .image img {
            max-height: 350px; } }
    #unsere-destillate .destillat .col2 .single-image {
      position: absolute;
      left: 55%;
      bottom: -20px; }
      @media screen and (max-width: 1100px) {
        #unsere-destillate .destillat .col2 .single-image {
          transform: scale(0.8); } }
      @media screen and (max-width: 576px) {
        #unsere-destillate .destillat .col2 .single-image {
          left: 0 !important;
          right: 0 !important;
          transform: scale(0.5);
          bottom: -100px; } }
  #unsere-destillate .destillat:nth-child(odd) {
    padding-left: 0; }
    @media screen and (max-width: 1240px) {
      #unsere-destillate .destillat:nth-child(odd) {
        padding-left: 20px; } }
    @media screen and (max-width: 576px) {
      #unsere-destillate .destillat:nth-child(odd) {
        background-color: transparent; } }
    #unsere-destillate .destillat:nth-child(odd) .col1 {
      order: 1; }
      @media screen and (max-width: 576px) {
        #unsere-destillate .destillat:nth-child(odd) .col1 {
          order: 2; } }
    #unsere-destillate .destillat:nth-child(odd) .col2 {
      order: 2; }
      @media screen and (max-width: 576px) {
        #unsere-destillate .destillat:nth-child(odd) .col2 {
          order: 1; } }
      #unsere-destillate .destillat:nth-child(odd) .col2 .bg {
        right: 0; }
        @media screen and (max-width: 1100px) {
          #unsere-destillate .destillat:nth-child(odd) .col2 .bg:after {
            background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 100%); } }
        @media screen and (max-width: 720px) {
          #unsere-destillate .destillat:nth-child(odd) .col2 .bg:after {
            background: linear-gradient(to left, rgba(255, 255, 255, 0) -40%, white 80%); } }
        @media screen and (max-width: 576px) {
          #unsere-destillate .destillat:nth-child(odd) .col2 .bg:after {
            display: none; } }
        #unsere-destillate .destillat:nth-child(odd) .col2 .bg img {
          text-align: right; }
      #unsere-destillate .destillat:nth-child(odd) .col2 .image {
        left: 46%;
        bottom: 0; }
  #unsere-destillate .destillat:nth-child(even) {
    padding-right: 0;
    background-color: transparent; }
    @media screen and (max-width: 1240px) {
      #unsere-destillate .destillat:nth-child(even) {
        padding-right: 20px; } }
    #unsere-destillate .destillat:nth-child(even) .col1 {
      order: 2; }
    #unsere-destillate .destillat:nth-child(even) .col2 {
      order: 1; }
      #unsere-destillate .destillat:nth-child(even) .col2 .bg {
        left: 0; }
        @media screen and (max-width: 1100px) {
          #unsere-destillate .destillat:nth-child(even) .col2 .bg:after {
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%); } }
        @media screen and (max-width: 720px) {
          #unsere-destillate .destillat:nth-child(even) .col2 .bg:after {
            background: linear-gradient(to right, rgba(255, 255, 255, 0) -40%, white 80%); } }
        @media screen and (max-width: 576px) {
          #unsere-destillate .destillat:nth-child(even) .col2 .bg:after {
            display: none; } }
        #unsere-destillate .destillat:nth-child(even) .col2 .bg img {
          text-align: left; }
      #unsere-destillate .destillat:nth-child(even) .col2 .image {
        right: 46%;
        bottom: 0; }
  #unsere-destillate .destillat:first-child {
    margin-top: 110px; }

#partner .partner-wrapper {
  margin-top: 100px; }
  #partner .partner-wrapper .group {
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 800;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 20px; }
  #partner .partner-wrapper .partner-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 1200px;
    margin-bottom: 100px; }
    @media screen and (max-width: 1280px) {
      #partner .partner-wrapper .partner-container {
        width: 900px; } }
    @media screen and (max-width: 960px) {
      #partner .partner-wrapper .partner-container {
        width: 600px; } }
    @media screen and (max-width: 640px) {
      #partner .partner-wrapper .partner-container {
        width: 300px; } }
    #partner .partner-wrapper .partner-container .partner {
      height: 180px;
      width: 299px;
      position: relative; }
      #partner .partner-wrapper .partner-container .partner .partner-image {
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; }
      #partner .partner-wrapper .partner-container .partner .info {
        opacity: 0;
        background-color: #d7aa02;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        transition: 0.2s; }
        #partner .partner-wrapper .partner-container .partner .info .name {
          font-family: 'Droid Serif';
          color: white;
          text-transform: uppercase;
          text-align: center;
          font-size: 18px;
          hyphens: auto;
          padding-left: 10px;
          padding-right: 10px; }
        #partner .partner-wrapper .partner-container .partner .info a {
          font-family: 'Montserrat';
          color: white;
          text-transform: uppercase;
          text-align: center;
          font-size: 14px;
          font-weight: 800; }
      #partner .partner-wrapper .partner-container .partner:hover > .info {
        opacity: 1;
        transition: 0.2s; }

.academy-sektion1 {
  padding-top: 20px !important;
  padding-bottom: 30px !important; }
  .academy-sektion1.even {
    padding-bottom: 200px !important; }
    .academy-sektion1.even .left {
      flex-basis: 60% !important; }
    .academy-sektion1.even .right {
      flex-basis: 60% !important; }
  .academy-sektion1.odd .left {
    flex-basis: 60% !important; }
  .academy-sektion1.odd .right {
    flex-basis: 60% !important; }
  .academy-sektion1 .block .title {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    font-size: 28px !important;
    color: black !important;
    text-align: left !important;
    margin-bottom: 20px !important;
    hyphens: auto; }

.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  @media screen and (max-width: 1200px) {
    .team {
      justify-content: center; } }
  .team team-member {
    width: 500px;
    height: 330px;
    background-size: cover;
    background-position: center;
    width: 500px;
    height: 330px;
    margin: 20px;
    position: relative; }
    @media screen and (max-width: 440px) {
      .team team-member {
        height: 250px; } }
    @media screen and (max-width: 360px) {
      .team team-member {
        height: 200px; } }
    .team team-member:nth-of-type(4n - 3) {
      margin-left: 11%; }
      @media screen and (max-width: 1200px) {
        .team team-member:nth-of-type(4n - 3) {
          margin-left: 20px; } }
    .team team-member .details {
      display: none;
      padding: 10px;
      background-color: rgba(215, 170, 2, 0.8);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .team team-member .details .name {
        font-family: 'Montserrat';
        font-weight: 800;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 10px; }
      .team team-member .details .email {
        font-family: 'Droid Serif';
        color: white;
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline; }
        .team team-member .details .email a {
          color: white; }
          .team team-member .details .email a:hover {
            color: white; }
          .team team-member .details .email a:visited {
            color: white; }
          .team team-member .details .email a:active {
            color: white; }
      .team team-member .details .position {
        font-family: 'Droid Serif';
        color: white;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 10px; }
    .team team-member:hover .details {
      display: flex; }

.cms-brennerei .cms-split-section, .cms-cognac .cms-split-section {
  display: flex;
  flex-direction: row;
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: flex-start;
  align-items: center; }
  .cms-brennerei .cms-split-section.odd, .cms-cognac .cms-split-section.odd {
    padding-right: 100px; }
    @media screen and (max-width: 992px) {
      .cms-brennerei .cms-split-section.odd, .cms-cognac .cms-split-section.odd {
        padding-right: 85px; } }
    @media screen and (max-width: 768px) {
      .cms-brennerei .cms-split-section.odd, .cms-cognac .cms-split-section.odd {
        padding-left: 85px;
        padding-top: 60px;
        flex-direction: column; } }
    @media screen and (max-width: 576px) {
      .cms-brennerei .cms-split-section.odd, .cms-cognac .cms-split-section.odd {
        padding-left: 20px;
        padding-right: 20px; } }
    .cms-brennerei .cms-split-section.odd .left, .cms-cognac .cms-split-section.odd .left {
      margin-right: 120px;
      flex-basis: 50%;
      flex-grow: 2; }
      @media screen and (max-width: 992px) {
        .cms-brennerei .cms-split-section.odd .left, .cms-cognac .cms-split-section.odd .left {
          margin-right: 40px; } }
      @media screen and (max-width: 768px) {
        .cms-brennerei .cms-split-section.odd .left, .cms-cognac .cms-split-section.odd .left {
          order: 1;
          margin: 0 auto; }
          .cms-brennerei .cms-split-section.odd .left .image, .cms-cognac .cms-split-section.odd .left .image {
            text-align: center; } }
    .cms-brennerei .cms-split-section.odd .right, .cms-cognac .cms-split-section.odd .right {
      flex-basis: 60%;
      flex-grow: 1; }
      @media screen and (max-width: 768px) {
        .cms-brennerei .cms-split-section.odd .right, .cms-cognac .cms-split-section.odd .right {
          order: 0; } }
  .cms-brennerei .cms-split-section.even, .cms-cognac .cms-split-section.even {
    padding-left: 100px; }
    @media screen and (max-width: 992px) {
      .cms-brennerei .cms-split-section.even, .cms-cognac .cms-split-section.even {
        padding-left: 85px; } }
    @media screen and (max-width: 768px) {
      .cms-brennerei .cms-split-section.even, .cms-cognac .cms-split-section.even {
        padding-right: 85px;
        padding-top: 60px;
        flex-direction: column; } }
    @media screen and (max-width: 576px) {
      .cms-brennerei .cms-split-section.even, .cms-cognac .cms-split-section.even {
        padding-left: 20px;
        padding-right: 20px; } }
    .cms-brennerei .cms-split-section.even .left, .cms-cognac .cms-split-section.even .left {
      flex-basis: 60%;
      flex-grow: 1; }
      @media screen and (max-width: 768px) {
        .cms-brennerei .cms-split-section.even .left, .cms-cognac .cms-split-section.even .left {
          order: 0; } }
    .cms-brennerei .cms-split-section.even .right, .cms-cognac .cms-split-section.even .right {
      margin-left: 120px;
      flex-basis: 50%;
      flex-grow: 2; }
      @media screen and (max-width: 992px) {
        .cms-brennerei .cms-split-section.even .right, .cms-cognac .cms-split-section.even .right {
          margin-left: 40px; } }
      @media screen and (max-width: 768px) {
        .cms-brennerei .cms-split-section.even .right, .cms-cognac .cms-split-section.even .right {
          order: 1;
          margin: 0 auto; }
          .cms-brennerei .cms-split-section.even .right .image, .cms-cognac .cms-split-section.even .right .image {
            text-align: center; } }
      .cms-brennerei .cms-split-section.even .right .image img, .cms-cognac .cms-split-section.even .right .image img {
        float: right; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button, .slick-dots li .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li .actions-toolbar > .action, .slick-dots li .action-gift {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:hover, .slick-dots li .action-gift:hover, .slick-dots li button:focus, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:focus, .slick-dots li .action-gift:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover:before, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:hover:before, .slick-dots li .action-gift:hover:before, .slick-dots li button:focus:before, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus:before, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:focus:before, .slick-dots li .action-gift:focus:before {
          opacity: 1; }
      .slick-dots li button:before, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:before, .slick-dots li .action-gift:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before, .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action:before, .cart.table-wrapper .slick-dots li.slick-active .actions-toolbar > .action:before, .slick-dots li.slick-active .action-gift:before {
      color: black;
      opacity: 0.75; }

body {
  background-color: #ddd; }

p a {
  color: #d7aa02; }
  p a:visited {
    color: #d7aa02 !important; }
  p a:hover {
    color: #d7aa02 !important; }

.page-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  display: block !important;
  overflow: hidden; }
  .page-wrapper .logo-toptitle {
    height: 40px;
    background-image: url(../images/die-kunst-des-destillierens.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #ddd; }
    @media screen and (max-width: 576px) {
      .page-wrapper .logo-toptitle {
        background-size: 90%; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .page-wrapper {
      overflow: visible !important; } }

.page-main {
  background-color: #fff;
  max-width: 1440px;
  padding-left: 0;
  padding-right: 0; }

.page-header {
  border: none !important;
  margin-bottom: 0 !important; }

.page-footer {
  background-color: #fff; }

.columns {
  margin-left: 100px;
  margin-right: 100px; }
  @media screen and (max-width: 1280px) {
    .columns {
      margin-left: 70px;
      margin-right: 85px; } }
  @media screen and (max-width: 768px) {
    .columns {
      margin-right: 85px;
      margin-left: 85px; } }
  @media screen and (max-width: 576px) {
    .columns {
      margin-left: 20px;
      margin-right: 20px; } }

.vallendar-menu {
  padding: 0 !important; }

.vallendar-submenu {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%; }
  .vallendar-submenu .sub-item {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase; }
    .vallendar-submenu .sub-item:hover {
      text-decoration: underline; }
    @media screen and (max-width: 576px) {
      .vallendar-submenu .sub-item {
        font-size: 14px; }
        .vallendar-submenu .sub-item a {
          padding-top: 6px !important;
          padding-bottom: 6px !important; } }

.minicart-items .product-item .product {
  display: flex; }
  .minicart-items .product-item .product:before, .minicart-items .product-item .product:after {
    display: none; }
  .minicart-items .product-item .product .product-image-container {
    width: 100px !important;
    height: 100px !important;
    flex-shrink: 0;
    margin-right: 15px; }
  .minicart-items .product-item .product .product-item-details {
    display: block;
    padding: 0;
    height: unset;
    flex-grow: 1; }
    .minicart-items .product-item .product .product-item-details .product-item-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0; }
      .minicart-items .product-item .product .product-item-details .product-item-inner .product-item-name {
        min-height: unset !important;
        font-weight: 700;
        word-break: break-word;
        hyphens: auto; }
        .minicart-items .product-item .product .product-item-details .product-item-inner .product-item-name strong,
        .minicart-items .product-item .product .product-item-details .product-item-inner .product-item-name a {
          color: #333;
          font-weight: 700; }
      .minicart-items .product-item .product .product-item-details .product-item-inner .details-qty {
        font-size: 12px;
        margin-top: 8px; }
      .minicart-items .product-item .product .product-item-details .product-item-inner .subtotal {
        display: flex;
        flex-grow: 1;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-end; }
        .minicart-items .product-item .product .product-item-details .product-item-inner .subtotal .cart-price .price {
          font-size: 16px;
          font-weight: 700; }
    .minicart-items .product-item .product .product-item-details .product-item-name {
      min-height: unset !important;
      font-weight: 700;
      word-break: break-word;
      hyphens: auto; }
      .minicart-items .product-item .product .product-item-details .product-item-name strong,
      .minicart-items .product-item .product .product-item-details .product-item-name a {
        color: #333;
        font-weight: 700; }
    .minicart-items .product-item .product .product-item-details .product-item-pricing {
      display: flex;
      flex-direction: column; }
      .minicart-items .product-item .product .product-item-details .product-item-pricing .qty {
        order: 1; }
        .minicart-items .product-item .product .product-item-details .product-item-pricing .qty label {
          margin-right: 8px; }
        .minicart-items .product-item .product .product-item-details .product-item-pricing .qty .update-cart-item {
          display: inline-block;
          background-color: #d7aa02;
          color: white;
          border: none;
          border-radius: unset;
          font-family: 'Montserrat';
          text-transform: uppercase;
          font-weight: 500;
          height: 20px;
          padding: 0 5px;
          margin-top: 6px;
          margin-left: -6px; }
          .minicart-items .product-item .product .product-item-details .product-item-pricing .qty .update-cart-item:hover {
            background: #d7aa02;
            border: none;
            color: white; }
      .minicart-items .product-item .product .product-item-details .product-item-pricing .price-container {
        order: 2; }

.parent-link {
  padding: 10px 0 10px 0px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 800;
  font-family: 'Montserrat';
  cursor: pointer; }
  .parent-link:hover {
    text-decoration: underline; }
  @media screen and (max-width: 576px) {
    .parent-link {
      font-size: 18px;
      padding-top: 8px !important;
      padding-bottom: 8px !important; } }

.main-menu a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 800;
  font-family: 'Montserrat'; }
  .main-menu a:hover {
    text-decoration: underline; }
  @media screen and (max-width: 576px) {
    .main-menu a {
      font-size: 18px;
      padding-top: 8px !important;
      padding-bottom: 8px !important; } }

.message {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
  transition: 0.2s;
  cursor: pointer;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 60px !important; }
  .message div:before {
    top: 26px !important;
    right: unset;
    left: 10px; }
  .message.error {
    background: #f88 !important; }
  .message:hover {
    opacity: 1;
    transition: 0.2s; }
  .message div {
    display: inline-block;
    font-family: 'Montserrat';
    color: black !important;
    font-weight: 500; }
  .message .close-message {
    display: inline-block;
    margin-right: 10px;
    color: black !important;
    font-weight: 800;
    cursor: pointer; }
    .message .close-message:hover {
      text-decoration: none; }
  .message.warning {
    margin-top: 0 !important; }

div.mage-error {
  display: none !important; }
  div.mage-error.visible {
    display: block !important; }

input.mage-error {
  display: block !important;
  border-color: red !important;
  border-width: 3px !important; }

#password-error {
  position: absolute;
  top: 57px;
  background-color: #f2f2f2;
  padding: 20px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }

.page-header {
  background-color: #fff;
  position: relative; }
  .page-header .panel.wrapper {
    display: none; }
  .page-header .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: none !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding-top: 20px; }
    .page-header .logo img {
      width: auto;
      height: 80px; }
    @media screen and (max-width: 768px) {
      .page-header .logo {
        max-width: 100px !important;
        margin: 0 auto !important;
        padding-top: 17px; }
        .page-header .logo img {
          height: auto; } }
    @media screen and (max-width: 380px) {
      .page-header .logo {
        left: 29%;
        margin: 0 !important; } }
    @media screen and (max-width: 330px) {
      .page-header .logo {
        left: 24%;
        margin: 0 !important; } }
    @media screen and (max-width: 890px) {
      .page-header .logo a {
        transform: scale(0.8); } }
    @media screen and (max-width: 768px) {
      .page-header .logo a {
        transform: none; } }
    .page-header .logo a:hover {
      text-decoration: none; }
    @media screen and (max-width: 768px) {
      .page-header .logo a img {
        height: auto !important; } }
  .page-header #btn-minicart-close {
    display: none !important; }
  .page-header .minicart-wrapper {
    position: absolute;
    top: 40px;
    right: 96px;
    z-index: 15;
    margin: 0; }
    @media screen and (max-width: 1280px) {
      .page-header .minicart-wrapper {
        right: 61px !important; } }
    @media screen and (max-width: 768px) {
      .page-header .minicart-wrapper {
        top: 34px !important;
        right: 26px !important; } }
    @media screen and (max-width: 639px) {
      .page-header .minicart-wrapper {
        right: 26px !important; } }
    .page-header .minicart-wrapper .ui-widget .block-minicart {
      padding-top: 20px;
      padding-bottom: 20px; }
      @media screen and (max-width: 767px) {
        .page-header .minicart-wrapper .ui-widget .block-minicart {
          right: -15px !important; }
          .page-header .minicart-wrapper .ui-widget .block-minicart:before {
            right: 13px; }
          .page-header .minicart-wrapper .ui-widget .block-minicart:after {
            right: 12px; } }
      @media screen and (max-width: 480px) {
        .page-header .minicart-wrapper .ui-widget .block-minicart {
          right: -11px !important;
          width: calc(100vw - 30px); } }
    .page-header .minicart-wrapper #minicart-content-wrapper .paypal.checkout.after {
      display: none !important; }
    .page-header .minicart-wrapper #minicart-content-wrapper #btn-minicart-close {
      right: 20px;
      top: 20px; }
    .page-header .minicart-wrapper #minicart-content-wrapper .actions {
      line-height: 40px;
      padding-left: 10px;
      padding-bottom: 10px; }
      .page-header .minicart-wrapper #minicart-content-wrapper .actions a {
        color: #d7aa02; }
    .page-header .minicart-wrapper #minicart-content-wrapper .minicart-items-wrapper {
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px; }
    .page-header .minicart-wrapper #minicart-content-wrapper .items-total {
      font-family: 'Montserrat'; }
    .page-header .minicart-wrapper #minicart-content-wrapper .subtotal {
      font-family: 'Montserrat'; }
    .page-header .minicart-wrapper #minicart-content-wrapper .block-content .actions {
      line-height: unset;
      padding: 0; }
    .page-header .minicart-wrapper .counter.qty {
      background-color: #d7aa02 !important;
      border-radius: 50% !important;
      position: absolute !important; }
      @media screen and (max-width: 768px) {
        .page-header .minicart-wrapper .counter.qty {
          transform: scale(0.75) translateX(-5px) translateY(-5px); } }

.cms-page-main .page-title-wrapper {
  display: none; }

.page-header {
  height: 120px; }
  @media screen and (max-width: 768px) {
    .page-header {
      height: 72px; } }
  .page-header:before {
    content: "";
    background-image: url(../images/vintage_papier_links_120.png);
    background-repeat: no-repeat;
    position: absolute;
    background-position-x: left;
    height: 120px;
    width: 175px;
    top: 0; }
    @media screen and (max-width: 768px) {
      .page-header:before {
        height: 72px;
        background-size: 40%; } }
  .page-header:after {
    content: "";
    background-image: url(../images/vintage_papier_rechts_120.png);
    background-repeat: no-repeat;
    position: absolute;
    background-position-x: right;
    height: 120px;
    width: 175px;
    top: 0;
    right: 0; }
    @media screen and (max-width: 768px) {
      .page-header:after {
        height: 72px;
        background-size: 50%; } }
    @media screen and (max-width: 1440px) {
      .page-header:after {
        right: 0; } }

.action.nav-close {
  left: 10%; }
  @media screen and (max-width: 576px) {
    .action.nav-close {
      left: 10px;
      top: 0; } }

.nav-sections-item-content {
  height: 100vh; }
  .nav-sections-item-content .navigation {
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    max-width: 100% !important;
    margin-top: 10px; }
    .nav-sections-item-content .navigation .level0 > .level-top {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
      .nav-sections-item-content .navigation .level0 > .level-top:hover {
        text-decoration: underline; }

.action.nav-toggle {
  background-image: url(../images/Vallendar_Burger.png);
  background-size: 71px;
  width: 71px;
  height: 71px;
  left: 85px;
  top: 22px;
  display: block !important; }
  .action.nav-toggle:before {
    content: ""; }
  @media screen and (max-width: 1280px) {
    .action.nav-toggle {
      left: 70px; } }
  @media screen and (max-width: 768px) {
    .action.nav-toggle {
      background-image: url(../images/Vallendar_Burger_m.png);
      background-size: 36px;
      width: 36px;
      height: 36px;
      left: 15px;
      top: 20px; } }

.block-search {
  position: absolute;
  right: 290px;
  display: block !important;
  width: auto !important;
  top: 26px;
  cursor: pointer;
  z-index: 50; }
  @media screen and (max-width: 1280px) {
    .block-search {
      right: 225px; } }
  @media screen and (max-width: 890px) {
    .block-search {
      right: 210px; } }
  @media screen and (max-width: 768px) {
    .block-search {
      top: 20px;
      right: 102px; } }
  .block-search .block-title {
    background-image: url(../images/Vallendar_Search.png);
    background-size: 71px;
    width: 71px;
    height: 71px;
    display: block;
    margin-bottom: 0 !important; }
    @media screen and (max-width: 768px) {
      .block-search .block-title {
        background-image: url(../images/Vallendar_Search_m.png);
        background-size: 36px;
        width: 36px;
        height: 36px; } }
    .block-search .block-title strong {
      display: none; }
  .block-search .block-content {
    display: none;
    position: absolute;
    padding: 30px;
    border: 1px solid #aaa;
    background-color: #fff;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    right: 0;
    margin-top: 5px; }
    @media screen and (max-width: 768px) {
      .block-search .block-content {
        top: 44px;
        right: -13px; } }
    @media screen and (max-width: 576px) {
      .block-search .block-content {
        width: 72vw !important;
        right: -73px; } }
    .block-search .block-content:before {
      right: 25px;
      top: -12px;
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99;
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
      @media screen and (max-width: 576px) {
        .block-search .block-content:before {
          right: 84px; } }
    .block-search .block-content:after {
      right: 24px;
      top: -14px;
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98;
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
      @media screen and (max-width: 576px) {
        .block-search .block-content:after {
          right: 83px; } }
    .block-search .block-content .label {
      clip: unset !important;
      overflow: unset !important;
      position: absolute;
      right: 60px;
      cursor: pointer; }
      @media screen and (max-width: 767px) {
        .block-search .block-content .label {
          position: absolute !important;
          right: 10px !important;
          top: 30px !important; } }
    .block-search .block-content .control {
      padding: 0 !important; }
      @media screen and (max-width: 767px) {
        .block-search .block-content .control {
          border-top: none !important; } }
      .block-search .block-content .control input {
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        border-radius: 0 !important;
        padding-left: 0 !important;
        padding-right: 30px !important; }
        @media screen and (max-width: 767px) {
          .block-search .block-content .control input {
            position: static !important;
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 0 !important;
            padding-right: 30px !important; } }
        .block-search .block-content .control input:focus {
          box-shadow: none !important; }
    .block-search .block-content .actions {
      display: none; }
    .block-search .block-content .nested a {
      color: #777;
      font-family: 'Droid Serif';
      font-style: italic; }
  .block-search .show {
    display: block !important; }
  .block-search #search {
    background-color: #fff;
    color: black;
    border: 1px solid #aaa;
    border-radius: 4px;
    font-size: 16px;
    padding: 2px;
    padding-left: 10px;
    font-family: 'Montserrat'; }

.breadcrumbs {
  background-color: #f4f4f4;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 100px;
  max-width: 1440px;
  margin-left: 0;
  margin-right: 0 !important;
  margin-bottom: 0;
  font-family: 'Droid Serif';
  font-weight: 400; }
  @media screen and (max-width: 1280px) {
    .breadcrumbs {
      padding-left: 85px; } }
  @media screen and (max-width: 768px) {
    .breadcrumbs {
      padding-left: 15px; } }
  .breadcrumbs .items .item {
    font-size: 14px; }
    .breadcrumbs .items .item strong {
      color: #cc9900; }

.logo-subtitle {
  font-family: 'Times New Roman';
  font-size: 10px;
  text-align: center;
  color: black;
  letter-spacing: 3px;
  font-weight: bold;
  padding-left: 6px; }
  .logo-subtitle:hover {
    text-decoration: none; }
  @media screen and (max-width: 768px) {
    .logo-subtitle {
      display: none; } }

.minicart-wrapper .action.showcart:before,
.minicart-wrapper .action.showcart.active:before {
  display: none; }

.showcartlink {
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  text-decoration: none; }

.showcart,
.showcartlink {
  background-image: url(../images/Vallendar_Shop.png);
  background-size: 71px;
  width: 71px;
  height: 71px;
  position: relative;
  margin-top: -14px;
  right: 30px; }
  .showcart.isshop,
  .showcartlink.isshop {
    background-image: url(../images/Vallendar_Cart.png);
    background-size: 81px;
    width: 81px;
    right: 0; }
  @media screen and (max-width: 768px) {
    .showcart,
    .showcartlink {
      background-image: url(../images/Vallendar_Cart_m.png);
      background-size: 36px;
      width: 36px;
      height: 36px;
      right: 0; }
      .showcart.isshop,
      .showcartlink.isshop {
        background-image: url(../images/Vallendar_Cart_m.png);
        background-size: 36px;
        width: 36px;
        height: 36px;
        right: 0; } }

.navigation-header {
  background-image: url(../images/menu_top.svg);
  background-repeat: no-repeat;
  width: 150px;
  height: 180px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 576px) {
    .navigation-header {
      display: none; } }

.header.content {
  width: 100%;
  max-width: 100%;
  padding: 0; }
  @media screen and (max-width: 768px) {
    .header.content {
      padding-left: 0; } }

.block-search {
  display: flex;
  align-items: center;
  justify-content: center;
  float: none !important;
  margin: 0 !important;
  width: 100%;
  padding: 0 !important; }
  .block-search input {
    padding: 25px 20px;
    font-size: 2.6rem;
    background: #d6aa03;
    border: 2px solid #fff; }
    .block-search input::-webkit-input-placeholder {
      color: #fff; }
    .block-search input:-moz-placeholder {
      color: #fff; }
    .block-search input::-moz-placeholder {
      color: #fff; }
    .block-search input:-ms-input-placeholder {
      color: #fff; }
  .block-search .block-content {
    width: 280px; }
  .block-search .action.search {
    right: auto !important;
    margin-left: 160px; }

.newsletter-bottom-container {
  background-image: url(../images/newsletter_bg.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  color: white; }
  .newsletter-bottom-container .newsletter-bottom-header {
    font-family: 'Droid Serif';
    font-style: italic;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px; }
  .newsletter-bottom-container .newsletter-header-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 28px;
    margin-bottom: 30px; }
  .newsletter-bottom-container .block.newsletter {
    margin: 0 auto;
    margin-top: 40px;
    width: 45%; }
    @media screen and (max-width: 768px) {
      .newsletter-bottom-container .block.newsletter {
        width: 75%; } }
    @media screen and (max-width: 540px) {
      .newsletter-bottom-container .block.newsletter {
        width: 90%; } }
    .newsletter-bottom-container .block.newsletter .field.newsletter {
      margin-right: 20px; }
      @media screen and (max-width: 1200px) {
        .newsletter-bottom-container .block.newsletter .field.newsletter {
          margin-right: 0;
          margin-bottom: 20px; } }
      .newsletter-bottom-container .block.newsletter .field.newsletter .control:before {
        display: none !important; }
      .newsletter-bottom-container .block.newsletter .field.newsletter .control input {
        height: 55px;
        font-family: 'Droid Serif';
        font-size: 18px;
        text-align-last: center;
        padding-left: 10px;
        padding-right: 10px;
        border: none; }
        .newsletter-bottom-container .block.newsletter .field.newsletter .control input:focus {
          box-shadow: none; }
    .newsletter-bottom-container .block.newsletter .actions {
      width: 47%;
      margin-left: 10px; }
      @media screen and (max-width: 1200px) {
        .newsletter-bottom-container .block.newsletter .actions {
          width: 100%;
          margin-left: 0;
          display: block; } }
      .newsletter-bottom-container .block.newsletter .actions button, .newsletter-bottom-container .block.newsletter .actions .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .newsletter-bottom-container .block.newsletter .actions .actions-toolbar > .action, .newsletter-bottom-container .block.newsletter .actions .action-gift {
        width: 100%;
        height: 55px;
        border-radius: 2px;
        color: #fff;
        font-size: 18px;
        font-weight: 500; }

.products-grid .product-item-name a {
  font-weight: 800 !important;
  hyphens: none !important; }

.products-grid .product-item-name .details {
  font-weight: 400 !important;
  margin-top: 3px; }

.product-item-name {
  margin: 0 !important;
  text-transform: uppercase;
  font-size: 14px;
  min-height: 66px !important;
  overflow: hidden;
  line-height: 16px; }

.price-box {
  margin-top: 6px !important;
  margin-bottom: 4px !important; }
  .price-box .price {
    font-size: 16px !important;
    font-weight: 700 !important; }
  .price-box .special-price {
    display: inline-block;
    margin-right: 10px; }
  .price-box .old-price {
    color: #c31e1e; }
  .price-box .price-label {
    display: none; }

.action.primary {
  background-color: #d7aa02;
  border-radius: 0;
  border: none;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400; }
  .action.primary:hover {
    border: none;
    background-color: #e1b202; }
  .action.primary:focus {
    border: none;
    background-color: #e6b602; }

.pre-footer {
  background-color: #fff; }
  .pre-footer .pre-footer-grey-bar {
    height: 20px;
    background-color: #eee;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); }
  .pre-footer .columns-wrapper-lower {
    background-color: #eee; }
    .pre-footer .columns-wrapper-lower.block {
      display: block;
      padding-bottom: 1px;
      margin-bottom: 0;
      padding-top: 40px; }
  .pre-footer .columns-wrapper-upper, .pre-footer .columns-wrapper-lower {
    display: flex;
    justify-content: space-around;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 80px;
    text-align: center;
    font-family: 'Droid Serif';
    font-weight: 400;
    margin-top: -20px; }
    @media screen and (max-width: 1200px) {
      .pre-footer .columns-wrapper-upper, .pre-footer .columns-wrapper-lower {
        flex-wrap: wrap; } }
    @media screen and (max-width: 768px) {
      .pre-footer .columns-wrapper-upper, .pre-footer .columns-wrapper-lower {
        padding-left: 85px;
        padding-right: 85px; } }
    @media screen and (max-width: 576px) {
      .pre-footer .columns-wrapper-upper, .pre-footer .columns-wrapper-lower {
        padding-left: 20px;
        padding-right: 20px; } }
    .pre-footer .columns-wrapper-upper .pre-footer-col, .pre-footer .columns-wrapper-lower .pre-footer-col {
      flex-grow: 0;
      flex-basis: 25%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 80px; }
      @media screen and (max-width: 1200px) {
        .pre-footer .columns-wrapper-upper .pre-footer-col, .pre-footer .columns-wrapper-lower .pre-footer-col {
          flex-basis: 42%; } }
      @media screen and (max-width: 700px) {
        .pre-footer .columns-wrapper-upper .pre-footer-col, .pre-footer .columns-wrapper-lower .pre-footer-col {
          flex-basis: 100%; } }
      .pre-footer .columns-wrapper-upper .pre-footer-col .title, .pre-footer .columns-wrapper-lower .pre-footer-col .title {
        font-weight: 800;
        font-family: 'Montserrat';
        font-style: normal;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 35px; }
      .pre-footer .columns-wrapper-upper .pre-footer-col .content, .pre-footer .columns-wrapper-lower .pre-footer-col .content {
        color: #777;
        line-height: 30px;
        font-style: normal;
        font-size: 16px; }
        .pre-footer .columns-wrapper-upper .pre-footer-col .content strong, .pre-footer .columns-wrapper-lower .pre-footer-col .content strong {
          font-weight: 800;
          font-style: normal;
          color: #666;
          display: block; }
        .pre-footer .columns-wrapper-upper .pre-footer-col .content a, .pre-footer .columns-wrapper-lower .pre-footer-col .content a {
          font-style: italic;
          text-decoration: none;
          color: inherit; }
        .pre-footer .columns-wrapper-upper .pre-footer-col .content .row img, .pre-footer .columns-wrapper-lower .pre-footer-col .content .row img {
          margin-right: 2px;
          margin-left: 2px;
          display: inline-block; }
        .pre-footer .columns-wrapper-upper .pre-footer-col .content img, .pre-footer .columns-wrapper-lower .pre-footer-col .content img {
          display: block;
          margin: 0 auto; }
        .pre-footer .columns-wrapper-upper .pre-footer-col .content .separator, .pre-footer .columns-wrapper-lower .pre-footer-col .content .separator {
          width: 80%;
          height: 1px;
          background-color: #ccc;
          margin: 0 auto;
          margin-top: 10px;
          margin-bottom: 20px; }

.page-footer {
  background-color: #eee; }
  .page-footer .footer.content {
    border-top: none;
    margin-top: 0 !important; }
    .page-footer .footer.content p {
      font-family: 'Droid Serif';
      margin-top: 30px;
      font-size: 12px;
      color: #777;
      text-align: center;
      line-height: 24px; }

.footer.links {
  display: flex !important;
  justify-content: space-evenly;
  padding: 0 !important;
  list-style-type: none;
  margin-bottom: -30px; }
  @media screen and (max-width: 1100px) {
    .footer.links {
      flex-wrap: wrap; } }
  @media screen and (max-width: 1100px) {
    .footer.links .nav.item {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 30px; } }
  .footer.links .nav.item a {
    color: #777;
    font-family: 'Droid Serif';
    font-size: 16px;
    font-style: italic; }
    .footer.links .nav.item a:hover {
      color: #777; }

.spacer {
  height: 10px;
  width: 100%; }

.spacer-l {
  height: 20px;
  width: 100%; }

.spacer-xl {
  height: 30px;
  width: 100%; }

.customer-welcome {
  background-image: url(../images/Vallendar_Account.png);
  position: absolute;
  top: 26px;
  right: 202px;
  background-size: 71px;
  width: 71px;
  height: 71px;
  cursor: pointer;
  z-index: 10; }
  @media screen and (max-width: 1280px) {
    .customer-welcome {
      right: 157px; } }
  @media screen and (max-width: 890px) {
    .customer-welcome {
      right: 147px; } }
  @media screen and (max-width: 768px) {
    .customer-welcome {
      background-image: url(../images/Vallendar_Account_m.png);
      background-size: 36px;
      width: 36px;
      height: 36px;
      top: 20px;
      right: 65px; } }

.customer-dropdown {
  display: none;
  position: absolute;
  padding: 30px;
  padding-bottom: 20px;
  border: 1px solid #aaa;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  right: 197px;
  top: 117px;
  z-index: 50; }
  @media screen and (max-width: 1280px) {
    .customer-dropdown {
      right: 147px; } }
  @media screen and (max-width: 768px) {
    .customer-dropdown {
      right: 52px;
      top: 64px; } }
  .customer-dropdown:before {
    right: 25px;
    top: -12px;
    border: 6px solid;
    border-color: transparent transparent #fff transparent;
    z-index: 99;
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0; }
  .customer-dropdown:after {
    right: 24px;
    top: -14px;
    border: 7px solid;
    border-color: transparent transparent #bbb transparent;
    z-index: 98;
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0; }
  .customer-dropdown .customer-name {
    font-weight: 800; }
  .customer-dropdown .item {
    margin-bottom: 10px;
    font-family: 'Droid Serif'; }
    .customer-dropdown .item a {
      color: #777; }
  .customer-dropdown.show {
    display: block !important; }

#wishlist-sidebar .product-item .product-item-info .product-item-details {
  margin-left: 0 !important;
  padding: 0 !important;
  height: unset !important;
  margin-bottom: 40px; }
  #wishlist-sidebar .product-item .product-item-info .product-item-details .product-item-name {
    margin-left: 25px !important;
    margin-bottom: 10px !important; }
  #wishlist-sidebar .product-item .product-item-info .product-item-details .price-box {
    margin-left: 25px; }
  #wishlist-sidebar .product-item .product-item-info .product-item-details .product-item-actions {
    position: unset !important; }
    #wishlist-sidebar .product-item .product-item-info .product-item-details .product-item-actions .actions-secondary .btn-remove {
      right: unset !important;
      left: 0 !important;
      top: -2px !important; }

.action.back {
  display: none !important; }

.shipping-cost-details a {
  color: #d7aa02; }

.arrows .arrow-left {
  position: absolute;
  top: 35%;
  left: -24px;
  background-image: url(../images/weiter-zurueck.svg);
  width: 24px;
  height: 24px;
  background-size: cover;
  cursor: pointer;
  user-select: none;
  display: block;
  transform: rotate(180deg); }
  .arrows .arrow-left.slick-hidden {
    display: none; }
  .arrows .arrow-left.slick-disabled {
    cursor: default;
    filter: saturate(0%);
    opacity: 0.2; }

.arrows .arrow-right {
  position: absolute;
  top: 35%;
  right: -24px;
  background-image: url(../images/weiter-zurueck.svg);
  width: 24px;
  height: 24px;
  background-size: cover;
  cursor: pointer;
  user-select: none;
  display: block; }
  .arrows .arrow-right.slick-hidden {
    display: none; }
  .arrows .arrow-right.slick-disabled {
    cursor: default;
    filter: saturate(0%);
    opacity: 0.2; }

.productslider {
  width: 100%; }
  .productslider .product {
    margin: 10px;
    border: 2px solid #f2f2f2; }
    .productslider .product .image {
      height: 300px;
      background-color: #f2f2f2;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
    .productslider .product .details {
      padding: 20px;
      padding-top: 17px;
      text-align: left;
      background-color: white; }
      .productslider .product .details .title {
        margin: 0 !important;
        text-transform: uppercase;
        font-size: 14px;
        height: 70px !important;
        line-height: 16px;
        font-weight: 800;
        text-align: left; }
        .productslider .product .details .title a {
          color: black; }
        .productslider .product .details .title .alc {
          font-weight: 400;
          margin-top: 4px; }
      .productslider .product .details .price {
        font-family: 'Montserrat';
        font-weight: 800;
        font-size: 16px;
        margin-bottom: 10px; }
        .productslider .product .details .price .label {
          font-weight: 400;
          font-size: 12px; }
        .productslider .product .details .price .old-price {
          color: #c31e1e;
          text-decoration: line-through;
          padding-left: 10px; }
      .productslider .product .details .tax {
        font-family: 'Montserrat';
        margin-bottom: 4px; }
        .productslider .product .details .tax a {
          color: #d7aa02; }

.catalog-product-compare-index .column.main {
  padding: 0 !important; }
  .catalog-product-compare-index .column.main .table-comparison tbody:first-of-type tr {
    background-color: white; }
    .catalog-product-compare-index .column.main .table-comparison tbody:first-of-type tr:nth-child(odd) {
      background-color: white; }
  .catalog-product-compare-index .column.main .table-comparison tbody tr:nth-child(odd) {
    background-color: #efefef; }

.page-print .columns {
  margin: 0; }

.page-print .page-title-wrapper {
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 20px !important; }

.page-print #maincontent {
  background-color: white; }

a.order-number {
  color: #d7aa02 !important; }
  a.order-number:hover {
    color: #d7aa02 !important; }
  a.order-number:visited {
    color: #d7aa02 !important; }
  a.order-number:active {
    color: #d7aa02 !important; }

a.print {
  background-color: #d7aa02;
  padding: 10px;
  color: white;
  border-radius: 3px;
  margin: 0; }
  a.print:hover {
    text-decoration: none;
    color: white; }

.catalogsearch-result-index .sidebar-additional {
  display: none !important; }

.cc-banner.cc-bottom {
  z-index: 20; }
