//------------------------------------------------------------
// PRODUCT VIEW PAGE
//------------------------------------------------------------

.fotorama--fullscreen {
    .fotorama__wrap {
        .fotorama__stage {
            height: 100vh !important;
        }
    }
}

.catalog-product-view {

    .columns {
        @media screen and (max-width: 768px){
            margin-left: 45px;
            margin-right: 45px;
        }
        @media screen and (max-width: 576px){
            margin-left: 20px;
            margin-right: 20px;
        }

        // .column.main {
        //     display: flex;
        //     flex-wrap: wrap;

        //     .product-info-main {
        //         margin-left: 50px;
        //         margin-bottom: 25px;
        //         order: 2;
        //         position: relative;
        //     }
        //     .product-media {
        //         order: 1;
        //     }
        //     .product.info.detailed {
        //         order: 3;
        //         width: 100%;
        //     }
        //     .block.related {
        //         order: 4;
        //         width: 100%;
        //     }
        // }
    }

    .page-main {
        padding-top: 40px;
        @media screen and (max-width: 768px){
            padding-top: 0;
        }
    }
    .product.media {
        margin-left: -100px;
        @media screen and (max-width: 768px){
            width: 50%;
            margin-left: -45px;
        }
        @media screen and (max-width: 767px){
            width: 100vw;
        }
        @media screen and (max-width: 576px){
            margin-left: -20px;
        }
        .fotorama__stage {
            height: 500px !important;
            background-color: #f2f2f2;

            @media screen and (max-width: 576px){
                height: 350px !important;
            }

            .fotorama__caption {
                display: none;
            }
        }
        .fotorama__nav.fotorama__nav--thumbs {
            display: flex !important;
            justify-content: space-evenly !important;
        }
        .fotorama__arr {
            display: none !important;
        }

        .fotorama__wrap {
            position: relative !important;
        }
        .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
            position: absolute !important;
            bottom: 10px !important;
        }
        .fotorama__thumb__arr {
            display: none !important;
        }
        .fotorama__nav__frame.fotorama__nav__frame--thumb {
            border-radius: 50%;
            overflow: hidden;
            padding: 0!important;
            margin-right: 2px;

            .fotorama__thumb {
                background-color: #999;

                img {
                    display: none;
                }
            }
        }
        .fotorama__thumb-border {
            width: 10px;
            height: 10px;
            background-color: rgb(215, 170, 2);
            border: none;
            border-radius: 50%;
            margin-top: 0 !important;
        }
        .fotorama__nav__frame--dot {
            &.fotorama__active {
                .fotorama__dot {
                    background-color: $primary_yellow;
                    border-color: $primary_yellow;
                    height: 10px;
                    width: 10px;
                }
            }
            .fotorama__dot {
                background-color: #999;
                border-color: #999;
                height: 10px;
                width: 10px;
            }
        }
    }
    .product.info.detailed {
        @media screen and (max-width: 767px){
            margin-bottom: 60px !important;
        }
    }
    .product-info-main {
        width: 47%;
        margin-bottom: 40px;
        @media screen and (min-width: 768px){
            float: right !important;
        }
        @media screen and (max-width: 768px){
            width: 50%;
            margin-top: 40px;
        }
        @media screen and (max-width: 767px){
            width: 100%;
        }

        .page-title-wrapper {
            margin-top: 7px;
        }
        .price-box {
            display: none;
        }
        .price-details {
            display: none;
        }
        .product-info-price {
            display: inline-block;
            margin-right: 20px;

            .price-box {
                display: block;
                margin-top: 0 !important;
                margin-bottom: 6px !important;

                .special-price {
                    display: inline;
                }
                .price-label {
                    display: none;
                }
                .price {
                    font-family: 'Droid Serif';
                    font-size: 18px !important;
                    font-weight: 700 !important;
                    margin-right: 20px !important;
                }
            }
            .price-details {
                display: block;
                margin-bottom: 20px;
            }
        }
        .product.attribute.delivery_time {
            display: inline-block;
            font-family: 'Droid Serif';
            color: #777;
            @media screen and (max-width: 768px){
                margin-bottom: 20px;
            }

            .type {
                color: black;
                line-height: 29px;
            }
        }

        .product.attribute.description {
            position: relative;

            .description-toggle {
                display: block;
                cursor: pointer;
                padding-top: 20px;
                width: 160px;
                transition: 0.2s;
                font-family: 'Droid Serif';
                font-size: 16px;
                color: #777;
            }
            .description {
                max-height: 158px;
                overflow: hidden;
                transition: 0.2s;
                font-family: 'Droid Serif' !important;
                font-size: 16px;
                color: #777;
                line-height: 28px;
                hyphens: auto;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 40px;
                    left: 0;
                    right: 0;
                    height: 140px;
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                    transition: 0.2s;
                }
                &:hover {
                    &:after {
                        height: 50px;
                        transition: 0.2s;
                    }
                }

                p {
                    font-family: 'Droid Serif' !important;
                    font-size: 16px;
                    color: #777 !important;
                    line-height: 28px;
                    hyphens: auto;

                    span {
                        font-family: 'Droid Serif' !important;
                        font-size: 16px;
                        color: #777 !important;
                        line-height: 28px;
                        hyphens: auto;
                    }
                }
            }
            .description-hover {
                &:after{
                    height: 20px;
                    transition: 0.2s;
                }
            }
            .opened {
                max-height: 100000px;
                transition: 0.2s;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 40px;
                    left: 0;
                    right: 0;
                    height: 0px;
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                    transition: 0.2s;
                }
                &:hover {
                    &:after {
                        height: 0 !important;
                    }
                }
            }
        }

        #product-addtocart-button {
            padding-left: 40px;
            padding-right: 40px;
            margin-left: 20px;
            @media screen and (max-width: 940px){
                margin-left: 0;
                display: block;
                width: 100%;
            }
        }

        .action.tocompare,
        .action.towishlist {
            background-color: white;
            border-radius: 0;
            height: 32px;
            width: 200px;
            text-align: center;
            line-height: 21px;
            font-family: 'Montserrat';
            font-weight: 400;
            text-transform: uppercase;
            font-size: 12px;
            overflow: hidden;
            margin-right: 4px;
            @media screen and (max-width: 767px){
                width: 100%;
                line-height:40px;
                height: 50px;
                font-size: 16px;
            }
        }

        .product-add-form {
            border-bottom: 1px solid #ddd;
            padding-bottom: 20px;
            margin-bottom: 40px;
            @media screen and (max-width: 767px){
                border: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        .quantity-selector {
            display: inline-block;
            margin-top: 24px;

            .qty-minus {
                display: inline-block;
                border: 2px solid #777;
                color: #777;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 26px;
                font-size: 28px;
                cursor: pointer;
                user-select: none;
            }
            .qty-plus {
                display: inline-block;
                border: 2px solid #777;
                color: #777;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                font-size: 28px;
                transform: translateY(2px);
                cursor: pointer;
                user-select: none;
            }
        }


        .box-tocart {
            @media screen and (max-width: 768px){
                width: 100%;
            }

            .field.qty {
                @media screen and (max-width: 940px){
                    display: block;
                }
                label {
                    display: none;
                }
            }
            .actions {
                @media screen and (max-width: 940px){
                    padding-top: 0;
                    display: block;
                    width: 100%;
                }
            }

            input.input-text.qty {
                border: none;
                font-size: 24px;
                font-family: 'Montserrat';
                user-select: none;

                &:focus {
                    box-shadow: none;
                }
            }
        }

    }
    .page-title {
        font-family: 'Droid Serif';
        font-size: 28px;
        margin-bottom: 18px !important;
    }

    .data.item.title.active {
        color: $color_white !important;

        @media screen and (max-width: 576px){
            width: 100vw;
            margin-left: -20px;
        }
        a {
            background-color: $primary_yellow !important;
            font-family: 'Montserrat' !important;
            border: none !important;
            font-weight: 500 !important;
            text-transform: uppercase !important;
            color: inherit !important;

            @media screen and (max-width: 767px){
                background-color: transparent !important;
                color: black !important;
                font-weight: 800 !important;
                border-top: 1px solid #ddd !important;

                &:after {
                    content: '-';
                    float: right;
                }
            }
        }
    }
    .data.item.title {
        margin-right: 0 !important;
        margin-bottom: 0 !important;

        @media screen and (max-width: 576px){
            width: 100vw !important;
            margin-left: -20px !important;
        }
        a {
            background-color: transparent !important;
            font-family: 'Montserrat' !important;
            border: 1px solid #aaa !important;
            border-bottom: none !important;
            font-weight: 500 !important;
            text-transform: uppercase !important;

            @media screen and (max-width: 767px){
                background-color: transparent !important;
                color: black !important;
                font-weight: 800 !important;
                border-left: none !important;
                border-right: none !important;
                border-top: 1px solid #ddd !important;
                border-bottom: 1px solid #ddd !important;

                &:after {
                    content: '+';
                    float: right;
                }
            }
        }
    }
    .data.item.content {
        border: 1px solid #aaa !important;
        border-top-color: $primary_yellow !important;

        @media screen and (max-width: 767px){
            border: none !important;
            padding: 0 !important;
            padding-top: 10px !important;
        }
        &#additional {
            padding-top: 35px;
        }
        &#awards {
            padding-top: 35px;

            .award-details {
                font-family: "Droid Serif";
                font-size: 16px;
                line-height: 28px;
                font-weight: 400;
                color: #333;
                font-style: italic;

                .single-award {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .additional-attributes-wrapper {
        font-family: 'Droid Serif';
        font-size: 16px;
        font-style: italic;

        th {
            font-weight: 800;
        }
        td {
            font-weight: 400;
            white-space: pre-line;
        }
    }

    .more-from-category-header {
        font-size: 22px;
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 40px;
    }

    .more-products-slider-wrapper {
        display: flex;
        .product-item {
            padding-left: 0;
            border: 2px solid #f2f2f2;
            margin-right: 20px;
            width: 300px;
            &:last-child {
                margin-right: 0;
            }

            .product-item-details {
                height: auto;
            }
        }

    }
    .review-form {
        max-width: 600px;

        .fieldset.review-fieldset {

            .legend.review-legend {

                span {
                    display: inline-block;
                    font-family: 'Droid Serif';
                    font-style: italic;
                    font-size: 18px;
                    margin-right: 10px;
                }
                strong {
                    display: inline-block;
                    font-family: 'Droid Serif';
                    font-style: italic;
                    font-size: 18px;
                }
            }
            .field {

                label {
                    font-family: 'Droid Serif';
                    font-size: 16px;
                    font-style: italic;
                    text-align: left;
                    width: 100%;
                }
                .control {
                    width: 100%;

                    input {
                        font-family: 'Droid Serif';
                    }
                }
            }
        }
        .actions-toolbar.review-form-actions {
            margin-left: 0;

            .primary.actions-primary {
                button {
                    background-color: $primary_yellow;
                    border: none;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 30px;
                    padding-right: 30px;
                    color: white;
                    font-size: 18px;
                    &:hover {
                        border: none;
                        background-color: lighten($primary-yellow, 2);
                    }
                    &:focus {
                        border: none;
                        background-color: lighten($primary-yellow, 3);
                    }
                }
            }
        }
    }

    .review {
        font-family: 'Droid Serif';
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 576px){
            flex-direction: column;
        }

        &:last-child {
            border-bottom: none;
        }
        .rating {
            display: inline-block;
            width: 100px;
            margin-right: 20px;
            flex-shrink: 0;
        }
        .text{
            display: inline-block;
            font-size: 16px;
            font-style: italic;
        }
    }
    .hidden-reviews {
        display: none;
    }
    .show-reviews {
        display: block;
    }

    .block.related {

        .block-content {
            position: relative;
        }

        .arrow-left {
            position: absolute;
            top: 35%;
            left: -40px;
            background-image: url(../images/weiter-zurueck.svg);
            width: 24px;
            height: 24px;
            background-size: cover;
            transform: rotate(180deg);
            cursor: pointer;
            user-select: none;
            @media screen and (max-width: 576px){
                top: 103%;
                left: 33%;
            }
            &.slick-disabled {
                cursor: default;
                filter: saturate(0%);
                opacity: 0.2;
            }
        }
        .arrow-right {
            position: absolute;
            top: 35%;
            right: -40px;
            background-image: url(../images/weiter-zurueck.svg);
            width: 24px;
            height: 24px;
            background-size: cover;
            cursor: pointer;
            user-select: none;
            @media screen and (max-width: 576px){
                top: 103%;
                right: 33%;
            }
            &.slick-disabled {
                cursor: default;
                filter: saturate(0%);
                opacity: 0.2;
            }
        }
        .products.list {
            margin-left: 0;

            .item.product {
                padding-left: 0;
                border: 2px solid #f2f2f2;

                .field.choice.related {
                    display: none;
                }
            }

            .item.product + .item.product {
                //margin-left: 20px;
            }
        }
    }

    .slick-slide {
        margin: 0 10px;
    }
    .slick-list {
        margin: 0 -10px;
    }

    .show-all-reviews {
        margin-top: 20px;
    }

    .tax-details {
        font-family: 'Droid Serif';
        color: #777;
        font-size: 14px;
    }

    .product-info-stock-sku {
        margin-bottom: 8px;
    }

    .field.configurable {
        margin-top: 0px;
        margin-bottom: 20px;

        .label {
            display: none;

            &.mage-error {
                border-width: 2px;
            }
        }
        .control {
            display: block;

            select {
                width: 100%;
                height: 32px;
                text-align-last: center;
                padding-bottom: 7px;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 13px;
                font-family: 'Montserrat';
                line-height: 16px;
                appearance: none;
                border-radius: 0;
                cursor: pointer;
            }
        }
    }

    .swatch-attribute-label { z-index: 99999;}
    .swatch-option-tooltip {
        max-width: 0px !important;
        max-height: 0px !important;
        overflow:hidden;
        min-height: 0px !important;
        min-width: 0px !important; border:none !important;
        background:transparent !important; 
    }

    .swatch-attribute.amount {

        .swatch-option-tooltip {
            display: none !important;
        }
        
        .swatch-attribute-label {
            display: none;
        }
        .swatch-attribute-selected-option {
            display: none;
        }
        .swatch-attribute-options {
            margin-top: 0;
            display: inline-block;
            
            &:focus {
                box-shadow: none;

                .swatch-option {
                    border-color: red;
                }
            }

            .swatch-option + .swatch-option {
                margin-left: 10px;
            }

            .swatch-option {
                height: 35px;
                width: 64px;
                line-height: 35px;
                font-size: 12px;
                font-weight: 500;
                font-family: 'Montserrat';
                border: 1px solid #aaa;
                border-radius: 2px;
                background-color: white;
                outline: none !important;
                padding: 0;
                margin-bottom: 0;
                margin-right: 0;

                &:hover {
                    outline: none;
                    background-color: #f2f2f2;
                }
                
                &.selected {
                    background-color: $primary-yellow !important;
                    color: white !important;
                    border: 1px solid $primary-yellow;
                    outline: none !important;
                }
            }
        }
    }

    .stock.available {
        display: none;
    }
    
}