$grey: #777;

@mixin step-title {
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 40px;

    @media screen and (max-width: 768px){
        margin-bottom: 50px;
    }
}

@mixin radioStyle {
    appearance: none;
    width: 25px;
    height: 25px;
    border: 1px solid $primary_yellow;
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;

    &:checked {
        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: $primary_yellow;
        }
    }
}

.checkout-index-index {
    font-family: 'Montserrat';

    form {
        width: 100% !important;
        max-width: unset !important;

        &.form-login {
            border: none !important;
        }

        .fieldset {
            width: 100%;
            max-width: unset;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: 13px;
            margin: 0;

            @media screen and (max-width: 768px){
                display: block;
            }

            &#customer-email-fieldset {
                grid-template-columns: 1fr;

                .actions-toolbar {
                    margin: 0;

                    .primary {
                        width: 100%;

                        button {
                            width: 100%;
                        }
                    }

                    .secondary {
                        a {
                            color: $primary_yellow;
                        }
                    }
                }
            }

            legend {
                display: none !important;
            }
        }

        .field-tooltip {
            display: none;
        }

        .field {
            width: 100%;
            max-width: unset;
            position: relative;
            color: #777;
            margin: 0 0 30px;
            height: 32px;

            @media screen and (max-width: 768px){
                margin: 0 0 30px;
            }

            &[name="shippingAddress.street.0"] {
                label {
                    padding: 6px 15px 0 0;

                    &:after {
                        content: '*';
                        color: $primary_yellow;
                        margin-left: 2px;
                        position: absolute;
                        font-size: 1.2rem;
                    }
                }
            }

            label {
                position: absolute !important;
                top: 0px;
                padding: 0 !important;
                font-family: 'Droid Serif';
                display: block !important;
                float: none;
                width: 100% !important;
                float: none !important;
                text-align: left !important;
                font-size: 16px;
                font-weight: 400 !important;
                z-index: 1;
                transition: 0.2s;
                @media screen and (max-width: 700px){
                    bottom: 0;
                }
                &:after {
                    color: $primary_yellow !important;
                    margin-left: 2px !important;
                    top: unset !important;
                }
            }
            .control {
                width: 100% !important;
                display: block;
                position: relative;
                z-index: 10;
                @media screen and (max-width: 540px){
                    width: 100%;
                }

                input,
                select {
                    appearance: none;
                    border: none;
                    border-bottom: 2px solid #aaa;
                    width: 100%;
                    display: block;
                    background: transparent;
                    padding: 0;
                    font-family: 'Droid Serif';
                    font-size: 16px;
                    color: #777;
                    transition: 0.2s;

                    &:focus {
                        box-shadow: none;
                    }

                    &:placeholder-shown {
                        text-align: end;
                    }
                }

                .field-error {
                    display: none;
                }
            }

            &.focused {

                label {
                    top: -24px;
                    font-size: 14px;
                    font-weight: 400 !important;
                    transition: 0.2s;
                    @media screen and (max-width: 700px){
                        bottom: 30px;
                    }
                }
                .control {

                    input,
                    select {
                        border-color: $primary_yellow;
                        color: $primary_yellow;
                        transition: 0.2s;

                        &:placeholder-shown {
                            text-align: start;
                        }
                    }
                }
            }

            &._error {
                label {
                    color: #e02b27;
                }
                .control {
                    input,
                    select {
                        border-color: #e02b27;
                    }
                }
            }
        }
    }

    aside {
        .modal-inner-wrap {
            .modal-content {
                word-break: break-word;
                hyphens: auto;
            }
        }

        @media screen and (max-width: 768px) {
            left: 20px !important;

            .modal-inner-wrap {
                height: 100vh !important;
            }
        }

        #opc-new-shipping-address {
            padding-top: 50px;

            .field.choice {
                grid-column-start: 1;
                grid-column-end: span 2;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                height: unset;

                &:before,
                &:after {
                    display: none !important;
                }

                input[type="checkbox"] {
                    @include radioStyle;
                    margin-right: 25px;
                    flex-shrink: 0;
                }

                label {
                    position: relative !important;
                    top: unset;
                    bottom: unset;
                    font-size: 16px;
                    font-weight: 700 !important;
                    font-family: 'Montserrat';
                }
            }
        }
    }

    .page-main {
        background-color: #f4f4f4;
        padding-top: 25px;

        @media screen and (max-width: 768px) {
            padding-top: 15px;
        }

        .page-title-wrapper {
            display: none;
        }
        .columns {
            max-width: 1240px;
            margin: 0 auto;
            @media screen and (max-width: 1280px){
                margin-left: 20px;
                margin-right: 20px;
            }
            .column.main {

                .checkout-container {
                    display: grid;
                    grid-template-columns: repeat(12, minmax(0, 1fr));
                    grid-column-gap: 20px;

                    .authentication-wrapper {
                        display: none !important;
                    }

                    .opc-estimated-wrapper {
                        @media screen and (max-width: 768px){
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 20px;
                            margin: 0;
                            grid-column-start: 1;
                            grid-column-end: span 12;
                            border: none;
                            margin-bottom: 20px;
                            background: white;

                            &:before,
                            &:after {
                                display: none;
                            }

                            .estimated-block {
                                font-size: 16px;
                                line-height: 120%;
                                font-family: 'Droid Serif';
                                font-weight: 400;
                            }

                            .minicart-wrapper {
                                .showcart {
                                    background: $primary_yellow;
                                    border: none;
                                    border-radius: 0;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        top: 0;
                                        bottom: 0;
                                        background-image: url(../images/Vallendar_Cart_m.png);
                                        background-size: 100% 100%;
                                        background-repeat: no-repeat;
                                        filter: invert(1);
                                    }

                                    .counter.qty {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .opc-progress-bar {
                        display: contents;
                        margin-bottom: 60px;

                        .opc-progress-bar-item {
                            width: 100%;
                            margin-bottom: 60px;

                            @media screen and (max-width: 768px) {
                                margin-bottom: 20px;
                            }

                            &:first-child {
                                grid-column-start: 1;
                                grid-column-end: span 6;

                                span {
                                    &:after {
                                        content: '1';
                                    }
                                }
                            }

                            &:last-child {
                                grid-column-start: 7;
                                grid-column-end: span 6;

                                span {
                                    &:after {
                                        content: '2';
                                    }
                                }
                            }

                            &:before {
                                height: 1px;
                                background: $grey;
                            }

                            span {
                                color: $grey;
                                font-size: 18px;
                                line-height: 150%;
                                font-weight: 400;
                                padding-top: 40px;

                                @media screen and (max-width: 768px) {
                                    font-size: 12px
                                }

                                &:before {
                                    width: 24px;
                                    height: 24px;
                                    margin-left: -12px;
                                    top: 8px;
                                    background: $grey;
                                }

                                &:after {
                                    color: $grey;
                                    font-family: 'Montserrat';
                                    font-size: 16px;
                                    line-height: 22px;
                                    font-weight: 500;
                                    width: 20px;
                                    height: 20px;
                                    margin-left: -10px;
                                    top: 10px;
                                }
                            }

                            &._active,
                            &._complete {
                                &:before {
                                    background: $primary_yellow;
                                }

                                span {
                                    color: $primary_yellow;
                                    font-weight: 700;

                                    &:before {
                                        background: $primary_yellow;
                                    }

                                    &:after {
                                        color: $primary_yellow;
                                        font-family: 'Montserrat';
                                    }
                                }
                            }
                        }
                    }

                    .opc-wrapper {
                        width: unset;
                        max-width: unset;
                        margin: 0;
                        padding: 0;
                        grid-column-start: 1;
                        grid-column-end: span 7;

                        @media screen and (max-width: 1024px){
                            grid-column-start: 1;
                            grid-column-end: span 12;
                        }

                        .opc {
                            li {
                                background: white;
                                padding: 35px;
                                margin-bottom: 35px;
                                position: relative;

                                @media screen and (max-width: 768px){
                                    padding: 20px;
                                    margin-bottom: 20px;
                                }

                                .step-title {
                                    margin-left: 70px;
                                    @include step-title;

                                    &:before {
                                        position: absolute;
                                        left: 35px;
                                        top: 0;
                                        background: $primary_yellow;
                                        color: white;
                                        width: 53px;
                                        height: 62px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        @media screen and (max-width: 768px){
                                            left: 20px;
                                        }
                                    }
                                }

                                .step-content {
                                    margin: 0;
                                }

                                &#shipping {
                                    .step-title {
                                        &:before {
                                            content: '1';
                                        }
                                    }

                                    .shipping-address-items {
                                        .shipping-address-item {
                                            padding: 15px 35px 15px 18px;

                                            &.selected-item {
                                                border-color: $primary_yellow;

                                                &:after {
                                                    background: $primary_yellow;
                                                }
                                            }
                                        }

                                        .action-select-shipping-item {
                                            float: right;
                                            margin: 0;
                                            position: absolute;
                                            top: 0;
                                            bottom: 0;
                                            left: 0;
                                            right: 0;
                                            color: transparent;
                                            background: transparent;
                                            border-color: transparent;

                                            &:hover {
                                                border-color: $primary_yellow;
                                            }
                                        }
                                    }

                                    .new-address-popup {
                                        button {
                                            border: none;
                                            border-radius: 0;
                                            background: $primary_yellow;
                                            color: white;
                                            font-weight: 500;
                                            text-transform: uppercase;
                                        }
                                    }

                                    .edit-address-link {
                                        span {
                                            color: $primary_yellow;
                                        }
                                    }
                                }

                                &#opc-shipping_method {
                                    .step-title {
                                        margin-bottom: 10px !important;
                                        padding-bottom: 10px !important;

                                        &:before {
                                            content: '2';
                                        }

                                        @media screen and (max-width: 768px) {
                                            margin-bottom: 20px !important;
                                        }
                                    }

                                    .checkout-shipping-method {
                                        position: unset;

                                        .table-checkout-shipping-method {
                                            font-size: 16px;
                                            width: 100%;
                                            display: block;

                                            tbody {
                                                tr {
                                                    td {
                                                        padding-top: 10px;
                                                        padding-bottom: 10px;

                                                        &.col-price {
                                                            width: 50px;
                                                        }

                                                        &.col-method {
                                                            &:last-of-type {
                                                                display: none;
                                                            }

                                                            input[type="radio"] {
                                                                @include radioStyle;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &#payment {
                                    .step-title {
                                        margin-bottom: 30px;

                                        &:before {
                                            content: '3';
                                        }
                                    }
                                    .step-content {
                                        display: contents;

                                        #co-payment-form {
                                            display: contents;

                                            > fieldset {
                                                display: contents;

                                                > br {
                                                    display: none;
                                                }

                                                .opc-payment {
                                                    display: contents;

                                                    .payment-methods {
                                                        display: contents;

                                                        .payment-group {
                                                            .payment-method {
                                                                padding: 25px 0;
                                                                border-bottom: 1px solid #9BA2A7;

                                                                &:last-child {
                                                                    border-bottom: none;
                                                                }

                                                                .payment-method-title {
                                                                    padding: 0;
                                                                    margin: 0;
                                                                    display: flex;
                                                                    align-items: center;
                                                                    border: none;
                                                                    height: unset;

                                                                    input[type="radio"] {
                                                                        @include radioStyle;
                                                                        margin-right: 25px;
                                                                        flex-shrink: 0;
                                                                    }

                                                                    label {
                                                                        position: relative !important;
                                                                        top: unset;
                                                                        bottom: unset;
                                                                        font-size: 16px;
                                                                        font-weight: 700 !important;
                                                                        font-family: 'Montserrat';

                                                                        img {
                                                                            display: none !important;
                                                                        }

                                                                        .action-help {
                                                                            display: none !important;
                                                                        }
                                                                    }
                                                                }

                                                                .payment-method-content {
                                                                    padding: 0;
                                                                    padding-top: 20px;
                                                                    font-size: 16px;
                                                                    color: #777;

                                                                    .payment-image,
                                                                    .payment-description,
                                                                    .payment-method-note {
                                                                        display: none;
                                                                    }

                                                                    .payment-method-billing-address {
                                                                        .checkout-billing-address {
                                                                            margin: 0;

                                                                            > .field {
                                                                                display: flex;
                                                                                align-items: center;
                                                                                margin-bottom: 10px;
                                                                                height: unset;

                                                                                input[type="checkbox"] {
                                                                                    @include radioStyle;
                                                                                    margin-right: 25px;
                                                                                    flex-shrink: 0;
                                                                                }

                                                                                label {
                                                                                    position: relative !important;
                                                                                    top: unset;
                                                                                    bottom: unset;
                                                                                    font-size: 16px;
                                                                                    font-weight: 700 !important;
                                                                                    font-family: 'Montserrat';
                                                                                }
                                                                            }

                                                                            .billing-address-details {
                                                                                padding-left: 50px;
                                                                            }

                                                                            > fieldset {
                                                                                display: block;
                                                                                padding-left: 50px;
                                                                                margin-top: 40px;
                                                                                box-sizing: border-box;
                                                                                margin-bottom: 50px;

                                                                                .field-select-billing {
                                                                                    margin: unset;
                                                                                    height: unset;
                                                                                }

                                                                                .billing-address-form {
                                                                                    max-width: unset;
                                                                                }

                                                                                .actions-toolbar {
                                                                                    margin: 0;

                                                                                    .primary {
                                                                                        float: unset;
                                                                                        display: grid;
                                                                                        grid-template-columns: repeat(2, minmax(0, 1fr));
                                                                                        grid-column-gap: 30px;

                                                                                        .action-update,
                                                                                        .action-cancel {
                                                                                            border-radius: 0;
                                                                                            border: none;
                                                                                            font-family: 'Montserrat';
                                                                                            text-transform: uppercase;
                                                                                            color: white;
                                                                                            margin: 0;
                                                                                        }

                                                                                        .action-update {
                                                                                            background-color: $primary_yellow;
                                                                                        }

                                                                                        .action-cancel {
                                                                                            background-color: $grey;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    p[data-bind="html: getInstructions()"] {
                                                                        padding-left: 50px;
                                                                        margin-bottom: 50px;
                                                                    }

                                                                    .checkout-agreements-block {
                                                                        .checkout-agreements {
                                                                            display: block;

                                                                            .field {
                                                                                display: flex;
                                                                                align-items: center;
                                                                                margin-bottom: 20px;
                                                                                height: unset;

                                                                                &:before {
                                                                                    display: none;
                                                                                }

                                                                                input[type="checkbox"] {
                                                                                    @include radioStyle;
                                                                                    margin-right: 25px;
                                                                                    flex-shrink: 0;
                                                                                }

                                                                                label {
                                                                                    position: relative !important;
                                                                                    top: unset;
                                                                                    bottom: unset;
                                                                                    font-size: 16px;
                                                                                    font-weight: 700 !important;
                                                                                    font-family: 'Montserrat';

                                                                                    &:after {
                                                                                        display: none;
                                                                                    }

                                                                                    button {
                                                                                        text-align: start;

                                                                                        span {
                                                                                            color: $primary_yellow;
                                                                                            text-decoration: underline;
                                                                                            font-size: 14px;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .discount-code {
                                                    margin: 0;

                                                    .payment-option-title {
                                                        display: none;
                                                    }

                                                    .payment-option-content {
                                                        display: block !important;
                                                        padding: 0;

                                                        .form-discount {
                                                            display: grid;
                                                            grid-template-columns: repeat(2, minmax(0, 1fr));
                                                            grid-column-gap: 30px;
                                                            margin-top: 40px;
                                                            max-width: unset;

                                                            @media screen and (max-width: 768px) {
                                                                grid-template-columns: 1fr;
                                                                grid-row-gap: 20px;
                                                            }

                                                            .payment-option-inner {
                                                                margin: 0;
                                                                margin-top: 8px;

                                                                .field {
                                                                    margin: 0;

                                                                    label {
                                                                        height: unset;
                                                                        clip: unset;
                                                                        overflow: unset;
                                                                    }

                                                                    input::placeholder {
                                                                        color: transparent;
                                                                    }
                                                                }
                                                            }

                                                            .actions-toolbar {
                                                                .primary {
                                                                    width: 100%;
                                                                    height: 100%;
                                                                    font-size: 18px;
                                                                    font-weight: 500;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .opc-sidebar {
                        width: unset;
                        max-width: unset;
                        margin-top: 0;
                        grid-column-start: 9;
                        grid-column-end: span 4;

                        @media screen and (max-width: 1200px){
                            grid-column-start: 8;
                            grid-column-end: span 5;
                        }

                        @media screen and (max-width: 1024px){
                            grid-column-start: 1;
                            grid-column-end: span 12;
                        }

                        @media screen and (max-width: 767px){
                            left: 20px;

                            .modal-inner-wrap {
                                background: white;
                            }
                        }

                        .opc-block-summary {
                            background: white;
                            padding: 35px;
                            margin-bottom: 35px;

                            @media screen and (max-width: 768px){
                                padding: 20px;
                            }

                            > .title {
                                @include step-title;
                                margin-bottom: 25px;

                                @media screen and (max-width: 768px){
                                    margin-bottom: 25px;
                                }
                            }

                            .table-totals {
                                font-size: 16px;

                                tr {
                                    th {
                                        padding-left: 0;
                                    }
                                    td {
                                        padding-right: 0;
                                    }

                                    &.grand.totals.incl {
                                        th,
                                        td {
                                            font-weight: 700;
                                            border-top: 1px solid $grey;
                                            padding-bottom: 20px;
                                            padding-top: 20px;

                                            strong {
                                                font-weight: 700;
                                            }
                                        }
                                    }

                                    &.grand.totals.excl {
                                        th,
                                        td {
                                            font-size: 16px;
                                            border-top: 1px solid $grey;
                                            padding-top: 20px;
                                            padding-bottom: 0;
                                        }
                                        th {
                                            color: $grey;
                                        }
                                    }

                                    &.totals.shipping.incl {
                                        th {
                                            color: $grey;

                                            .value {
                                                display: none;
                                            }
                                        }
                                        th,
                                        td {
                                            padding-bottom: 20px;
                                        }
                                    }

                                    &.totals.sub {
                                        th,
                                        td {
                                            padding-top: 0;
                                            padding-bottom: 0;
                                        }
                                        th {
                                            color: $grey;
                                        }
                                    }

                                    &.totals-tax {
                                        th {
                                            color: $grey;
                                        }
                                        th,
                                        td {
                                            padding-bottom: 20px;
                                        }
                                    }
                                }
                            }

                            .block.items-in-cart {
                                border-top: 1px solid $grey;
                                border-bottom: 1px solid $grey;

                                .title {
                                    border: none;
                                    margin-bottom: 0;
                                    font-size: 16px;
                                    font-weight: 700;
                                    padding: 20px 40px 20px 0;

                                    &:after {
                                        top: 20px;
                                    }

                                    strong {
                                        font-size: inherit;
                                        font-weight: inherit;
                                    }
                                }

                                .content.minicart-items {
                                    padding-bottom: 20px;
                                }
                            }

                            .minicart-items-wrapper {
                                margin: 0;
                                padding: 0;

                                @media screen and (max-width: 768px) {
                                    max-height: unset;
                                }
                            }
                        }

                        .opc-block-shipping-information {
                            padding: 0;

                            .shipping-information {
                                padding: 35px;
                                background: white;

                                @media screen and (max-width: 768px) {
                                    padding: 20px;
                                }

                                .shipping-information-title {
                                    font-family: 'Montserrat';
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    font-size: 24px;
                                    border: none;
                                    padding: 0;

                                    .action-edit {
                                        top: 0;

                                        &:before {
                                            content: '';
                                            background-image: url(../images/pencil.svg);
                                            background-repeat: no-repeat;
                                            width: 27px;
                                            height: 27px;
                                            background-size: 27px 27px;
                                        }
                                    }
                                }

                                .shipping-information-content {
                                    font-size: 16px;
                                    color: $grey;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
