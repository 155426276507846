.photo-album {
    margin: 0 auto;

    .album-item {
        width: calc(33.33% - 6px);
        margin-bottom: 7px;
        cursor: pointer;

        @media screen and (max-width: 1198px) {
            width: calc(50% - 5px);
        }
        @media screen and (max-width: 795px) {
            width: 100%;
        }
        .album-image {
            width: 100%;
        }
        .all-products-block {
            height: 200px;
            background-color: #eee;
            padding-top: 50px;
            margin-bottom: 3px;

            a {
                &:hover {
                    text-decoration: none;
                }
            }

            img {
                mix-blend-mode: multiply;
            }
            .link {
                font-family: 'Montserrat';
                text-transform: uppercase;
                color: black;
                font-size: 16px;
                font-weight: 800;
                margin-top: 8px;
            }
        }
    }

}

.other-albums {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    a.other-album {
        display: inline-block;

        &:first-of-type {
            
        }
        &:last-of-type {

        }

        .image-bg {
            position: relative;
            background-size: cover;
            height: 260px;
            width: auto;
            margin: 10px;
            background-position: center;
            background-repeat: no-repeat;

            &:hover {
                box-shadow: inset 0px 0px 0px 5px $primary-yellow;
        
                .title {
                    padding-left: 25px;
                    padding-right: 25px;
                    width: calc(100% - 60px);
                    padding-bottom: 15px;
                    margin-left: 5px;
                    margin-bottom: 5px;
                    margin-right: 5px;
                }
            }

            .title {
                color: white;
                position: absolute;
                margin-bottom: 0;
                hyphens: auto;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                line-height: 24px;
                font-family: 'Droid Serif';
                text-transform: initial;
                font-size: 18px;
                font-weight: 400;
                padding-bottom: 20px;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 30px;
                width: calc(100% - 60px);
                margin-top: 0;
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%);
            }
        }
    }
}

.lightbox{
    margin-top: 1000%;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0.95);
    z-index: 99999;
    transition: margin-top 1.5s;
    .full-image-slider{
        width: 1200px;
        height: auto;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);
        @media screen and (max-width: 1280px){
            width: 900px;
        }
        @media screen and (max-width: 991px){
            width: 700px;
        }
        @media screen and (max-width: 767px){
            width: 500px;
        }
        @media screen and (max-width: 575px){
            width: 320px;
        }
        .image-strip{
            width: calc(100% * 2);
            .img-wrapper{
                height: 50vh;
                width: 1200px;
                float: left;
                position: relative;
                @media screen and (max-width: 1280px){
                    width: 900px;
                }
                @media screen and (max-width: 991px){
                    width: 700px;
                }
                @media screen and (max-width: 767px){
                    width: 500px;
                }
                @media screen and (max-width: 575px){
                    width: 320px;
                }

                img{
                    display: block;
                    height: 100%;
                    max-height: 50vh;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }
    .thumbnails-wrapper{
        width: 100%;
        position: absolute;
        bottom: 4vh;
        .thumbnails{
            display: flex;
            flex-direction: row;
            justify-content: center;
            .thumbnail{
                margin: 10px;
                width: 100px;
                height: 100px;
                cursor: pointer;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                @media screen and (max-width: 1280px){
                    width: 80px;
                    height: 80px
                }
                @media screen and (max-width: 991px){
                    width: 60px;
                    height: 60px
                }
                @media screen and (max-width: 767px){
                    width: 50px;
                    height: 50px
                }
                @media screen and (max-width: 575px){
                    width: 30px;
                    height: 30px
                }
                &:hover {
                    box-shadow: inset 0px 0px 0px 2px white;
                }
            }
            .item-selected {
                box-shadow: inset 0px 0px 0px 2px white;
            }
        }
    }
    .lightbox-close{
        float: right;
        margin-right: 40px;
        margin-top: 40px;
        background-color: transparent;
        border: none;
        color: white;
        cursor: pointer;
        &:focus{
            outline: none;
        }
    }
}
.lightbox-visible {
    margin-top: 0;
    transition: margin-top 0.7s;
}

#fotoalben {

    .albums {
        display: block;
        margin-top: 60px;
        margin: 0px auto;

        .album {
            display: block;
            margin-bottom: 10px;
            overflow: hidden;

            .image-bg {
                position: relative;
                background-size: cover;
                height: 260px;
                width: 390px;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (max-width: 390px){
                    width: 320px;
                    height: 230px;
                }

                &:hover {
                    box-shadow: inset 0px 0px 0px 5px $primary-yellow;
            
                    .title {
                        padding-left: 25px;
                        padding-right: 25px;
                        width: calc(100% - 60px);
                        padding-bottom: 15px;
                        margin-left: 5px;
                        margin-bottom: 5px;
                        margin-right: 5px;
                    }
                }

                .title {
                    color: white;
                    position: absolute;
                    margin-bottom: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    text-align: center;
                    line-height: 24px;
                    font-family: 'Droid Serif';
                    text-transform: initial;
                    font-size: 18px;
                    font-weight: 400;
                    padding-bottom: 20px;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 30px;
                    width: calc(100% - 60px);
                    margin-top: 0;
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%);
                }
            }
        }
    }
}

.album-switcher {
    border-top: 2px solid #aaa;
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    align-items: center;

    .album-title {
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
    }
    .previous {
        background-image: url(../images/arrow.svg);
        transform: rotate(180deg);
        height: 18px;
        width: 35px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .next {
        background-image: url(../images/arrow.svg);
        height: 18px;
        width: 35px;
        background-repeat: no-repeat;
        background-position: center;
    }
}

