.grey-login-wrapper-outer {
    background-color: #f4f4f4;
    padding-top: 50px;
    padding-bottom: 100px;
    @media screen and (max-width: 540px){
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .grey-login-wrapper-inner {
        max-width: 1200px;
        margin: 0 auto;
        @media screen and (max-width: 1250px){
            margin-left: 30px;
            margin-right: 30px;
        }
        @media screen and (max-width: 540px){
            margin-left: 10px;
            margin-right: 10px;
        }

        h2 {
            font-family: 'Montserrat';
            text-transform: uppercase;
            font-size: 28px;
            font-weight: 800;
            margin-bottom: 60px;
            margin-top: 50px;
            padding-left: 20px;
        }

        .login-container {

            .block{
                padding: 20px;
                width: 45%;
                padding-top: 40px;
                padding-bottom: 40px;
                @media screen and (max-width: 900px){
                    width: calc(100% - 40px);
                    margin-right: 0;
                    float: none;
                }

                .block-title {
                    font-size: 18px;
                    font-family: 'Montserrat';
                    text-transform: uppercase;
                    font-weight: 800;
                    border-bottom: none;
                    padding-bottom: 0;

                    strong {
                        font-weight: inherit;
                    }
                }
                .block-content {
                    font-family: 'Droid Serif';
                    color: #777;
                    font-size: 16px;
                    line-height: 28px;

                    .fieldset {
                        box-shadow: none !important;
                        padding: 0 !important;
                        background-color: unset !important;

                        .field.email,
                        .field.password {
                            width: 50%;
                            display: inline-block;
                            position: relative;
                            @media screen and (max-width: 540px){
                                width: 100%;
                            }
                        }
                        .field {
                            position: relative;

                            label {
                                position: absolute !important;
                                bottom: 0px;
                                display: block;
                                float: none;
                                width: 90%;
                                text-align: left;
                                font-size: 16px;
                                font-weight: 400;
                                position: relative;
                                z-index: 1;
                                transition: 0.2s;
                                @media screen and (max-width: 540px){
                                    width: 100%;
                                }

                                &:after {
                                    color: $primary_yellow;
                                }
                            }
                            .control {
                                width: 90%;
                                display: block;
                                position: relative;
                                z-index: 10;
                                @media screen and (max-width: 540px){
                                    width: 100%;
                                }

                                input {
                                    border: none;
                                    border-bottom: 2px solid #aaa;
                                    width: 100%;
                                    display: block;
                                    background: transparent;
                                    padding: 0;
                                    font-family: 'Droid Serif';
                                    font-size: 16px;
                                    color: #777;
                                    transition: 0.2s;

                                    &:focus {
                                        box-shadow: none;
                                    }
                                }
                            }

                            &.focused {

                                label {
                                    font-size: 14px;
                                    transition: 0.2s;
                                    bottom: 30px;
                                }
                                .control {

                                    input {
                                        border-color: $primary_yellow;
                                        color: $primary_yellow;
                                        transition: 0.2s;
                                    }
                                }
                            }
                        }
                        .actions-toolbar {
                            @media screen and (max-width: 540px){
                                margin-top: 40px;
                            }

                            .primary {
                                button {
                                    height: 40px;
                                }
                            }
                            .secondary {
                                float: none;
                                margin-left: 20px;
                                @media screen and (max-width: 540px){
                                    margin-left: 0;
                                }

                                a {
                                    color: $primary_yellow;
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .fieldset.login {

                        &:after {
                            display: none;
                        }
                    }
                    #email-error {
                        position: absolute;
                        width: 200%;
                    }
                }
            }
            .block-customer-login {
                background-color: white;
                box-shadow: 0 1px 2px rgba(0,0,0,0.1);
            }
        }
    }
    #remember-me-box {
        display: flex;
        align-items: center;

        &:before {
            display: none !important;
        }
        &:after {
            display: none !important;
        }
        .tooltip.wrapper {
            display: none !important;
        }
        .label {
            position: relative !important;
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            bottom: -1px;
            width: auto;
        }
        .checkbox {
            filter: sepia(1) brightness(1.1) saturate(1.6);
            position: relative !important;
            display: inline-block;
            margin-right: 10px;

            &:focus {
                box-shadow: none !important;
            }
        }
    }
}
