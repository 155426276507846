.checkout-cart-index {

    .paypal.checkout {
        display: none;
    }

    .page-main {
        background-color: #f4f4f4;

        .page-title-wrapper {
            margin-left: 100px;
            padding-left: 20px;
            padding-top: 100px;
            padding-bottom: 15px;
            @media screen and (max-width: 1280px){
                margin-left: 70px;
            }
            @media screen and (max-width: 768px){
                margin-left: 85px;
            }
            @media screen and (max-width: 576px){
                margin-left: 20px;
            }
            @media screen and (max-width: 540px){
                padding-top: 40px;
            }

            .page-title {
                font-family: 'Montserrat';
                text-transform: uppercase;
                font-weight: 800;
                font-size: 28px;
            }
        }
        .columns {
            
            .column.main {

                .cart-container {
                    
                    .cart-summary {
                        background-color: white;
                        padding: 20px;
                        box-shadow: 0 1px 2px rgba(0,0,0,0.1);
                        font-family: 'Droid Serif';
                        width: 27% !important;
                        top: 0 !important;

                        @media screen and (max-width: 1090px){
                            float: unset !important;
                            width: 100% !important;
                        }
                        .summary.title {
                            font-family: 'Montserrat';
                            text-transform: uppercase;
                            font-weight: 800;
                        }
                        .block {

                            .title {
                                font-family: 'Montserrat';
                                text-transform: uppercase;
                                padding-left: 0;

                                strong {
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                            }
                            .estimate {
                                .field.note {
                                    display: none !important;
                                }
                            }
                        }
                    }
                    .form-cart {
                        background-color: white;
                        box-shadow: 0 1px 2px rgba(0,0,0,0.1);
                        margin-bottom: 100px;
                        width: 70% !important;
                        @media screen and (max-width: 1090px){
                            float: unset !important;
                            width: 100% !important;
                        }
                        .table-wrapper {
                            font-family: 'Droid Serif';

                            .cart {
                                border-bottom: 1px solid #ddd;
                            }
                            
                            thead {
                                font-family: 'Montserrat';
                                text-transform: uppercase;
                                border-bottom: 1px solid #ddd;
                                tr {
                                    .col.item {
                                        padding-left: 20px !important;

                                    }
                                    .col.subtotal {
                                        padding-right: 20px !important;
                                    }
                                }
                            }
                            .cart.item {
                                border-bottom: 1px solid #ddd;
                                border-top: none !important;
                            
                                .item-info {
                            
                                    .col.item {
                                        padding-top: 20px !important;
                                        padding-left: 20px !important;
                            
                                        .product-item-details {
                                            padding-top: 0 !important;

                                            .product-item-name {
                                                font-weight: 800;
                                            }
                                        }
                                    }
                                    .col.subtotal {
                                        padding-right: 20px !important;
                                    }
                                }
                                .item-actions {
                                    td {
                                        padding-left: 20px !important;
                                        padding-right: 20px !important;
                                    }
                                }
                            }
                        }
                        .main.actions {
                            padding: 20px;

                            .action {
                                background-color: $primary_yellow;
                                border: none;
                                -webkit-appearance: unset;
                                color: white;
                                font-family: 'Montserrat';
                                text-transform: uppercase;
                                font-weight: 500;
                                border-radius: 0px;
                                padding: 10px;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                        .action {
                            background-color: transparent;
                            border: 1px solid #bbb;
                            -webkit-appearance: unset;
                            color: black;
                            font-family: 'Montserrat';
                            text-transform: uppercase;
                            font-weight: 400;
                            border-radius: 0px;
                            padding: 10px;
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }
}