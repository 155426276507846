//------------------------------------------------------------
// PRODUCT LIST PAGE
//------------------------------------------------------------

$br-1366: 1366px;
$br-932: 932px;
$br-768: 768px;
$br-650: 650px;
$br-576: 576px;


.page-products {

    .column.main {
        @media screen and (min-width: 1024px) {
            width: 74.2% !important;
        }
        @media screen and (max-width: 1023px) {
            width: 70% !important;
        }

    }

    .page-title-wrapper {
        display: none !important;
    }

    .page-wrapper {
        background-color: white;
    }

    .columns {
        margin-top: 50px;

        @media screen and (max-width: 768px){
            display: flex;
        }
    }

}

.abs-actions-addto-gridlist:hover:before {
    color: $primary-yellow !important;
}

.category-image {
    margin-bottom: 50px;
    height: 350px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width: 768px){
        height: 260px;
    }
}

.product.attribute.delivery_time {
    .description-toggle {
        display: none !important;
    }
}

.image-text-container {
    position: relative;
    width: 100%;
    max-width: 1440px;
    color: $color_white;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .page-title-wrapper {
        text-align: center;
        width: 70%;
        margin: 0 auto;

        h1 {
            font-size: 86px;
            font-family: 'Droid Serif';
            font-style: italic;
            margin-bottom: 0;
        }
    }
    .category-description {
        text-align: center;
        max-width: 1240px;
        margin: 0 auto;

        &.product-line-selected {
            h1 {
                margin-top: 60px !important;
                margin-bottom: 60px !important;
            }
        }

        @media screen and (max-width: 1440px){
            margin-left: 100px;
            margin-right: 100px;
        }
        @media screen and (max-width: 1280px){
            margin-left: 85px;
            margin-right: 85px;
        }
        @media screen and (max-width: 576px){
            margin-left: 20px;
            margin-right: 20px;
        }

        h1 {
            font-size: 86px;
            font-family: 'Droid Serif';
            font-style: italic;
            margin-bottom: 20px;

            @media screen and (max-width: $br-932){
                font-size: 48px;
                line-height: 64px;
                text-align: left;
            }
            @media screen and (max-width: $br-768){
                font-size: 36px;
                line-height: 48px;
            }
        }

        p {
            font-size: 16px;

            @media screen and (max-width: $br-932){
                text-align: left;
                font-weight: 100;
            }
        }
    }
}

.sidebar {
    @media screen and (min-width: 1024px) {
        width: 25% !important;
        padding-right: 10px !important;
    }
    @media screen and (max-width: 1023px) {
        width: 26% !important;
        padding-right: 10px !important;
    }
    @media screen and (max-width: 768px){
        padding-right: 0px !important;
    }



}
.columns > .sidebar {
    margin-top: 75px !important;

    @media screen and (max-width: $br-768){
        margin-top: 0 !important;
    }
}
.columns > .sidebar ~ .sidebar {
    margin-top: 0 !important;
}

.products.list.items.product-items {
    margin-left: 0 !important;
}

.page-products .products-grid .product-item {
    margin-right: 2%;
    margin-left: 0;
    padding: 0;
    border: 2px solid #f2f2f2;
    margin-bottom: 30px !important;
    @media screen and (min-width: 1367px) {
        width: 31.33%;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: $br-1366) {
        width: 49%;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: $br-932) {
        width: 100%;
        margin-right: 0;
    }
    @media screen and (max-width: $br-768) {
        width: 49%;
        margin-right: 2%;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: $br-650) {
        width: 100%;
        margin-right: 0;
    }
    @media screen and (max-width: $br-576) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .swatch-attribute.amount {

        .swatch-attribute-options {
            margin-top: 0;

            .swatch-option {
                width: 45px;
                padding-left: 0;
                padding-right: 0;
                height: 10px;
                font-size: 10px;
                line-height: 10px;
                font-weight: 400;
                margin-bottom: 0px;
                margin-right: 0;
                background-color: white;
                border-radius: 2px;
                border-color: #777;

                &:hover {
                    outline: none;
                    background-color: #f2f2f2;
                }
                &.selected {
                    background-color: $primary-yellow !important;
                    color: white;
                    border-color: $primary-yellow;
                    outline: none;
                }
            }
            .swatch-option + .swatch-option {
                margin-left: 4px;
            }
        }
    }
}
.swatch-attribute-label { z-index: 99999;}
.swatch-option-tooltip {
    max-width: 0px !important;
    max-height: 0px !important;
    overflow:hidden;
    min-height: 0px !important;
    min-width: 0px !important; border:none !important;
    background:transparent !important;
}


.product-image-wrapper {
    background-color: #f2f2f2;
}
.product-item-info {
    width: 100% !important;

    .product-image-container {
        width: 100% !important;

        .product-image-photo {
            max-height: 100%;
            width: auto;
        }
    }
}

.toolbar-sorter {
    @media screen and (max-width: 768px){
        text-align: left;
        padding-bottom: 15px;
        border-bottom: 1px solid #aaa;
        float: none;
    }
    .sorter-label {
        font-family: 'Droid Serif';
        font-size: 16px;
        font-style: italic;
        color: #777;
        @media screen and (max-width: 950px){
            display: none;
        }
        @media screen and (max-width: 768px){
            display: block;
            font-family: 'Montserrat';
            text-transform: uppercase;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            padding-bottom: 10px;
            color: black;
            margin-bottom: 0;
        }
    }
    .sorter-options {
        width: 170px;
        height: 35px;
        text-align-last: center;
        padding-bottom: 7px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 13px;
        font-family: 'Montserrat';
        line-height: 16px;
        appearance: none;
        border-radius: 0;
        cursor: pointer;
        @media screen and (max-width: 768px){
            border: none;
            font-family: 'Droid Serif';
            text-transform: none;
            font-size: 16px;
            height: 32px;
            text-align: left;
            text-align-last: initial;
            padding-left: 0;
            margin-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            line-height: normal;
            color: #777;
            display: inline-block;
            width: calc(100% - 32px);
        }
        &:focus {
            box-shadow: none;
        }
    }
    .sorter-action {
        &:before {
            @media screen and (max-width: 768px){
                transform: translateX(8px);
            }
        }
    }
}

.product-item-actions {
    position: absolute;
    bottom: 15px;
    width: 100%;
    margin: 0 !important;
    @media screen and (max-width: 639px){
        display: block !important;
    }
    .actions-primary, .actions-secondary {
        display: inline-block !important;
    }
    .actions-secondary {
        width: 62px !important;

        .action.tocompare {
            display: none !important;
        }
    }
}

.product-item-details {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 17px;
    font-family: 'Montserrat';
    height: 185px;
}

.tax-span {
    margin-bottom: 15px;
    color: #777;
}

#top-toolbar {
    padding: 0;
    @media screen and (max-width: 768px){
        margin-bottom: 80px;
    }
}

.filter-wrapper {
    display: flex;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 40px;
    max-height: 5000px;
    overflow: hidden;
    transition: 0.1s;

    .filter-wrapper-title {
        margin-top: -50px;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: -85px;
        margin-right: -85px;
        top: 0;
        border-bottom: 2px solid #ddd;
        padding-left: 85px;
        padding-right: 85px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: 'Montserrat';
        text-transform: uppercase;
        font-weight: 800;
        font-size: 20px;
        cursor: pointer;
        &:after {
            content: '-';
            position: absolute;
            right: 85px;
        }
        @media screen and (max-width: 576px){
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 20px;
            padding-right: 20px;
            &:after {
                right: 20px;
            }
        }
    }
    &.hidden {
        max-height: 0px;
        margin-bottom: 0;
        margin-top: 30px;
        transition: 0.1s;
        .filter-wrapper-title {
            &:after {
                content: '+' !important;
            }
        }
    }
}

.filter-options {

    .filter-options-title {
        word-break: break-all;
        font-family: 'Droid Serif';
        font-style: italic;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 22px;
        position: relative;
        @media screen and (max-width: 768px){
            font-family: 'Montserrat';
            text-transform: uppercase;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            padding-bottom: 20px;
            margin-bottom: 0;
            cursor: pointer;
            &:after {
                content: "▶︎";
                position: absolute;
                right: 0;
                transform: rotate(0deg);
                transition: 0.2s;
                font-size: 20px;
                line-height: 22px;
            }
        }
    }
    .filter-options-title-open {
        @media screen and (max-width: 768px){
            &:after {
                transform: rotate(90deg);
                transition: 0.2s;
            }
        }
    }
    .filter-options-content {
        margin-bottom: 50px;
        transition: max-height 0.2s;

        @media screen and (max-width: $br-768){
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;
            margin-bottom: 22px;
            border-bottom: 1px solid #aaa;
            transition: max-height 0.2s;
        }

        .items {

            .item {
                margin-bottom: 22px;
                font-family: 'Droid Serif';
                color: #bbb;
                font-size: 16px;
                font-weight: 400;
                font-style: italic;
                line-height: 22px;
                margin-left: 8px;
                @media screen and (max-width: 768px){
                    font-style: normal;
                }

                &:before {
                    content: '→ ';
                    display: inline-block;
                    margin-right: -8px;
                    color: #aaa;
                    transform: translateX(-10px);
                }
                a {
                    color: #777;

                    .count {
                        color: #777;
                    }
                }
                .count {
                    color: #bbb;
                }
            }
        }
    }
    .mobile-open {
        max-height: 5000px;
        transition: max-height 0.2s;
    }

}
.filter-current {

    .item {
        margin-bottom: 50px;
        @media screen and (max-width: 768px){
            border-bottom: 1px solid #aaa;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }

        .filter-label {
            display: block;
            word-break: break-all;
            font-family: 'Droid Serif';
            font-style: italic;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 16px;

            @media screen and (max-width: 768px){
                font-family: 'Montserrat';
                text-transform: uppercase;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                margin-bottom: 10px;
            }

            &:after {
                content: none;
            }
        }
        .filter-value {
            margin-bottom: 10px;
            font-family: 'Droid Serif';
            color: $primary_yellow;
            font-size: 16px;
            font-weight: 400;
            font-style: italic;
            line-height: 32px;
            @media screen and (max-width: 768px){
                color: #777;
                font-style: normal;
            }
        }
        .action.remove {

            &:hover {
                text-decoration: none;

                & + .filter-value {
                    text-decoration: line-through;
                }
            }

            .remove-filter {
                background-image: url(../images/delete_icon.svg);
                width: 20px;
                height: 20px;
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
                display: inline-block;
                transform: translateY(3px);
                @media screen and (max-width: 768px){
                    filter: saturate(0);
                    margin-left: -3px;
                }
            }
        }
    }
}
.block-actions.filter-actions {
    float: left;
    line-height: 43px;
    margin-left: 10px;
    display: none;
    @media screen and (max-width: 768px){
        margin-left: 0;
        width: 100%;
    }

    &.visible {
        display: block;
    }

    .action.clear.filter-clear {
        padding-left: 20px;
        padding-right: 20px;
        line-height: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        color: black;
        border: 1px solid #c2c2c2;
        @media screen and (max-width: 768px){
            text-align: center;
            display: block;
            background-color: #d7aa02;
            color: white;
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            border: none;
        }

        &:hover {
            text-decoration: none;
        }
    }
}
#toolbar-amount {
    font-family: 'Droid Serif';
    font-style: italic;
    font-size: 16px;
    color: #777;
}

#limiter.limiter-options {
    width: 70px;
    height: 35px;
    text-align-last: center;
    padding-bottom: 7px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    font-family: 'Montserrat';
    line-height: 16px;
    font-style: normal;
    appearance: none;
    border-radius: 0;
    cursor: pointer;
    &:focus {
        box-shadow: none;
    }
}
.field.limiter {
    font-family: 'Droid Serif';
    color: #777;
    font-size: 16px;
    font-style: italic;
}

.pages {
    .items {
        .item {
            strong {
                span {
                    font-family: 'Droid Serif';
                    font-size: 16px;
                    font-weight: 800;
                    color: $primary_yellow;
                }
            }
            a {
                span {
                    color: #777;
                    font-family: 'Droid Serif';
                    font-size: 16px;
                    font-weight: 400;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.sidebar-additional {

    .block-wishlist {
        @media screen and (max-width: 768px){
            display: none !important;
        }
    }

    .block {

        .block-title {
            font-family: 'Droid Serif';
            font-style: italic;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 16px;

            .counter.qty {
                display: none !important;
            }
        }
        .empty {
            margin-bottom: 10px;
            font-family: 'Droid Serif';
            color: #bbb;
            font-size: 16px;
            font-weight: 400;
            font-style: italic;
            line-height: 32px;
        }

        .product-item-name {
            font-family: 'Droid Serif';
            color: #d7aa02;
            font-size: 16px;
            font-weight: 400;
            font-style: italic;
            line-height: 24px;
            font-variant-caps: normal;
            text-transform: none;
            height: auto !important;
            margin-left: 20px !important;
            hyphens: none !important;

            a {
                color: inherit !important;
                hyphens: none !important;
            }
        }
        .product-item-photo {
            display: none !important;

            .product-image-container {
                display: none !important;
            }
        }
        .action.delete {
            width: 30px;
            top: -3px;
            &:before {
                content: "";
                background-image: url(../images/delete_icon.svg);
                width: 20px;
                height: 20px;
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
                display: inline-block;
                -webkit-transform: translateY(3px);
                transform: translateY(3px);
            }
        }
        .actions-toolbar {
            .primary {
                float: none;
                a {
                    padding-left: 20px;
                    padding-right: 20px;
                    line-height: 20px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: black;
                    border: 1px solid #c2c2c2;
                    border-radius: 0px;
                    background-color: transparent;
                }
            }
            .secondary {
                display: block;
                float: none;
                text-align: left;
                font-family: 'Droid Serif';
                font-style: italic;
                font-size: 16px;
                margin-top: 10px;
                a {
                    color: #777;
                    &:before {
                        content: '→ ';
                        display: inline-block;
                        margin-right: 4px;
                        color: #aaa;
                    }
                }
            }
        }
    }
}

.category-view {
    background-image: url(../images/standard_category_hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.category-termine {
    .category-view {
        display: none;
    }
}
