@import '_custom_variables.scss';
@import '_custom_fonts.scss';
@import '_product_list.scss';
@import '_product_view.scss';
@import '_login.scss';
@import '_register.scss';
@import '_dashboard.scss';
@import '_checkout.scss';
@import '_cart.scss';
@import '_1-col-layout.scss';

@import 'modules/Luum_Age.scss';
@import 'modules/Luum_Cookie.scss';
@import 'modules/Luum_Blog.scss';
@import 'modules/Luum_Gallery.scss';

@import 'cms_pages/global.scss';
@import 'cms_pages/startseite.scss';
@import 'cms_pages/hubertus_vallendar.scss';
@import 'cms_pages/destillate.scss';
@import 'cms_pages/partner.scss';
@import 'cms_pages/academy.scss';
@import 'cms_pages/brennerei.scss';

@import 'slick/slick.scss';
@import 'slick/slick-theme.scss';

//------------------------------------------------------------
// GLOBAL STYLES
//------------------------------------------------------------
body{
    background-color: #ddd;
}

p {
    a {
        color: $primary_yellow;
        &:visited {
            color: $primary_yellow !important;
        }
        &:hover {
            color: $primary_yellow !important;
        }
    }
}

.page-wrapper {
    .logo-toptitle {
        height: 40px;
        background-image: url(../images/die-kunst-des-destillierens.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #ddd;
        @media screen and (max-width: 576px){
            background-size: 90%;
        }
    }

    max-width: 1440px;
    margin: 0 auto;
    display: block !important;
    overflow: hidden;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        overflow: visible !important;
   }
}

.page-main {
    background-color: $color_white;
    max-width: 1440px;
    padding-left: 0;
    padding-right: 0;
}

.page-header {
    border: none !important;
    margin-bottom: 0 !important;
}

.page-footer{
    background-color: $color_white;
}

.columns {
    margin-left: 100px;
    margin-right: 100px;
    @media screen and (max-width: 1280px){
        margin-left: 70px;
        margin-right: 85px;
    }
    @media screen and (max-width: 768px){
        margin-right: 85px;
        margin-left: 85px;
    }
    @media screen and (max-width: 576px){
        margin-left: 20px;
        margin-right: 20px;
    }
}
.vallendar-menu {
    padding: 0 !important;
}
.vallendar-submenu {
    overflow: hidden;
    background-color: rgba(0,0,0,0.1);
    width: 100%;

    .sub-item {
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        &:hover {
            text-decoration: underline;
        }
        @media screen and (max-width: 576px){
            font-size: 14px;

            a {
                padding-top: 6px !important;
                padding-bottom: 6px !important;
            }
        }
    }
}

.minicart-items {
    .product-item {
        .product {
            display: flex;

            &:before,
            &:after {
                display: none;
            }

            .product-image-container {
                width: 100px !important;
                height: 100px !important;
                flex-shrink: 0;
                margin-right: 15px;
            }

            .product-item-details {
                display: block;
                padding: 0;
                height: unset;
                flex-grow: 1;

                .product-item-inner {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    margin: 0;

                    .product-item-name {
                        min-height: unset !important;
                        font-weight: 700;
                        word-break: break-word;
                        hyphens: auto;

                        strong,
                        a {
                            color: #333;
                            font-weight: 700;
                        }
                    }

                    .details-qty {
                        font-size: 12px;
                        margin-top: 8px;
                    }

                    .subtotal {
                        display: flex;
                        flex-grow: 1;
                        height: 100%;
                        align-items: flex-end;
                        justify-content: flex-end;

                        .cart-price {
                            .price {
                                font-size: 16px;
                                font-weight: 700;
                            }
                        }
                    }
                }

                .product-item-name {
                    min-height: unset !important;
                    font-weight: 700;
                    word-break: break-word;
                    hyphens: auto;

                    strong,
                    a {
                        color: #333;
                        font-weight: 700;
                    }
                }

                .product-item-pricing {
                    display: flex;
                    flex-direction: column;

                    .qty {
                        order: 1;

                        label {
                            margin-right: 8px;
                        }

                        .update-cart-item {
                            display: inline-block;
                            background-color: $primary-yellow;
                            color: white;
                            border: none;
                            border-radius: unset;
                            font-family: 'Montserrat';
                            text-transform: uppercase;
                            font-weight: 500;
                            height: 20px;
                            padding: 0 5px;
                            margin-top: 6px;
                            margin-left: -6px;
                            &:hover {
                                background: $primary-yellow;
                                border: none;
                                color: white;
                            }
                        }
                    }

                    .price-container {
                        order: 2;
                    }
                }
            }
        }
    }
}

.parent-link {
    padding: 10px 0 10px 0px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 800;
    font-family: 'Montserrat';
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    @media screen and (max-width: 576px){
        font-size: 18px;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}
.main-menu {
    a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 800;
        font-family: 'Montserrat';
        &:hover {
            text-decoration: underline;
        }
        @media screen and (max-width: 576px){
            font-size: 18px;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }
    }
}

.message {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    opacity: 0.7;
    transition: 0.2s;
    cursor: pointer;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 60px !important;
    div {
        &:before {
            top: 26px !important;
            right: unset;
            left: 10px;
        }
    }
    &.error {
        background: #f88 !important;
    }
    &:hover {
        opacity: 1;
        transition: 0.2s;
    }
    div {
        display: inline-block;
        font-family: 'Montserrat';
        color: black !important;
        font-weight: 500;
    }
    .close-message {
        display: inline-block;
        margin-right: 10px;
        color: black !important;
        font-weight: 800;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
    &.warning {
        margin-top: 0 !important;
    }
}


div.mage-error {
    display: none !important;

    &.visible {
        display: block !important;
    }
}
input.mage-error {
    display: block !important;
    border-color: red !important;
    border-width: 3px !important;
}
#password-error {
    position: absolute;
    top: 57px;
    background-color: #f2f2f2;
    padding: 20px;
    box-shadow: 0 3px 2px rgba(0,0,0,0.1);
}


.page-header {
    background-color: $color_white;
    position: relative;

    .panel.wrapper {
        display: none;
    }

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        float: none !important;
        max-width: 100% !important;
        margin: 0 !important;
        padding-top: 20px;

        img {
            width: auto;
            height: 80px;
        }

        @media screen and (max-width: 768px){
            max-width: 100px !important;
            margin: 0 auto !important;
            padding-top: 17px;
            img {
                height: auto;
            }

        }
        @media screen and (max-width: 380px){
            left: 29%;
            margin: 0 !important;
        }
        @media screen and (max-width: 330px){
            left: 24%;
            margin: 0 !important;
        }
        a {
            @media screen and (max-width: 890px){
                transform: scale(0.8);
            }
            @media screen and (max-width: 768px){
                transform: none;
            }
            &:hover {
                text-decoration: none;
            }

            img {
                @media screen and (max-width: 768px){
                    height: auto !important;
                }
            }
        }
    }

    #btn-minicart-close {
        display: none !important;
    }

    .minicart-wrapper {
        position: absolute;
        top: 40px;
        right: 96px;
        z-index: 15;
        margin: 0;

        @media screen and (max-width: 1280px) {
            right: 61px !important;
        }
        @media screen and (max-width: 768px) {
            top: 34px !important;
            right: 26px !important;
        }
        @media screen and (max-width: 639px) {
            right: 26px !important;
        }
        .ui-widget {
            .block-minicart {
                padding-top: 20px;
                padding-bottom: 20px;

                @media screen and (max-width: 767px){
                    right: -15px !important;
                    &:before {
                        right: 13px;
                    }
                    &:after {
                        right: 12px;
                    }
                }
                @media screen and (max-width: 480px){
                    right: -11px !important;
                    width: calc(100vw - 30px);
                }
            }
        }
        #minicart-content-wrapper {

            .paypal.checkout.after {
                display: none !important;
            }

            #btn-minicart-close {
                right: 20px;
                top: 20px;
            }

            .actions {
                line-height: 40px;
                padding-left: 10px;
                padding-bottom: 10px;

                a {
                    color: $primary_yellow;
                }
            }
            .minicart-items-wrapper {
                margin-bottom: 20px;
                padding-left: 20px;
                padding-right: 20px;
            }
            .items-total {
                font-family: 'Montserrat';
            }
            .subtotal {
                font-family: 'Montserrat';
            }
            .block-content {
                .actions {
                    line-height: unset;
                    padding: 0;
                }
            }
        }

        .counter.qty {
            background-color: $primary_yellow !important;
            border-radius: 50% !important;
            position: absolute !important;
            @media screen and (max-width: 768px){
                transform: scale(0.75) translateX(-5px) translateY(-5px);
            }
        }
    }
}

.cms-page-main {
    .page-title-wrapper {
        display: none;
    }
}


.page-header {
    height: 120px;

    @media screen and (max-width: 768px){
        height: 72px;
    }

    &:before {
        content: "";
        background-image: url(../images/vintage_papier_links_120.png);
        background-repeat: no-repeat;
        position: absolute;
        background-position-x: left;
        height: 120px;
        width: 175px;
        top: 0;

        @media screen and (max-width: 768px){
            height: 72px;
            background-size: 40%;
        }
    }
    &:after {
        content: "";
        background-image: url(../images/vintage_papier_rechts_120.png);
        background-repeat: no-repeat;
        position: absolute;
        background-position-x: right;
        height: 120px;
        width: 175px;
        top: 0;
        right: 0;

        @media screen and (max-width: 768px){
            height: 72px;
            background-size: 50%;
        }
        @media screen and (max-width: 1440px) {
            right: 0;
        }
    }
}

.action.nav-close {
    left: 10%;
    @media screen and (max-width: 576px){
        left: 10px;
        top: 0;
    }
}

.nav-sections-item-content {
    height: 100vh;

    .navigation {
        display: flex;
        flex-direction: column;
        height: 90vh;
        justify-content: center;
        max-width: 100% !important;
        margin-top: 10px;

        .level0 > .level-top {
            padding-top: 15px !important;
            padding-bottom: 15px !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


.action.nav-toggle {
    &:before {
        content: "";
    }
    background-image: url(../images/Vallendar_Burger.png);
    background-size: 71px;
    width: 71px;
    height: 71px;
    left: 85px;
    top: 22px;
    display: block !important;
    @media screen and (max-width: 1280px){
        left: 70px;
    }
    @media screen and (max-width: 768px){
        background-image: url(../images/Vallendar_Burger_m.png);
        background-size: 36px;
        width: 36px;
        height: 36px;
        left: 15px;
        top: 20px;
    }
}

.block-search {
    position: absolute;
    right: 290px;
    display: block !important;
    width: auto !important;
    top: 26px;
    cursor: pointer;
    z-index: 50;
    @media screen and (max-width: 1280px){
        right: 225px;
    }
    @media screen and (max-width: 890px) {
        right: 210px;
    }
    @media screen and (max-width: 768px){
        top: 20px;
        right: 102px;
    }
    .block-title {
        background-image: url(../images/Vallendar_Search.png);
        background-size: 71px;
        width: 71px;
        height: 71px;
        display: block;
        margin-bottom: 0 !important;
        @media screen and (max-width: 768px){
            background-image: url(../images/Vallendar_Search_m.png);
            background-size: 36px;
            width: 36px;
            height: 36px;
        }
        strong {
            display: none;
        }
    }
    .block-content {
        display: none;
        position: absolute;
        padding: 30px;
        border: 1px solid #aaa;
        background-color: $color_white;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
        right: 0;
        margin-top: 5px;
        @media screen and (max-width: 768px){
            top: 44px;
            right: -13px;
        }
        @media screen and (max-width: 576px){
            width: 72vw !important;
            right: -73px;
        }
        &:before {
            right: 25px;
            top: -12px;
            border: 6px solid;
            border-color: transparent transparent #fff transparent;
            z-index: 99;
            border-bottom-style: solid;
            content: '';
            display: block;
            height: 0;
            position: absolute;
            width: 0;
            @media screen and (max-width: 576px){
                right: 84px;
            }
        }
        &:after {
            right: 24px;
            top: -14px;
            border: 7px solid;
            border-color: transparent transparent #bbb transparent;
            z-index: 98;
            border-bottom-style: solid;
            content: '';
            display: block;
            height: 0;
            position: absolute;
            width: 0;
            @media screen and (max-width: 576px){
                right: 83px;
            }
        }
        .label {
            clip: unset !important;
            overflow: unset !important;
            position: absolute;
            right: 60px;
            cursor: pointer;

            @media screen and (max-width: 767px){
                position: absolute !important;
                right: 10px !important;
                top: 30px !important;
            }
        }
        .control {
            padding: 0 !important;
            @media screen and (max-width: 767px){
                border-top: none !important;
            }

            input {
                border: none !important;
                border-bottom: 1px solid #ccc !important;
                border-radius: 0 !important;
                padding-left: 0 !important;
                padding-right: 30px !important;
                @media screen and (max-width: 767px){
                    position: static !important;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-left: 0 !important;
                    padding-right: 30px !important;
                }
                &:focus {
                    box-shadow: none !important;
                }
            }
        }

        .actions {
            display: none;
        }
        .nested {
            a {
                color: #777;
                font-family: 'Droid Serif';
                font-style: italic;
            }
        }
    }

    .show {
        display: block !important;
    }

    #search {
        background-color: $color_white;
        color: black;
        border: 1px solid #aaa;
        border-radius: 4px;
        font-size: 16px;
        padding: 2px;
        padding-left: 10px;
        font-family: 'Montserrat';
    }
}


.breadcrumbs {
    background-color: #f4f4f4;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 100px;
    max-width: 1440px;
    margin-left: 0;
    margin-right: 0 !important;
    margin-bottom: 0;
    font-family: 'Droid Serif';
    font-weight: 400;

    @media screen and (max-width: 1280px){
        padding-left: 85px;
    }

    @media screen and (max-width: 768px){
        padding-left: 15px;
    }

    .items {
        .item {
            font-size: 14px;
            strong {
                color: #cc9900;
            }
        }
    }
}

.logo-subtitle {
    font-family: 'Times New Roman';
    font-size: 10px;
    text-align: center;
    color: black;
    letter-spacing: 3px;
    font-weight: bold;
    padding-left: 6px;
    &:hover {
        text-decoration: none;
    }
    @media screen and (max-width: 768px){
        display: none;
    }
}

.minicart-wrapper .action.showcart:before,
.minicart-wrapper .action.showcart.active:before {
    display: none;
}

.showcartlink {
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}


.showcart,
.showcartlink {
    background-image: url(../images/Vallendar_Shop.png);
    background-size: 71px;
    width: 71px;
    height: 71px;
    position: relative;
    margin-top: -14px;
    right: 30px;

    &.isshop {
        background-image: url(../images/Vallendar_Cart.png);
        background-size: 81px;
        width: 81px;
        right: 0;
    }

    @media screen and (max-width: 768px){
        background-image: url(../images/Vallendar_Cart_m.png);
        background-size: 36px;
        width: 36px;
        height: 36px;
        right: 0;


        &.isshop {
            background-image: url(../images/Vallendar_Cart_m.png);
            background-size: 36px;
            width: 36px;
            height: 36px;
            right: 0;
        }
    }
}

.navigation-header {
    background-image: url(../images/menu_top.svg);
    background-repeat: no-repeat;
    width: 150px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 576px){
        display: none;
    }
}

.header {
    &.content {
        width: 100%;
        max-width: 100%;
        padding: 0;
        @media screen and (max-width: 768px){
            padding-left: 0;
        }
    }
}

.block-search {
    display: flex;
    align-items: center;
    justify-content: center;
    float: none !important;
    margin: 0 !important;
    width: 100%;
    padding: 0 !important;

    input {
        @include lib-input-placeholder($color-white);
        padding: 25px 20px;
        font-size: 2.6rem;
        background: $navigation__background;
        border: 2px solid $color-white;
    }
    .block-content {
        width: 280px;
    }
    .action.search {
        right: auto !important;
        margin-left: 160px;
    }
}

.newsletter-bottom-container {
    background-image: url(../images/newsletter_bg.jpg);
    background-size: cover;
    background-position: center;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    color: white;

    .newsletter-bottom-header {
        font-family: 'Droid Serif';
        font-style: italic;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
    }
    .newsletter-header-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 28px;
        margin-bottom: 30px;
    }

    .block.newsletter {
        margin: 0 auto;
        margin-top: 40px;
        width: 45%;
        @media screen and (max-width: 768px){
            width: 75%;
        }
        @media screen and (max-width: 540px){
            width: 90%;
        }

        .field.newsletter {
            margin-right: 20px;
            @media screen and (max-width: 1200px){
                margin-right: 0;
                margin-bottom: 20px;
            }

            .control {
                &:before {
                    display: none !important;
                }
                input {
                    height: 55px;
                    font-family: 'Droid Serif';
                    font-size: 18px;
                    text-align-last: center;
                    padding-left: 10px;
                    padding-right: 10px;
                    border: none;
                    &:focus{
                        box-shadow: none;
                    }
                }
            }
        }

        .actions {
            width: 47%;
            margin-left: 10px;
            @media screen and (max-width: 1200px){
                width: 100%;
                margin-left: 0;
                display: block;
            }

            button {
                width: 100%;
                height: 55px;
                border-radius: 2px;
                color: $color_white;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}

.products-grid {
    .product-item-name {
        a {
            font-weight: 800 !important;
            hyphens: none !important;
        }
        .details {
            font-weight: 400 !important;
            margin-top: 3px;
        }
    }
}

.product-item-name {
    margin: 0 !important;
    text-transform: uppercase;
    font-size: 14px;
    min-height: 66px !important;
    overflow: hidden;
    line-height: 16px;
}
.price-box {
    margin-top: 6px !important;
    margin-bottom: 4px !important;
    .price {
        font-size: 16px !important;
        font-weight: 700 !important;
    }
    .special-price {
        display: inline-block;
        margin-right: 10px;
    }
    .old-price {
        color: #c31e1e;
    }
    .price-label {
        display: none;
    }
}

.action.primary {
    background-color: $primary-yellow;
    border-radius: 0;
    border: none;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    &:hover {
        border: none;
        background-color: lighten($primary-yellow, 2);
    }
    &:focus {
        border: none;
        background-color: lighten($primary-yellow, 3);
    }
}

.pre-footer {
    background-color: $color_white;

    .pre-footer-grey-bar {
        height: 20px;
        background-color: #eee;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }

    .columns-wrapper-lower {
        background-color: #eee;

        &.block {
            display: block;
            padding-bottom: 1px;
            margin-bottom: 0;
            padding-top: 40px;
        }
    }

    .columns-wrapper-upper, .columns-wrapper-lower {
        display: flex;
        justify-content: space-around;
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 80px;
        text-align: center;
        font-family: 'Droid Serif';
        font-weight: 400;
        margin-top: -20px;
        @media screen and (max-width: 1200px){
            flex-wrap: wrap;
        }
        @media screen and (max-width: 768px){
            padding-left: 85px;
            padding-right: 85px;
        }
        @media screen and (max-width: 576px){
            padding-left: 20px;
            padding-right: 20px;
        }

        .pre-footer-col {
            flex-grow: 0;
            flex-basis: 25%;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 80px;
            @media screen and (max-width: 1200px){
                flex-basis: 42%;
            }
            @media screen and (max-width: 700px){
                flex-basis: 100%;
            }

            .title {
                font-weight: 800;
                font-family: 'Montserrat';
                font-style: normal;
                text-transform: uppercase;
                font-size: 20px;
                margin-bottom: 35px;
            }
            .content {
                color: #777;
                line-height: 30px;
                font-style: normal;
                font-size: 16px;

                strong {
                    font-weight: 800;
                    font-style: normal;
                    color: #666;
                    display: block;
                }
                a {
                    font-style: italic;
                    text-decoration: none;
                    color: inherit;
                }
                .row {
                    img {
                        margin-right: 2px;
                        margin-left: 2px;
                        display: inline-block;
                    }
                }
                img {
                    display: block;
                    margin: 0 auto;
                }
                .separator {
                    width: 80%;
                    height: 1px;
                    background-color: #ccc;
                    margin: 0 auto;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.page-footer {
    background-color: #eee;

    .footer.content {
        border-top: none;
        margin-top: 0 !important;

        p {
            font-family: 'Droid Serif';
            margin-top: 30px;
            font-size: 12px;
            color: #777;
            text-align: center;
            line-height: 24px;
        }
    }
}

.footer.links {
    display: flex !important;
    justify-content: space-evenly;
    padding: 0 !important;
    list-style-type: none;
    margin-bottom: -30px;

    @media screen and (max-width: 1100px){
        flex-wrap: wrap;
    }
    .nav.item {
        @media screen and (max-width: 1100px){
            flex-basis: 100%;
            text-align: center;
            margin-bottom: 30px;
        }

        a {
            color: #777;
            font-family: 'Droid Serif';
            font-size: 16px;
            font-style: italic;
            &:hover {
                color: #777;
            }
        }
    }

}

.spacer {
    height: 10px;
    width: 100%;
}
.spacer-l {
    height: 20px;
    width: 100%;
}
.spacer-xl {
    height: 30px;
    width: 100%;
}

.customer-welcome {
    background-image: url(../images/Vallendar_Account.png);
    position: absolute;
    top: 26px;
    right: 202px;
    background-size: 71px;
    width: 71px;
    height: 71px;
    cursor: pointer;
    z-index: 10;
    @media screen and (max-width: 1280px) {
        right: 157px;
    }
    @media screen and (max-width: 890px) {
        right: 147px;
    }
    @media screen and (max-width: 768px){
        background-image: url(../images/Vallendar_Account_m.png);
        background-size: 36px;
        width: 36px;
        height: 36px;
        top: 20px;
        right: 65px;
    }
}

.customer-dropdown {
    display: none;
    position: absolute;
    padding: 30px;
    padding-bottom: 20px;
    border: 1px solid #aaa;
    background-color: $color_white;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    right: 197px;
    top: 117px;
    z-index: 50;
    @media screen and (max-width: 1280px){
        right: 147px;
    }
    @media screen and (max-width: 768px){
        right: 52px;
        top: 64px;
    }
    &:before {
        right: 25px;
        top: -12px;
        border: 6px solid;
        border-color: transparent transparent #fff transparent;
        z-index: 99;
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }
    &:after {
        right: 24px;
        top: -14px;
        border: 7px solid;
        border-color: transparent transparent #bbb transparent;
        z-index: 98;
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }

    .customer-name {
        font-weight: 800;
    }

    .item {
        margin-bottom: 10px;
        font-family: 'Droid Serif';

        a {
            color: #777;
        }
    }

    &.show {
        display: block !important;
    }
}

#wishlist-sidebar {

    .product-item {

        .product-item-info {

            .product-item-details {
                margin-left: 0 !important;
                padding: 0 !important;
                height: unset !important;
                margin-bottom: 40px;

                .product-item-name {
                    margin-left: 25px !important;
                    margin-bottom: 10px !important;
                }
                .price-box {
                    margin-left: 25px;
                }
                .product-item-actions {
                    position: unset !important;

                    .actions-secondary {
                        .btn-remove {
                            right: unset !important;
                            left: 0 !important;
                            top: -2px !important;
                        }
                    }
                }
            }
        }
    }
}
.action.back {
    display: none !important;
}

.shipping-cost-details {
    a {
        color: $primary_yellow;
    }
}

.arrows {
    .arrow-left {
        position: absolute;
        top: 35%;
        left: -24px;
        background-image: url(../images/weiter-zurueck.svg);
        width: 24px;
        height: 24px;
        background-size: cover;
        cursor: pointer;
        user-select: none;
        display: block;
        transform: rotate(180deg);

        &.slick-hidden {
            display: none;
        }
        &.slick-disabled {
            cursor: default;
            filter: saturate(0%);
            opacity: 0.2;
        }
    }
    .arrow-right {
        position: absolute;
        top: 35%;
        right: -24px;
        background-image: url(../images/weiter-zurueck.svg);
        width: 24px;
        height: 24px;
        background-size: cover;
        cursor: pointer;
        user-select: none;
        display: block;

        &.slick-hidden {
            display: none;
        }
        &.slick-disabled {
            cursor: default;
            filter: saturate(0%);
            opacity: 0.2;
        }
    }
}

.productslider {
    width: 100%;

    .product {
        margin: 10px;
        //box-shadow: 0 1px 5px rgba(0,0,0,0.2);
        border: 2px solid #f2f2f2;

        .image {
            height: 300px;
            background-color: #f2f2f2;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        .details {
            padding: 20px;
            padding-top: 17px;
            text-align: left;
            background-color: white;

            .title {
                margin: 0 !important;
                text-transform: uppercase;
                font-size: 14px;
                height: 70px !important;
                line-height: 16px;
                font-weight: 800;
                text-align: left;

                a {
                    color: black;

                }
                .alc {
                    font-weight: 400;
                    margin-top: 4px;
                }
            }

            .price {
                font-family: 'Montserrat';
                font-weight: 800;
                font-size: 16px;
                margin-bottom: 10px;

                .label {
                    font-weight: 400;
                    font-size: 12px;
                }
                .old-price {
                    color: #c31e1e;
                    text-decoration: line-through;
                    padding-left: 10px;
                }
            }
            .tax {
                font-family: 'Montserrat';
                margin-bottom: 4px;

                a {
                    color: $primary_yellow;
                }
            }
        }
    }
}

.catalog-product-compare-index {

    .column.main {
        padding: 0 !important;

        .table-comparison {

            tbody {
                &:first-of-type {
                    tr {
                        background-color: white;

                        &:nth-child(odd) {
                            background-color: white;
                        }
                    }
                }
                tr {
                    &:nth-child(odd){
                        background-color: #efefef;
                    }
                }
            }
        }
    }
}

.page-print {
    .columns {
        margin: 0;
    }
    .page-title-wrapper {
        margin: 0 !important;
        padding: 0 !important;
        padding-top: 20px !important;
    }
    #maincontent {
        background-color: white;
    }
}

a.order-number {
    color: $primary-yellow !important;
    &:hover {
        color: $primary-yellow !important;
    }
    &:visited {
        color: $primary-yellow !important;
    }
    &:active {
        color: $primary-yellow !important;
    }
}

a.print {
    background-color: $primary-yellow;
    padding: 10px;
    color: white;
    border-radius: 3px;
    margin: 0;
    &:hover {
        text-decoration: none;
        color: white;
    }
}

.catalogsearch-result-index {
    .sidebar-additional {
        display: none !important;
    }
}

.cc-banner.cc-bottom {
    z-index: 20;
}
