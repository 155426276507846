#hubertus-vallendar {

    .block-1 {
        display: flex;
        align-items: center;
        margin-top: 100px;
        margin-bottom: 100px;
        @media screen and (max-width: 768px){
            margin-right: 20px;
        }
        @media screen and (max-width: 576px){
            flex-direction: column;
            margin-top: 40px;
        }

        .image {
            margin-right: 50px;
            flex-basis: 50%;
            @media screen and (max-width: 576px){
                margin-right: 20px;
                margin-bottom: 40px;
            }
        }

        .text {
            column-count: 1;
            flex-basis: 50%;
        }

    }
    .block-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 300px;
        margin-top: -80px;
        @media screen and (max-width: 850px){
            margin-bottom: 120px;
        }
        @media screen and (max-width: 576px){
            margin-top: -140px;
            margin-bottom: 40px;
        }

        .image {
            margin-right: 150px;
            flex: 0 0 auto;
            @media screen and (max-width: 768px){
                transform: translateX(50%);
            }
            @media screen and (max-width: 576px){
                display: none;
            }
        }
        .image-stack {
            img {
                &:first-child {
                    position: relative;
                    z-index: 1;
                }
                &:nth-child(2) {
                    position: absolute;
                    top: 230px;
                    z-index: 0;
                    @media screen and (max-width: 1300px){
                        right: 0;
                    }
                    @media screen and (max-width: 850px){
                        float: right;
                        top: 0;
                        position: relative;
                    }
                }
            }
        }
    }
    .block-3 {
        margin-top: 60px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 576px){
            flex-direction: column;
        }

        .text {
            column-count: 1;
            margin-right: 100px;
            @media screen and (max-width: 768px){
                margin-right: 20px;
            }
            @media screen and (max-width: 576px){
                order: 1;
                margin-right: 0;
            }

            p {
                margin-bottom: 30px;
            }
        }
        .image {
            margin-right: 100px;
            flex: 0 0 auto;
            flex-basis: 32.6%;
            @media screen and (max-width: 768px){
                margin-right: 50px;
            }
            @media screen and (max-width: 576px){
                order: 2;
                margin-right: 0;
            }
        }
    }
    .quote-2 {
        margin-left: 30%;
        @media screen and (max-width: 768px){
            margin-left: 20px;
            margin-right: 20px;
        }
        @media screen and (max-width: 576px){
            margin-left: 20px;
            margin-right: 20px;
        }
    }
    .quote-3 {
        margin-left: 0%;
        text-indent: 0%;
        @media screen and (max-width: 576px){
            margin-right: 20px;
            margin-left: 20px;
        }
    }
    .block-4 {
        display: flex;
        justify-content: space-between;
        max-height: 630px;
        height: 47vw;
        margin-top: -100px;
        @media screen and (max-width: 576px){
            flex-direction: column;
            max-height: unset;
            height: unset;
        }

        .image:first-of-type {
            align-self: flex-start;
            margin-right: 20px;
            @media screen and (max-width: 576px){
                margin-right: 0;
                margin-left: 20px;
                transform: translate(50px, -40px);
                z-index: 1;
                position: relative;
            }
        }
        .image:nth-of-type(2) {
            align-self: flex-end;
            @media screen and (max-width: 576px){
                margin-left: 20px;
                margin-right: 20px;
                margin-top: -10px;
            }
        }
    }
    .block-5{
        margin-top: 100px;
        display: flex;
        padding-left: 12%;
        @media screen and (max-width: 768px){
            padding-left: 0;
        }
        @media screen and (max-width: 576px){
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;
        }

        .image {
            margin-right: 50px;
            flex: 0 0 auto;
            flex-basis: 50%;
            @media screen and (max-width: 576px){
                margin-left: 20px;
                margin-right: 0;
            }
        }
        .text {
            column-count: 1;
            margin-right: 20px;
            @media screen and (max-width: 576px){
                margin-right: 0;
                padding-top: 40px;
            }
        }
    }
    .block-6 {

        .block-images {
            display: flex;
            max-height: 580px;
            height: 48vw;
            justify-content: space-between;
            @media screen and (max-width: 576px){
                flex-direction: column;
                height: auto;
                margin-top: -60px;
                margin-bottom: -40px;
            }
            .image:first-child {
                align-self: flex-start;
                margin-right: 20px;
                @media screen and (max-width: 576px){
                    transform: translateX(-60px);
                }
            }
            .image:nth-child(2) {
                align-self: flex-end;
                @media screen and (max-width: 576px){
                    margin-left: 20px;
                    margin-top: 30px;
                }
            }
        }
        .text {
            column-count: 1;
            max-width: 890px;
            margin: 0 auto;
            margin-top: 80px;
            margin-bottom: 60px;
            @media screen and (max-width: 768px){
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }
}