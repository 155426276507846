#partner {

    .partner-wrapper {
        margin-top: 100px;

        .group {
            font-family: 'Montserrat';
            text-transform: uppercase;
            font-weight: 800;
            font-size: 28px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .partner-container {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            width: 1200px;
            margin-bottom: 100px;
            @media screen and (max-width: 1280px){
                width: 900px;
            }
            @media screen and (max-width: 960px){
                width: 600px;
            }
            @media screen and (max-width: 640px){
                width: 300px;
            }

            .partner {
                height: 180px;
                width: 299px;
                position: relative;

                .partner-image {
                    background-size: auto;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
                .info {
                    opacity: 0;
                    background-color: $primary_yellow;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    transition: 0.2s;

                    .name {
                        font-family: 'Droid Serif';
                        color: white;
                        text-transform: uppercase;
                        text-align: center;
                        font-size: 18px;
                        hyphens: auto;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    a {
                        font-family: 'Montserrat';
                        color: white;
                        text-transform: uppercase;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 800;
                    }
                }
                &:hover > .info {
                    opacity: 1;
                    transition: 0.2s;
                }
            }
        }
    }
}