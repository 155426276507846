.page-layout-1column:not(.catalog-product-view):not(.customer-account-create):not(.checkout-cart-index):not(.checkout-index-index) {
    
    .page-wrapper {

        .page-main {
            background-color: #f4f4f4;
            padding-bottom: 1px;

            .page-title-wrapper {
                margin-left: 100px;
                margin-right: 100px;
                padding-left: 20px;
                padding-top: 100px;
                padding-bottom: 15px;
                @media screen and (max-width: 1280px){
                    margin-left: 70px;
                    margin-right: 85px;
                }
                @media screen and (max-width: 768px){
                    margin-left: 85px;
                    margin-right: 85px;
                }
                @media screen and (max-width: 576px){
                    margin-left: 20px;
                    margin-right: 20px;
                }

                .page-title {
                    text-transform: uppercase;
                    font-weight: 800;
                    font-size: 28px;
                }
            }
            .columns {

                .column.main {
                    background-color: white;
                    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
                    padding: 20px;
                    margin-bottom: 100px;
                    font-family: 'Droid Serif';
                }
            }
        }
    }
}