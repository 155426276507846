.amgdprcookie-modal-container {
    align-items: center;
    justify-content: center;
    display: flex;

    .modal-inner-wrap {
        max-width: 900px;
        @media screen and (min-width: 1440px) {
            width: 50%;
        }
    }

    h1 {
        font-family: Noto Serif;
        font-style: normal;
        font-weight: bold;
        font-size: 55px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        margin-bottom: 10px;
        color: #333333;
        @media screen and (max-width: 767px) {
            font-size: 30px;
        }

    }

    .amgdprcookie-policy-container {
        margin-bottom: 30px;
    }

    font-family: Montserrat;
    color: #333333;
    line-height: 150%;

    .amgdprcookie-groups-container {
        height: auto !important;

        .amgdprcookie-cookie-container.-modal {
            margin-bottom: 30px;

            .amgdprcookie-header {
                text-transform: uppercase;
                display: table;
                width: 100%;
                padding: 10px 0;
                border-bottom: 1px solid #333333;

                .amgdprcookie-caption {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    margin: 0;
                    color: #333333;
                    display: table-cell;
                    vertical-align: bottom;
                }
                .amgdprcookie-link {
                    cursor: pointer;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    display: table-cell;
                    vertical-align: bottom;
                    text-align: right;
                }
            }

            .amgdprcookie-content {
                clear: both;
                display: table;
                width: 100%;
                padding: 10px 0;

                .amgdprcookie-text {
                    display: table-cell;
                    width: 85%;
                    @media screen and (max-width: 767px) {
                        width: 65%;
                    }
                }
                .amgdprcookie-toggle-cookie {
                    display: table-cell;
                }
            }
        }
    }


    .amgdprcookie-buttons-block {
        margin-top: 50px;

        .amgdprcookie-button {
            background: #E1B203;
            border: none;
            border-radius: 0;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 2px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            width: 49%;
            min-height: 35px;
            &.-save {
                float: left;
            }
            &.-allow {
                float: right;
            }
        }
    }

    .modal-content {
        padding: 0 2.6rem 5.6rem;
    }

    /* toggle switch */
    .amgdprcookie-toggle-cookie {
        position: relative;
        padding-left: 50px;

        .amgdprcookie-input {
            visibility: hidden;
            width: 0;
            height: 0;
        }

        .amgdprcookie-label {
            position: absolute;
            top: 0;
            right: 0;
            width: 85px;
            height: 40px;
            @media screen and (max-width: 767px) {
                width: 50px;
                height: 29px;
            }
            border-radius: 40px;
            background: #a5a5a5;
            box-shadow: 0 2px 5px rgba(0, 0, 0, .05);
            cursor: pointer;

            &:after {
                position: absolute;
                top: 2px;
                left: 2px;
                width: 36px;
                height: 36px;
                border-radius: 40px;
                background: #fff;
                content: '';
                transition: all .4s ease;
                @media screen and (max-width: 767px) {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .amgdprcookie-input:checked:disabled + .amgdprcookie-label {
            & {
                background: #e8e8e8;
            }

            &:after {
                left: 95%;
                transform: translateX(-100%);
            }
        }

        .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
            & {
                background: #E1B203;
            }

            &:after {
                left: 95%;
                transform: translateX(-100%);
            }
        }

        .amgdprcookie-input:not([checked]) + .amgdprcookie-label {
            background: #a5a5a5;
        }

        .amgdprcookie-input:disabled + .amgdprcookie-label {
            & {
                background: #e8e8e8;
            }

            &:after {
                left: 2px;
            }
        }


    }

}
