#unsere-destillate {
   
    .title {
        margin-bottom: 110px;
    }
    .destillat {
        display: flex;
        flex-direction: row;
        padding: 60px;
        position: relative;
        margin-bottom: 60px;
        height: 310px;
        @media screen and (max-width: 768px){
            height: unset;
        }
        @media screen and (max-width: 576px){
            padding: 20px;
            flex-direction: column;
        }

        .col1 {
            flex: 1;
            z-index: 1;

            .title {
                font-family: 'Droid Serif';
                font-size: 32px;
                color: #777;
                font-weight: 400;
                text-align: left;
                text-transform: unset;
                margin-bottom: 20px;
                margin-top: 0;
                @media screen and (max-width: 1100px){
                    font-size: 22px;
                    margin-bottom: 10px;
                }
            }
            .text {
                column-count: 1;
                font-size: 16px;
                line-height: 24px;
                padding: 0 !important;
                @media screen and (max-width: 1100px){
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            .link {
                text-align: left;
                margin-top: 20px;
                font-family: 'Droid Serif';
                font-size: 16px;
                @media screen and (max-width: 1100px){
                    font-size: 14px;
                }
                a {
                    color: $primary_yellow;
                }
            }
        }
        .col2 {
            flex: 1.1;
            @media screen and (max-width: 576px){
                position: relative;
                height: 220px;
                flex: unset;
            }

            .bg {
                position: absolute;
                top: 0;
                bottom: 0;
                @media screen and (max-width: 1100px){
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }
                @media screen and (max-width: 576px){
                    overflow: hidden;
                    height: 160px;
                    width: 100%;
                }

                img {
                    height: 100%;

                    @media screen and (max-width: 1100px){
                        &:after {

                        }
                    }
                    @media screen and(max-width: 576px){
                        width: 100%;
                        height: auto;
                        transform: translateY(-25%);
                    }
                }
            }
            .image {
                position: absolute;
                @media screen and (max-width: 1100px){
                    transform: scale(0.8);
                    bottom: 0;
                }
                @media screen and (max-width: 720px){
                    bottom: 15%;
                }
                @media screen and (max-width: 576px){
                    right: 0 !important;
                    left: 0 !important;
                    transform: scale(0.7);
                    bottom: -20px !important;
                    img {
                        max-height: 350px;
                    }
                }
            }
            .single-image {
                position: absolute;
                left: 55%;
                bottom: -20px;
                @media screen and (max-width: 1100px){
                    transform: scale(0.8);
                }
                @media screen and (max-width: 576px){
                    left: 0 !important;
                    right: 0 !important;
                    transform: scale(0.5);
                    bottom: -100px;
                }
            }
        }

        &:nth-child(odd) {
            padding-left: 0;
            @media screen and (max-width: 1240px){
                padding-left: 20px;
            }
            @media screen and (max-width: 576px){
                background-color: transparent;
            }

            .col1 {
                order: 1;
                @media screen and (max-width: 576px){
                    order: 2;
                }
            }
            .col2 {
                order: 2;
                @media screen and (max-width: 576px){
                    order: 1;
                }

                .bg {
                    right: 0;
                    &:after {
                        @media screen and (max-width: 1100px){
                            background: linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                        }
                        @media screen and (max-width: 720px){
                            background: linear-gradient(to left, rgba(255,255,255,0) -40%, rgba(255,255,255,1) 80%);
                        }
                        @media screen and (max-width: 576px){
                            display: none;
                        }
                    }
                    img {
                        text-align: right;
                    }
                }
                .image {
                    left: 46%;
                    bottom: 0;
                }
            }
        }
        &:nth-child(even) {
            padding-right: 0;
            background-color: transparent;
            @media screen and (max-width: 1240px){
                padding-right: 20px;
            }

            .col1 {
                order: 2;
            }
            .col2 {
                order: 1;

                .bg {
                    left: 0;
                    &:after {
                        @media screen and (max-width: 1100px){
                            background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                        }
                        @media screen and (max-width: 720px){
                            background: linear-gradient(to right, rgba(255,255,255,0) -40%, rgba(255,255,255,1) 80%);
                        }
                        @media screen and (max-width: 576px){
                            display: none;
                        }
                    }
                    img {
                        text-align: left;
                    }
                }
                .image {
                    right: 46%;
                    bottom: 0;
                }
            }
        }
        &:first-child {
            margin-top: 110px;
        }
    }
}