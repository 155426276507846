.account:not(.page-print):not(.form-create-account) {

    #maincontent {
        background-color: #f4f4f4;

        @media screen and (max-width: 768px){
            padding-top: 41px;
        }

        .page-title-wrapper {
            max-width: 1240px;
            margin: 0 auto;
            padding-top: 80px;
            padding-bottom: 10px;
            @media screen and (max-width: 1440px){
                margin-left: 100px;
                margin-right: 100px;
            }
            @media screen and (max-width: 1280px){
                margin-left: 70px;
                margin-right: 85px;
            }
            @media screen and (max-width: 768px){
                margin-left: 85px;
                margin-right: 85px;
                padding-top: 0;
                padding-bottom: 0;
            }
            @media screen and (max-width: 576px){
                margin-left: 20px;
                margin-right: 20px;
            }

            .page-title {
                font-family: 'Montserrat';
                text-transform: uppercase;
                font-size: 28px;
                font-weight: 800;
            }
        }

        .columns {

            .main {
                width: 70%;
                @media screen and (max-width: 1090px){
                    width: 60%;
                }
                @media screen and (max-width: 768px){
                    width: 100%;
                }

                .block {

                    .block-title {
                        font-family: 'Montserrat';
                        text-transform: uppercase;
                        margin-left: 30px;
                        strong {
                            font-weight: 800;
                        }
                    }
                    .block-content {

                        .box {
                            background-color: white;
                            padding: 30px;
                            box-shadow: 0 1px 2px rgba(0,0,0,0.1);
                            width: 40%;
                            min-height: 100px;
                            font-family: 'Droid Serif';
                            font-size: 16px;
                            line-height: 32px;
                            color: #777;

                            @media screen and (max-width: 1090px){
                                width: calc(100% - 60px);
                                float: none;
                            }

                            .box-title {
                                margin-bottom: 0;

                                span {
                                    font-weight: 800;
                                    font-size: 16px;
                                    line-height: 32px;
                                }
                            }
                            .box-content {
                                line-height: 32px;
                            }
                            .box-actions {
                                margin-top: 20px;

                                a {
                                    color: $primary_yellow;
                                    font-size: 14px;
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }

                .table-wrapper {
                    table {
                        background-color: white;
                        box-shadow: 0 1px 2px rgba(0,0,0,0.2);
                        font-family: 'Montserrat';

                        th {
                            padding: 20px;
                            text-transform: uppercase;
                        }
                        tbody {
                            tr {
                                &:nth-child(even) {
                                    background-color: #f4f4f4;
                                }
                            }
                        }
                        td {
                            padding: 20px;

                            &.col.actions {
                                a.action.view {
                                    background-image: url(../images/open.svg);
                                    width: 18px;
                                    height: 18px;
                                    display: block;
                                    background-repeat: no-repeat;
                                }
                            }
                        }
                    }
                }

            }
            .sidebar {

                @media screen and (max-width: 1090px){
                    width: 36% !important;
                }
                @media screen and (max-width: 768px){
                    width: 100% !important;
                }

                &.sidebar-main {
                    margin-top: 0 !important;

                    .block {

                        .content {
                            background-color: #e9e9e9;

                            .nav.items {
                                font-family: 'Droid Serif';
                                font-size: 16px;
                                color: #777;

                                .nav.item {
                                    a {
                                        color: #777;
                                    }
                                }
                                .current {
                                    border: none;

                                    a {
                                        border: none;

                                        strong {
                                            padding-left: 0;
                                        }
                                    }
                                    strong {
                                        border: none;
                                        color: $primary_yellow;
                                        padding-left: 15px;

                                        &:before {
                                            content: '→ ';
                                            display: inline-block;
                                            margin-right: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
                &.sidebar-additional {
                    display: none !important;
                }
            }

        }

    }

    .products-grid.wishlist {
        .product-items {

            .product-item {
                background-color: white;
                width: calc(33% - 20px );
                margin-right: 20px;
                box-shadow: 0 1px 4px rgba(0,0,0,0.2);
                padding: 0 !important;
                margin-left: 0 !important;

                @media screen and (max-width: 1380px){
                    width: calc(50% - 20px);
                }
                @media screen and (max-width: 960px){
                    width: 100%;
                    margin-right: 0;
                }

                .product-item-info {
                    font-family: 'Montserrat';

                    .product-item-name {
                        padding: 0 20px;
                        font-family: 'Montserrat';
                        text-transform: uppercase;
                    }
                    .price-box {
                        padding: 0 20px;
                    }
                    .price-details {
                        padding: 0 20px;
                    }
                    .product-item-inner {
                        padding: 0 20px;
                    }
                    .box-tocart {
                        .fieldset {
                            padding: 0;
                            box-shadow: none;
                            background: none;

                            .field.qty {
                                display: inline-block;
                            }
                            .product-item-actions {
                                position: relative;
                                width: auto;
                                transform: translateY(-31px);
                                float: right;
                            }
                        }
                    }
                    .product-item-actions {
                        position: relative;

                        .action.delete {
                            float: right;
                        }
                    }
                }
            }
        }
    }
    .fieldset {
        background-color: white;
        padding: 20px;
        padding-bottom: 0;
        box-shadow: 0 1px 2px rgba(0,0,0,0.2);

        &.password {
            padding-bottom: 40px;

            .new.password {
                @media screen and (max-width: 540px){
                    margin-bottom: 60px !important;
                }

                #password-strength-meter-container {
                    position: absolute;
                    width: 100%;

                    .password-strength-meter {
                        height: auto;
                    }
                }
            }
        }

        & > .legend {
            margin-bottom: 60px;

            span {
                font-family: 'Droid Serif';
                font-size: 18px;
                line-height: 32px;
            }
        }

        .field.email,
        .field.password {
            width: 50%;
            display: inline-block;
            position: relative;
            @media screen and (max-width: 540px){
                width: 100%;
            }
        }

        .choice {
            margin-bottom: 20px;

            &:before {
                display: none;
            }

        }

        .field.choice {
            font-family: 'Droid Serif';
            display: inline-block;
            margin-right: 20px;
        }

        .field:not(.choice) {
            position: relative;
            width: 50%;
            display: inline-block;
            margin-bottom: 30px !important;

            @media screen and (max-width: 540px){
                width: 100%;
            }

            &.street {
                .nested {
                    display: none;
                }
            }
            &.region {
                display: none;
            }

            label {
                font-family: 'Droid Serif';
                position: absolute !important;
                bottom: 0px;
                display: block;
                float: none !important;
                text-align: left !important;
                width: 90% !important;
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                position: relative;
                margin-bottom: 4px;
                z-index: 1;
                transition: 0.2s;
                @media screen and (max-width: 540px){
                    width: 100%;
                }

                &:after {
                    color: $primary_yellow !important;
                }
            }
            .control {
                width: 90%;
                display: block;
                position: relative;
                z-index: 10;
                @media screen and (max-width: 540px){
                    width: 100%;
                }

                > .field {
                    display: none;
                }

                input {
                    border: none;
                    border-bottom: 2px solid #aaa;
                    width: 100%;
                    display: block;
                    background: transparent;
                    padding: 0;
                    font-family: 'Droid Serif';
                    font-size: 16px;
                    color: #777;
                    transition: 0.2s;

                    &:focus {
                        box-shadow: none;
                    }
                }
                select {
                    appearance: none;
                    border: none;
                    border-radius: 0;
                    border-bottom: 2px solid #aaa;
                    width: 100%;
                    display: block;
                    background: transparent;
                    padding: 0;
                    font-family: 'Droid Serif';
                    font-size: 16px;
                    color: #777;
                    transition: 0.2s;
                }
            }

            &.focused {

                label {
                    font-size: 14px;
                    transition: 0.2s;
                    bottom: 30px;
                }
                .control {

                    input {
                        border-color: $primary_yellow;
                        color: $primary_yellow;
                        transition: 0.2s;
                    }
                    select {
                        border-color: $primary_yellow;
                        color: $primary_yellow;
                        transition: 0.2s;
                    }
                }
            }
        }
    }
    .actions-toolbar {
        margin-left: 0 !important;

        @media screen and (max-width: 768px){
            width: 100% !important;
        }

        @media screen and (max-width: 540px){
            margin-top: 40px;
        }

        .primary {
            @media screen and (max-width: 768px){
                width: 100% !important;
            }
            button {
                @media screen and (max-width: 768px){
                    width: 100% !important;
                }
            }
        }
        .secondary {
            float: none;
            margin-left: 20px;
            @media screen and (max-width: 540px){
                margin-left: 0;
            }

            a {
                color: $primary_yellow;
                font-size: 14px;
            }
        }
    }

    #swissup-address-fields-container {
        display: none;
    }

    .ui-datepicker-trigger {
        position: absolute;
        bottom: 0;
        right: 0px;
    }

    .swissup-customer-fields-container {
        display: none !important;
    }

    .items.addresses {
        .item:not(.actions) {
            background-color: white;
            padding: 30px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            width: 200px;
            min-height: 100px;
            font-family: 'Droid Serif';
            font-size: 16px;
            line-height: 32px;
            color: #777;
            margin: 0 !important;
            margin-right: 10px !important;
            margin-bottom: 10px !important;

            @media screen and (max-width: 1055px){
                width: calc(100% - 60px);
            }

            .item.actions {
                a {
                    color: #d7aa02;
                    span {
                        color: #d7aa02;
                        font-size: 14px;
                        margin-right: 20px;
                    }
                    &:hover {
                        color: #d7aa02;
                        span {
                            color: #d7aa02;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

}

.sales-order-history {
    .columns {
        .main {
            @media screen and (max-width: 1090px){
                width: 100% !important;
            }
        }
        .sidebar {
            @media screen and (max-width: 1090px){
                width: 100% !important;
            }
        }
    }
}

.wishlist-index-index {

    .block.block-wishlist {
        display: none !important;
    }

    .products-grid.wishlist {
        margin-right: 0 !important;
    }

    .actions-toolbar {
        .primary {
            button {
                background-color: $primary_yellow;
                border-radius: 0;
                border: none;
                font-family: 'Montserrat';
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 400;
                color: white;
            }
        }
    }

    .product-items {
        @media screen and (max-width: 768px){
            margin-left: 0 !important;
        }
    }

    .product-item {

        @media screen and (max-width: 768px){
            width: calc(50% - 10px) !important;
            margin-right: 20px !important;

            &:nth-child(2){
                margin-right: 0 !important;
            }

            .product-item-info {
                .product-item-photo {
                    float: none !important;
                    margin-right: 0 !important;
                    margin-bottom: 10px !important;

                    .product-image-container {
                        max-width: 100% !important;
                    }
                }
                .price-box.price-configured_price {
                    margin-left: 0 !important;
                }
                .product-item-name {
                    font-size: 14px !important
                }
            }
        }

        @media screen and (max-width: 639px){
            width: 100% !important;
            margin-right: 0 !important;
            margin-bottom: 20px !important;
        }

        .price-details {
            margin-bottom: 20px;
        }
        .product-item-tooltip {
            display: none;
        }
        .field.qty {
            display: none !important;
        }
        .remove-item-from-wishlist {
            background-image: url(../images/delete_icon.svg);
            height: 24px;
            width: 24px;
            background-size: cover;
            background-position: center;
        }
        .product-item-inner {
            .box-tocart {
                width: auto;
                display: inline-block;
            }
            .product-item-actions {
                width: auto;
                display: inline-block;
                transform: unset !important;
                float: right;
                transform: translateY(4px) !important;
                @media screen and (max-width: 767px){
                    transform: none !important;
                }
            }
        }
    }
    .fieldset {
        box-shadow: none;
        padding: 0;
    }
}

.sales-order-view,
.sales-order-shipment,
.sales-order-invoice {
    .payment-method.purchase.order {
        .content {
            display: none;
        }
    }
}
.sales-order-shipment {
    a.action.print {
        float: right;
    }
    .order-details-items.shipments {
        .actions-toolbar {
            margin-bottom: 10px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;

            .action.track {
                line-height: 40px;
            }
        }
        .order-tracking {
            display: none !important;
        }
        .table-wrapper.order-items-shipment {
            margin-top: 10px;
        }
    }
}
.sales-order-invoice {
    a.action.print {
        float: right;
    }
    .order-details-items.invoice {
        .actions-toolbar {
            margin-bottom: 10px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
        }
    }
}
