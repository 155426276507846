.page-layout-blog_page_layout {

    &.blog-index-index {

        .blog-post-list-item {
            .blog-post-item-content {
                img {
                    display: none;
                }
            }
        }

        #maincontent {
            .blog-wrapper {
                max-width: 1240px;
                @media screen and (max-width: 1440px){
                    margin-left: 100px;
                    margin-right: 100px;
                }
                @media screen and (max-width: 1280px){
                    margin-left: 85px;
                    margin-right: 85px;
                }
                @media screen and (max-width: 576px){
                    margin-left: 20px;
                    margin-right: 20px;
                }

                .blog-post-list-item {
                    padding-bottom: 20px;
                    border-bottom: 2px solid #ccc;
                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                    .content {
                        width: 100%;

                        .blog-post-item-date {
                            text-align: left !important;
                            margin-bottom: 10px !important;
                        }
                    }
                }
            }
        }
    }

    #maincontent {
        background-color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;

        .blog-wrapper {
            padding-bottom: 60px;
            max-width: 600px;
            margin: 0 auto;
            @media screen and (max-width: 768px){
                margin-left: 85px;
                margin-right: 85px;
            }
            @media screen and (max-width: 576px){
                margin-left: 20px;
                margin-right: 20px;
            }

            &.blog-wrapper-wide {
                max-width: 1000px !important;

                @media screen and (max-width: 1200px){
                    max-width: 100% !important;
                    margin-left: 85px !important;
                    margin-right: 85px !important;
                }
                @media screen and (max-width: 576px){
                    margin-left: 20px !important;
                    margin-right: 20px !important;
                }
            }
            .page-title-wrapper {
                display: block !important;
                max-width: 1240px;
                margin: 0 auto;
                padding-top: 40px;
                padding-bottom: 10px;
                font-family: 'Montserrat';
                font-weight: 800;
                text-transform: uppercase;
                @media screen and (max-width: 576px){
                    padding-top: 20px;
                }

                h1 {
                    font-size: 28px;
                    font-weight: 800;
                    margin-bottom: 20px;
                    text-align: center;
                }
                .caption {
                    font-family: 'Droid Serif';
                    text-align: center;
                    font-style: italic;
                    color: #777;
                    font-size: 14px;
                    font-weight: 400;
                    width: 70%;
                    margin: 0 auto;
                }
            }
            .blog-post-list {

                .blog-post-list-item {
                    margin-bottom: 40px;
                    display: flex;
                    flex-direction: row;
                    @media screen and (max-width: 900px){
                        flex-direction: column;
                    }

                    .image-link {
                        flex: 0 0 300px;
                        height: auto;
                        background-size: cover;
                        background-position: center;
                        background-color: #f2f2f2;

                        @media screen and (max-width: 900px){
                            width: 100%;
                            flex: 0 0 300px;
                            margin-bottom: 20px;
                        }
                        @media screen and (max-width: 576px){
                            flex: 0 0 200px;
                        }

                        .image {
                            height: 100%;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                        }
                    }

                    .content {
                        padding-left: 20px;
                        @media screen and (max-width: 768px){
                            padding-left: 0;
                        }

                        p, span {
                            font-family: 'Droid Serif' !important;
                            font-size: 16px !important;
                            color: #777 !important;
                            line-height: 28px !important;
                            font-weight: 400 !important;
                            text-align: left !important;
                        }
                        strong {
                            font-family: 'Droid Serif' !important;
                            font-size: 16px !important;
                            color: #777 !important;
                            line-height: 28px !important;
                            font-weight: 800 !important;
                            text-align: left !important;
                        }

                        &.no-image {
                            padding-left: 0;
                        }
                        .blog-post-item-date {
                            font-family: 'Droid Serif';
                            font-size: 14px;
                            color: #777;
                            margin-bottom: 40px;
                            text-align: center;
                        }
                        .blog-post-item-title {
                            font-family: 'Montserrat';
                            font-weight: 800;
                            text-transform: uppercase;
                            font-size: 18px;
                            margin-bottom: 15px;
                            margin-top: 0;

                            a {
                                color: #333;
                            }
                        }
                        .blog-post-item-content {
                            font-family: 'Droid Serif';
                            line-height: 28px;
                            font-size: 16px;
                            color: #777;

                            a {
                                color: #777;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
