.team {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media screen and (max-width: 1200px){
        justify-content: center;
    }

    team-member {
        width: 500px;
        height: 330px;
        background-size: cover;
        background-position: center;
        width: 500px;
        height: 330px;
        margin: 20px;
        position: relative;
        @media screen and (max-width: 440px){
            height: 250px
        }       
        @media screen and (max-width: 360px){
            height: 200px
        }       

        &:nth-of-type(4n - 3) {
            margin-left: 11%;
            @media screen and (max-width: 1200px){
                margin-left: 20px;
            }       
        }

        .details {
            display: none;
            padding: 10px;
            background-color: rgba(215, 170, 2, 0.8);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 10px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .name {
                font-family: 'Montserrat';
                font-weight: 800;
                font-size: 16px;
                text-transform: uppercase;
                color: white;
                margin-bottom: 10px;
            }
            .email {
                font-family: 'Droid Serif';
                color: white;
                font-weight: 400;
                font-size: 16px;
                text-decoration: underline;
                a {
                    color: white;
                    &:hover {
                        color: white;
                    }
                    &:visited {
                        color: white;
                    }
                    &:active {
                        color: white;
                    }
                }
            }
            .position {
                font-family: 'Droid Serif';
                color: white;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }

        &:hover {
            .details {
                display: flex;
            }
        }
    }
}

.cms-brennerei, .cms-cognac {
    .cms-split-section {
        display: flex;
        flex-direction: row;
        padding-top: 100px;
        padding-bottom: 100px;
        justify-content: flex-start;
        align-items: center;

        &.odd {
            padding-right: 100px;
            
            @media screen and (max-width: 992px){
                padding-right: 85px;
            }
            @media screen and (max-width: 768px){
                padding-left: 85px;
                padding-top: 60px;
                flex-direction: column;
            }
            @media screen and (max-width: 576px){
                padding-left: 20px;
                padding-right: 20px;
            }
            .left {
                margin-right: 120px;
                flex-basis: 50%;
                flex-grow: 2;
                @media screen and (max-width: 992px){
                    margin-right: 40px;
                }
                @media screen and (max-width: 768px){
                    order: 1;
                    margin: 0 auto;
                    .image {
                        text-align: center;
                    }
                }
            }
            .right {
                flex-basis: 60%;
                flex-grow: 1;
                @media screen and (max-width: 768px){
                    order: 0;
                }
            }
        }
        &.even {
            padding-left: 100px;

            @media screen and (max-width: 992px){
                padding-left: 85px;
            }
            @media screen and (max-width: 768px){
                padding-right: 85px;
                padding-top: 60px;
                flex-direction: column;
            }
            @media screen and (max-width: 576px){
                padding-left: 20px;
                padding-right: 20px;
            }
            .left {
                flex-basis: 60%;
                flex-grow: 1;
                @media screen and (max-width: 768px){
                    order: 0;
                }
            }
            .right {
                margin-left: 120px;
                flex-basis: 50%;
                flex-grow: 2;
                @media screen and (max-width: 992px){
                    margin-left: 40px;
                }
                @media screen and (max-width: 768px){
                    order: 1;
                    margin: 0 auto;
                    .image {
                        text-align: center;
                    }
                }
                .image {
                    img {
                        float: right;
                    }
                }
            }
        }
    }
}