.customer-account-create {

    .page-wrapper {

        .page-main {
            background-color: #f4f4f4;

            .page-title-wrapper {
                margin-left: 100px;
                padding-left: 20px;
                padding-top: 100px;
                padding-bottom: 15px;
                @media screen and (max-width: 1280px){
                    margin-left: 70px;
                }
                @media screen and (max-width: 768px){
                    margin-left: 85px;
                }
                @media screen and (max-width: 576px){
                    margin-left: 20px;
                }
                @media screen and (max-width: 540px){
                    padding-top: 40px;
                }

                .page-title {
                    font-family: 'Montserrat';
                    text-transform: uppercase;
                    font-weight: 800;
                    font-size: 28px;
                }
            }
            .columns {

                .column.main {
                    display: flex;
                    justify-content: space-around;
                    padding-bottom: 140px;
                    @media screen and (max-width: 1040px){
                        display: block;
                    }
                    @media screen and (max-width: 540px){
                        padding-bottom: 40px;
                    }

                    .block-new-customer {
                        padding: 40px;
                        width: 45%;
                        padding-top: 40px;
                        padding-bottom: 40px;

                        @media screen and (max-width: 1040px){
                            display: none;
                        }

                        .block-title {
                            font-size: 18px;
                            font-family: 'Montserrat';
                            text-transform: uppercase;
                            font-weight: 800;
                            border-bottom: none;
                            padding-bottom: 0;
                            margin-bottom: 20px;

                            strong {
                                font-weight: inherit;
                            }
                        }
                        .block-content {
                            font-family: 'Droid Serif';
                            color: #777;
                            font-size: 16px;
                            line-height: 28px;

                            .actions-toolbar {
                                display: none;
                            }
                        }
                    }
                    .form-create-account {
                        background-color: white;
                        padding: 20px;
                        padding-top: 40px;
                        padding-bottom: 40px;
                        box-shadow: 0 1px 2px rgba(0,0,0,0.1);
                        @media screen and (max-width: 768px){
                            min-width: unset !important;
                            width: unset !important;
                        }

                        .fieldset {
                            &.create.account {
                                margin-top: 20px;
                                margin-bottom: 60px;

                                .field {
                                    .control {
                                        width: 97.5%;
                                    }
                                }
                            }
                            .legend {
                                font-family: 'Montserrat';
                                text-transform: uppercase;
                                font-weight: 800;
                                font-size: 18px;
                                margin-bottom: 50px;
                                @media screen and (max-width: 700px){
                                    margin-bottom: 10px;
                                }
                            }

                            .field.email,
                            .field.password {
                                width: 50%;
                                display: inline-block;
                                position: relative;
                                @media screen and (max-width: 700px){
                                    width: 100%;
                                }
                            }
                            .field {
                                position: relative;
                                color: #777;
                                @media screen and (max-width: 700px){
                                    margin-top: 20px;
                                }

                                &.field-name-firstname {
                                    width: 50%;
                                    display: inline-block;
                                    @media screen and (max-width: 700px){
                                        width: 100%;
                                    }
                                }
                                &.field-name-lastname {
                                    width: 50%;
                                    display: inline-block;
                                    @media screen and (max-width: 700px){
                                        width: 100%;
                                    }
                                }
                                &.newsletter {
                                    display: flex;

                                    label {
                                        display: inline-block;
                                        bottom: 0;
                                    }
                                    input {
                                        display: inline-block;
                                        height: 20px;
                                        margin-right: 20px;
                                    }
                                    &.focused {
                                        label {
                                            position: relative !important;
                                            bottom: 0;
                                            font-size: 16px;
                                        }
                                    }
                                    &:before {
                                        display: none;
                                    }
                                }
                                &.field-dob {
                                    label {
                                        bottom: 8px;
                                        @media screen and (max-width: 700px){
                                            bottom: 0;
                                        }
                                    }
                                    &.focused {
                                        label {
                                            bottom: 38px;
                                            @media screen and (max-width: 700px){
                                                bottom: 30px;
                                            }
                                        }
                                    }
                                    .control {
                                        width: 97.5%;
                                        input {
                                            width: calc(100% - 50px);
                                            display: inline-block;
                                        }
                                        button {
                                            display: inline-block;
                                        }
                                    }
                                }
                                &.password {
                                    margin-top: 10px;
                                    display: inline-block;
                                    width: 50%;
                                    @media screen and (max-width: 700px){
                                        width: 100%;
                                        margin-top: 30px;
                                    }

                                    label {
                                        top: 0;
                                    }
                                    &.focused {
                                        label {
                                            top: -30px;
                                        }
                                    }
                                    .control {
                                        width: 95% !important;

                                        .password-strength-meter {
                                            font-family: 'Droid Serif';
                                            position: absolute;
                                            left: 0;
                                            right: 0;
                                            cursor: pointer;

                                            &:after {
                                                content: "?";
                                                color: white;
                                                position: absolute;
                                                right: 10px;
                                                z-index: 10;
                                                cursor: pointer;
                                                background-color: #d7aa02;
                                                border-radius: 50%;
                                                width: 20px;
                                                height: 20px;
                                                line-height: 20px;
                                                text-align: center;
                                                top: 5px;
                                            }
                                        }
                                    }
                                }
                                &.confirmation {
                                    display: inline-block;
                                    width: 50%;
                                    margin-top: 10px;
                                    margin-bottom: 30px;
                                    @media screen and (max-width: 700px){
                                        width: 100%;
                                        margin-top: 60px;
                                    }


                                    .control {
                                        width: 95% !important;
                                    }
                                }
                                label {
                                    position: absolute !important;
                                    bottom: 4px;
                                    font-family: 'Droid Serif';
                                    display: block;
                                    float: none;
                                    width: 95%;
                                    text-align: left;
                                    font-size: 16px;
                                    font-weight: 400;
                                    position: relative;
                                    z-index: 1;
                                    transition: 0.2s;
                                    @media screen and (max-width: 700px){
                                        bottom: 0;
                                    }
                                    @media screen and (max-width: 540px){
                                        width: 100%;
                                    }
                                    &:after {
                                        color: $primary_yellow;
                                    }
                                }
                                .control {
                                    width: 95%;
                                    display: block;
                                    position: relative;
                                    z-index: 10;
                                    @media screen and (max-width: 540px){
                                        width: 100%;
                                    }

                                    input {
                                        border: none;
                                        border-bottom: 2px solid #aaa;
                                        width: 100%;
                                        display: block;
                                        background: transparent;
                                        padding: 0;
                                        font-family: 'Droid Serif';
                                        font-size: 16px;
                                        color: #777;
                                        transition: 0.2s;

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }
                                }

                                &.focused {

                                    label {
                                        bottom: 34px;
                                        font-size: 14px;
                                        transition: 0.2s;
                                        @media screen and (max-width: 700px){
                                            bottom: 30px;
                                        }
                                    }
                                    .control {

                                        input {
                                            border-color: $primary_yellow;
                                            color: $primary_yellow;
                                            transition: 0.2s;
                                        }
                                    }
                                }
                            }
                            .actions-toolbar {
                                @media screen and (max-width: 540px){
                                    margin-top: 40px;
                                }

                                .primary {
                                    button {
                                        height: 40px;
                                    }
                                }
                                .secondary {
                                    float: none;
                                    margin-left: 20px;
                                    @media screen and (max-width: 540px){
                                        margin-left: 0;
                                    }

                                    a {
                                        color: $primary_yellow;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .fieldset.login {

                            &:after {
                                display: none;
                            }
                        }
                        .actions-toolbar {
                            margin: 0;

                            .primary {
                                width: 100%;

                                button {
                                    padding-top: 15px;
                                    padding-bottom: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}