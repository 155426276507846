.cms-page-wrapper, .cms-page-main {

    .slider {
        position: relative;
        width: 100%;
        height: 680px;
        @media screen and (max-width: 768px){
            height: 500px;
        }
        @media screen and (max-width: 576px){
            height: 260px;
        }

        .title {
            position: absolute;
            bottom: 40%;
            left: 10%;
            color: white;
            font-family: 'Droid Serif';
            font-style: italic;
            font-size: 86px;
            line-height: 100px;
            z-index: 1;
            @media screen and (max-width: 768px){
                font-size: 66px;
                line-height: 80px;
            }
            @media screen and (max-width: 576px){
                font-size: 34px;
                line-height: 46px;
            }
        }
        .title.right-align {
            left: 45%;
            @media screen and (max-width: 1040px){
                left: 10%;
            }
        }
        .title.center-align {
            left: 0;
            right: 0;
            bottom: 45%;
            text-align: center;
            &.bottom-align {
                bottom: 25%;
            }
        }
        .subtitle {
            position: absolute;
            bottom: 35%;
            left: 10%;
            color: white;
            font-family: 'Montserrat';
            font-size: 20px;
            line-height: 30px;
            z-index: 1;
            @media screen and (max-width: 768px){
                bottom: 30%;
            }
            @media screen and (max-width: 576px){
                bottom: 15%;
                font-size: 16px;
                line-height: 26px;
            }
        }
        .subtitle.right-align {
            left: 45%;
            @media screen and (max-width: 1040px){
                left: 10%;
            }
        }
        .subtitle.center-align {
            left: 0;
            right: 0;
            bottom: unset;
            top: 55%;
            text-align: center;
            &.bottom-align {
                top: 75%;
            }
        }

        .slides {
            height: 100%;
            width: 100%;
            overflow: hidden;

            .slick-list {
                height: 100%;

                .slick-track {
                    height: 100%;
                }
            }

            .slide {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                height: 100%;
            }
            .slick-dots {
                bottom: 40px;

                @media screen and (max-width: 576px){
                    bottom: unset;
                    top: 20px;
                    left: 20px;
                    text-align: left;
                }

                li.slick-active button:before {
                    color: white !important;
                    font-size: 46px !important;
                }
                li button:before {
                    color: white !important;
                    font-size: 46px !important;
                }
            }
        }
    }
    .cms-section-wrapper.top,
    .cms-section-wrapper.bottom {
        .cms-section {
            position: relative;
            z-index: 10;
        }
    }
    .cms-section-wrapper.grey {
        background-color: #efefef;
    }
    .cms-section-wrapper.light-grey {
        background-color: #f2f2f2;
    }
    .cms-section-wrapper.bordered {
        border-top: 2px solid #efefef;
        border-bottom: 2px solid #efefef;
    }
    .border {
        height: 2px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background-color: #efefef;
        @media screen and (max-width: 1240px){
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-right: 20px;
        }
    }
    .no-padding-top {
        padding-top: 1px !important;
    }
    .no-padding-bottom {
        padding-top: 0px !important;
    }
    .cms-section {
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        .title {
            font-family: 'Montserrat';
            text-transform: uppercase;
            font-weight: 800;
            font-size: 28px;
            margin-top: 20px;
            margin-bottom: 20px;

            &.italic {
                font-family: 'Droid Serif';
                font-style: italic;
                color: #666;
                font-weight: 500;
                margin-top: 80px;
                text-transform: unset;
                font-size: 32px;
            }
        }
        .subtitle {
            font-family: 'Droid Serif';
            font-style: italic;
            max-width: 70%;
            margin: 0 auto;
            font-size: 16px;
            color: #777;
            line-height: 28px;
            margin-bottom: 60px;
            @media screen and (max-width: 576px){
                max-width: 100%;
                padding-left: 20px;
                padding-right: 20px;
            }
            &.wider {
                max-width: 70%;
                @media screen and (max-width: 576px){
                    max-width: 100%;
                }
            }
            a {
                color: $primary-yellow;
            }
        }
        .text {
            column-count: 2;
            text-align: left;
            column-gap: 50px;
            font-size: 18px;
            font-family: 'Droid Serif';
            color: #777;
            line-height: 32px;
            @media screen and (max-width: 1280px){
                padding-left: 20px;
                padding-right: 20px;
            }
            @media screen and (max-width: 768px){
                column-count: 1;
                padding-left: 20px;
                padding-right: 20px;
                font-size: 16px;
                line-height: 28px;
            }
        }
        .cto {
            img {
                margin-top: 70px;
                margin-bottom: 10px;
                mix-blend-mode: multiply;
            }
            span {
                font-family: 'Montserrat';
                text-transform: uppercase;
                color: black;
                font-weight: 800;
                font-size: 16px;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    .quote {
        font-family: 'Droid Serif';
        font-style: italic;
        font-size: 36px;
        color: #777;
        margin-left: 20%;
        text-indent: -40%;
        @media screen and (max-width: 768px){
            text-indent: unset;
            margin-left: 20px;
            margin-right: 20px;
        }
        @media screen and (max-width: 576px){
            text-indent: unset;
            margin-left: 20px;
            margin-right: 20px;
            font-size: 30px;
            text-align: left;
        }
    }
    .cms-section.full-width {
        max-width: 1440px;
    }
    .cms-section-wrapper.top {
        position: relative;

        &:before {
            content: "";
            background-image: url(../images/cms_pages/paper_top_left.png);
            background-position-y: top;
            background-position-x: left;
            height: 100%;
            width: 215px;
            position: absolute;
            background-repeat: no-repeat;
            left: 0;
            top: 0;
            @media screen and (max-width: 576px){
                background-size: 30%;
            }
        }
        &:after {
            content: "";
            background-image: url(../images/cms_pages/paper_top_right.png);
            background-position-y: top;
            background-position-x: right;
            height: 100%;
            width: 215px;
            position: absolute;
            background-repeat: no-repeat;
            right: 0;
            top: 0;
            @media screen and (max-width: 576px){
                background-size: 50%;
            }
        }
    }
    .cms-section-wrapper.bottom {
        position: relative;

        &:before {
            content: "";
            background-image: url(../images/cms_pages/paper_top_right.png);
            background-position-y: top;
            background-position-x: right;
            transform: rotate(180deg);
            height: 100%;
            width: 215px;
            position: absolute;
            background-repeat: no-repeat;
            left: 0;
            bottom: 0;
            @media screen and (max-width: 576px){
                background-size: 50%;
            }
        }
        &:after {
            content: "";
            background-image: url(../images/cms_pages/paper_top_left.png);
            background-position-y: top;
            background-position-x: left;
            transform: rotate(180deg);
            height: 100%;
            width: 215px;
            position: absolute;
            background-repeat: no-repeat;
            right: 0;
            bottom: 0;
            @media screen and (max-width: 576px){
                background-size: 30%;
            }
        }

    }
    .cms-section-wrapper.top-hor {
        position: relative;

        .cms-section, .cms-split-section {
            position: relative;
            z-index: 10;
        }

    }
    .cms-section-wrapper.bottom-hor {
        position: relative;

        .cms-section, .cms-split-section {
            position: relative;
            z-index: 10;
        }
        &:after {
            content: '';
            z-index: 1;
            background-image: url(../images/cms_pages/content-vintage-oben.jpeg);
            background-size: contain;
            background-position-y: bottom;
            height: 100%;
            width: 100%;
            position: absolute;
            background-repeat: no-repeat;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }
    .cms-section-wrapper.top-hor {
        position: relative;

        .cms-section, .cms-split-section {
            position: relative;
            z-index: 10;
        }
        &:after {
            content: '';
            z-index: 1;
            background-image: url(../images/cms_pages/content-vintage-unten.jpeg);
            background-size: contain;
            background-position-y: top;
            height: 100%;
            width: 100%;
            position: absolute;
            background-repeat: no-repeat;
            right: 0;
            left: 0;
            top: 0;
        }
    }
    .cms-split-section {
        display: flex;
        flex-direction: row;
        padding-top: 100px;
        padding-bottom: 100px;
        justify-content: flex-start;
        align-items: center;
        padding-right: 100px;
        padding-left: 100px;
        @media screen and (max-width: 992px){
            padding-right: 85px;
            padding-left: 85px;
        }
        @media screen and (max-width: 768px){
            padding-left: 85px;
            padding-right: 85px;
        }
        @media screen and (max-width: 576px){
            padding-left: 20px;
            padding-right: 20px;
        }

        &.odd {

            @media screen and (max-width: 768px){
                padding-top: 60px;
                flex-direction: column;
            }
            .left {
                margin-right: 120px;
                flex-basis: 30%;
                flex-grow: 2;
                @media screen and (max-width: 992px){
                    margin-right: 40px;
                }
                @media screen and (max-width: 768px){
                    order: 1;
                    margin: 0 auto;
                    .image {
                        text-align: center;
                    }
                }
            }
            .right {
                flex-basis: 60%;
                flex-grow: 1;
                @media screen and (max-width: 768px){
                    order: 0;
                }
            }
        }
        &.even {

            @media screen and (max-width: 768px){
                padding-top: 60px;
                flex-direction: column;
            }
            .left {
                flex-basis: 60%;
                flex-grow: 1;
                @media screen and (max-width: 768px){
                    order: 0;
                }
            }
            .right {
                margin-left: 120px;
                flex-basis: 30%;
                flex-grow: 2;
                @media screen and (max-width: 992px){
                    margin-left: 40px;
                }
                @media screen and (max-width: 768px){
                    order: 1;
                    margin: 0 auto;
                    .image {
                        text-align: center;
                    }
                }
            }
        }
        &.brennerei-section {
            padding-top: 0;
            padding-bottom: 60px;
            .left {
                flex-basis: 50% !important;
            }
        }

        .left {
            width: 100%;
        }
        .right {

        }
        .block {
            font-size: 18px;
            font-family: 'Droid Serif';
            color: #777;
            line-height: 32px;
            margin-bottom: 40px;

            .title {
                text-align: center;
                font-size: 32px;
                font-style: italic;
                margin-bottom: 30px;
                color: #666;
            }
            .content {
                hyphens: auto;
            }
        }
    }
    .brennerei-title {
        text-align: left !important;
        color: #333 !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        text-transform: uppercase !important;
        font-weight: 800 !important;
        font-size: 28px !important;

        &.center-align {
            text-align: center !important;
        }
    }

    .light-grey {
        .cms-split-section {
            padding-top: 0;
            padding-bottom: 100px;

            &.odd {
                .left {
                    flex-basis: 50%;
                }
            }
            &.even {
                .right {
                    flex-basis: 50%;
                }
            }
            &.no-padding-bottom {
                padding-bottom: 0;
            }
            .block {
                .title {
                    color: #333;
                    font-style: normal;
                    text-transform: uppercase;
                    font-size: 22px;
                    font-weight: 800;
                }
            }
        }
    }
    .grey-box-outline {
        border: 1px solid #eee;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: -20px;
        max-width: 1050px;
        margin: 0 auto;
        margin-top: 60px;
        @media screen and (max-width: 576px){
            margin-left: 40px;
            margin-right: -20px;
            padding-bottom: 50px;
            z-index: 10;
            position: relative;
        }

        .grey-box {
            background-color: #f2f2f2;
            padding: 100px;
            padding-top: 80px;
            padding-bottom: 80px;
            column-count: 1;
            column-gap: 50px;
            font-size: 18px;
            font-family: 'Droid Serif';
            color: #777;
            line-height: 32px;
            text-align: center;
            transform: translateY(25px);
            @media screen and (max-width: 576px){
                margin-left: -47px;
                margin-right: 13px;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 40px;
                padding-bottom: 40px;
                text-align: left;
                font-size: 16px;
                line-height: 28px;
            }

            p {
                margin-bottom: 30px;
            }

            a {
                color: $primary-yellow;
            }
        }
    }
    .grey-box-block {
        display: flex;
        justify-content: space-between;
        max-height: 630px;
        height: 47vw;
        margin-top: -100px;
        @media screen and (max-width: 576px){
            flex-direction: column;
            max-height: unset;
            height: unset;
        }

        .image:first-of-type {
            align-self: flex-start;
            margin-right: 20px;
            @media screen and (max-width: 576px){
                margin-right: 0;
                margin-left: 20px;
                transform: translate(50px, -40px);
                z-index: 1;
                position: relative;
            }
        }
        .image:nth-of-type(2) {
            align-self: flex-end;
            @media screen and (max-width: 576px){
                margin-left: 20px;
                margin-right: 20px;
                margin-top: -10px;
            }
        }
    }
}

.page-layout-legal_pages_page_layout {
    .cms-page-main {
        .cms-section-wrapper {
            .cms-section {
                text-align: center;
                font-family: 'Droid Serif' !important;
                font-size: 16px !important;
                color: #777 !important;
                line-height: 28px !important;
                max-width: 1000px;
                @media screen and (max-width: 1040px){
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .title {
                    color: black !important;
                }

                h1 {
                    font-family: 'Montserrat' !important;
                    text-transform: uppercase !important;
                    font-weight: 800 !important;
                    font-size: 28px !important;
                    margin-top: 20px;
                    margin-bottom: 40px;
                    text-align: center !important;
                    color: black !important;
                    a {
                        color: $primary-yellow !important;
                        &:hover {
                            color: $primary-yellow !important;
                        }
                    }
                }
                h2, h3, h4, h5 {
                    text-align: left !important;
                    font-family: 'Montserrat' !important;
                    text-transform: uppercase !important;
                    color: black !important;
                    font-weight: 800 !important;
                    a {
                        color: $primary-yellow !important;
                        &:hover {
                            color: $primary-yellow !important;
                        }
                    }
                    p, span {
                        color: black !important;
                        font-weight: 800 !important;
                        a {
                            color: $primary-yellow !important;
                            &:hover {
                                color: $primary-yellow !important;
                            }
                        }
                    }
                }
                p, span {
                    text-align: left !important;
                    font-family: 'Droid Serif' !important;
                    font-size: 16px !important;
                    color: #777 !important;
                    line-height: 28px !important;
                    a {
                        color: $primary-yellow !important;
                        &:hover {
                            color: $primary-yellow !important;
                        }
                    }
                }
                table, ul {
                    text-align: left !important;
                    a {
                        color: $primary-yellow !important;
                        &:hover {
                            color: $primary-yellow !important;
                        }
                    }
                }
                ul {
                    li {
                        a {
                            color: $primary-yellow !important;
                            &:hover {
                                color: $primary-yellow !important;
                            }
                        }
                        span, p {
                            a {
                                color: $primary-yellow !important;
                                &:hover {
                                    color: $primary-yellow !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
