@font-face {
    font-family: 'Droid Serif';
    src: url(../fonts/DroidSerif-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Droid Serif';
    src: url(../fonts/DroidSerif-Bold.ttf);
    font-weight: 800;
}
@font-face {
    font-family: 'Droid Serif';
    src: url(../fonts/DroidSerif-Italic.ttf);
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Droid Serif';
    src: url(../fonts/DroidSerif-BoldItalic.ttf);
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Medium.ttf);
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Bold.ttf);
    font-weight: 700;
}