//
//  Variables
//  _____________________________________________

$active-nav-indent: 0px;

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    @include lib-css(background, $navigation__background);
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size        : 28px,
        $_icon-font-color       : $header-icons-color,
        $_icon-font-color-hover : $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    left: 50px;
    top: 58px;
    z-index: 14;
}

.nav-close {
    @include lib-icon-font(
        $icon-remove,
        $_icon-font-size        : 45px,
        $_icon-font-color       : $header-icons-color,
        $_icon-font-color-hover : $header-icons-color-hover
    );
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    left: 41px;
    top: 43px;
    z-index: 14;

    &:before {
        color: $color-white;
    }


}


//
//  Mobile
//  _____________________________________________


.navigation {
    padding: 0;
    text-align: center;


    .parent {
        .level-top {
            position: relative;
            @include lib-icon-font(
                $_icon-font-content  : $icon-down,
                $_icon-font-size     : 42px,
                $_icon-font-position : after,
                $_icon-font-display  : block
            );

            &:after {
                position: absolute;
                right: 7px;
                top: -8px;
            }

            &.ui-state-active {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }
    }

    .level0 {
        font-size: 2.6rem !important;
        border: none !important;
    }

    a {
        border: none !important;
        padding: 10px 0 10px 0px !important;
    }
}

.nav-sections {
    -webkit-overflow-scrolling: touch;
    @include lib-css(transition, left 0.3s, 1);
    height: 100%;
    left: -100%;
    left: calc(-1 * (100% - #{$active-nav-indent}));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    width: calc(100% - #{$active-nav-indent});

    .switcher {
        border-top: 1px solid $color-gray82;
        font-size: 1.6rem;
        font-weight: 700;
        margin: 0;
        padding: 0.8rem 3.5rem 0.8rem 2rem;

        .label {
            display: block;
            margin-bottom: $indent__xs;
        }

        &:last-child {
            border-bottom: 1px solid $color-gray82;
        }
    }

    .switcher-trigger {
        strong {
            position: relative;
            @include lib-icon-font(
                $_icon-font-content  : $icon-down,
                $_icon-font-size     : 42px,
                $_icon-font-position : after,
                $_icon-font-display  : block
            );

            &:after {
                position: absolute;
                right: -40px;
                top: -16px;
            }
        }

        &.active strong {
            @include lib-icon-font-symbol(
                $_icon-font-content  : $icon-up,
                $_icon-font-position : after
            );
        }
    }

    .switcher-dropdown {
        @include lib-list-reset-styles();
        padding: $indent__s 0;
    }

    .header.links {
        @include lib-list-reset-styles();
        border-bottom: 1px solid $color-gray82;

        li {
            font-size: 1.6rem;
            margin: 0;

            &.greet.welcome {
                border-top: 1px solid $color-gray82;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            > a {
                border-top: 1px solid $color-gray82;
            }
        }

        a,
        a:hover {
            @include lib-css(color, $navigation-level0-item__color);
            @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
            display: block;
            font-weight: 700;
            padding: 0.8rem $indent__base;
        }

        .header.links {
            border: 0;
        }
    }
}

.nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%;

    .page-wrapper {
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: 0;
        overflow: hidden;
        position: relative;
    }

    body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}

.nav-open {
    .page-wrapper {
        left: 80%;
        left: calc(100% - #{$active-nav-indent});
    }

    .nav-sections {
        $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

        @include lib-css(box-shadow, $_shadow, 1);
        left: 0;
        z-index: 99;
    }

    .nav-toggle {
        &:after {
            background: rgba(0, 0, 0, $overlay__opacity);
            content: '';
            display: block;
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }
}

.nav-sections-items {
    @include lib-clearfix();
    position: relative;
    z-index: 1;
}

.nav-sections-item-title {
    display: none;
    background: darken($navigation__background, 5%);
    border: solid darken($navigation__background, 10%);
    border-width: 0 0 1px 1px;
    box-sizing: border-box;
    float: left;
    height: 71px;
    padding-top: 24px;
    text-align: center;
    width: 33.33%;

    &.active {
        background: transparent;
        border-bottom: 0;
    }

    .nav-sections-item-switch {
        &:hover {
            text-decoration: none;
        }
    }
}

.nav-sections-item-content {
    @include lib-clearfix();
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    /* margin-top: 71px; */
    width: 100%;

    &.active {
        display: block;
    }

    padding: $indent__m 0;
}

@include lib-main-navigation();


